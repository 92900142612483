import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { RingLoader } from 'react-spinners';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from './Button';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Base_Uri } from '../Constant/BaseUri';


export default function CreditWarningModal({ heading, placeholder, text, emailLoading, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, loading, credit, onChange, emailbutton, emailInvoice }) {


    const userData = useSelector((state) => state.loginReducer.updatedState);


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { lg: "70%", md: "80%", sm: "90%", xs: "98%" },
        borderRadius: "10px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    };


    const [isConfirmCredentials, setIsConfirmCredentials] = React.useState(false)
    const [reason, setReason] = React.useState("")
    const [creditDays, setCreditDays] = React.useState("")


    const [userCredentials, setUserCredentials] = React.useState({
        employeeId: "",
        password: ""
    })



    const handleConfirmCredentials = async () => {

        if (!userCredentials?.employeeId) {
            alert("Employee Id is missing")
            return
        }

        if (!userCredentials?.password) {
            alert("Password is missing")
            return
        }


        if (userData?.employeeId !== userCredentials?.employeeId) {
            alert("Invalid Employee Id")
            return
        }


        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;


        let dataToSend = {

            employeeId: userCredentials?.employeeId,
            password: userCredentials?.password

        }

        axios.post(`${Base_Uri}confirmEmployeeCredentials`, dataToSend, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {

            let data = res.data

            if (!data?.status) {

                alert(data?.message)
                return

            }

            setIsConfirmCredentials(true)

        }).catch((error) => {

            alert(error?.message)
            return

        })

    }

    const handleConfirmInvoice = () => {

        if (!isConfirmCredentials) {
            alert("kindly confirm user credentials")
            return
        }

        if (!reason) {
            alert("kindly confirm reason")
            return
        }

        // if(!creditDays){
        //     alert("credit days are missing")
        //     return
        // }


        confirm(reason,creditDays)




    }


    return (
        <div>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[style, { containerStyle }]}>

                    <Box onClick={() => closeModal()} style={{ position: "absolute", top: 10, right: 10 }} >
                        <CloseIcon fontSize="large" />
                    </Box>


                    <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                        {heading}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", mt: 1, padding: "5px 10px 5px 10px ", textAlign: "center", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                        {text}
                    </Typography>

                    {!isConfirmCredentials ? <Box>
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            onChange={(e) => setUserCredentials({ ...userCredentials, employeeId: e.target.value })}

                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                                fontSize: "12px",
                                style: {
                                    textAlign: "center",
                                    fontSize: "16px",
                                    alignSelf: "center",
                                },
                            }}
                            inputProps={{
                                disableUnderline: true,
                                fontSize: "12px",

                            }}
                            placeholder={"Enter Employee Id"}
                            sx={{
                                width: "50%",
                                // height: width < 600 ? "25px" : "35px",
                                fontSize: "12px",
                                padding: 1,
                                border: `1px solid ${Colors.border}`,
                                borderRadius: "5px",
                                marginBottom: 2,
                                textAlign: "center", // Move textAlign to sx
                                fontFamily: "Poppins",
                                fontWeight: "bold",

                            }}
                        />
                        <Box>
                            <TextField
                                variant="standard"
                                margin="none"
                                required
                                onChange={(e) => setUserCredentials({ ...userCredentials, password: e.target.value })}

                                fullWidth
                                InputProps={{
                                    disableUnderline: true,
                                    fontSize: "12px",
                                    style: {
                                        textAlign: "center",
                                        fontSize: "16px",
                                        alignSelf: "center",
                                    },
                                }}
                                type="password"
                                inputProps={{
                                    disableUnderline: true,
                                    fontSize: "12px",

                                }}
                                placeholder={"Enter Password"}
                                sx={{
                                    width: "50%",
                                    // height: width < 600 ? "25px" : "35px",
                                    fontSize: "12px",
                                    padding: 1,
                                    border: `1px solid ${Colors.border}`,
                                    borderRadius: "5px",
                                    marginBottom: 2,
                                    textAlign: "center", // Move textAlign to sx
                                    fontFamily: "Poppins",
                                    fontWeight: "bold",

                                }}
                            />
                        </Box>
                    </Box> :

                        <Box>
                            <Box>
                                <TextField
                                    variant="standard"
                                    margin="none"
                                    required
                                    multiline={true}
                                    numberOfLines={5}
                                    onChange={(e) => setReason(e?.target?.value)}
                                    value={reason}
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                        fontSize: "12px",
                                        style: {
                                            textAlign: "center",
                                            fontSize: "16px",
                                            alignSelf: "center",
                                        },
                                    }}
                                    type="text"
                                    inputProps={{
                                        disableUnderline: true,
                                        fontSize: "12px",

                                    }}
                                    placeholder={"Enter Reason"}
                                    sx={{
                                        width: "50%",
                                        // height: width < 600 ? "25px" : "35px",
                                        fontSize: "12px",
                                        padding: 1,
                                        border: `1px solid ${Colors.border}`,
                                        borderRadius: "5px",
                                        height: 200,
                                        marginBottom: 2,
                                        textAlign: "center", // Move textAlign to sx
                                        fontFamily: "Poppins",
                                        fontWeight: "bold",

                                    }}
                                />
                            </Box>

                            {/* <TextField
                                variant="standard"
                                margin="none"
                                required
                                
                                onChange={(e) => setCreditDays(e?.target?.value)}
                                value={creditDays}
                                fullWidth
                                InputProps={{
                                    disableUnderline: true,
                                    fontSize: "12px",
                                    style: {
                                        textAlign: "center",
                                        fontSize: "16px",
                                        alignSelf: "center",
                                    },
                                }}
                                type="number"
                                inputProps={{
                                    disableUnderline: true,
                                    fontSize: "12px",

                                }}
                                placeholder={"Enter Credit Days"}
                                sx={{
                                    width: "50%",
                                    // height: width < 600 ? "25px" : "35px",
                                    fontSize: "12px",
                                    padding: 1,
                                    border: `1px solid ${Colors.border}`,
                                    borderRadius: "5px",
                                    // height: 200,
                                    marginBottom: 2,
                                    textAlign: "center", // Move textAlign to sx
                                    fontFamily: "Poppins",
                                    fontWeight: "bold",

                                }}
                            /> */}

                        </Box>

                    }





                    {!isConfirmCredentials && <CustomButton onClick={() => handleConfirmCredentials()} text="Confirm Credentails" style={{ width: "50%", padding: 1, fontSize: "12px", borderRadius: 1, marginBottom: "10px" }} />
                    }


                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: "10px", ...buttonContainerStyle }} >

                        <Button onClick={closeModal} sx={{ backgroundColor: Colors.white, color: Colors.button, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", ...buttonStyle }} >
                            Cancel
                        </Button>


                        <Button onClick={() => handleConfirmInvoice()} sx={{
                            backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", '&:hover': {
                                color: Colors.button, // Set your desired hover color
                            }, ...buttonStyle2
                        }} >
                            {loading ? <RingLoader size={30} loading={loading} /> : "Confirm"}
                        </Button>


                    </Box>



                </Box>



            </Modal>
        </div>
    );
}