import { Box, Button, Grid, TextField, Typography, alpha, colors } from '@mui/material';
import React, { useMemo, useRef, useState, useCallback, useEffect } from 'react';
import { Colors } from '../../../Constant/Color';
import BasicSelect from '../../../Components/Dropdown';
import { HexColorPicker } from "react-colorful";
import { useDropzone } from 'react-dropzone'
import { useNavigate, useNavigation } from 'react-router-dom';
import CheckBox from '../../../Components/checkbox';
import { DatePicker } from '@mui/x-date-pickers';
import { useStaticRangePicker } from '@mui/x-date-pickers-pro/internals/hooks/useStaticRangePicker';
import DatePickers from '../../../Components/DatePicker';
import CustomButton from '../../../Components/Button';
import axios from 'axios';
import { Base_Uri } from '../../../Constant/BaseUri';
import { RingLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { updateCustomers } from '../../../Redux/LoginReduces';
import CustomModal from '../../../Components/modal';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Document, Page, Text, View, PDFViewer, PDFDownloadLink, Font } from '@react-pdf/renderer';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


import MyFontRegular from '../../../fonts/Poppins-Medium.ttf';
import MyFontBold from '../../../fonts/Poppins-Bold.ttf';


function CustomerAccountDetails() {


  Font.register({
    family: 'MyFont',
    fonts: [
      { src: MyFontBold, fontWeight: 'bold' },
      { src: MyFontRegular, fontWeight: 'normal' },
    ]
  });


  const dispatch = useDispatch()

  const customers = useSelector(state => state.loginReducer.customers)
  const vat = useSelector(state => state?.loginReducer?.vat)
  const userData = useSelector((state) => state.loginReducer.updatedState);

  const [pdfDownloadView, setPdfDownloadView] = useState(false)

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)





  const getCustomers = async () => {


    let token = await localStorage.getItem('userData')
    token = JSON.parse(token)

    axios.get(`${Base_Uri}getCustomers`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Include other headers as needed
      },
    }).then((res) => {

      let { data } = res

      if (data.status) {

        let myData = [...data?.data]

        let sortedData = myData && myData?.length > 0 && myData?.sort((a, b) => a?.accountNo - b?.accountNo)

        dispatch(updateCustomers(sortedData && sortedData?.length > 0 ? sortedData : []))
        setAllAccounts(sortedData && sortedData?.length > 0 ? sortedData : [])
      }


    }).catch((error) => {


      alert(error.message)

      console.log(error, "error")



    })


  }


  useEffect(() => {
    getCustomers()
  }, [])



  const Header = () => {

    return (
      <View fixed >

        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10, alignItems: "flex-start" }} >
          <View>
            <Text style={{ color: "black", fontSize: "14px", fontFamily: "MyFont", fontWeight: "normal" }} >{vat?.companyName}</Text>
            <Text style={{ color: "black", fontSize: "12px", fontFamily: "MyFont", fontWeight: "normal" }} >{vat?.companyAddress}</Text>
            <Text style={{ color: "black", fontSize: "12px", fontFamily: "MyFont", fontWeight: "normal" }} >{vat?.mobileNumber}</Text>
          </View>

          <View>
            <Text style={{ color: "black", fontSize: "12px", fontFamily: "MyFont", fontWeight: "normal" }} >
              Print Date:   {
                new Date().toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              }



              <Text style={{ marginLeft: "5px" }} >  {new Date().toLocaleTimeString()}
              </Text>
            </Text>





          </View>


        </View>


        <View style={{ flexDirection: "row", borderBottomWidth: 1, borderColor: '#000', paddingBottom: 5, paddingTop: 5, border: "1px solid black", backgroundColor: "gray", alignItems: "center", padding: 10 }}>
          <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold" }}>Account No</Text>
          </View>
          <View style={{ flex: 2 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold" }}>Customer Name</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold" }}>Invoice Balance</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold" }}>Quotation Balance</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold" }}>Account Balance</Text>
          </View>
        </View>

      </View>
    )
  }

  const MyDocument = () => (
    <Document size="A4"  >

      {/* <Header /> */}

      <Page  >

        <Header />
        {/* Data Rows */}
        {customers.map((item, index) => (
          <View key={index} style={{ flexDirection: "row", borderBottomWidth: 1, borderColor: '#000', padding: "3px 10px" }}>
            <View style={{ flex: 1 }}>
              <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item.accountNo}</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item.customerName}</Text>
            </View>
            {(selectedSummaryType?.toLowerCase() == "invoice" || selectedSummaryType?.toLowerCase() == "both") ? <View style={{ flex: 1 }}>
              <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>£{Number(item.creditBalance).toFixed(2)}</Text>
            </View> :

              <View style={{ flex: 1 }}>
              </View>
            }
            {(selectedSummaryType?.toLowerCase() == "quotation" || selectedSummaryType?.toLowerCase() == "both") ? <View style={{ flex: 1 }}>
              <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>£{Number(item.quotationBalance).toFixed(2)}</Text>
            </View> :
              <View style={{ flex: 1 }}>
              </View>
            }
            <View style={{ flex: 1 }}>
              <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>£{Number(item.quotationBalance + item?.creditBalance).toFixed(2)}</Text>
            </View>
          </View>
        ))}




        <View style={{ flexDirection: "row", borderBottomWidth: 1, borderColor: '#000', paddingBottom: 5, paddingTop: 5, border: "1px solid black", alignItems: "center", padding: 10 }}>
          <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold" }}>Total</Text>
          </View>
          <View style={{ flex: 2 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold" }}></Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold" }}>

              £{Number(customers?.reduce((pre, cur) => {
                return (Number(pre) || 0) + (Number(cur?.creditBalance) || 0);
              }, 0)).toFixed(2)}

            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold" }}>


              £{Number(customers?.reduce((pre, cur) => {
                return (pre || 0) + (Number(cur?.quotationBalance) || 0);
              }, 0)).toFixed(2)}

            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold" }}>

              £{Number(customers?.reduce((pre, cur) => {
                return (pre || 0) + (Number(cur?.quotationBalance) || 0);
              }, 0) +
                customers?.reduce((pre, cur) => {
                  return (pre || 0) + (Number(cur?.creditBalance) || 0);
                }, 0)
              ).toFixed(2)}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );



  const screenWidth = window.innerWidth


  const navigate = useNavigate()

  const [email, setEmail] = useState("")

  const [searchText, setSearchText] = useState("")
  const [allAccounts, setAllAccounts] = useState([])
  const [searchAccount, setSearchAccount] = useState([])
  const [isCompanyAddress, setIsCompanyAddress] = useState({
    selected: false
  })
  const [byInvoiceNumber, setByInvoiceNumber] = useState({
    selected: false
  })

  const [summaryType, seySummaryType] = useState([
    "invoice",
    "quotation",
    "Both"

  ])
  const [selectedSummaryType, setSelectedSummaryType] = useState("Both")


  const [stockCategory, setStockCategory] = useState([
    {
      name: "Amazon",
      selected: false,
      id: 1
    },
    {
      name: "Ebay",
      selected: false,
      id: 2
    },
    {
      name: "Website",
      selected: false,
      id: 3
    },
    {
      name: "Physical Store",
      selected: false,
      id: 4
    },
    {
      name: "Online",
      selected: false,
      id: 5
    },
  ])

  const [priceLevel, setPriceLevel] = useState([
    {
      name: "Price Level A (Trader)",
      selected: false,
      id: 1
    },
    {
      name: "Warehouse Price",
      selected: false,
      id: 2
    },
    {
      name: "Retail Price (R)",
      selected: false,
      id: 3
    },
  ])


  const [compatilityData, setCompatibilityData] = useState([
    'A', 'B', 'C'
  ])

  const [options, setOptions] = useState([

    {
      option: "Created by",
      selected: false
    },
    {
      option: "Updated by",
      selected: false
    },
    {
      option: "Delivery Address",
      selected: false
    },
    // {
    //     option: "Contact Persons",
    //     selected: false
    // },
    {
      option: "Comments",
      selected: true
    },
    {
      option: "Email/Other Details",
      selected: false
    },
    // {
    //     option: "VAT Rate",
    //     selected: false
    // },
  ])

  const [loading, setLoading] = useState({
    save: false,
    delete: false,
  })

  const formatDate = (date) => {
    const day = date.getDate() + 1;
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const [customerDetails, setCustomerDetails] = useState({

    customerName: "",
    created_at: formatDate(new Date()),
    address: "",
    city: "",
    email: "",
    postalCode: "",
    telephoneNo: "",
    businessName: "",
    accountNo: "",
    telephoneNo2: "",
    mobileNumber: "",
    accountManager: "select",
    orderLocation: [],
    creditLimits: "",
    creditDays: "",
    discount: "",
    deliveryAddress: "",
    employeeDetails: "",
    deliveryCity: "",
    deliveryPostalCode: "",
    openingInvoiceBalance: "",
    openingQuotationBalance: "",
    priceLevel: []
  })


  const [emailText, setEmailText] = useState("")
  const [emailSubject, setEmailSubject] = useState("")





  useEffect(() => {

    setAllAccounts(customers)

  }, [customers])





  const selectStockCategory = (ind) => {

    setStockCategory(stockCategory.map((e, i) => {
      if (ind == i) {
        return {
          ...e,
          selected: e?.selected ? false : true
        }
      } else {
        return e
      }
    }))
  }

  const selectPriceLevel = (ind) => {

    setPriceLevel(priceLevel.map((e, i) => {
      if (ind == i) {
        return {
          ...e,
          selected: e?.selected ? false : true
        }
      } else {
        return {
          ...e,
          selected: false
        }
      }
    }))

  }

  const selectByInvoiceNumber = () => {


    setByInvoiceNumber({
      ...byInvoiceNumber,
      selected: !byInvoiceNumber?.selected
    })


  }

  const selectOption = (ind) => {

    setOptions(options.map((e, i) => {
      if (ind == i) {
        return {
          ...e,
          selected: true
        }
      } else {
        return {
          ...e,
          selected: false
        }
      }
    }))

  }


  const selectCustomer = (e, ind) => {

    let selectedData = allAccounts.map((e, i) => {
      if (ind == i) {
        return {
          ...e,
          selected: !e.selected
        }
      } else {
        return {
          ...e,
          selected: false
        }
      }
    })

    setAllAccounts(selectedData)

    const anySelected = selectedData.some(acc => acc.selected);

    if (anySelected) {

      setCustomerDetails(e)

      const updatedPriceLevels = priceLevel.map(priceLevel => {
        const matchingProperty = e?.priceLevel?.find(prop => prop.id === priceLevel.id);

        if (matchingProperty && matchingProperty.selected) {
          // Update the price based on your logic
          return { ...priceLevel, selected: true }; // Update the price, for example, doubling it
        } else {
          return {
            ...priceLevel,
            selected: false

          }; // No update needed
        }

      })



      const updatedOrderLocation = stockCategory.map(priceLevel => {
        const matchingProperty = e?.orderLocation?.find(prop => prop.id === priceLevel.id);

        if (matchingProperty && matchingProperty.selected) {
          // Update the price based on your logic
          return { ...priceLevel, selected: true }; // Update the price, for example, doubling it
        } else {
          return {
            ...priceLevel,
            selected: false
          }; // No update needed
        }

      })

      setPriceLevel(updatedPriceLevels)
      setStockCategory(updatedOrderLocation)

    } else {
      setCustomerDetails({

        customerName: "",
        created_at: formatDate(new Date()),
        address: "",
        city: "",
        email: "",
        postalCode: "",
        telephoneNo: "",
        telephoneNo2: "",
        mobileNumber: "",
        businessName: "",
        accountNo: "",
        accountManager: "select",
        orderLocation: [],
        creditLimits: "",
        creditDays: "",
        discount: "",
        deliveryAddress: "",
        deliveryCity: "",
        deliveryPostalCode: "",
        priceLevel: []
      })
    }

  }

  const handleAddCustomer = async () => {



    if (customerDetails.id && allAccounts.some((e, i) => e.selected)) {


      let accountDetails = { ...customerDetails }

      accountDetails.updatedBy = userData


      var dateParts = customerDetails?.created_at?.split('/');

      var formattedDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);


      const selectedPriceLevel = priceLevel.filter((e, i) => e.selected)
      const selectedOrderLocation = stockCategory.filter((e, i) => e.selected)

      accountDetails.created_at = formattedDate

      accountDetails.priceLevel = selectedPriceLevel
      accountDetails.orderLocation = selectedOrderLocation
      accountDetails.accountManager = selectedPriceLevel?.[0]?.id == 1 ? "A" : selectedPriceLevel?.[0]?.id == 2 ? "B" : "C"



      if (!accountDetails?.businessName) {

        alert("Business Name is missing")
        return

      }


      if (!accountDetails?.accountNo) {

        alert("Account Number is missing")
        return
      }

      if (!accountDetails?.customerName) {
        alert("Customer Name is missing")
        return
      }


      if (!accountDetails?.address) {
        alert("Address is missing")
        return
      }


      if (!accountDetails?.city) {
        alert("City is missing")
        return
      }


      if (!accountDetails?.email) {
        alert("Email is missing")
        return
      }


      if (!accountDetails?.mobileNumber) {
        alert("Mobile Number is missing")
        return
      }

      if (!accountDetails?.creditLimits) {
        alert("Credit Limit is missing")
        return
      }

      if (!accountDetails?.creditDays) {
        alert("Credit Days are missing")
        return
      }

      if (!accountDetails?.priceLevel || accountDetails?.priceLevel?.length == 0) {
        alert("Price level is missing")
        return
      }

      setLoading({
        ...loading,
        save: true
      })

      let data = await localStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data

      axios.put(`${Base_Uri}updateCustomer`, accountDetails, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json', // Include other headers as needed
        },

      }).then((res) => {

        let { data } = res

        if (!data.status) {

          alert(data.message)
          setLoading({
            ...loading,
            save: false
          })
          return
        }

        let newAccounts = customers.map((e, i) => {

          if (e.id == data.data.id) {

            return {
              ...data.data,
              selected: true
            }

          } else {
            return e
          }
        })


        setAllAccounts(newAccounts)

        alert(data.message)

        setLoading({
          ...loading,
          save: false
        })

        dispatch(updateCustomers(newAccounts))

        setSearchText("")

        window.scrollTo({
          top: 50,
          behavior: "smooth"
        })


      }).catch((error) => {

        alert(error?.message)

        setLoading({
          ...loading,
          save: false
        })

      })



      return
    }

    const selectedPriceLevel = priceLevel.filter((e, i) => e.selected)
    const selectedOrderLocation = stockCategory.filter((e, i) => e.selected)


    let accountDetails = { ...customerDetails }

    accountDetails.employeeDetails = userData
    accountDetails.priceLevel = selectedPriceLevel
    accountDetails.orderLocation = selectedOrderLocation
    accountDetails.accountManager = selectedPriceLevel?.[0]?.id == 1 ? "A" : selectedPriceLevel?.[0]?.id == 2 ? "B" : "C"


    if (!accountDetails?.businessName) {

      alert("Business Name is missing")
      return

    }


    if (!accountDetails?.accountNo) {

      alert("Account Number is missing")
      return
    }

    if (!accountDetails?.customerName) {
      alert("Customer Name is missing")
      return
    }


    if (!accountDetails?.address) {
      alert("Address is missing")
      return
    }


    if (!accountDetails?.city) {
      alert("City is missing")
      return
    }


    if (!accountDetails?.email) {
      alert("Email is missing")
      return
    }


    if (!accountDetails?.mobileNumber) {
      alert("Mobile Number is missing")
      return
    }

    if (!accountDetails?.creditLimits) {
      alert("Credit Limit is missing")
      return
    }

    if (!accountDetails?.creditDays) {
      alert("Credit Days are missing")
      return
    }

    if (!accountDetails?.priceLevel || accountDetails?.priceLevel?.length == 0) {
      alert("Price level is missing")
      return
    }

    var formattedDate;
    if (accountDetails?.created_at) {
      var dateParts = accountDetails?.created_at?.split('/');
      formattedDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0] - 1);
    }

    accountDetails.created_at = formattedDate ? formattedDate : new Date()

    setLoading({
      ...loading,
      save: true
    })

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data

    axios.post(`${Base_Uri}createCustomer`, accountDetails, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Include other headers as needed
      },
    }).then((res) => {

      let { data } = res

      if (!data.status) {
        alert(data.message)

        setLoading({
          ...loading,
          save: false
        })
        return
      }

      if (data.status) {


        alert(data.message)

        let allCustomers = [...customers, data.data]

        getCustomers()
        dispatch(updateCustomers(allCustomers))

        window.scrollTo({
          top: 50,
          behavior: "smooth"
        })

        setCustomerDetails({

          customerName: "",
          created_at: formatDate(new Date()),
          address: "",
          city: "",
          email: "",
          postalCode: "",
          telephoneNo: "",
          telephoneNo2: "",
          mobileNumber: "",
          businessName: "",
          accountNo: "",
          accountManager: "select",
          orderLocation: [],
          creditLimits: "",
          creditDays: "",
          discount: "",
          deliveryAddress: "",
          creditBalance: "",
          quotationBalance: "",
          deliveryCity: "",
          deliveryPostalCode: "",
          priceLevel: []
        })


        setLoading({
          ...loading,
          save: false
        })
      }


    }).catch((error) => {


      setLoading({
        ...loading,
        save: false
      })


    })

  }


  const handleAddNewAccount = () => {

    setAllAccounts(allAccounts.length > 0 && allAccounts.map((e, i) => {

      return {
        ...e,
        selected: false
      }

    }))

    setCustomerDetails({

      customerName: "",
      created_at: formatDate(new Date()),
      address: "",
      city: "",
      email: "",
      postalCode: "",
      telephoneNo: "",
      telephoneNo2: "",
      mobileNumber: "",
      businessName: "",
      accountNo: "",
      accountManager: "select",
      orderLocation: [],
      creditLimits: "",
      creditDays: "",
      discount: "",
      deliveryAddress: "",
      employeeDetails: "",
      creditBalance: "",
      quotationBalance: "",
      deliveryCity: "",
      deliveryPostalCode: "",
      priceLevel: []
    })

    window.scrollTo({
      top: 50,
      behavior: 'smooth' // You can use 'auto' instead of 'smooth' for instant scrolling
    });


  }

  const handleDeleteCustomer = async () => {


    if (!allAccounts.some((e, i) => e.selected)) {

      alert("Kindly select Customer")
      return
    }

    setDeleteLoading(true)

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data


    axios.delete(`${Base_Uri}deleteCustomer/${customerDetails.id}`, {

      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Include other headers as needed
      },
    }

    ).then((res) => {

      let data = res.data

      if (!data.status) {
        alert(data.message)
        setDeleteLoading(false)
        return
      }

      let deletedData = data.data


      alert(data.message)

      let newUpdatedAccounts = customers.filter((e, i) => e.id !== deletedData.id)

      setAllAccounts(newUpdatedAccounts)
      setDeleteLoading(false)
      setOpenDeleteModal(false)
      dispatch(updateCustomers(newUpdatedAccounts))
      window.scrollTo({
        top: 50,
        behavior: 'smooth' // You can use 'auto' instead of 'smooth' for instant scrolling
      });

      setCustomerDetails({

        customerName: "",
        created_at: formatDate(new Date()),
        address: "",
        city: "",
        email: "",
        postalCode: "",
        telephoneNo: "",
        telephoneNo2: "",
        mobileNumber: "",
        businessName: "",
        accountNo: "",
        accountManager: "select",
        orderLocation: [],
        creditLimits: "",
        creditDays: "",
        discount: "",
        creditBalance: "",
        quotationBalance: "",
        deliveryAddress: "",
        deliveryCity: "",
        deliveryPostalCode: "",
        priceLevel: []
      })


    }).catch((error) => {

      alert(error.message)
      setDeleteLoading(false)

    })


  }

  const handleSetAsCompanyAbove = async () => {

    setIsCompanyAddress({ ...isCompanyAddress, selected: !isCompanyAddress.selected })

    if (!isCompanyAddress.selected) {



    }
    else {

      setCustomerDetails({
        ...customerDetails,
        deliveryAddress: customerDetails.deliveryAddress ? customerDetails.deliveryAddress : "",
        deliveryCity: customerDetails.deliveryCity ? customerDetails.deliveryCity : "",
        deliveryPostalCode: customerDetails.deliveryPostalCode ? customerDetails.deliveryPostalCode : ""
      })



    }

  }


  const handleSendEmail = async () => {


    if (!customerDetails?.email) {

      alert("This customer has no email address")
      return

    }



    if (!emailSubject) {

      alert("Kindly write Subject")
      return
    }

    if (!emailText) {

      alert("Kindly write email")
      return
    }




    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data


    let dataToSend = {
      to: customerDetails.email,
      body: emailText,
      subject: emailSubject,
      id: customerDetails.id,
      name: customerDetails?.customerName
    }

    axios.post(`${Base_Uri}sendEmailToCustomer`, dataToSend, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Include other headers as needed
      },
    }).then((res) => {

      let { data } = res

      if (!data.status) {
        alert(data.message)
        return
      }


      alert("Email Successfully send")
      setEmailSubject("")
      setEmailText("")



    }).catch((error) => {

      alert(error?.message)

    })





  }


  const handleSearchCustomer = (e) => {

    let text = e.target.value

    setSearchText(text)


    if (!text) {
      setAllAccounts(customers)
      return
    }

    if (byInvoiceNumber.selected) {

      setAllAccounts(customers && customers.length > 0 && customers.filter((e, i) => {


        if (e?.accountNo == text) {

          return e
        }



      }))

      return


    }

    setAllAccounts(customers && customers.length > 0 && customers.filter((e, i) => {


      if (e?.businessName?.toLowerCase().includes(text.toLowerCase()) || e?.email?.toLowerCase().includes(text.toLowerCase()) || e?.customerName?.toLowerCase().includes(text.toLowerCase()) || e?.id?.toLowerCase().includes(text.toLowerCase()) || e?.mobileNumber?.toLowerCase().includes(text.toLowerCase())) {

        return e
      }



    }))



  }


  const generateAccountNumber = () => {

    let uniqueNumber = customers.length + 1 + 100;


    setCustomerDetails({
      ...customerDetails,
      accountNo: uniqueNumber
    })

  }


  const handleDownloadPdf = async () => {


    setPdfDownloadView(true)
    window.scrollTo({
      top: 100,
      behavior: "smooth"
    })
  }




  return (

    pdfDownloadView ? <Box style={{ wodth: "100%", height: "100%", display: "flex", justifyContent: "center" }} >

      <PDFViewer width={850} height={800} >
        <MyDocument />
      </PDFViewer>

      <CloseOutlinedIcon fontSize="large" style={{ color: Colors.black, cursor: "pointer", }} onClick={() => setPdfDownloadView(false)} />

    </Box>
      :
      <Box style={{ color: 'black' }}>



        <Box
          sx={{
            width: '100%',
            marginTop: 0,
            margin: 0,
            padding: { xs: "20px 0px 0px 0px", sm: "20px 10px 0px 10px" },
            backgroundColor: Colors?.main,
          }}
        >


          <Grid container spacing={2}>

            <Grid item xs={12} md={6}>


              <Box style={{ padding: 10 }}>
                {/*  Content for the first column */}

                <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, marginTop: 10, borderRadius: 5, backgroundColor: Colors.white }} >

                  <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}  >
                    <Typography style={{ color: Colors.black, fontSize: 16, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                      Business Account
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >



                    <Box sx={{
                      width: "100%", marginTop: { xs: "0px", sm: "10px", md: "10px" }, border: `1px solid ${Colors.button}`, borderRadius: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px 10px 5px 10px", margin: 0,
                      backgroundColor: Colors.white
                    }} >
                      <TextField
                        variant="standard" // <== changed this
                        margin="normal"
                        required
                        value={searchText}
                        onChange={handleSearchCustomer}
                        fullWidth
                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true,
                          fontSize: "12px",
                        }}
                        placeholder="Search Name ,Id , Phone"
                        sx={{ width: "90%", fontSize: "12px", padding: 0, fontFamily: "Poppins", fontWeight: "bold", margin: 0 }}
                      />
                      <img src={require("../../../Images/search.png")} style={{ height: "20px" }} />
                    </Box>


                  </Box>

                  <Box style={{ display: "flex", marginTop: 10 }} >

                    <CheckBox data={byInvoiceNumber} onClick={() => selectByInvoiceNumber()} />

                    <Typography style={{ color: Colors.text, fontSize: 12, fontWeight: "500", fontFamily: "Poppins", textAlign: "left", marginLeft: "10px" }} >
                      By account no
                    </Typography>

                  </Box>

                </Box>


                <Box style={{ marginTop: "20px" }} >
                  {/* <Grid xs={12} sm={12} md={12} container > */}

                  {/* <Grid item xs={4} sm={3} md={3}  > */}
                  <Box sx={{ width: "100%", display: "flex", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }} >

                    <Box sx={{ width: "25%", padding: "10px", backgroundColor: Colors.button, borderRight: `2px solid ${Colors.border}`, }} >

                      <Typography sx={{ color: Colors.white, fontFamily: "Poppins", fontWeight: "bold", fontSize: "14px", textAlign: "left" }} >
                        Accounts No
                      </Typography>

                    </Box>

                    <Box sx={{ width: "75%", padding: "10px", backgroundColor: Colors.button, }} >

                      <Typography sx={{ color: Colors.white, fontFamily: "Poppins", fontWeight: "bold", fontSize: "14px", textAlign: "left" }} >
                        Business Name
                      </Typography>

                    </Box>

                  </Box>

                  <Box sx={{ height: "1000px", overflow: "auto", backgroundColor: Colors.white, width: "100%" }} >

                    {allAccounts && allAccounts.length > 0 && allAccounts?.map((e, i) => {

                      return (
                        <Button onClick={() => selectCustomer(e, i)} style={{ width: "100%", display: "flex", borderBottom: `1px solid ${Colors.border}`, backgroundColor: e.selected ? Colors.buttonLight : Colors.white }} >
                          <Box sx={{ width: { xl: "25.5%", xs: "25%" }, padding: "10px", borderRight: `2px solid ${Colors.border}`, margin: "0px" }} >
                            <Typography sx={{ color: Colors.text, fontFamily: "Poppins", fontWeight: "600", fontSize: "14px", textAlign: "left", }} >
                              {e?.accountNo}
                            </Typography>
                          </Box>
                          <Box sx={{ width: { xl: "74.5%", xs: "75%" }, padding: "10px", }} >
                            <Typography sx={{ color: Colors.text, fontFamily: "Poppins", fontWeight: "600", fontSize: "14px", textAlign: "left" }} >
                              {e?.businessName}
                            </Typography>
                          </Box>
                        </Button>
                      )


                    })}


                  </Box>




                </Box>


                <Box sx={{ width: "100%", backgroundColor: Colors.button, borderRadius: "5px", padding: "15px", marginTop: "20px", marginBottom: "20px" }} >

                  <Typography sx={{ color: Colors.white, fontFamily: "Poppins", fontWeight: "600", fontSize: "14px", textAlign: "left", marginTop: "5px" }} >
                    Account Summary Type
                  </Typography>


                  <BasicSelect style={{ marginTop: 0.5, backgroundColor: Colors.white }} value={selectedSummaryType} status="Select" id={summaryType} width={"100%"} onChange={(e) => setSelectedSummaryType(e)} />


                  <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "15px" }} >

                    <Button onClick={() => handleDownloadPdf()} sx={{
                      color: Colors.button, backgroundColor: Colors.white, borderRadius: "5px", width: "120px", fontFamily: "Poppins", fontSize: "10px", fontWeight: "bold", marginRight: "10px", marginTop: "5px", '&:hover': {
                        color: Colors.button,
                        backgroundColor: Colors.white
                        // Set your desired hover color
                      }
                    }} >
                      Download Report
                    </Button>

                  </Box>


                </Box>


              </Box>

            </Grid>


            <Grid item xs={12} sm={12} md={6}>
              <Box style={{ padding: 10 }}>
                {/* Content for the second column */}

                <Box style={{ marginTop: "10px", padding: 15, backgroundColor: Colors.white, borderTopRightRadius: "5px", borderTopLeftRadius: "5px", marginBottom: "0px" }} >

                  <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", }} >


                    <Typography sx={{ fontSize: "16px", fontWeight: "bold", fontFamily: "Poppins" }} >
                      Account Details
                    </Typography>
                  </Box>

                  <Box style={{ marginTop: 10 }} >


                    <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between" }} >
                      <Grid xs={12} md={5.5} >
                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                          Business Name
                        </Typography>
                        <Box
                          sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                          <TextField

                            onChange={(e) => setCustomerDetails({
                              ...customerDetails,
                              businessName: e.target.value
                            })}
                            value={customerDetails.businessName}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            fullWidth
                            sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                            placeholder="Enter Business Name.."
                            InputProps={{
                              // startAdornment: <AccountCircle />, // <== adjusted this
                              disableUnderline: true, // <== added this
                              // fontSize: "2px"
                            }}
                          />
                        </Box>


                      </Grid>
                      <Grid xs={12} md={6} >
                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                          Account Number <Button onClick={() => generateAccountNumber()} sx={{ ml: 1, m: 0, p: 0, fontSize: "12px" }} >Auto Generate</Button>
                        </Typography>
                        <Box sx={{
                          border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center"
                        }} >

                          <TextField

                            onChange={(e) => setCustomerDetails({
                              ...customerDetails,
                              accountNo: e.target.value
                            })}
                            value={customerDetails.accountNo}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            fullWidth
                            sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                            placeholder="000"
                            InputProps={{
                              // startAdornment: <AccountCircle />, // <== adjusted this
                              disableUnderline: true, // <== added this
                              // fontSize: "2px"
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>


                    <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between" }} >
                      <Grid xs={12} md={5.5} >
                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                          Customer Name:
                        </Typography>
                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                          <TextField

                            onChange={(e) => setCustomerDetails({
                              ...customerDetails,
                              customerName: e.target.value
                            })}
                            value={customerDetails.customerName}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            fullWidth
                            sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                            placeholder="000"
                            InputProps={{
                              // startAdornment: <AccountCircle />, // <== adjusted this
                              disableUnderline: true, // <== added this
                              // fontSize: "2px"
                            }}
                          />
                        </Box>


                      </Grid>
                      <Grid xs={12} md={6} >
                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                          Created Date/OP
                        </Typography>
                        <Box sx={{ width: "100%" }} >

                          <DatePickers selected={allAccounts.some((e) => e?.selected) ? true : false} disabled={true} values={customerDetails?.created_at} onChange={(e) => setCustomerDetails({
                            ...customerDetails,
                            created_at: e
                          })}
                            label="Select Date" />

                        </Box>
                      </Grid>
                    </Grid>



                    <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                      <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                        Address
                      </Typography>


                    </Box>

                    <Box sx={{ marginTop: "2px" }} >
                      <textarea
                        // value={data.cityName}
                        // onChange={(e) =>
                        //   setData((prev) => ({ ...prev, cityName: e.target.value }))
                        // }

                        multiline={true}
                        row={10}
                        value={customerDetails.address}
                        onChange={(e) => setCustomerDetails({
                          ...customerDetails,
                          address: e.target.value
                        })}
                        placeholder="Enter Address"
                        style={{
                          width: "100%", fontSize: 14, padding: "5px 10px 5px 10px", marginTop: 1, fontFamily: "Poppins", border: `1px solid ${Colors.border}`, height: 100,
                          // fontStyle: editorState?.italic ? "italic" : "normal",
                          // textDecoration: editorState?.underline ? "underline" : "none",
                          // textAlign: editorState?.alignment,
                          borderRadius: "5px",

                        }}
                      />
                    </Box>


                    <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                      <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                        City
                      </Typography>


                    </Box>

                    <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: "2px" }} >

                      <TextField

                        onChange={(e) => setCustomerDetails({
                          ...customerDetails,
                          city: e.target.value
                        })}
                        value={customerDetails.city}
                        variant="standard" // <== changed this
                        margin="normal"
                        required
                        fullWidth
                        sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                        placeholder="Enter City"
                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true, // <== added this
                          // fontSize: "2px"
                        }}
                      />
                    </Box>


                    <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                      <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                        Email
                      </Typography>


                    </Box>

                    <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: "2px" }} >

                      <TextField

                        onChange={(e) => setCustomerDetails({
                          ...customerDetails,
                          email: e.target.value
                        })}
                        value={customerDetails.email}
                        variant="standard" // <== changed this
                        margin="normal"
                        required
                        fullWidth
                        sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                        placeholder="Enter Email"
                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true, // <== added this
                          // fontSize: "2px"
                        }}
                      />
                    </Box>


                    <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }} >
                      <Grid xs={12} md={5.5} >
                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                          Postal Code:
                        </Typography>
                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                          <TextField
                            onChange={(e) => setCustomerDetails({
                              ...customerDetails,
                              postalCode: e.target.value
                            })}
                            value={customerDetails.postalCode}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            type="text"
                            fullWidth
                            sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                            placeholder="Enter Potal Code..."
                            InputProps={{
                              // startAdornment: <AccountCircle />, // <== adjusted this
                              disableUnderline: true, // <== added this
                              // fontSize: "2px"
                            }}
                          />
                        </Box>


                      </Grid>


                      <Grid xs={12} md={5.5} >
                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                          Mobile Number:
                        </Typography>
                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                          <TextField
                            onChange={(e) => setCustomerDetails({
                              ...customerDetails,
                              mobileNumber: e.target.value
                            })}
                            value={customerDetails.mobileNumber}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            type="number"
                            fullWidth
                            sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                            placeholder="Enter Mobile Number..."
                            InputProps={{
                              // startAdornment: <AccountCircle />, // <== adjusted this
                              disableUnderline: true, // <== added this
                              // fontSize: "2px"
                            }}
                          />
                        </Box>


                      </Grid>

                    </Grid>

                    <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }} >
                      <Grid xs={12} md={5.5} >
                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                          Telephone No:
                        </Typography>
                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                          <TextField
                            onChange={(e) => setCustomerDetails({
                              ...customerDetails,
                              telephoneNo: e.target.value
                            })}
                            value={customerDetails.telephoneNo}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            type="number"
                            fullWidth
                            sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                            placeholder="Enter Telephone No..."
                            InputProps={{
                              // startAdornment: <AccountCircle />, // <== adjusted this
                              disableUnderline: true, // <== added this
                              // fontSize: "2px"
                            }}
                          />
                        </Box>


                      </Grid>
                      <Grid xs={12} md={6} >
                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                          Telephone No 2:
                        </Typography>
                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                          <TextField
                            onChange={(e) => setCustomerDetails({
                              ...customerDetails,
                              telephoneNo2: e.target.value
                            })}
                            value={customerDetails.telephoneNo2}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            type="number"
                            fullWidth
                            sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                            placeholder="Enter Telephone No 2..."
                            InputProps={{
                              // startAdornment: <AccountCircle />, // <== adjusted this
                              disableUnderline: true, // <== added this
                              // fontSize: "2px"
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box style={{ width: "100%", marginTop: 20 }} >
                      <Grid container sm={12} xs={12} md={12} >

                        <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                          <Typography style={{ color: Colors.text, fontSize: 12, fontWeight: "bold", fontFamily: "Poppins" }} >
                            Order Location
                          </Typography>


                        </Box>

                        {stockCategory && stockCategory.length > 0 && stockCategory?.map((e, i) => {
                          return (

                            <Grid xl={2.3} xs={6} sm={2.3} lg={2.3} md={2.8} >

                              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: { xs: "flex-start", sm: "flex-start" }, marginTop: "5px" }} >
                                <CheckBox onClick={() => selectStockCategory(i)} data={e} />
                                <Typography sx={{ fontFamily: "Poppins", fontWeight: "600", fontSize: { xs: "7px", sm: "8px", md: "12px" }, color: Colors.text, marginLeft: "5px" }} >
                                  {e?.name}
                                </Typography>

                              </Box>

                            </Grid>

                          )
                        })}



                      </Grid>

                    </Box>


                    <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }} >
                      <Grid xs={12} md={5.5} >
                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                          Credit Limit
                        </Typography>
                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: "5px" }} >

                          <TextField
                            onChange={(e) => setCustomerDetails({
                              ...customerDetails,
                              creditLimits: e.target.value
                            })}
                            value={customerDetails.creditLimits}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            type="number"
                            fullWidth
                            sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                            placeholder="0"
                            InputProps={{
                              // startAdornment: <AccountCircle />, // <== adjusted this
                              disableUnderline: true, // <== added this
                              // fontSize: "2px"
                            }}
                          />
                        </Box>


                      </Grid>
                      <Grid xs={12} md={5.5} >
                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                          Credit Days
                        </Typography>
                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: "5px" }} >

                          <TextField
                            onChange={(e) => setCustomerDetails({
                              ...customerDetails,
                              creditDays: e.target.value
                            })}
                            value={customerDetails.creditDays}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            type="number"
                            fullWidth
                            sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                            placeholder="0"
                            InputProps={{
                              // startAdornment: <AccountCircle />, // <== adjusted this
                              disableUnderline: true, // <== added this
                              // fontSize: "2px"
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>


                    <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }} >
                      <Grid xs={12} md={5.5} >
                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                          Opening Invoice Balance
                        </Typography>
                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: "5px" }} >

                          <TextField
                            onChange={(e) => setCustomerDetails({
                              ...customerDetails,
                              openingInvoiceBalance: e.target.value
                            })}
                            value={customerDetails?.openingInvoiceBalance ? customerDetails?.openingInvoiceBalance : ""}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            type="number"
                            fullWidth
                            sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                            placeholder="0"
                            InputProps={{
                              // startAdornment: <AccountCircle />, // <== adjusted this
                              disableUnderline: true, // <== added this
                              // fontSize: "2px"
                            }}
                          />
                        </Box>


                      </Grid>
                      <Grid xs={12} md={5.5} >
                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                          Opening Quotation Balance
                        </Typography>
                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: "5px" }} >

                          <TextField
                            onChange={(e) => setCustomerDetails({
                              ...customerDetails,
                              openingQuotationBalance: e.target.value
                            })}
                            value={customerDetails?.openingQuotationBalance ? customerDetails?.openingQuotationBalance : ""}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            type="number"
                            fullWidth
                            sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                            placeholder="0"
                            InputProps={{
                              // startAdornment: <AccountCircle />, // <== adjusted this
                              disableUnderline: true, // <== added this
                              // fontSize: "2px"
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>



                    <Box style={{ width: "100%", marginTop: 20 }} >
                      <Grid container sm={12} xs={12} md={12} >

                        <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                          <Typography style={{ color: Colors.text, fontSize: 12, fontWeight: "bold", fontFamily: "Poppins" }} >
                            Price Level
                          </Typography>

                        </Box>

                        {priceLevel && priceLevel.length > 0 && priceLevel?.map((e, i) => {
                          return (

                            <Grid xl={4} xs={6} sm={4} lg={4} md={4} >

                              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: { xs: "flex-start", sm: "flex-start" }, marginTop: "5px" }} >
                                <CheckBox onClick={() => selectPriceLevel(i)} data={e} />
                                <Typography sx={{ fontFamily: "Poppins", fontWeight: "600", fontSize: { xs: "7px", sm: "8px", md: "12px" }, color: Colors.text, marginLeft: "5px" }} >
                                  {e?.name}
                                </Typography>

                              </Box>

                            </Grid>

                          )
                        })}

                      </Grid>

                    </Box>


                  </Box>

                </Box>

                {allAccounts && allAccounts.length > 0 && allAccounts.some((e, i) => e.selected) && <Box>
                  <Box sx={{ width: "100%", padding: "10px 5px 10px 5px", backgroundColor: Colors.button, display: "flex", flexWrap: "wrap", borderRadius: "1px", margin: 0, position: "relative", top: 0 }} >

                    {options && options.length > 0 && options?.map((e, i) => {
                      return (

                        <Button onClick={() => selectOption(i)} sx={{ marginRight: "15px", fontSize: "12px", color: e?.selected ? Colors.button : Colors.white, fontFamily: "Poppins", fontWeight: "bold", width: "fit-content", backgroundColor: e?.selected ? Colors.white : Colors.button }} >
                          {e.option}
                        </Button>

                      )
                    })}

                  </Box>

                  <Box sx={{ padding: "10px", backgroundColor: Colors.white, borderBottomRightRadius: "5px", borderBottomLeftRadius: "5px" }} >


                    {options && options.length > 0 && options?.map((e, i) => {


                      if (e?.option == "Delivery Address" && e?.selected) {

                        return (
                          <Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }} >
                              <Typography sx={{ fontSize: "14px", fontFamily: "Poppins", fontWeight: "600", color: Colors.text }} >
                                Delivery Address
                              </Typography>
                              <Box sx={{ display: "flex", alignItems: "center" }} >
                                <CheckBox data={isCompanyAddress} onClick={() => handleSetAsCompanyAbove()} />
                                <Typography sx={{ marginLeft: "10px", fontSize: "14px", fontFamily: "Poppins", fontWeight: "500", color: Colors.text }} >
                                  Same as Company Address
                                </Typography>
                              </Box>
                            </Box>
                            <textarea
                              multiline={true}
                              onChange={(e) => setCustomerDetails({
                                ...customerDetails,
                                deliveryAddress: e.target.value
                              })}
                              value={isCompanyAddress.selected ? customerDetails?.address : customerDetails?.deliveryAddress ? customerDetails?.deliveryAddress : ""}
                              style={{ height: "100px", border: `1px solid ${Colors.border}`, width: "100%", borderRadius: "5px", padding: "10px 15px 10px 15px", fontFamily: "Poppins", fontSize: "14px", marginTop: "5px" }}
                              rows={10}
                              placeholder='Enter Address...'

                            />

                            <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }} >
                              <Grid xs={12} md={5.5} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                  City:
                                </Typography>
                                <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                  <TextField

                                    onChange={(e) => setCustomerDetails({
                                      ...customerDetails,
                                      deliveryCity: e.target.value
                                    })}
                                    value={isCompanyAddress.selected ? customerDetails?.city : customerDetails?.deliveryCity ? customerDetails?.deliveryCity : ""}
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    type="text"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder=""
                                    InputProps={{
                                      // startAdornment: <AccountCircle />, // <== adjusted this
                                      disableUnderline: true, // <== added this
                                      // fontSize: "2px"
                                    }}
                                  />
                                </Box>


                              </Grid>
                              <Grid xs={12} md={6} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                  Postal Code:
                                </Typography>
                                <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                  <TextField

                                    onChange={(e) => setCustomerDetails({
                                      ...customerDetails,
                                      deliveryPostalCode: e.target.value
                                    })}
                                    value={isCompanyAddress.selected ? customerDetails?.postalCode : customerDetails?.deliveryPostalCode ? customerDetails?.deliveryPostalCode : ""}
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    // type="number"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}

                                    InputProps={{
                                      // startAdornment: <AccountCircle />, // <== adjusted this
                                      disableUnderline: true, // <== added this
                                      // fontSize: "2px"
                                    }}
                                  />
                                </Box>
                              </Grid>

                            </Grid>




                          </Box>

                        )

                      }
                      if (e?.option?.toLowerCase() == "created by" && e?.selected) {

                        return (
                          <Box>


                            <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }} >
                              <Grid xs={12} md={5.5} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                  Employee Name:
                                </Typography>
                                <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                  <TextField


                                    value={customerDetails?.employeeDetails?.fullName ?? ""}
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    disabled
                                    type="text"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder=""
                                    InputProps={{
                                      // startAdornment: <AccountCircle />, // <== adjusted this
                                      disableUnderline: true, // <== added this
                                      // fontSize: "2px"
                                    }}
                                  />
                                </Box>


                              </Grid>
                              <Grid xs={12} md={6} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                  Employee Id:
                                </Typography>
                                <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                  <TextField
                                    value={customerDetails?.employeeDetails?.employeeId ?? ""}
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    disabled
                                    // type="number"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}

                                    InputProps={{
                                      // startAdornment: <AccountCircle />, // <== adjusted this
                                      disableUnderline: true, // <== added this
                                      // fontSize: "2px"
                                    }}
                                  />
                                </Box>
                              </Grid>

                            </Grid>




                          </Box>

                        )

                      }
                      if (e?.option?.toLowerCase() == "updated by" && e?.selected) {

                        return (
                          <Box>


                            <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }} >
                              <Grid xs={12} md={5.5} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                  Employee Name:
                                </Typography>
                                <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                  <TextField


                                    value={customerDetails?.updatedBy?.fullName ?? ""}
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    disabled
                                    type="text"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder=""
                                    InputProps={{
                                      // startAdornment: <AccountCircle />, // <== adjusted this
                                      disableUnderline: true, // <== added this
                                      // fontSize: "2px"
                                    }}
                                  />
                                </Box>


                              </Grid>
                              <Grid xs={12} md={6} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                  Employee Id:
                                </Typography>
                                <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                  <TextField

                                    value={customerDetails?.updatedBy?.employeeId ?? ""}
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    disabled
                                    // type="number"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}

                                    InputProps={{
                                      // startAdornment: <AccountCircle />, // <== adjusted this
                                      disableUnderline: true, // <== added this
                                      // fontSize: "2px"
                                    }}
                                  />
                                </Box>
                              </Grid>

                            </Grid>




                          </Box>

                        )

                      }

                      if (e?.option == "Contact Persons" && e?.selected) {

                        return (
                          <Box>

                            <Typography sx={{ color: Colors.text, fontFamily: "Poppins", fontWeight: "600", fontSize: "14px", textAlign: "left", marginTop: "5px" }} >
                              Contact Persons
                            </Typography>

                            <BasicSelect style={{ marginTop: 0.5, backgroundColor: Colors.white }} value={selectedSummaryType} status="Select" id={summaryType} width={"100%"} onChange={(e) => setSelectedSummaryType(e)} />

                            <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                              <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                Contact Name
                              </Typography>
                            </Box>

                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                              <TextField
                                variant="standard" // <== changed this
                                margin="normal"
                                required
                                fullWidth
                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                placeholder="Select"
                                InputProps={{
                                  // startAdornment: <AccountCircle />, // <== adjusted this
                                  disableUnderline: true, // <== added this
                                  // fontSize: "2px"
                                }}
                              />
                            </Box>



                            <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }} >
                              <Grid xs={12} md={5.5} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                  Position
                                </Typography>
                                <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                  <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    type="text"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Select"
                                    InputProps={{
                                      // startAdornment: <AccountCircle />, // <== adjusted this
                                      disableUnderline: true, // <== added this
                                      // fontSize: "2px"
                                    }}
                                  />
                                </Box>


                              </Grid>
                              <Grid xs={12} md={6} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                  Contact Number
                                </Typography>
                                <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                  <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    type="number"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder='Select'
                                    InputProps={{
                                      // startAdornment: <AccountCircle />, // <== adjusted this
                                      disableUnderline: true, // <== added this
                                      // fontSize: "2px"
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>

                            <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }} >
                              <Grid xs={12} md={5.5} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                  Mobile
                                </Typography>
                                <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                  <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    type="number"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Select"
                                    InputProps={{
                                      // startAdornment: <AccountCircle />, // <== adjusted this
                                      disableUnderline: true, // <== added this
                                      // fontSize: "2px"
                                    }}
                                  />
                                </Box>


                              </Grid>
                              <Grid xs={12} md={6} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                  E-mail
                                </Typography>
                                <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                  <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    type="email"
                                    placeholder='Select'
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    InputProps={{
                                      // startAdornment: <AccountCircle />, // <== adjusted this
                                      disableUnderline: true, // <== added this
                                      // fontSize: "2px"
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>


                            <Box sx={{ display: "flex", flexDirection: "row", width: "100%", flexWrap: "wrap", marginTop: "10px" }} >

                              <Button sx={{ width: "130px", fontSize: "12px", fontFamily: "Poppins", color: Colors.button, backgroundColor: Colors.white, border: `1px solid ${Colors.button}`, fontWeight: "bold", marginRight: "10px", marginTop: "5px" }} >
                                New
                              </Button>
                              <Button sx={{ width: "130px", fontSize: "12px", fontFamily: "Poppins", color: Colors.button, backgroundColor: Colors.white, border: `1px solid ${Colors.button}`, fontWeight: "bold", marginRight: "10px", marginTop: "5px" }} >
                                Save
                              </Button>
                              <Button sx={{ width: "130px", fontSize: "12px", fontFamily: "Poppins", color: Colors.button, backgroundColor: Colors.white, border: `1px solid ${Colors.button}`, fontWeight: "bold", marginRight: "10px", marginTop: "5px" }} >
                                Remove
                              </Button>

                            </Box>


                          </Box>
                        )

                      }

                      if (e?.option == "Comments" && e?.selected) {

                        return (
                          <Box>
                            <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                              <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                Comment
                              </Typography>

                            </Box>


                            <textarea
                              multiline={true}
                              onChange={(e) => setCustomerDetails({
                                ...customerDetails,
                                comment: e.target.value
                              })}

                              value={customerDetails?.comment}

                              style={{ height: "150px", border: `1px solid ${Colors.border}`, width: "100%", borderRadius: "5px", padding: "10px 15px 10px 15px", fontFamily: "Poppins", fontSize: "14px", marginTop: "5px" }}
                              rows={10}
                              placeholder='Write Comment...'

                            />

                          </Box>

                        )

                      }
                      if (e?.option == ("Email/Other Details") && e?.selected) {

                        return (
                          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start" }} >


                            <Box style={{ width: "100%" }} >

                              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                  Email Subject:
                                </Typography>
                              </Box>

                              {/* <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} > */}


                              <Box sx={{ marginTop: "2px" }} >
                                <TextField
                                  variant="standard" // <== changed this
                                  margin="normal"
                                  onChange={(e) => setEmailSubject(e.target.value)}
                                  value={emailSubject}
                                  required
                                  fullWidth
                                  sx={{ width: "100%", fontSize: "10px", padding: 1, borderRadius: 1, margin: 0, fontFamily: "Poppins", fontWeight: "bold", border: `1px solid ${Colors.border}` }}
                                  placeholder="Enter Subject.."
                                  InputProps={{
                                    disableUnderline: true, // <== added this
                                  }}
                                />
                              </Box>


                              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                  Write Email
                                </Typography>
                              </Box>

                              {/* <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} > */}


                              <Box sx={{ marginTop: "2px" }} >
                                <textarea

                                  multiline={true}
                                  row={10}
                                  value={emailText}
                                  onChange={(e) => setEmailText(e.target.value)}
                                  placeholder="Enter Email"
                                  style={{
                                    width: "100%", fontSize: 14, padding: "5px 10px 5px 10px", marginTop: 1, fontFamily: "Poppins", border: `1px solid ${Colors.border}`, height: 100,
                                    // fontStyle: editorState?.italic ? "italic" : "normal",
                                    // textDecoration: editorState?.underline ? "underline" : "none",
                                    // textAlign: editorState?.alignment,
                                    borderRadius: "5px",

                                  }}
                                />
                                {/* </Box> */}
                              </Box>

                            </Box>

                            <Box>

                              <Button onClick={handleSendEmail} sx={{ width: "130px", fontSize: "12px", fontFamily: "Poppins", color: Colors.button, backgroundColor: Colors.white, border: `1px solid ${Colors.button}`, fontWeight: "bold" }} >
                                Send Email
                              </Button>

                            </Box>

                          </Box>
                        )

                      }

                      if (e?.option == ("VAT Rate") && e?.selected) {

                        return (
                          <Box sx={{ height: "180px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start" }} >
                            <Box style={{ width: "100%" }} >

                              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                  VAT Number
                                </Typography>
                              </Box>

                              <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                <TextField
                                  variant="standard" // <== changed this
                                  margin="normal"
                                  required
                                  fullWidth
                                  sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                  placeholder="VAT NUMBER.."
                                  InputProps={{
                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                    disableUnderline: true, // <== added this
                                    // fontSize: "2px"
                                  }}
                                />
                              </Box>

                            </Box>



                          </Box>
                        )

                      }

                    })}





                  </Box>


                </Box>
                }

                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", flexWrap: "wrap", marginTop: 2, marginBottom: { xs: 2, sm: 2, md: 0 }, backgroundColor: Colors.white, padding: "20px", borderRadius: "5px" }} >
                  <Box sx={{ width: "100%", display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", flexWrap: "wrap" }} >
                    <CustomButton onClick={handleAddNewAccount} style={{ backgroundColor: Colors.white, border: `1px solid ${Colors.text}`, color: Colors.text, width: { xs: "100%", sm: "fit-content" }, marginTop: { xs: "10px", sm: "10px" }, fontSize: { md: "10px", lg: "12px" } }} text="New Account" />


                    <CustomButton onClick={() => setOpenDeleteModal(true)} style={{ backgroundColor: Colors.white, border: `1px solid ${Colors.text}`, color: Colors.text, width: { xs: "100%", sm: 120 }, marginTop: { xs: "10px", sm: "10px" }, fontSize: { md: "10px", lg: "12px" } }} text="Delete" />


                    <CustomButton onClick={() => navigate("/PointOfSale")} style={{ backgroundColor: Colors.white, border: `1px solid ${Colors.button}`, color: Colors.button, width: { xs: "100%", sm: 120 }, marginTop: { xs: "10px", sm: "10px" }, fontSize: { md: "10px", lg: "12px" } }} text="Exit" />

                    <CustomButton onClick={handleAddCustomer} style={{ backgroundColor: Colors.button, border: `1px solid ${Colors.button}`, color: Colors.white, width: { xs: "100%", sm: 120 }, marginTop: { xs: "10px", sm: "10px" }, fontSize: { md: "10px", lg: "12px" } }} text={loading.save ? <RingLoader loading={loading.save} size={30} /> : "Save"} />

                  </Box>

                </Box>




              </Box>
            </Grid>
          </Grid>
        </Box>

        {openDeleteModal && (
          <CustomModal
            heading={`Delete Customer`}
            loading={deleteLoading}
            text={"Are you sure you want to delete this customer?"}
            open={openDeleteModal}
            closeModal={() => setOpenDeleteModal(false)}
            confirm={handleDeleteCustomer}
          />
        )}

      </Box >
  )
}

export default CustomerAccountDetails
