import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { InputAdornment, TextField } from '@mui/material';
import Checkbox from './checkbox';
import { RingLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Base_Uri } from '../Constant/BaseUri';
import { DataGrid } from '@mui/x-data-grid';
import DatePickers from './DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from "@mui/icons-material/Download";
import { Document, Page, Text, View, PDFViewer, PDFDownloadLink, Font } from '@react-pdf/renderer';
import MyFontRegular from '../fonts/Poppins-Medium.ttf';
import MyFontBold from '../fonts/Poppins-Bold.ttf';
import CustomButton from './Button';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { lg: "80%", md: "95%", sm: "95%", xs: "100%" },
    borderRadius: "10px",
    maxHeight: "80vh",
    overflow: "auto",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

function DamageLedgerModal({ data, loading, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, text1 }) {


    Font.register({
        family: 'MyFont',
        fonts: [
            { src: MyFontBold, fontWeight: 'bold' },
            { src: MyFontRegular, fontWeight: 'normal' },
        ]
    });

    const vat = useSelector(state => state?.loginReducer?.vat)


    const [selectedDate, setSelectedDate] = useState(null)

    const [pdfDownloadView, setPdfDownloadView] = useState(false)


    const [dateFrom, setDateFrom] = useState(null)

    let initialData = {
        qty: "",
        cost_price: "",
        trade_price: "",
        warehouse_price: "",
        retail_price: ""
    }



    const [searchDate, setSearchDate] = useState({
        selected: false
    })

    const { productLedger } = data

    
    


    let addProductLedger = productLedger && productLedger.length > 0 && productLedger?.map((e, i) => {

        let balanceQty = e.qty || 0;

        if (i > 0) {

            balanceQty = productLedger.slice(0, i).reduce((acc, curr) => {
                return (acc ? acc : e.qty) + (curr.qty || 0);
            }, 0);
        } else {
            balanceQty = e.qty || 0; // For the first entry, balance quantity is simply its own quantity
        }


        return {
            ...e,
            id: i,
            balanceQty: balanceQty,
            sale_price: e?.discount_price ? e?.discount_price : (e?.status == "sale" || e?.status == "Return") ? (e?.invoiceDetails?.customerDetails?.[0]?.accountManager?.toLowerCase() == "a") ? e?.trade_price :
                (e?.invoiceDetails?.customerDetails?.[0].accountManager.toLowerCase() == "b") ? e?.warehouse_price : e?.retail_price : "-"

        }
    })

    addProductLedger = addProductLedger && addProductLedger?.length > 0 && addProductLedger?.sort((a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime())


    let [restockData, setRestockData] = useState(initialData)

    const columns = [


        {
            field: "date",
            headerName: "Date",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (cellValues) => {
                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "30%",

                            backgroundColor: Colors.white,
                        }}
                    >
                        {`${new Date(cellValues?.row?.date).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                        })}`}
                    </Box>
                );
            },
        },
        {
            field: "qty",
            headerName: "Qty",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: "cost_price",
            headerName: "Cost Price",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },

        {
            field: "sale_price",
            headerName: "Sale Price",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },

        {
            field: "trade_price",
            headerName: "Trade Price",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },

        {
            field: "warehouse_price",
            headerName: "Warehouse Price",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },

        {
            field: "retail_price",
            headerName: "Retail Price",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },

        {
            field: "balanceQty",
            headerName: "Balance",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (cellValues) => {
                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "30%",
                            color: Colors.red,
                            backgroundColor: Colors.white,
                        }}
                    >
                        {`${cellValues?.row?.balanceQty}`}
                    </Box>
                );
            },
        },
        {
            field: "invoiceNumber",
            headerName: "Invoice#",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (cellValues) => {
                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "30%",

                            backgroundColor: Colors.white,
                        }}
                    >
                        {`${cellValues?.row?.invoiceDetails?.invoiceNumber ? cellValues?.row?.invoiceDetails?.invoiceNumber : "-"}`}
                    </Box>
                );
            },
        },
        {
            field: "businessName",
            headerName: "Customer",
            width: 150,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (cellValues) => {
                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "30%",

                            backgroundColor: Colors.white,
                        }}
                    >
                        {`${cellValues?.row?.invoiceDetails?.customerDetails?.[0]?.businessName ? cellValues?.row?.invoiceDetails?.customerDetails?.[0]?.businessName : "-"}`}
                    </Box>
                );
            },
        },

        {
            field: "supplier_name",
            headerName: "Supplier",
            width: 150,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (cellValues) => {
                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "30%",

                            backgroundColor: Colors.white,
                        }}
                    >
                        {`${(cellValues?.row?.status == "purchase" || cellValues?.row?.status == "local purchase") && cellValues?.row?.supplierDetails?.supplier_name ? cellValues?.row?.supplierDetails?.supplier_name : "-"}`}
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            headerClassName: 'super-app-theme--header',
            width: 220,
            filterable: false,
            color: "green",
        },

    ];


    const [searchData, setSearchData] = useState([])


    React.useEffect(() => {

        if (searchDate?.selected && selectedDate && dateFrom) {
            setSearchData(addProductLedger && addProductLedger.filter((e) => {
                let addDate = new Date(e.date);

                // Parse selectedDate and dateFrom
                let selectedDateParts = selectedDate.split('/');
                let dateFromParts = dateFrom.split('/');

                // Create Date objects from the parsed components
                let selectedDateTime = new Date(
                    parseInt(selectedDateParts[2]),   // Year
                    parseInt(selectedDateParts[1]),   // Month (zero-based)
                    parseInt(selectedDateParts[0]),   // Day
                    23, // Hours
                    59, // Minutes
                    59  // Seconds
                );

                let dateFromTime = new Date(
                    parseInt(dateFromParts[2]),    // Year
                    parseInt(dateFromParts[1]),     // Month (zero-based)
                    parseInt(dateFromParts[0]),     // Day
                    0,  // Hours
                    0,  // Minutes
                    0   // Seconds
                );

                // Check if addDate is between dateFrom and selectedDate
                return addDate >= dateFromTime && addDate <= selectedDateTime;
            }));

        } else {
            setSearchData([]);
        }


    }, [selectedDate, searchDate?.selected, dateFrom])



    const Header = () => {

        return (
            <View fixed >

                <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10, alignItems: "flex-start" }} >
                    <View>
                        <Text style={{ color: "black", fontSize: "14px", fontFamily: "MyFont", fontWeight: "normal" }} >{vat?.companyName}</Text>
                        <Text style={{ color: "black", fontSize: "12px", fontFamily: "MyFont", fontWeight: "normal" }} >{vat?.companyAddress}</Text>
                        <Text style={{ color: "black", fontSize: "12px", fontFamily: "MyFont", fontWeight: "normal" }} >{vat?.mobileNumber}</Text>
                    </View>

                    <View>

                        <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                            Print Date:   {
                                new Date().toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                })
                            }



                            <Text style={{ marginLeft: "5px" }} >  {new Date().toLocaleTimeString()}
                            </Text>
                        </Text>


                        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }} >

                            <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                                Description:
                            </Text>


                            <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                                {data?.productName}
                            </Text>

                        </View>


                        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }} >

                            <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                                Barcode:
                            </Text>


                            <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                                {data?.barcode}
                            </Text>

                        </View>


                        {searchData && searchData?.length > 0 && <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }} >

                            <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                                Date From:
                            </Text>


                            <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                                {dateFrom}
                            </Text>

                        </View>}

                        {searchData && searchData?.length > 0 && <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }} >

                            <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                                Date Total:
                            </Text>


                            <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                                {selectedDate}
                            </Text>

                        </View>}


                    </View>


                </View>


                <View style={{ flexDirection: "row", borderBottomWidth: 1, borderColor: '#000', paddingBottom: 5, paddingTop: 5, border: "1px solid black", backgroundColor: "gray", alignItems: "center", padding: 10, marginTop: "20px" }}>
                    <View style={{ flex: 1 }}>
                        <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Date</Text>
                    </View>

                    <View style={{ flex: 3 }}>
                        <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Description</Text>
                    </View>

                    <View style={{ flex: 1 }}>
                        <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Qty</Text>
                    </View>

                    <View style={{ flex: 1 }}>
                        <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Cost</Text>
                    </View>

                    <View style={{ flex: 1 }}>
                        <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Sale</Text>
                    </View>
                    <View style={{ flex: 1 }}>
                        <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Trade</Text>
                    </View>
                    <View style={{ flex: 1 }}>
                        <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Warehouse</Text>
                    </View>

                    <View style={{ flex: 1 }}>
                        <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Retail</Text>
                    </View>

                    <View style={{ flex: 1 }}>
                        <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Balance</Text>
                    </View>

                </View>

            </View>
        )
    }

    const MyDocument = () => (
        <Document size="A4"  >

            {/* <Header /> */}

            <Page style={{ padding: "0px 10px" }} >

                <Header />
                {/* Data Rows */}
                <View>

                    {searchData && searchData?.length > 0 ?
                        searchData?.map((item, index) => {

                            return (
                                <View key={index} style={{ flexDirection: "row", borderBottomWidth: 1, borderColor: '#000', padding: "3px 10px" }}>
                                    <View style={{ flex: 1 }}>
                                        <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>

                                            {new Date(item?.date).toLocaleDateString("en-GB", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "2-digit",
                                            })}

                                        </Text>
                                    </View>

                                    <View style={{ flex: 3 }}>
                                        <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item.status}</Text>
                                    </View>


                                    <View style={{ flex: 1 }}>
                                        <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item.qty}</Text>
                                    </View>


                                    <View style={{ flex: 1 }}>
                                        <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.cost_price ? Number(item.cost_price).toFixed(2) : "-"}</Text>
                                    </View>


                                    <View style={{ flex: 1 }}>
                                        <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item.sale_price && item?.sale_price !== "-" ? Number(item.sale_price).toFixed(2) : "-"}</Text>
                                    </View>


                                    <View style={{ flex: 1 }}>
                                        <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.trade_price && item?.trade_price !== "-" ? Number(item.trade_price).toFixed(2) : '-'}</Text>
                                    </View>


                                    <View style={{ flex: 1 }}>
                                        <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.warehouse_price && item?.warehouse_price !== "-" ? Number(item.warehouse_price).toFixed(2) : "-"}</Text>
                                    </View>


                                    <View style={{ flex: 1 }}>
                                        <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.retail_price && item?.retail_price !== "-" ? Number(item.retail_price).toFixed(2) : "-"}</Text>
                                    </View>


                                    <View style={{ flex: 1 }}>
                                        <Text style={{ fontFamily: "MyFont", fontSize: 8, color: "red" }}>{item.balanceQty}</Text>
                                    </View>

                                </View>

                            )


                        }) : addProductLedger && addProductLedger?.length > 0 && addProductLedger?.map((item, index) => (
                            <View key={index} style={{ flexDirection: "row", borderBottomWidth: 1, borderColor: '#000', padding: "3px 10px" }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>

                                        {new Date(item?.date).toLocaleDateString("en-GB", {
                                            day: "2-digit",
                                            month: "2-digit",
                                            year: "2-digit",
                                        })}

                                    </Text>
                                </View>

                                <View style={{ flex: 3 }}>
                                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item.status}</Text>
                                </View>


                                <View style={{ flex: 1 }}>
                                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item.qty}</Text>
                                </View>


                                <View style={{ flex: 1 }}>
                                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.cost_price ? Number(item.cost_price).toFixed(2) : "-"}</Text>
                                </View>


                                <View style={{ flex: 1 }}>
                                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item.sale_price && item?.sale_price !== "-" ? Number(item.sale_price).toFixed(2) : "-"}</Text>
                                </View>


                                <View style={{ flex: 1 }}>
                                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.trade_price && item?.trade_price !== "-" ? Number(item.trade_price).toFixed(2) : '-'}</Text>
                                </View>


                                <View style={{ flex: 1 }}>
                                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.warehouse_price && item?.warehouse_price !== "-" ? Number(item.warehouse_price).toFixed(2) : "-"}</Text>
                                </View>


                                <View style={{ flex: 1 }}>
                                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.retail_price && item?.retail_price !== "-" ? Number(item.retail_price).toFixed(2) : "-"}</Text>
                                </View>


                                <View style={{ flex: 1 }}>
                                    <Text style={{ fontFamily: "MyFont", fontSize: 8, color: "red" }}>{item.balanceQty}</Text>
                                </View>

                            </View>
                        ))}


                </View>

            </Page>
        </Document>
    );


    const handleDownloadPdf = () => {

        setPdfDownloadView(true)
        window.scrollTo({
            top: 100,
            behavior: "smooth"
        })

    }


    return (

        <Box>

            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {pdfDownloadView ? <Box style={{ wodth: "100%", height: "100%", border: "1px solid red", display: "flex", justifyContent: "center" }} >

            <PDFViewer width={850} height={700} style={{ marginTop: 20 }} >
    <MyDocument />
</PDFViewer>

<CloseOutlinedIcon fontSize="large" style={{ color: Colors.black, marginTop: "20px", cursor: "pointer", }} onClick={() => setPdfDownloadView(false)} />
      
</Box> :<Box sx={[style, { containerStyle }]}>

                    <Box onClick={() => closeModal()} style={{ position: "absolute", top: 10, right: 10 }} >
                        <CloseIcon fontSize="large" />
                    </Box>
                    <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                        {"Damage Product Ledger"}
                    </Typography>

                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
                        <Box>
                            <Typography sx={{ fontSize: "20px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                                Product Name : {data?.productName}
                            </Typography>


                            <Typography sx={{ fontSize: "20px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                                Barcode : {data?.barcode}
                            </Typography>

                            
                            <Button
                                onClick={() => handleDownloadPdf()}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginLeft: "10px",
                                    width: "50%",
                                    backgroundColor: Colors.white,
                                }}
                            >
                                <DownloadIcon />
                            </Button>
                        </Box>


                        <Box sx={{ width: "40%" }} >
                            <Box sx={{ display: "flex" }} >
                                <Box>
                                    <Typography sx={{ fontSize: "20px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                                        Date From
                                    </Typography>
                                    <DatePickers values={dateFrom} onChange={(e) => setDateFrom(e)} label="Select Date" />
                                </Box>
                                <Box sx={{ ml: 1 }} >
                                    <Typography sx={{ fontSize: "20px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                                        Date To
                                    </Typography>
                                    <DatePickers values={selectedDate} onChange={(e) => setSelectedDate(e)} label="Select Date" />
                                </Box>

                            </Box>


                            <Box sx={{ display: "flex", marginTop: 1 }} >


                                <Typography sx={{ fontSize: "14px", fontFamily: "Poppins", mr: 1, color: Colors.text }} >
                                    Search By Date
                                </Typography>
                                <Checkbox
                                    data={searchDate}
                                    onClick={() => setSearchDate(prevState => ({ ...prevState, selected: !prevState.selected }))}
                                />

                            </Box>

                        </Box>


                    </Box>

                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: 1, ...buttonContainerStyle }} >


                        <div style={{ width: "100%" }}>
                            <DataGrid
                                rows={selectedDate && searchDate?.selected && searchData.length == 0 ? [] : (searchData && searchData?.length > 0) ? searchData : (addProductLedger && addProductLedger?.length > 0) ? addProductLedger : []}
                                columns={columns}

                                sx={{
                                    minHeight: 500, width: "100%",


                                    '& .MuiDataGrid-cell': {
                                        fontFamily: 'Poppins',
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        color: "#000"
                                        // Change to your desired cell text color
                                    },

                                    // '& .MuiDataGrid-columnHeader': {
                                    //     backgroundColor: Colors.black,
                                    //     color: "white",
                                    //     fontWeight: 700,
                                    //     border: "2px solid white",
                                    //     margin: 0,
                                    //     padding: '8px', // Adjust this value as needed
                                    //     alignItems: "center"
                                    // },
                                    '& .MuiDataGrid-colCell, & .MuiDataGrid-colCellTitle': {
                                        padding: '0px', // Adjust this value as needed
                                    },
                                    '& .MuiDataGrid-columnHeaderCheckbox': {
                                        backgroundColor: Colors.black, // Change this to your desired checkbox background color
                                        color: 'white', // Change this to your desired checkbox color
                                    },
                                    // '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked':{
                                    //     color:"blue" 
                                    // },

                                    '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
                                        color: "gray"
                                    }
                                    // '& .MuiDataGrid-columnHeaderCheckbox': {
                                    //     backgroundColor: Colors.white
                                    // },
                                    ,
                                    '& .MuiDataGrid-columnHeader': {
                                        fontFamily: "Poppins",
                                        // color: "black",
                                        fontWeight: "bold",
                                    },

                                }}
                            />
                        </div>


                    </Box>



                </Box>}



            </Modal>
        </Box>

    )
}

export default DamageLedgerModal