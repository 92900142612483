import React from 'react'
import { Colors } from '../Constant/Color'
import { Button, Box } from '@mui/material'

function CustomButton({ onClick, style, text, icon,disabled=false }) {
    return (
        <Button disabled={disabled} onClick={onClick} sx={{
            backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: 5, padding: "10px 25px 10px 25px", fontSize: "12px", fontFamily: "Poppins", fontWeight: "400", width: "48%", '&:hover': {
                color: Colors.button, // Set your desired hover color
            }, ...style
        }} >
            {text}
            {icon &&
                icon}

        </Button>
    )
}

export default CustomButton
