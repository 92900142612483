import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { lg: "25%", md: "35%", sm: "50%", xs: "100%" },
    borderRadius: "10px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function ProductAddModal({heading, text, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm}) {


    return (
        <div>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[style, { containerStyle }]}>
                    <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                        {heading}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", mt: 1, padding: "5px 10px 5px 10px ", textAlign: "center", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                        {text}
                    </Typography>

                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", ...buttonContainerStyle }} >

                        <Button onClick={closeModal} sx={{ backgroundColor: Colors.white, color: Colors.button, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", ...buttonStyle }} >
                            Cancel
                        </Button>


                        <Button onClick={confirm} sx={{ backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", ...buttonStyle2 }} >
                            Confirm
                        </Button>


                    </Box>



                </Box>



            </Modal>
        </div>
    );
}