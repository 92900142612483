import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Colors } from '../Constant/Color';
import { CalendarIcon } from '@mui/x-date-pickers';
import dayjs from 'dayjs';



export default function DatePickers(prop) {
    const { label, onChange, values, style, noIcon,disabled,selected } = prop
    const [value, setValue] = React.useState(null);
    const [defaultValue, setDefaultValue] = React.useState(values);




    useEffect(() => {

        if (values && typeof values == "string" && values.includes("/")) {
            var dateParts = values?.split('/');
            var formattedDate = new Date(dateParts[2], ((disabled) ? dateParts[1] -1  :  dateParts[1]  ) , ((disabled && !selected) ?  dateParts[0] - 1 : dateParts[0]));
            setDefaultValue(formattedDate);
        }

        else if (values && typeof values == "object") {

            setDefaultValue(values)
        }

        else {
            setDefaultValue(null)
        }

    }, [values])



    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker

                value={dayjs(defaultValue)}
                format="DD/MM/YYYY"
                disabled={disabled}
                slots={{
                    openPickerIcon: () => !noIcon && <CalendarIcon />
                }}

                // value={value !== null ? value : defaultValue}
                sx={{
                    width: "100%", padding: 0, borderRadius: 2, color: Colors.text, '& .MuiInputBase-root': {
                        fontSize: '12px',
                        fontWeight: "bold",
                        color: Colors.text,
                        height: "45px",
                        ...style
                        // Adjust the font size for the input field
                    },
                }}
                size="small"
                onChange={(newValue) => {

                    const dates = newValue
                    const dateOfAdmission = `${dates.$D}/${dates.$M}/${dates.$y}`
                    onChange(dateOfAdmission)
                    setValue(newValue);

                }}
                renderInput={(params) => <TextField  {...params} sx={{ color: Colors.text, padding: 0 }} />}
            />
        </LocalizationProvider>
    );
}
