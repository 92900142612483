import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Colors } from "../../../Constant/Color";
import CustomButton from "../../../Components/Button";
import { DataGrid } from "@mui/x-data-grid";
import { Navigate, useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";
import axios from "axios";
import { Base_Uri } from "../../../Constant/BaseUri";
import { useDispatch, useSelector } from "react-redux";
import CreatePurchaseOrderModal from "../../../Components/createPurchaseOrderModal";
import { updatePurchaseOrders } from "../../../Redux/LoginReduces";
import ViewPurchaseOrder from "../../../Components/ViewPO";

function PurchaseOrders({ barcodeData, setBarcodeRead }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const purchaseOrder = useSelector(
    (state) => state?.loginReducer?.purchaseOrders
  );

  const [gridKey, setGridKey] = useState(0);
  const [searchCategory, setSearchCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [viewPurchaseOrderModal, setViewPurchaseOrderModal] = useState(false);

  const [dataClicked, setDataClicked] = useState([]);
  const [openPurchaseOrderModal, setOpenPurchaseOrderModal] = useState(false);

  const column = [
    {
      field: "id",
      headerName: "S.no",
      headerClassName: "super-app-theme--header",
      width: "75",
    },
    {
      field: "purchaseOrder_number",
      headerName: "P0#",
      headerClassName: "super-app-theme--header",
      width: "75",
    },
    {
      field: "total_items",
      headerName: "Total Items",
      headerClassName: "super-app-theme--header",
      width: "150",
    },
    {
      field: "supplier_name",
      headerName: "Supplier Name",
      headerClassName: "super-app-theme--header",
      width: "200",
    },
    {
      field: "supplier_address",
      headerName: "Supplier Address",
      headerClassName: "super-app-theme--header",
      width: "300",
    },
    {
      field: "supplier_mobile_number",
      headerName: "Supplier Number",
      headerClassName: "super-app-theme--header",
      width: "200",
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "super-app-theme--header",
      width: "150",
      renderCell: (cellValues) => {
        return (
          <p>
            {new Date(cellValues?.row?.date).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </p>
        );
      },
    },
    {
      field: "status",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      width: "150",
      renderCell: (cellValues) => {
        return (
          <p
            style={{
              color:
                cellValues?.row?.status == "pending"
                  ? Colors.red
                  : Colors.green,
            }}
          >
            {cellValues?.row?.status}
          </p>
        );
      },
    },

    {
      field: "action",
      headerName: "View PO",
      headerClassName: "super-app-theme--header",
      width: "150",
      renderCell: (cellValues) => {
        return (
          <CustomButton
            text={"View PO"}
            onClick={() => handleOpenViewPO(cellValues?.row)}
            style={{ width: "200px", borderRadius: 1, padding: "2px" }}
          />
        );
      },
    },
  ];

  const getPurchaseOrders = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getAllPurchaseOrders`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let myData = res.data;

        let { data } = myData;

        if (!data || data.length == 0) {
          setPurchaseOrders([]);
        } else {
          data =
            data &&
            data?.length > 0 &&
            data?.map((e, i) => {
              return {
                ...e,
                id: i + 1,
                supplier_name: e?.supplierDetails?.[0]?.supplier_name,
                supplier_address: e?.supplierDetails?.[0]?.supplier_address,

                supplier_mobile_number:
                  e?.supplierDetails?.[0]?.supplier_mobile_number,

                date: e?.created_at,
              };
            });
          data &&
            data.length > 0 &&
            data.sort((a, b) => {
              if (a.status === "pending" && b.status !== "pending") {
                return -1; // a comes before b
              } else if (a.status !== "pending" && b.status === "pending") {
                return 1; // b comes before a
              } else {
                return 0; // no change in order
              }
            });
          dispatch(updatePurchaseOrders(data));
          setPurchaseOrders(data);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    getPurchaseOrders();
  }, [purchaseOrder?.length]);

  const handleOpenViewPO = (data) => {
    setDataClicked(data);
    setViewPurchaseOrderModal(true);
  };

  const handleSearchPo = (text) => {
    if (!text) {
      setSearchCategory([]);
      return;
    }

    setSearchCategory(
      purchaseOrder &&
        purchaseOrder?.length > 0 &&
        purchaseOrder?.filter((e, i) => {

          if (
            e?.supplier_name?.toLowerCase()?.includes(text?.toLowerCase()) ||
            // e?.purchaseOrder_number?.toString()?.includes(text?.toLowerCase()) ||
            e?.productDetails?.some((j) =>
              j?.product_name?.toLowerCase()?.includes(text?.toLowerCase())
            ) ||
            e?.productDetails?.some((j) =>
              j?.barcode?.toLowerCase()?.includes(text?.toLowerCase())
            )
          ) {
            return e;
          }
        })
    );
  };

  useEffect(() => {
    if (
      barcodeData?.length > 0 &&
      !viewPurchaseOrderModal &&
      !openPurchaseOrderModal
    ) {
      let searchpo =
        purchaseOrder &&
        purchaseOrder?.length > 0 &&
        purchaseOrder?.find((e) =>
          e?.productDetails?.some((j) => j?.barcode == barcodeData)
        );

      if (searchpo) {
        setSearchCategory([searchpo]);
        setBarcodeRead("");
      }
    }
  }, [barcodeData]);
  

  return loading ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "70vh",
        width: "100%",
      }}
    >
      <RingLoader loading={loading} size={100} color={Colors.black} />
      <Typography
        style={{ fontSize: "24px", color: Colors.black, fontFamily: "Poppins" }}
      >
        Getting Purchase Orders...
      </Typography>
    </Box>
  ) : (
    <Box
      sx={{ padding: "20px", backgroundColor: Colors.white, marginTop: "20px" }}
    >
      <Grid md={6} xs={10} sm={10}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            xs={6}
            sm={4}
            md={5}
            lg={4}
            xl={3}
            style={{ marginRight: "5px" }}
          >
            <CustomButton
              onClick={() => setOpenPurchaseOrderModal(true)}
              text="Create New Purchase Order"
              style={{
                width: "100%",
                padding: "10px 5px 10px 5px",
                fontSize: {
                  xl: "12px",
                  lg: "12px",
                  md: "10px",
                  sm: "10px",
                  xs: "8px",
                },
                borderRadius: 1,
                display: "flex",
                justifyContent: "space-around",
                marginRight: "5px",
                alignItems: "center",
              }}
            />
          </Grid>

          <Box
            sx={{
              width: "50%",
              marginTop: { xs: "10px", sm: "10px", md: "0px" },
              border: `1px solid ${Colors.button}`,
              borderRadius: "5px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px 10px 5px 10px",
              margin: 0,
            }}
          >
            <TextField
              onChange={(e) => handleSearchPo(e?.target?.value)}
              variant="standard" // <== changed this
              margin="normal"
              required
              fullWidth
              InputProps={{
                // startAdornment: <AccountCircle />, // <== adjusted this
                disableUnderline: true,
                fontSize: "12px",
                // <== added this
                // fontSize: "2px"
              }}
              placeholder="Search Product Name ,Supplier,Barcode,Po"
              sx={{
                width: "90%",
                fontSize: "12px",
                padding: 0,
                fontFamily: "Poppins",
                fontWeight: "bold",
                margin: 0,
              }}
            />
            <img
              src={require("../../../Images/search.png")}
              style={{ height: "20px" }}
            />
          </Box>
        </Box>
      </Grid>

      <Box sx={{ height: "500px", backgroundColor: "white", marginTop: 5 }}>
        <DataGrid
          key={gridKey}
          sx={{
            "& .MuiDataGrid-cell": {
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: "600",
              color: "#000",
            },

            "& .MuiDataGrid-colCell, & .MuiDataGrid-colCellTitle": {
              padding: "0px", // Adjust this value as needed
            },
            "& .MuiDataGrid-columnHeaderCheckbox": {
              backgroundColor: Colors.black, // Change this to your desired checkbox background color
              color: "white", // Change this to your desired checkbox color
            },

            "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root": {
              color: "gray",
            },
            "& .MuiDataGrid-columnHeader": {
              fontFamily: "Poppins",
              fontWeight: "bold",
            },
          }}
          rows={
            searchCategory.length > 0
              ? searchCategory
              : purchaseOrders && purchaseOrders?.length > 0
              ? purchaseOrders
              : []
          }
          columns={column}
        />
      </Box>

      {openPurchaseOrderModal && (
        <CreatePurchaseOrderModal
          open={openPurchaseOrderModal}
          barcodeData={openPurchaseOrderModal ? barcodeData : ""}
          setBarcodeRead={setBarcodeRead}
          closeModal={() => setOpenPurchaseOrderModal(false)}
        />
      )}

      {viewPurchaseOrderModal && (
        <ViewPurchaseOrder
          data={dataClicked}
          barcodeData={viewPurchaseOrderModal ? barcodeData : ""}
          setBarcodeRead={setBarcodeRead}
          setPurchaseOrders={setPurchaseOrders}
          open={viewPurchaseOrderModal}
          closeModal={() => setViewPurchaseOrderModal(false)}
        />
      )}
    </Box>
  );
}

export default PurchaseOrders;
