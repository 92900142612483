import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { useSelector } from 'react-redux';
import { paymentMethod } from '../Redux/LoginReduces';
import { Document, Page, Text, View, PDFViewer, PDFDownloadLink, Font } from '@react-pdf/renderer';
import MyFontRegular from '../fonts/Poppins-Medium.ttf';
import MyFontBold from '../fonts/Poppins-Bold.ttf';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { RingLoader } from 'react-spinners';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { lg: "50%", md: "60%", sm: "85%", xs: "98%" },
  borderRadius: "10px",
  maxHeight: "80vh",
  overflow: "auto",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function CustomPOSModal({ loading, heading, text, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, specialTextStyle, buttonText, specialText }) {



  Font.register({
    family: 'MyFont',
    fonts: [
      { src: MyFontBold, fontWeight: 'bold' },
      { src: MyFontRegular, fontWeight: 'normal' },
    ]
  });


  const [showChequeDetails, setShowChequeDetails] = React.useState(false)

  const endSummary = useSelector(state => state.loginReducer.endSaleSummary)
  const userData = useSelector((state) => state.loginReducer.updatedState);
  const todayAllInvoices = useSelector(state => state.loginReducer.shiftInvoice)
  const expenses = useSelector(state => state.loginReducer.employeeExpense)
  const employeeRefundData = useSelector(state => state.loginReducer.employeeShiftRefund)
  const employeeCreditPayment = useSelector(state => state?.loginReducer?.shiftCreditPayment)
  const shiftStartTime = useSelector(state => state.loginReducer.shiftStartTime)


  const vat = useSelector(state => state?.loginReducer?.vat)
  const [shiftInvoices, setShiftInvoices] = React.useState([])
  const [employeeInvoices, setEmployeeInvoices] = React.useState([])


  const [refundInvoices, setRefundInvoices] = React.useState([])

  const [creditPayment, setCreditPayment] = React.useState([])


  const [pdfDownloadView, setPdfDownloadView] = React.useState(false)



  const handleSetInvoices = () => {


    setShiftInvoices(todayAllInvoices)

    const groupedData = {};
    todayAllInvoices && todayAllInvoices?.length > 0 && todayAllInvoices.forEach((invoice) => {
      const { businessName } = invoice?.customerDetails[0];

      if (!groupedData[businessName]) {
        groupedData[businessName] = [];
      }
      groupedData[businessName].push(invoice);
    });

    setEmployeeInvoices(groupedData)


  }


  const handleSetRefundData = () => {

    const groupedData = {}

    employeeRefundData && employeeRefundData?.length > 0 && employeeRefundData.forEach((invoice) => {
      const { business_name } = invoice?.customerData;

      if (!groupedData[business_name]) {
        groupedData[business_name] = [];
      }
      groupedData[business_name].push(invoice);
    });

    setRefundInvoices(groupedData)
  }

  const handleSetCreditPaymentData = () => {

    const groupedData = {}

    employeeCreditPayment && employeeCreditPayment?.length > 0 && employeeCreditPayment.forEach((invoice) => {
      const { business_name } = invoice?.customerData;

      if (!groupedData[business_name]) {
        groupedData[business_name] = [];
      }
      groupedData[business_name].push(invoice);
    });

    setCreditPayment(groupedData)
  }

  React.useEffect(() => {


    handleSetInvoices()
    handleSetRefundData()
    handleSetCreditPaymentData()

  }, [open])




  const UserSaleReportHeader = () => {

    return (
      <View fixed >

        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10, alignItems: "flex-start" }} >
          <View>
            <Text style={{ color: "black", fontSize: "14px", fontFamily: "MyFont", fontWeight: "bold" }} >{vat?.companyName}</Text>
            <Text style={{ color: "black", fontSize: "12px", fontFamily: "MyFont", fontWeight: "normal" }} >User report for {userData?.fullName}</Text>
          </View>



          <View>

            <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
              Print Date:   {
                new Date().toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              }



              <Text style={{ marginLeft: "5px" }} >  {new Date().toLocaleTimeString()}
              </Text>
            </Text>



            <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal", marginTop: "10px" }} >
              Shift Start:   {
                new Date(shiftStartTime).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              }



              <Text style={{ marginLeft: "5px" }} >  {new Date(shiftStartTime).toLocaleTimeString()}
              </Text>
            </Text>




            <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal", marginTop: "10px" }} >
              Shift End:   {
                new Date().toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              }



              <Text style={{ marginLeft: "5px" }} >  {new Date().toLocaleTimeString()}
              </Text>
            </Text>

          </View>


        </View>


      </View>
    )
  }

  const UserSaleReportDocument = () => (
    <Document size="A4"   >

      {/* <Header /> */}

      <Page style={{ padding: "0px 10px" }} orientation="landscape" >

        <UserSaleReportHeader />

        <View>

          <Text style={{ fontSize: 12, fontWeight: "bold", color: "black", fontFamily: "MyFont", width: "100%", marginTop: "10px" }} >
            Invoice Details:
          </Text>


          {Object.entries(employeeInvoices).map(([key, value]) => {
            return (
              <View>

                <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", color: "red" }} >
                  {key}
                </Text>

                <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-between", borderBottomWidth: 1, }} >


                  <View style={{ flex: 1 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Date
                    </Text>

                  </View>

                  <View style={{ flex: 1 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Invoice Amount
                    </Text>

                  </View>


                  <View style={{ flex: 1 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Paid
                    </Text>
                  </View>


                  <View style={{ flex: 2 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      MOP
                    </Text>

                  </View>



                  <View style={{ flex: 1 }} >

                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Cash
                    </Text>

                  </View>

                  <View style={{ flex: 1 }} >

                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      On Account
                    </Text>
                  </View>
                  <View style={{ flex: 1 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Card
                    </Text>
                  </View>

                  <View style={{ flex: 1 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Online
                    </Text>
                  </View>

                  <View style={{ flex: 1 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Cheque
                    </Text>
                  </View>



                  <View style={{ flex: 1 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Invoice Type
                    </Text>
                  </View>

                </View>


                {value && value.length > 0 && value?.map((e, i) => {
                  return (
                    <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-between", borderBottomWidth: 1, }} >



                      <View style={{ flex: 1 }} >


                        <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                          {
                            new Date(e?.saleDate ? e?.saleDate : e?.saleReturnDate ? e?.saleReturnDate : e?.exchangeDate).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                            })
                          }

                        </Text>

                      </View>


                      <View style={{ flex: 1 }} >


                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          {e?.saleReturnDate && "-"} £{Number(e?.subtotal).toFixed(2)}
                        </Text>
                      </View>


                      <View style={{ flex: 1 }} >


                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          £{Number(e?.cash || 0) + Number(e?.online || 0) + Number(e?.card || 0)}
                        </Text>
                      </View>


                      <View style={{ flex: 2 }} >


                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          {e?.paymentMethod && Array?.isArray(e?.paymentMethod) ? e?.paymentMethod?.map((e) => e).join(",") : e?.paymentMethod}
                        </Text>

                      </View>



                      <View style={{ flex: 1 }} >
                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          {e?.paymentMethod && !Array?.isArray(e?.paymentMethod) && e?.paymentMethod?.toLowerCase() == "refund cash" ? `£${-Number(e?.subtotal).toFixed(2)}` :

                            e?.paymentMethod && Array?.isArray(e?.paymentMethod) && e?.paymentMethod?.some((j) => j?.toLowerCase() == "refund cash") ? `£${Number(e?.subtotal).toFixed(2)}`

                              : `£${e?.cash || 0}`}
                        </Text>
                      </View>

                      <View style={{ flex: 1 }} >

                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          {e?.paymentMethod && !Array?.isArray(e?.paymentMethod) && e?.paymentMethod?.toLowerCase() == "credit note" ? `£${-Number(e?.subtotal).toFixed(2)}` :

                            e?.paymentMethod && Array?.isArray(e?.paymentMethod) && e?.paymentMethod?.some((j) => j?.toLowerCase() == "credit note") ? `£${Number(e?.subtotal).toFixed(2)}` :

                              `£${e?.credit || 0}`}
                        </Text>
                      </View>
                      <View style={{ flex: 1 }} >


                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          {e?.paymentMethod && !Array?.isArray(e?.paymentMethod) && e?.paymentMethod?.toLowerCase() == "refund card" ? `£${-Number(e?.subtotal).toFixed(2)}` :

                            e?.paymentMethod && Array?.isArray(e?.paymentMethod) && e?.paymentMethod?.some((j) => j?.toLowerCase() == "refund card") ? `£${Number(e?.subtotal).toFixed(2)}`

                              : `£${e?.card || 0}`}
                        </Text>
                      </View>

                      <View style={{ flex: 1 }} >


                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          £{e?.online || 0}
                        </Text>
                      </View>

                      <View style={{ flex: 1 }} >


                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          £{e?.cheque || 0}
                        </Text>
                      </View>


                      <View style={{ flex: 1 }} >


                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          {e?.exchangeDate ? "Exchange" : e?.status}
                        </Text>
                      </View>

                    </View>

                  )
                })}


                <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", borderBottomWidth: 1 }} >

                  <View style={{ flex: 1 }} >
                    <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                      Total
                    </Text>
                  </View>


                  <View style={{ flex: 1 }} >
                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      £{Number(value && value?.length > 0 && value?.reduce((pre, curr) => {



                        if (curr?.saleReturnDate) {
                          return (pre || 0) - Number(curr?.subtotal)
                        }
                        else {
                          return (pre || 0) + Number(curr?.subtotal)
                        }

                      }, 0)).toFixed(2)}
                    </Text>
                  </View>


                  <View style={{ flex: 1 }} >
                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      £{Number(value && value?.length > 0 && value?.reduce((pre, curr) => {

                        return (pre || 0) + Number(Number(curr?.cash || 0) + Number(curr?.online || 0) + Number(curr?.card || 0))

                      }, 0)).toFixed(2)}
                    </Text>
                  </View>



                  <View style={{ flex: 2 }} >

                  </View>


                  <View style={{ flex: 1 }} >

                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      £{Number(value && value?.length > 0 && value?.reduce((pre, curr) => {

                        if (curr?.paymentMethod && !Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.toLowerCase() == "refund cash") {

                          return (pre || 0) - Number(curr?.subtotal)

                        }
                        else if (curr?.paymentMethod && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((j) => j.toLowerCase() == "refund cash")) {

                          return (pre || 0) + Number(curr?.subtotal)

                        }

                        else {

                          return (pre || 0) + Number(curr?.cash || 0)
                        }
                      }, 0)).toFixed(2)}
                    </Text>

                  </View>



                  <View style={{ flex: 1 }} >

                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      £{Number(value && value?.length > 0 && value?.reduce((pre, curr) => {

                        if (curr?.paymentMethod && !Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.toLowerCase() == "credit note") {

                          return (pre || 0) - Number(curr?.subtotal)

                        }

                        else if (curr?.paymentMethod && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((j) => j.toLowerCase() == "credit note")) {

                          return (pre || 0) + Number(curr?.subtotal)

                        }

                        else {

                          return (pre || 0) + Number(curr?.credit || 0)
                        }
                      }, 0)).toFixed(2)}
                    </Text>

                  </View>



                  <View style={{ flex: 1 }} >

                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      £{Number(value && value?.length > 0 && value?.reduce((pre, curr) => {

                        if (curr?.paymentMethod && !Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.toLowerCase() == "refund card") {

                          return (pre || 0) - Number(curr?.subtotal)

                        }
                        else if (curr?.paymentMethod && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((j) => j.toLowerCase() == "refund card")) {

                          return (pre || 0) + Number(curr?.subtotal)

                        }

                        else {

                          return (pre || 0) + Number(curr?.card || 0)
                        }
                      }, 0)).toFixed(2)}
                    </Text>




                  </View>



                  <View style={{ flex: 1 }} >

                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      £{Number(value && value?.length > 0 && value?.reduce((pre, curr) => {


                        return (pre || 0) + Number(curr?.online || 0)


                      }, 0)).toFixed(2)}
                    </Text>


                  </View>


                  <View style={{ flex: 1 }} >

                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      £{Number(value && value?.length > 0 && value?.reduce((pre, curr) => {



                        return (pre || 0) + Number(curr?.cheque || 0)


                      }, 0)).toFixed(2)}
                    </Text>

                  </View>

                  <View style={{ flex: 1 }} ></View>





                </View>


              </View>

            )

          })}

        </View>


        <View>

          <Text style={{ fontSize: 12, fontWeight: "bold", color: "black", fontFamily: "MyFont", width: "100%", marginTop: "10px" }} >
            Refund Details:
          </Text>


          {Object.keys(refundInvoices).length > 0 && Object.entries(refundInvoices).map(([key, value]) => {
            return (
              <View>

                <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", color: "red" }} >
                  {key}
                </Text>

                <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-between", borderBottomWidth: 1, }} >


                  <View style={{ flex: 1 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Date
                    </Text>

                  </View>

                  <View style={{ flex: 1 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Refund Amount
                    </Text>

                  </View>


                  <View style={{ flex: 2 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Payment Method
                    </Text>
                  </View>


                  <View style={{ flex: 2 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Transaction Type
                    </Text>

                  </View>



                  <View style={{ flex: 5 }} >

                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Reason
                    </Text>

                  </View>

                </View>


                {value && value.length > 0 && value?.map((e, i) => {
                  return (
                    <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-between", borderBottomWidth: 1, }} >



                      <View style={{ flex: 1 }} >


                        <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                          {
                            new Date(e?.date).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                            })
                          }

                        </Text>

                      </View>


                      <View style={{ flex: 1 }} >


                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          £{Number(e?.refund).toFixed(2)}
                        </Text>
                      </View>


                      <View style={{ flex: 2 }} >


                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          {e?.paymentMethod[0]}
                        </Text>
                      </View>


                      <View style={{ flex: 2 }} >


                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          {e?.transactionType}
                        </Text>

                      </View>



                      <View style={{ flex: 5 }} >
                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          {e?.reason}
                        </Text>
                      </View>
                    </View>

                  )
                })}


                <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", borderBottomWidth: 1 }} >

                  <View style={{ flex: 1 }} >
                    <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                      Total
                    </Text>
                  </View>


                  <View style={{ flex: 1 }} >
                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      £{Number(value && value?.length > 0 && value?.reduce((pre, curr) => {

                        return (pre || 0) + Number(curr?.refund)


                      }, 0)).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 9 }} ></View>






                </View>


              </View>

            )

          })}




        </View>


        <View>

          <Text style={{ fontSize: 12, fontWeight: "bold", color: "black", fontFamily: "MyFont", width: "100%", marginTop: "10px" }} >
            Credit Payment By Customer:
          </Text>


          {Object.keys(creditPayment).length > 0 && Object.entries(creditPayment).map(([key, value]) => {
            return (
              <View>

                <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", color: "red" }} >
                  {key}
                </Text>

                <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-between", borderBottomWidth: 1, }} >


                  <View style={{ flex: 1 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Date
                    </Text>

                  </View>

                  <View style={{ flex: 1 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Total Amount
                    </Text>

                  </View>


                  <View style={{ flex: 2 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Payment Method
                    </Text>
                  </View>


                  <View style={{ flex: 2 }} >


                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Transaction Type
                    </Text>

                  </View>



                  <View style={{ flex: 5 }} >

                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      Reason
                    </Text>

                  </View>

                </View>


                {value && value.length > 0 && value?.map((e, i) => {
                  return (
                    <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-between", borderBottomWidth: 1, }} >



                      <View style={{ flex: 1 }} >


                        <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                          {
                            new Date(e?.date).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                            })
                          }

                        </Text>

                      </View>


                      <View style={{ flex: 1 }} >


                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          £{Number(e?.deductCredit).toFixed(2)}
                        </Text>
                      </View>


                      <View style={{ flex: 2 }} >


                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          {e?.paymentMethod[0]}
                        </Text>
                      </View>


                      <View style={{ flex: 2 }} >


                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          {e?.transactionType}
                        </Text>

                      </View>


                      <View style={{ flex: 5 }} >
                        <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                          {e?.reason}
                        </Text>
                      </View>
                    </View>

                  )
                })}


                <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", borderBottomWidth: 1 }} >

                  <View style={{ flex: 1 }} >
                    <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                      Total
                    </Text>
                  </View>


                  <View style={{ flex: 1 }} >
                    <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                      £{Number(value && value?.length > 0 && value?.reduce((pre, curr) => {

                        return (pre || 0) + Number(curr?.deductCredit)


                      }, 0)).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 9 }} ></View>

                </View>


              </View>

            )

          })}




        </View>



        <View style={{ marginTop: "20px" }} >
          <Text style={{ fontSize: 12, fontWeight: "bold", color: "black", fontFamily: "MyFont", width: "100%" }} >
            Expense Details:
          </Text>


          <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-between", borderBottomWidth: 1, }} >


            <View style={{ flex: 1 }} >


              <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                Date
              </Text>

            </View>

            <View style={{ flex: 9 }} >


              <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                description
              </Text>

            </View>

            <View style={{ flex: 1 }} >


              <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                Amount
              </Text>

            </View>




          </View>


          {expenses && expenses.length > 0 && expenses.map((e, i) => {

            return (
              <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-between", borderBottomWidth: 1, }} >




                <View style={{ flex: 1 }} >
                  <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                    {
                      new Date(e?.date ? e?.date : e?.created_at).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      })
                    }
                  </Text>
                </View>

                <View style={{ flex: 9 }} >
                  <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                    {e?.description}
                  </Text>
                </View>



                <View style={{ flex: 1 }} >


                  <Text style={{ fontSize: 8, fontWeight: "normal", fontFamily: "MyFont" }} >
                    £{e?.amount || 0}
                  </Text>
                </View>

              </View>
            )
          })}

          <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", borderBottomWidth: 1 }} >

            <View style={{ flex: 10 }} >
              <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                Total
              </Text>
            </View>


            <View style={{ flex: 1 }} >

              <Text style={{ fontSize: 8, fontWeight: "bold", fontFamily: "MyFont" }} >
                £{Number(expenses && expenses?.length > 0 && expenses?.reduce((pre, curr) => {


                  return (pre || 0) + Number(curr?.amount || 0)


                }, 0)).toFixed(2)}
              </Text>

            </View>







          </View>








        </View>


        <View style={{ marginTop: "20px" }} >
          <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", borderBottomWidth: 1, width: "100%" }} >
            Payment Summary:
          </Text>


          <View style={{ marginTop: "10px" }} >

            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", }} >
              Cash:  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont", }} >
                £{Number(shiftInvoices && shiftInvoices?.length > 0 ? shiftInvoices?.reduce((pre, curr) => {

                    return (pre || 0) + (curr?.cash || 0)
                  
                }, 0) : 0).toFixed(2)}    </Text>
            </Text>

            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", }} >
              Cash Returns:  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont", }} >
                £{Number(shiftInvoices && shiftInvoices?.length > 0 ? shiftInvoices?.reduce((pre, curr) => {


                  if (curr?.paymentMethod && !Array.isArray(curr?.paymentMethod) && curr?.paymentMethod?.toLowerCase() == "refund cash") {
                    return (pre || 0) - (curr?.subtotal || 0)
                  }

                  else if (curr?.paymentMethod && Array.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((j) => j.toLowerCase() == "refund cash")) {

                    return (pre || 0) + (curr?.subtotal || 0)

                  }

                  else {
                    return (pre || 0)
                  }
                }, 0) : 0).toFixed(2)}    </Text>
            </Text>

            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", }} >
              Cash Refunds:  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont", }} >
                £({Number(employeeRefundData && employeeRefundData?.length > 0 ? employeeRefundData?.reduce((pre, curr) => {
                  if (curr?.paymentMethod && Array.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e) => e.toLowerCase() == "cash")) {
                    return (pre || 0) + (curr?.refund || 0)
                  } else {
                    return pre
                  }

                }, 0) : 0).toFixed(2)})    </Text>
            </Text>

            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", }} >
              Credit Payments:  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont", }} >
                £{Number(employeeCreditPayment && employeeCreditPayment?.length > 0 ? employeeCreditPayment?.reduce((pre, curr) => {
                  if (curr?.paymentMethod && Array.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e) => e.toLowerCase() == "cash")) {
                    return (pre || 0) + (curr?.deductCredit || 0)
                  } else {
                    return pre
                  }

                }, 0) : 0).toFixed(2)}    </Text>
            </Text>


            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", }} >
              Cash Expenses:  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont", }} >
                £({Number(expenses && expenses?.length > 0 ? expenses?.reduce((pre, curr) => {
                  return (pre || 0) + (Number(curr?.amount) || 0)
                }, 0) : 0).toFixed(2)})    </Text>
            </Text>




            <Text style={{ color: "red", fontFamily: "MyFont", fontWeight: "bold", fontSize: 10, borderBottomWidth: 1, borderBottomColor: "black" }} >




              Total Remaining Cash:    £{Number(Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {




                if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "refund cash") {

                  return (pre || 0) - curr?.subtotal


                }

                else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "refund cash")) {


                  return (pre || 0) + curr?.subtotal
                }

                else {

                  return (pre || 0) + (curr?.cash || 0)

                }



              }, 0))


                + Number(employeeCreditPayment && employeeCreditPayment?.length > 0 && employeeCreditPayment?.reduce((pre, curr) => {



                  if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "cash")) {

                    return (pre || 0) + curr?.deductCredit


                  } else {
                    return pre
                  }


                }, 0))


                - Number(employeeRefundData && employeeRefundData?.length > 0 && employeeRefundData?.reduce((pre, curr) => {



                  if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "cash")) {

                    return (pre || 0) + curr?.refund


                  } else {
                    return pre
                  }


                }, 0))

                -

                Number(expenses && expenses?.length > 0 && expenses?.reduce((pre, curr) => {


                  return (pre || 0) + Number(curr?.amount)


                }, 0))

              ).toFixed(2)}


            </Text>






            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", }} >
              On Account:  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont", }} >
                £{Number(shiftInvoices && shiftInvoices?.length > 0 ? shiftInvoices?.reduce((pre, curr) => {


                    return (pre || 0) + (curr?.credit || 0)

                }, 0) : 0).toFixed(2)}    </Text>
            </Text>

            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", }} >
              On Account Returns:  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont", }} >
                £{Number(shiftInvoices && shiftInvoices?.length > 0 ? shiftInvoices?.reduce((pre, curr) => {


                  if (curr?.paymentMethod && !Array.isArray(curr?.paymentMethod) && curr?.paymentMethod?.toLowerCase() == "credit note") {
                    return (pre || 0) - (curr?.subtotal || 0)
                  }

                  else if (curr?.paymentMethod && Array.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((j) => j.toLowerCase() == "credit note")) {

                    return (pre || 0) + (curr?.subtotal || 0)

                  }

                  else {
                    return (pre || 0)
                  }
                }, 0) : 0).toFixed(2)}    </Text>
            </Text>


            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", }} >
              On Account Refunds :  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont", }} >
                £({Number(employeeRefundData && employeeRefundData?.length > 0 ? employeeRefundData?.reduce((pre, curr) => {
                  if (curr?.paymentMethod && Array.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e) => e.toLowerCase() == "onaccount")) {
                    return (pre || 0) + (curr?.refund || 0)
                  } else {
                    return pre
                  }

                }, 0) : 0).toFixed(2)})    </Text>
            </Text>



            <Text style={{ color: "red", fontFamily: "MyFont", fontWeight: "bold", fontSize: 10, borderBottomWidth: 1, borderBottomColor: "black" }} >




              Total On Accounts:

              £{
                Number(

                  Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                    if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "credit note") {

                      return (pre || 0) - curr?.subtotal

                    }
                    else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "credit note")) {


                      return (pre || 0) + curr?.subtotal
                    }
                    else {

                      return (pre || 0) + (curr?.credit || 0)

                    }



                  }, 0))

                  -

                  Number(employeeRefundData && employeeRefundData?.length > 0 && employeeRefundData?.reduce((pre, curr) => {



                    if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "onaccount")) {

                      return (pre || 0) + curr?.refund

                    } else {
                      return pre
                    }


                  }, 0))



                ).toFixed(2)



              }

            </Text>





            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", }} >
              Card:  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont", }} >
                £{Number(shiftInvoices && shiftInvoices?.length > 0 ? shiftInvoices?.reduce((pre, curr) => {

                  return (pre || 0) + (curr?.card || 0)

                }, 0) : 0).toFixed(2)}    </Text>
            </Text>

            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", }} >
              Card Returns:  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont", }} >
                £{Number(shiftInvoices && shiftInvoices?.length > 0 ? shiftInvoices?.reduce((pre, curr) => {


                  if (curr?.paymentMethod && !Array.isArray(curr?.paymentMethod) && curr?.paymentMethod?.toLowerCase() == "refund card") {
                    return (pre || 0) - (curr?.subtotal || 0)
                  }

                  else if (curr?.paymentMethod && Array.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((j) => j.toLowerCase() == "refund card")) {

                    return (pre || 0) - (curr?.subtotal || 0)

                  }

                  else {
                    return (pre || 0)
                  }
                }, 0) : 0).toFixed(2)}    </Text>
            </Text>


            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }} >
              Card Refunds :  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont", }} >
                £({Number(employeeRefundData && employeeRefundData?.length > 0 ? employeeRefundData?.reduce((pre, curr) => {
                  if (curr?.paymentMethod && Array.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e) => e.toLowerCase() == "card")) {
                    return (pre || 0) + (curr?.refund || 0)
                  } else {
                    return pre
                  }

                }, 0) : 0).toFixed(2)})    </Text>
            </Text>



            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", }} >
              Credit Payments:  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont", }} >
                £{Number(employeeCreditPayment && employeeCreditPayment?.length > 0 ? employeeCreditPayment?.reduce((pre, curr) => {
                  if (curr?.paymentMethod && Array.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e) => e.toLowerCase() == "card")) {
                    return (pre || 0) + (curr?.deductCredit || 0)
                  } else {
                    return pre
                  }

                }, 0) : 0).toFixed(2)}    </Text>
            </Text>

            <Text style={{ color: "red", fontFamily: "MyFont", fontWeight: "bold", fontSize: 10, borderBottomWidth: 1, borderBottomColor: "black" }} >Total Remaining Card:- £{Number(Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

              if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "refund card") {

                return (pre || 0) - curr?.subtotal

              }
              else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "refund card")) {


                return (pre || 0) + curr?.subtotal
              }
              else {

                return (pre || 0) + (curr?.card || 0)

              }



            }, 0))


              + Number(employeeCreditPayment && employeeCreditPayment?.length > 0 && employeeCreditPayment?.reduce((pre, curr) => {



                if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "card")) {

                  return (pre || 0) + curr?.deductCredit


                } else {
                  return pre
                }


              }, 0))

              - Number(employeeRefundData && employeeRefundData?.length > 0 && employeeRefundData?.reduce((pre, curr) => {



                if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "card")) {

                  return (pre || 0) + curr?.refund


                } else {
                  return pre
                }


              }, 0))

            ).toFixed(2)}


            </Text>


            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }} >
              Bank Transfer:  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont" }} >
                £{Number(shiftInvoices && shiftInvoices?.length > 0 ? shiftInvoices?.reduce((pre, curr) => {

                  return (pre || 0) + (curr?.online || 0)

                }, 0) : 0).toFixed(2)}    </Text>
            </Text>




            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", }} >
              Credit Payments:  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont", }} >
                £{Number(Number(employeeCreditPayment && employeeCreditPayment?.length > 0 ? employeeCreditPayment?.reduce((pre, curr) => {
                  if (curr?.paymentMethod && Array.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e) => e.toLowerCase() == "bank transfer")) {
                    return (pre || 0) + (curr?.deductCredit || 0)
                  } else {
                    return pre
                  }

                }, 0) : 0)



                ).toFixed(2)}    </Text>
            </Text>

            <Text style={{ color: "red", fontFamily: "MyFont", fontWeight: "bold", fontSize: 10, borderBottomWidth: 1, borderBottomColor: "black" }} >


              Total Bank Transfer:   £{Number(Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                return (pre || 0) + (curr?.online || 0)

              }, 0))

                + Number(employeeCreditPayment && employeeCreditPayment?.length > 0 && employeeCreditPayment?.reduce((pre, curr) => {



                  if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "bank transfer")) {

                    return (pre || 0) + curr?.deductCredit


                  } else {
                    return pre
                  }


                }, 0))



              ).toFixed(2)}
            </Text>



            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", }} >
              Cheques:  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont", }} >
                £{Number(shiftInvoices && shiftInvoices?.length > 0 ? shiftInvoices?.reduce((pre, curr) => {

                  return (pre || 0) + (curr?.cheque || 0)

                }, 0) : 0).toFixed(2)}    </Text>
            </Text>


            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont", }} >
              Credit Payments:  <Text style={{ fontSize: 10, fontWeight: "normal", fontFamily: "MyFont", }} >
                £{Number(Number(employeeCreditPayment && employeeCreditPayment?.length > 0 ? employeeCreditPayment?.reduce((pre, curr) => {
                  if (curr?.paymentMethod && Array.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e) => e.toLowerCase() == "cheque")) {
                    return (pre || 0) + (curr?.deductCredit || 0)
                  } else {
                    return pre
                  }

                }, 0) : 0)



                ).toFixed(2)}    </Text>
            </Text>


            <Text style={{ color: "red", fontFamily: "MyFont", fontWeight: "bold", fontSize: 10 }} >


              Total Cheques:   £{Number(Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                return (pre || 0) + (curr?.cheque || 0)

              }, 0))

                + Number(employeeCreditPayment && employeeCreditPayment?.length > 0 && employeeCreditPayment?.reduce((pre, curr) => {



                  if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "cheque")) {

                    return (pre || 0) + curr?.deductCredit


                  } else {
                    return pre
                  }


                }, 0))


              ).toFixed(2)}
            </Text>







            <View style={{ marginTop: "10px", borderTopWidth: 1 }} >






              <Text style={{ color: "black", fontFamily: "MyFont", fontSize: 10 }}>
                Total Sales:  £{Number(shiftInvoices && shiftInvoices.length > 0 && shiftInvoices.reduce((pre, curr) => {


                  if (curr?.saleDate) {
                    return (pre || 0) + Number(curr?.subtotal)
                  } else {
                    return pre
                  }
                }, 0)).toFixed(2)}
              </Text>





              <Text style={{ color: "black", fontFamily: "MyFont", fontSize: 10 }}>
                Total Returns: £-{Number(shiftInvoices && shiftInvoices.length > 0 && shiftInvoices.reduce((pre, curr) => {


                  if (curr?.saleReturnDate) {
                    return (pre || 0) + Number(curr?.subtotal)
                  } else {
                    return pre
                  }
                }, 0)).toFixed(2)}
              </Text>


              <Text style={{ color: "black", fontFamily: "MyFont", fontSize: 10 }}>
                Total Exchange:  £{Number(shiftInvoices && shiftInvoices.length > 0 && shiftInvoices.reduce((pre, curr) => {


                  if (curr?.exchangeDate) {
                    return (pre || 0) + Number(curr?.subtotal)
                  } else {
                    return pre
                  }
                }, 0)).toFixed(2)}
              </Text>


              <Text style={{ fontSize: 14, fontWeight: "bold", fontFamily: "MyFont", color: "black", borderTopWidth: 1 }} >
                Net Sales:  <Text style={{ fontSize: 16, fontWeight: "normal", fontFamily: "MyFont", color: "red" }} >
                  £{Number(shiftInvoices && shiftInvoices?.length > 0 ? shiftInvoices?.reduce((pre, curr) => {

                    if (curr?.paymentMethod && !Array.isArray(curr?.paymentMethod) && (curr?.paymentMethod?.toLowerCase() == "refund cash" || curr?.paymentMethod?.toLowerCase() == "credit note" || curr?.paymentMethod?.toLowerCase() == "refund card")) {
                      return (pre || 0) - (curr?.subtotal || 0)
                    }

                    if (curr?.paymentMethod && Array.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e) => e?.toLowerCase() == "refund cash" || e?.toLowerCase() == "credit note" || e?.toLowerCase() == "refund card")) {
                      return (pre || 0) + (curr?.subtotal || 0)
                    }

                    else {
                      return (pre || 0) + (curr?.subtotal || 0)
                    }
                  }, 0) : 0).toFixed(2)}    </Text>
              </Text>



              <Text style={{ fontSize: 14, color: "black", fontWeight: "bold", fontFamily: "MyFont" }} >
                Gross Collection:  <Text style={{ color: "red", fontFamily: "MyFont", fontWeight: "bold", fontSize: 16 }}>
                  £{Number(

                    Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                      if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "refund cash") {

                        return (pre || 0) - curr?.subtotal


                      }

                      else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "refund cash")) {


                        return (pre || 0) + curr?.subtotal
                      }

                      else {

                        return (pre || 0) + (curr?.cash || 0)

                      }



                    }, 0))

                    +

                    Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                      if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "refund card") {

                        return (pre || 0) - curr?.subtotal

                      }

                      else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "refund card")) {


                        return (pre || 0) + curr?.subtotal
                      }

                      else {

                        return (pre || 0) + (curr?.card || 0)

                      }



                    }, 0))

                    +


                    Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                      return (pre || 0) + (curr?.online || 0)

                    }, 0))

                    +


                    Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                      if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "credit note") {

                        return (pre || 0) - curr?.subtotal

                      }
                      else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "credit note")) {


                        return (pre || 0) + curr?.subtotal
                      }
                      else {

                        return (pre || 0) + (curr?.credit || 0)

                      }



                    }, 0))

                    +

                    Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {


                      return (pre || 0) + (curr?.cheque || 0)





                    }, 0))

                  ).toFixed(2)}
                </Text>
              </Text>




              <Text style={{ fontSize: 14, color: "black", fontWeight: "bold", fontFamily: "MyFont", borderTopWidth: 1, borderTopColor: "black" }} >
                Extra cash received on invoices:  <Text style={{ color: "red", fontFamily: "MyFont", fontWeight: "bold", fontSize: 16 }}>
                  £{Number(




                    (Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                      if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "refund cash") {

                        return (pre || 0) - curr?.subtotal

                      }

                      else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "refund cash")) {


                        return (pre || 0) + curr?.subtotal
                      }

                      else {

                        return (pre || 0) + (curr?.cash || 0)

                      }



                    }, 0))

                      +

                      Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                        if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "refund card") {

                          return (pre || 0) - curr?.subtotal

                        }

                        else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "refund card")) {


                          return (pre || 0) + curr?.subtotal
                        }

                        else {

                          return (pre || 0) + (curr?.card || 0)

                        }



                      }, 0))

                      +


                      Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                        return (pre || 0) + (curr?.online || 0)

                      }, 0))

                      +


                      Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                        if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "credit note") {

                          return (pre || 0) - curr?.subtotal

                        }
                        else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "credit note")) {


                          return (pre || 0) + curr?.subtotal
                        }
                        else {

                          return (pre || 0) + (curr?.credit || 0)

                        }



                      }, 0))

                      +

                      Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {


                        return (pre || 0) + (curr?.cheque || 0)





                      }, 0)))

                    -
                    Number(shiftInvoices && shiftInvoices.length > 0 && shiftInvoices.reduce((pre, curr) => {

                      if (curr?.saleReturnDate) {
                        return (pre || 0) - Number(curr?.subtotal)
                      }
                      else {
                        return (pre || 0) + Number(curr?.subtotal)
                      }
                    }, 0))



                  ).toFixed(2)}
                </Text>
              </Text>





            </View>

          </View>


        </View>


      </Page>
    </Document >
  );


  return (
    pdfDownloadView ? <Box style={{ wodth: "100%", height: "100%", display: "flex", justifyContent: "center" }} >

      <PDFViewer width={850} height={700} >
        <UserSaleReportDocument />
      </PDFViewer>

      <CloseOutlinedIcon fontSize="large" style={{ color: Colors.black, cursor: "pointer", }} onClick={() => setPdfDownloadView(false)} />

    </Box>
      : <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={[style, { containerStyle }]}>
            <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", mb: 0, textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
              {"End Shift Summary"}
            </Typography>


            <Typography sx={{ fontSize: "24px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", textDecoration: "underline", ...textStyle }} id="modal-modal-description" >
              {"Sale Summary:"}
            </Typography>


            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Sales Invoices
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >{shiftInvoices && shiftInvoices?.length > 0 ? shiftInvoices?.filter((e, i) => e?.status?.toLowerCase() == "sale").length : 0}</span>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Return Invoices
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >{shiftInvoices && shiftInvoices?.length > 0 ? shiftInvoices?.filter((e, i) => e?.saleReturnDate).length : 0}</span>
            </Box>


            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid black" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Exchange Invoices
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >{shiftInvoices && shiftInvoices?.length > 0 ? shiftInvoices?.filter((e, i) => e?.exchangeDate).length : 0}</span>
            </Box>


            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid black" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Total Invoices
              </Typography>

              <span style={{ color: "red", fontWeight: "bold", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >{shiftInvoices.length}</span>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "5px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Cash
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >£{
                Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                  if (curr?.cash) {

                    return (pre || 0) + (curr?.cash || 0)

                  } else {
                    return Number(pre || 0)
                  }



                }, 0)).toFixed(2)}</span>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 0, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Cash Returns
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >£({Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {



                if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "refund cash") {

                  return (pre || 0) - curr?.subtotal


                }

                else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "refund cash")) {


                  return (pre || 0) + curr?.subtotal
                }

                else {
                  return Number(pre || 0)
                }


              }, 0)).toFixed(2)})</span>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 0, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Cash Refunds
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >£({Number(employeeRefundData && employeeRefundData?.length > 0 && employeeRefundData?.reduce((pre, curr) => {



                if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "cash")) {

                  return (pre || 0) + curr?.refund


                } else {
                  return pre
                }


              }, 0)).toFixed(2)})</span>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 0, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Credit Payments
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >£{Number(employeeCreditPayment && employeeCreditPayment?.length > 0 && employeeCreditPayment?.reduce((pre, curr) => {



                if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "cash")) {

                  return (pre || 0) + curr?.deductCredit


                } else {
                  return pre
                }


              }, 0)).toFixed(2)}</span>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 0, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Cash Expenses
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >£({Number(expenses && expenses?.length > 0 && expenses?.reduce((pre, curr) => {


                return (pre || 0) + Number(curr?.amount)


              }, 0)).toFixed(2)})</span>
            </Box>


            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px", borderTop: "1px solid black", borderBottom: "1px solid black" }} >

              <Typography sx={{ fontSize: "18px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", mt: 0, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Total Remaining Cash
              </Typography>


              <span style={{ color: "red", fontFamily: "Poppins", fontWeight: "bold", fontSize: "20px", ...specialTextStyle }} >£{Number(

                Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {



                  if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "refund cash") {

                    return (pre || 0) - curr?.subtotal


                  }

                  else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "refund cash")) {


                    return (pre || 0) + curr?.subtotal
                  }

                  else {
                    return Number((curr.cash || 0) + pre)
                  }


                }, 0))

                - Number(employeeRefundData && employeeRefundData?.length > 0 && employeeRefundData?.reduce((pre, curr) => {



                  if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "cash")) {

                    return (pre || 0) + curr?.refund


                  } else {
                    return pre
                  }


                }, 0))




                + Number(employeeCreditPayment && employeeCreditPayment?.length > 0 && employeeCreditPayment?.reduce((pre, curr) => {



                  if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "cash")) {

                    return (pre || 0) + curr?.deductCredit


                  } else {
                    return pre
                  }


                }, 0))


                -

                Number(expenses && expenses?.length > 0 && expenses?.reduce((pre, curr) => {


                  return (pre || 0) + Number(curr?.amount)


                }, 0))

              ).toFixed(2)}


              </span>


            </Box>




            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Card
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >£{
                Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {



                  return (pre || 0) + (curr?.card || 0)





                }, 0)).toFixed(2)}</span>
            </Box>


            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 0, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Card Returns
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >£({Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {



                if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "refund card") {
                  return (pre || 0) - curr?.subtotal
                }

                else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "refund card")) {

                  return (pre || 0) + curr?.subtotal
                }

                else {
                  return Number(pre || 0)
                }


              }, 0)).toFixed(2)})</span>
            </Box>


            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 0, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Card Refunds
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >£({Number(employeeRefundData && employeeRefundData?.length > 0 && employeeRefundData?.reduce((pre, curr) => {



                if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "card")) {

                  return (pre || 0) + curr?.refund


                } else {
                  return pre
                }


              }, 0)).toFixed(2)})</span>
            </Box>


            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 0, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Credit Payments
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >£{Number(employeeCreditPayment && employeeCreditPayment?.length > 0 && employeeCreditPayment?.reduce((pre, curr) => {



                if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "card")) {

                  return (pre || 0) + curr?.deductCredit


                } else {
                  return pre
                }


              }, 0)).toFixed(2)}</span>
            </Box>


            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px", borderTop: "1px solid black", borderBottom: "1px solid black" }} >

              <Typography sx={{ fontSize: "18px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", mt: 0, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Total Remaining Card
              </Typography>


              <span style={{ color: "red", fontFamily: "Poppins", fontWeight: "bold", fontSize: "20px", ...specialTextStyle }} >£{Number(Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "refund card") {

                  return (pre || 0) - curr?.subtotal


                }

                else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "refund card")) {


                  return (pre || 0) + curr?.subtotal
                }

                else {

                  return (pre || 0) + (curr?.card || 0)

                }



              }, 0))


                + Number(employeeCreditPayment && employeeCreditPayment?.length > 0 && employeeCreditPayment?.reduce((pre, curr) => {



                  if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "card")) {

                    return (pre || 0) + curr?.deductCredit


                  } else {
                    return pre
                  }


                }, 0))

                - Number(employeeRefundData && employeeRefundData?.length > 0 && employeeRefundData?.reduce((pre, curr) => {



                  if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "card")) {

                    return (pre || 0) + curr?.refund


                  } else {
                    return pre
                  }


                }, 0))

              ).toFixed(2)}


              </span>


            </Box>



            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Bank Transfer
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >£{Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                return (pre || 0) + (curr?.online || 0)

              }, 0)).toFixed(2)}</span>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 0, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Credit Payments
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >£{Number(employeeCreditPayment && employeeCreditPayment?.length > 0 && employeeCreditPayment?.reduce((pre, curr) => {



                if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "bank transfer")) {

                  return (pre || 0) + curr?.deductCredit


                } else {
                  return pre
                }


              }, 0)).toFixed(2)}</span>
            </Box>


            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px", borderTop: "1px solid black", borderBottom: "1px solid black" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Total Bank Transfers
              </Typography>

              <span style={{ color: "red", fontFamily: "Poppins", fontWeight: "bold", fontSize: "20px", ...specialTextStyle }} >£{Number(Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                return (pre || 0) + (curr?.online || 0)

              }, 0))



                + Number(employeeCreditPayment && employeeCreditPayment?.length > 0 && employeeCreditPayment?.reduce((pre, curr) => {



                  if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "bank transfer")) {

                    return (pre || 0) + curr?.deductCredit


                  } else {
                    return pre
                  }


                }, 0))




              ).toFixed(2)}




              </span>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                On Account
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >£{Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {


                return (pre || 0) + (curr?.credit || 0)



              }, 0)).toFixed(2)}</span>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 0, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                On Account Returns
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >£({Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {



                if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "credit note") {

                  return (pre || 0) - curr?.subtotal


                }

                else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "credit note")) {


                  return (pre || 0) + curr?.subtotal
                }

                else {
                  return Number(pre || 0)
                }


              }, 0)).toFixed(2)})</span>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 0, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                On Account Refunds
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >£({Number(employeeRefundData && employeeRefundData?.length > 0 && employeeRefundData?.reduce((pre, curr) => {



                if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "onaccount")) {

                  return (pre || 0) + curr?.refund

                } else {
                  return pre
                }


              }, 0)).toFixed(2)})</span>
            </Box>


            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px", borderTop: "1px solid black", borderBottom: "1px solid black" }} >

              <Typography sx={{ fontSize: "18px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", mt: 0, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Total On Account
              </Typography>


              <span style={{ color: "red", fontFamily: "Poppins", fontWeight: "bold", fontSize: "20px", ...specialTextStyle }} >£{Number(Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "credit note") {

                  return (pre || 0) - curr?.subtotal

                }
                else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "credit note")) {


                  return (pre || 0) + curr?.subtotal
                }
                else {

                  return (pre || 0) + (curr?.credit || 0)

                }



              }, 0))

                - Number(employeeRefundData && employeeRefundData?.length > 0 && employeeRefundData?.reduce((pre, curr) => {



                  if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "onaccount")) {

                    return (pre || 0) + curr?.refund


                  } else {
                    return pre
                  }


                }, 0))

              ).toFixed(2)}


              </span>


            </Box>



            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Cheque   {shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.some((e) => e?.paymentMethod && Array.isArray(e?.paymentMethod) && e?.paymentMethod?.some((j) => j?.toLowerCase() == "cheque")) ? <Button onClick={() => setShowChequeDetails(!showChequeDetails)} sx={{ ml: 1 }} >{showChequeDetails ? "Hide Details" : "Show Details"}</Button> : ""}
              </Typography>
              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} > £{Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {


                return (pre || 0) + (curr?.cheque || 0)





              }, 0)).toFixed(2)}</span>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 0, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Credit Payments
              </Typography>

              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }} >£{Number(Number(employeeCreditPayment && employeeCreditPayment?.length > 0 && employeeCreditPayment?.reduce((pre, curr) => {



                if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "cheque")) {

                  return (pre || 0) + curr?.deductCredit


                } else {
                  return pre
                }


              }, 0))



              ).toFixed(2)}</span>
            </Box>


            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "0px", borderTop: "1px solid black", borderBottom: "1px solid black" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Total Cheques
              </Typography>

              <span style={{ color: "red", fontFamily: "Poppins", fontWeight: "bold", fontSize: "20px", ...specialTextStyle }} > £{Number(Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {


                return (pre || 0) + (curr?.cheque || 0)





              }, 0))



                + Number(employeeCreditPayment && employeeCreditPayment?.length > 0 && employeeCreditPayment?.reduce((pre, curr) => {



                  if (Array.isArray(curr.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "cheque")) {

                    return (pre || 0) + curr?.deductCredit


                  } else {
                    return pre
                  }


                }, 0))
              ).toFixed(2)
              }</span>
            </Box>

            {showChequeDetails && <Box  >

              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: `1px solid ${Colors.border}` }} >

                <Typography sx={{ width: "33%", fontSize: "14px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "center", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                  Cheque No
                </Typography>
                <Typography sx={{ width: "33%", fontSize: "14px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "center", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                  Bank Name
                </Typography>
                <Typography sx={{ width: "33%", fontSize: "14px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "center", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                  Amount
                </Typography>

              </Box>

              <Box>

                {shiftInvoices && shiftInvoices.length > 0 && shiftInvoices.map((e, i) => {
                  if (e?.paymentMethod && Array?.isArray(e?.paymentMethod) ? e?.paymentMethod?.some((j) => j?.toLowerCase() == "cheque") : e.paymentMethod.toLowerCase() == "cheque") {
                    return (

                      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >


                        <Typography sx={{ width: "33%", fontSize: "14px", color: Colors.black, fontWeight: "400", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "center", textTransform: "capitalize", overflow: "auto", ...textStyle }} id="modal-modal-description" >
                          {e.cheque_no}
                        </Typography>


                        <Typography sx={{ width: "33%", fontSize: "14px", color: Colors.black, fontWeight: "400", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "center", textTransform: "capitalize", overflow: "auto", ...textStyle }} id="modal-modal-description" >
                          {e.bank_name}
                        </Typography>


                        <Typography sx={{ width: "33%", fontSize: "14px", color: Colors.black, fontWeight: "400", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "center", textTransform: "capitalize", overflow: "auto", ...textStyle }} id="modal-modal-description" >
                          {e.cheque}
                        </Typography>

                      </Box>


                    )
                  }


                })}


              </Box>

            </Box>}


            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderTop: `1px solid ${Colors.border}`, mt: 2 }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Total Sales
              </Typography>
              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }}>
                £{Number(shiftInvoices && shiftInvoices.length > 0 && shiftInvoices.reduce((pre, curr) => {


                  if (curr?.saleDate) {
                    return (pre || 0) + Number(curr?.subtotal)
                  } else {
                    return pre
                  }
                }, 0)).toFixed(2)}
              </span>

            </Box>


            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 0 }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Total Returns
              </Typography>
              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }}>
                £-{Number(shiftInvoices && shiftInvoices.length > 0 && shiftInvoices.reduce((pre, curr) => {


                  if (curr?.saleReturnDate) {
                    return (pre || 0) + Number(curr?.subtotal)
                  } else {
                    return pre
                  }
                }, 0)).toFixed(2)}
              </span>

            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 0 }} >
              <Typography sx={{ fontSize: "18px", color: Colors.black, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Total Exchange
              </Typography>
              <span style={{ color: "#5DC002", fontFamily: "Poppins", fontSize: "20px", ...specialTextStyle }}>
                £{Number(shiftInvoices && shiftInvoices.length > 0 && shiftInvoices.reduce((pre, curr) => {


                  if (curr?.exchangeDate) {
                    return (pre || 0) + Number(curr?.subtotal)
                  } else {
                    return pre
                  }
                }, 0)).toFixed(2)}
              </span>

            </Box>



            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderTop: `1px solid ${Colors.border}`, mt: 1 }} >
              <Typography sx={{ fontSize: "18px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Net Sales
              </Typography>
              <span style={{ color: "red", fontFamily: "Poppins", fontWeight: "bold", fontSize: "20px", ...specialTextStyle }}>
                £{Number(shiftInvoices && shiftInvoices.length > 0 && shiftInvoices.reduce((pre, curr) => {


                  if (curr?.saleReturnDate) {
                    return (pre || 0) - Number(curr?.subtotal)
                  }
                  else {
                    return (pre || 0) + Number(curr?.subtotal)
                  }
                }, 0)).toFixed(2)}
              </span>

            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 0 }} >
              <Typography sx={{ fontSize: "18px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Gross Collection
              </Typography>
              <span style={{ color: "red", fontFamily: "Poppins", fontWeight: "bold", fontSize: "20px", ...specialTextStyle }}>
                £{Number(

                  Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                    if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "refund cash") {

                      return (pre || 0) - curr?.subtotal


                    }


                    else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "refund cash")) {


                      return (pre || 0) + curr?.subtotal
                    }



                    else {

                      return (pre || 0) + (curr?.cash || 0)

                    }



                  }, 0))

                  +

                  Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {


                    if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "refund card") {

                      return (pre || 0) - curr?.subtotal


                    }
                    else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "refund card")) {


                      return (pre || 0) + curr?.subtotal
                    }
                    else {

                      return (pre || 0) + (curr?.card || 0)
                    }


                  }, 0))

                  +


                  Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                    return (pre || 0) + (curr?.online || 0)

                  }, 0))

                  +


                  Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                    if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "credit note") {

                      return (pre || 0) - curr?.subtotal

                    }
                    else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "credit note")) {


                      return (pre || 0) + curr?.subtotal
                    }
                    else {

                      return (pre || 0) + (curr?.credit || 0)

                    }



                  }, 0))

                  +

                  Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {


                    return (pre || 0) + (curr?.cheque || 0)





                  }, 0))

                ).toFixed(2)}
              </span>

            </Box>


            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 0, borderTop: "1px solid black" }} >
              <Typography sx={{ fontSize: "18px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", mt: 1, padding: "0px 5px 0px 5px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                Extra cash received on invoices 
              </Typography>
              <span style={{ color: "red", fontFamily: "Poppins", fontWeight: "bold", fontSize: "20px", ...specialTextStyle }}>
                £{Number(




                  (Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                    if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "refund cash") {

                      return (pre || 0) - curr?.subtotal

                    }

                    else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "refund cash")) {


                      return (pre || 0) + curr?.subtotal
                    }

                    else {

                      return (pre || 0) + (curr?.cash || 0)

                    }



                  }, 0))

                    +

                    Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                      if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "refund card") {

                        return (pre || 0) - curr?.subtotal

                      }

                      else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "refund card")) {


                        return (pre || 0) + curr?.subtotal
                      }

                      else {

                        return (pre || 0) + (curr?.card || 0)

                      }



                    }, 0))

                    +


                    Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                      return (pre || 0) + (curr?.online || 0)

                    }, 0))

                    +


                    Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {

                      if (curr?.saleReturnDate && curr?.paymentMethod?.toLowerCase() == "credit note") {

                        return (pre || 0) - curr?.subtotal

                      }
                      else if (curr?.exchangeDate && Array?.isArray(curr?.paymentMethod) && curr?.paymentMethod?.some((e, i) => e?.toLowerCase() == "credit note")) {


                        return (pre || 0) + curr?.subtotal
                      }
                      else {

                        return (pre || 0) + (curr?.credit || 0)

                      }



                    }, 0))

                    +

                    Number(shiftInvoices && shiftInvoices?.length > 0 && shiftInvoices?.reduce((pre, curr) => {


                      return (pre || 0) + (curr?.cheque || 0)





                    }, 0)))

                  -
                  Number(shiftInvoices && shiftInvoices.length > 0 && shiftInvoices.reduce((pre, curr) => {

                    if (curr?.saleReturnDate) {
                      return (pre || 0) - Number(curr?.subtotal)
                    }
                    else {
                      return (pre || 0) + Number(curr?.subtotal)
                    }
                  }, 0))



                ).toFixed(2)}
              </span>

            </Box>






            <Button onClick={() => setPdfDownloadView(true)} sx={{
              backgroundColor: "#5DC002", color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "200px", fontSize: "14px", textTransform: "capitalize", mt: 3, fontFamily: "Poppins",
              '&:hover': {
                color: '#5DC002', // Set your desired hover color
              },
              ...buttonStyle
            }} >
              {"Generate Report"}
            </Button>


            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", ...buttonContainerStyle }} >

              <Button onClick={confirm} sx={{
                backgroundColor: "#5DC002", color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "200px", fontSize: "14px", textTransform: "capitalize", mt: 3, fontFamily: "Poppins",
                '&:hover': {
                  color: '#5DC002', // Set your desired hover color
                },
                ...buttonStyle
              }} >
                {loading ? <RingLoader loading={loading} size={30} color={Colors.black} /> : "End Shift"}
              </Button>


              <Button onClick={closeModal} sx={{
                backgroundColor: "#5DC002", color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "200px", fontSize: "14px", textTransform: "capitalize", mt: 3, fontFamily: "Poppins",
                '&:hover': {
                  color: '#5DC002', // Set your desired hover color
                },
                ...buttonStyle
              }} >
                {"Cancel"}
              </Button>

            </Box>
          </Box>
        </Modal>
      </div>
  );
}