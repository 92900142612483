import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { RingLoader } from 'react-spinners';
import { TextField } from '@mui/material';
import { paymentMethod } from '../Redux/LoginReduces';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from './checkbox';
import CloseIcon from '@mui/icons-material/Close';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { lg: "35%", md: "35%", sm: "50%", xs: "100%" },
  borderRadius: "10px",
  maxHeight: "80vh",
  overflow: "auto",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function RoleAccessModal({ employeeData, heading, text, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, loading, credit, onChange }) {




  const [roleAccess, setRoleAccess] = useState([])

  useEffect(() => {


    setRoleAccess(employeeData.role_access)

  }, [employeeData?.role_access])



  const changeRoleAccess = (item, ind) => {


    setRoleAccess(roleAccess && roleAccess.length > 0 && roleAccess.map((e, i) => {


      if (i == ind) {
        return {
          ...e,
          selected: e?.selected ? false : true
        }
      } else {

        return e


      }

    }))

  }

  const changeSubAccessRole = (index, item, ind) => {


    let selectedRole = roleAccess && roleAccess.length > 0 && roleAccess?.find((e, i) => index == i)



    let subcategory = [...selectedRole?.subcategory]

    let newSubCateogories = subcategory && subcategory?.length > 0 && subcategory?.map((e, i) => {

      if (ind == i) {
        return {
          ...e,
          selected: !e?.selected
        }
      } else {
        return e
      }

    })


    setRoleAccess(roleAccess && roleAccess?.length > 0 && roleAccess?.map((e, i) => {
      if (index == i) {
        return {
          ...e,
          subcategory: newSubCateogories
        }
      } else {
        return e
      }
    }))


  }


  const handleEditRoleAccess = () => {

    confirm(roleAccess)
  }



  return (
    <div>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[style, { containerStyle }]}>


          <Box onClick={() => closeModal()} style={{ position: "absolute", top: 10, right: 10 }} >
            <CloseIcon fontSize="large" />
          </Box>

          <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
            {heading}
          </Typography>
          {/* <Typography sx={{ fontSize: "14px", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", mt: 1, padding: "5px 10px 5px 10px ", textAlign: "center", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                        {text}
                    </Typography> */}

          <div style={{ padding: "0 10px", margin: "10px" }}>
            {roleAccess && roleAccess?.length > 0 && roleAccess?.map((item, ind) => {
              return (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 5,
                    }}
                  >
                    <div
                      style={{
                        color: "#636363",
                        fontSize: 14,
                        fontFamily: "Poppins",
                        fontWeight: "500",
                      }}
                    >
                      {item.name}
                    </div>
                    <Checkbox
                      data={item}
                      onClick={() => changeRoleAccess(item, ind)}
                    />



                  </div>


                  {item?.subcategory && item?.subcategory?.length > 0 && item?.subcategory?.map((j, i) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: 5,
                          marginLeft: 10
                        }}
                      >
                        <div
                          style={{
                            color: "black",
                            fontSize: 14,
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                          }}
                        >
                          {i + 1}.  {j.name}
                        </div>
                        <Checkbox
                          data={j}
                          onClick={() => changeSubAccessRole(ind, j, i)}
                        />

                      </div>

                    )
                  })}



                </div>
              );
            })}
          </div>



          <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", ...buttonContainerStyle }} >

            <Button onClick={closeModal} sx={{ backgroundColor: Colors.white, color: Colors.button, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", ...buttonStyle }} >
              Cancel
            </Button>


            <Button onClick={() => handleEditRoleAccess()} sx={{
              backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", '&:hover': {
                color: Colors.button, // Set your desired hover color
              }, ...buttonStyle2
            }} >
              {loading ? <RingLoader size={30} loading={loading} /> : "Confirm"}
            </Button>


          </Box>



        </Box>



      </Modal>
    </div>
  );
}