import { Box, Button, Grid, TextField, Typography, alpha, colors } from '@mui/material';
import React, { useMemo, useRef, useState, useCallback } from 'react';
import { Colors } from '../../Constant/Color';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import BasicSelect from '../../Components/Dropdown';
import { HexColorPicker } from "react-colorful";
import { useDropzone } from 'react-dropzone'
import { useNavigate, useNavigation } from 'react-router-dom';
import CheckBox from '../../Components/checkbox';
import { DatePicker } from '@mui/x-date-pickers';
import { useStaticRangePicker } from '@mui/x-date-pickers-pro/internals/hooks/useStaticRangePicker';
import DatePickers from '../../Components/DatePicker';
import CustomButton from '../../Components/Button';


function ProductArrangementClaim() {


    const navigate = useNavigate()


    const [editorState, setEditorState] = useState({
        bold: false,
        italic: false,
        underline: false,
        alignment: 'left',
        marginTop: "10px"
    });

    const [status, setStatus] = useState([
        "inprocess",
        "return",
        "trash"
    ])
    const [selectedStatus, setSelectedStatus] = useState("select")

    const [damageProduct, setDamageProducts] = useState([
        {
            productName: "LCD One phs",
            quantity: 3,
            newQuantity: 3,
            color: require("../../Images/bluebox.png"),
            price: 97
        },
        {
            productName: "LCD One phs",
            quantity: 3,
            newQuantity: 3,
            color: require("../../Images/lightGray.png"),
            price: 97
        },
        {
            productName: "LCD One phs",
            quantity: 3,
            newQuantity: 3,
            color: require("../../Images/dark.png"),
            price: 97
        },
    ])

    const [stockCategory, setStockCategory] = useState([
        {
            name: "Fast Selling",
            selected: false,
            id: 1
        },
        {
            name: "OEM",
            selected: false,
            id: 2
        },
        {
            name: "Genuine",
            selected: false,
            id: 3
        },
        {
            name: "Empty",
            selected: false,
            id: 4
        },
        {
            name: "Compatible",
            selected: false,
            id: 5
        },
    ])
    const [stockDate, setStockDate] = useState("")

    const [compatilityData, setCompatibilityData] = useState([
        'A', 'B', 'C'
    ])

    const [supplierData, setSupplierData] = useState([
        'A Supplier', 'B Supplier', 'C Supplier'
    ])

    const [selectedCompatibility, setSelectedCompatibility] = useState("select")
    const [selectedSupplier, setSelectedSupplier] = useState("select")

    const selectStockCategory = (ind) => {

        setStockCategory(stockCategory.map((e, i) => {
            if (ind == i) {
                return {
                    ...e,
                    selected: e?.selected ? false : true
                }
            } else {
                return e
            }
        }))


    }


    const screenWidth = window.innerWidth

    return (
        <Box style={{ color: 'black' }}>




            <Box
                sx={{
                    width: '100%',
                    marginTop: 0,
                    margin: 0,
                    padding: { xs: "20px 0px 0px 0px", sm: "20px 10px 0px 10px" },
                    backgroundColor: Colors?.main,
                }}
            >





                <Grid container spacing={2}>




                    <Grid item xs={12} md={6}>





                        <Box style={{ padding: 10 }}>
                            {/* Content for the first column */}


                            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >

                                <Box sx={{
                                    width: "90%", marginTop: { xs: "0px", sm: "0px", md: "0px" }, border: `1px solid ${Colors.button}`, borderRadius: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px 10px 5px 10px", margin: 0,
                                    backgroundColor: Colors.white
                                }} >
                                    <TextField
                                        variant="standard" // <== changed this
                                        margin="normal"
                                        required
                                        fullWidth
                                        InputProps={{
                                            // startAdornment: <AccountCircle />, // <== adjusted this
                                            disableUnderline: true,
                                            fontSize: "12px",

                                            // <== added this
                                            // fontSize: "2px"
                                        }}
                                        placeholder="Search Name ,Id , Phone & Bar Code"
                                        sx={{ width: "90%", fontSize: "12px", padding: 0, fontFamily: "Poppins", fontWeight: "bold", margin: 0 }}
                                    />
                                    <img src={require("../../Images/search.png")} style={{ height: "20px" }} />
                                </Box>


                                <Box sx={{ margin: 0, height: "35px", width: "35px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: Colors.button }} >
                                    <img src={require("../../Images/qrcode.png")} style={{ height: "20px" }} />
                                </Box>

                            </Box>


                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, marginTop: 10, borderRadius: 5, backgroundColor: Colors.white }} >
                                <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between" }} >
                                    <Grid xs={12} sm={8} md={4} >
                                        <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}  >
                                            <Typography style={{ color: Colors.text, fontSize: 12, fontWeight: "400", fontFamily: "Poppins", textAlign: "left" }} >
                                                Customer Name
                                            </Typography>
                                            <Typography style={{ color: Colors.black, fontSize: 16, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                William Lucas
                                            </Typography>
                                        </Box>


                                    </Grid>
                                    <Grid xs={12} sm={8} md={8} >

                                        <Box style={{ display: "flex", justifyContent: "space-around" }} >
                                            <Grid xs={3.9} sm={3.9} md={3.9} >
                                                <CustomButton style={{ backgroundColor: Colors.buttonLight, height: "40px", color: Colors.button, fontSize: { lg: "12px", md: "10px", xs: "8px" }, borderRadius: "5px", width: "100%", padding: "0px" }} text={"Last Order"} />
                                            </Grid>
                                            <Grid xs={3.9} sm={3.9} md={3.9} >
                                                <CustomButton style={{ backgroundColor: Colors.buttonLight, height: "40px", color: Colors.button, fontSize: { lg: "12px", md: "10px", xs: "8px" }, borderRadius: "5px", width: "100%", padding: "0px" }} text={"Search A Date"} />
                                            </Grid>
                                            <Grid xs={3.9} sm={3.9} md={3.9} >
                                                <CustomButton style={{ backgroundColor: Colors.buttonLight, height: "40px", color: Colors.button, fontSize: { lg: "12px", md: "10px", xs: "8px" }, borderRadius: "5px", width: "100%", padding: "0px" }} text={"History"} />
                                            </Grid>

                                        </Box>

                                    </Grid>
                                </Grid>
                            </Box>


                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, marginTop: 10, borderRadius: 5, backgroundColor: Colors.white }} >
                                <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between" }} >
                                    <Grid xs={4} sm={4} md={5} >
                                        <Typography sx={{ color: Colors.black, fontSize: { xl: "20px", md: "16px", sm: "12px", xs: "10px" }, fontWeight: "800", fontFamily: "Poppins", textAlign: "left" }} >
                                            Product
                                        </Typography>

                                    </Grid>
                                    <Grid xs={8} sm={8} md={7} >

                                        <Box style={{ display: "flex", justifyContent: "space-between", }} >
                                            <Grid xs={3.9} sm={3.9} md={3.9} >
                                                <Typography sx={{ color: Colors.text, textAlign: "center", fontSize: { xl: "14px", md: "12px", sm: "10px", xs: "8px" }, fontWeight: "500", fontFamily: "Poppins" }} >
                                                    Quantity
                                                </Typography>
                                            </Grid>
                                            <Grid xs={3.9} sm={3.9} md={3.9} >

                                                <Typography sx={{ color: Colors.text, textAlign: "center", fontSize: { xl: "14px", md: "12px", sm: "10px", xs: "8px" }, fontWeight: "500", fontFamily: "Poppins", }} >
                                                    New Quantity
                                                </Typography>
                                            </Grid>
                                            <Grid xs={3.9} sm={3.9} md={3.9} >
                                                <Typography sx={{ color: Colors.text, textAlign: "center", fontSize: { xl: "14px", md: "12px", sm: "10px", xs: "8px" }, fontWeight: "500", fontFamily: "Poppins", }} >
                                                    Price
                                                </Typography>
                                            </Grid>

                                        </Box>

                                    </Grid>
                                </Grid>

                                <Box style={{ width: "100%" }} >
                                    {damageProduct && damageProduct?.length > 0 && damageProduct?.map((e, i) => {
                                        let width = window.innerWidth
                          
                                        return (
                                            <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }} >
                                                <Grid container xs={4} sm={4} md={5} lg={5} >
                                                    <Grid md={4} xs={4} xl={3} lg={3} sm={4} style={{ display: "flex", justifyContent: "flex-start" }} >
                                                        <img src={e?.color} style={{ height: width > 600 ? "50px" : width > 400 ? "30px" : "20px" }} />
                                                    </Grid>
                                                    <Grid md={8} xl={7} xs={8} lg={7} sm={8} style={{ display: "flex", justifyContent: "flex-start" }} >
                                                        <Box>
                                                            <Typography sx={{ color: Colors.black, fontSize: width < 400 ? "5px" : { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontWeight: "800", fontFamily: "Poppins", textAlign: "left" }} >
                                                                {e?.productName}
                                                            </Typography>
                                                            <Typography sx={{ color: Colors.black, fontSize: width < 400 ? "4px" : { xl: "14px", md: "12px", sm: "10px", xs: "6px" }, fontWeight: "600", fontFamily: "Poppins", textAlign: "left" }} >
                                                                {e?.productName}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>


                                                </Grid>
                                                <Grid xs={8} sm={8} md={7} >

                                                    <Box style={{ display: "flex", justifyContent: "space-between", }} >
                                                        <Grid xs={3.9} sm={3.9} md={3.9} >
                                                            <Typography sx={{ color: Colors.black, textAlign: "center", fontSize: { xl: "14px", md: "12px", sm: "10px", xs: "8px" }, fontWeight: "600", fontFamily: "Poppins" }} >
                                                                {e?.quantity}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={3.9} sm={3.9} md={3.9} >

                                                            <Typography sx={{ color: Colors.black, textAlign: "center", fontSize: { xl: "14px", md: "12px", sm: "10px", xs: "8px" }, fontWeight: "600", fontFamily: "Poppins", }} >
                                                                {e?.newQuantity}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={3.9} sm={3.9} md={3.9} >
                                                            <Typography sx={{ color: Colors.black, textAlign: "center", fontSize: { xl: "14px", md: "12px", sm: "10px", xs: "8px" }, fontWeight: "600", fontFamily: "Poppins", }} >
                                                                {e?.price}
                                                            </Typography>
                                                        </Grid>

                                                    </Box>

                                                </Grid>
                                            </Grid>
                                        )
                                    })}

                                </Box>



                            </Box>


                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, marginTop: 10, borderRadius: 5, backgroundColor: Colors.white }} >

                                <Box style={{ display: "flex", justifyContent: "flex-start" }} >
                                    <Typography sx={{ fontSize: { xl: "22px", md: "20px", sm: "16px", xs: "12px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                        Staff Seller Info
                                    </Typography>
                                </Box>

                                <Box style={{ display: "flex", alignItems: "center" }} >

                                    <img src={require("../../Images/profile.png")} style={{ height: screenWidth < 350 ? "30px" : screenWidth < 400 ? "60px" : "100px" }} />

                                    <Box sx={{ marginLeft: "10px" }} >
                                        <Box style={{ display: "flex" }} >
                                            <Typography sx={{ color: Colors.text, fontSize: { xl: "14px", md: "12px", sm: "10px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                                Name:
                                            </Typography>
                                            <Typography sx={{ marginLeft: "2px", fontSize: { xl: "15px", md: "13px", sm: "11px", xs: "9px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                                Daniyal Sheikh
                                            </Typography>
                                        </Box>
                                        <Box style={{ display: "flex" }} >
                                            <Typography sx={{ color: Colors.text, fontSize: { xl: "14px", md: "12px", sm: "10px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                                Shift Timing:
                                            </Typography> <Typography sx={{ marginLeft: "2px", fontSize: { xl: "15px", md: "13px", sm: "11px", xs: "9px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                                09 AM to 06PM
                                            </Typography>
                                        </Box>
                                        <Box style={{ display: "flex" }} >
                                            <Typography sx={{ color: Colors.text, fontSize: { xl: "14px", md: "12px", sm: "10px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                                Store Location:
                                            </Typography>
                                            <Typography sx={{ marginLeft: "2px", fontSize: { xl: "15px", md: "13px", sm: "11px", xs: "9px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                                London Fashion Gallery
                                            </Typography>
                                        </Box>
                                    </Box>



                                </Box>

                            </Box>













                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Box style={{ padding: 10 }}>
                            {/* Content for the second column */}







                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, borderRadius: 5, display: "flex", alignItems: "flex-start", flexDirection: "column", backgroundColor: Colors.white }} >



                                <Box style={{ display: "flex", justifyContent: "flex-start" }} >
                                    <Typography sx={{ fontSize: { xl: "22px", md: "20px", sm: "16px", xs: "12px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                        Store Info
                                    </Typography>
                                </Box>

                                <Box style={{ width: "100%", marginTop: 10 }} >

                                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", borderBottom: `1px solid ${Colors.border}`, padding: "10px 5px 10px 5px" }} >

                                        <Typography sx={{ color: Colors.text, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            Name:
                                        </Typography>
                                        <Typography sx={{ color: Colors.black, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            James Henry
                                        </Typography>

                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", borderBottom: `1px solid ${Colors.border}`, padding: "10px 5px 10px 5px" }} >

                                        <Typography sx={{ color: Colors.text, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            Store Name:
                                        </Typography>
                                        <Typography sx={{ color: Colors.black, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            Jawan Pakistan
                                        </Typography>

                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", borderBottom: `1px solid ${Colors.border}`, padding: "10px 5px 10px 5px" }} >

                                        <Typography sx={{ color: Colors.text, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            Phone Number:
                                        </Typography>
                                        <Typography sx={{ color: Colors.black, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            +44 5985 5845 23
                                        </Typography>

                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", borderBottom: `1px solid ${Colors.border}`, padding: "10px 5px 10px 5px" }} >

                                        <Typography sx={{ color: Colors.text, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            Location:
                                        </Typography>
                                        <Typography sx={{ color: Colors.black, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            Location Fashion Gallery
                                        </Typography>

                                    </Box>

                                </Box>







                            </Box>


                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, borderRadius: 5, display: "flex", alignItems: "flex-start", flexDirection: "column", backgroundColor: Colors.white, marginTop: 10 }} >




                                <Box style={{ width: "100%", marginTop: 10 }} >

                                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", borderBottom: `1px solid ${Colors.border}`, padding: "10px 5px 10px 5px" }} >

                                        <Typography sx={{ color: Colors.text, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            Section:
                                        </Typography>
                                        <Typography sx={{ color: Colors.black, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            A
                                        </Typography>

                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", borderBottom: `1px solid ${Colors.border}`, padding: "10px 5px 10px 5px" }} >

                                        <Typography sx={{ color: Colors.text, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            Discount:
                                        </Typography>
                                        <Typography sx={{ color: Colors.black, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            10%
                                        </Typography>

                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", borderBottom: `1px solid ${Colors.border}`, padding: "10px 5px 10px 5px" }} >

                                        <Typography sx={{ color: Colors.text, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            Warranty:
                                        </Typography>
                                        <Typography sx={{ color: Colors.black, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            Yes
                                        </Typography>

                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", borderBottom: `1px solid ${Colors.border}`, padding: "10px 5px 10px 5px" }} >

                                        <Typography sx={{ color: Colors.text, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            Payment:
                                        </Typography>
                                        <Typography sx={{ color: Colors.black, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold" }} >
                                            Cash
                                        </Typography>

                                    </Box>

                                </Box>







                            </Box>







                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, borderRadius: 5, display: "flex", alignItems: "flex-start", flexDirection: "column", backgroundColor: Colors.white, marginTop: 10 }} >




                                <Box style={{ width: "100%", marginTop: 10 }} >

                                    <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%", borderBottom: `1px solid ${Colors.border}`, padding: "10px 5px 10px 5px" }} >

                                        <Typography sx={{ width: "20%", color: Colors.text, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold", textAlign: "left" }} >
                                            To
                                        </Typography>
                                        <Typography sx={{ width: "80%", color: Colors.black, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold", textAlign: "left" }} >
                                            Supplier
                                        </Typography>

                                    </Box>

                                    <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%", borderBottom: `1px solid ${Colors.border}`, padding: "10px 5px 10px 5px" }} >

                                        <Typography sx={{ width: "20%", color: Colors.text, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold", textAlign: "left" }} >
                                            Subject
                                        </Typography>
                                        <Typography sx={{ width: "80%", color: Colors.black, fontSize: { xl: "16px", md: "14px", sm: "12px", xs: "8px" }, fontFamily: "Poppins", fontWeight: "bold", textAlign: "left" }} >
                                            Warranty Claims
                                        </Typography>

                                    </Box>
                                    <Box style={{ marginTop: 10 }} >
                                        <textarea
                                            // value={data.cityName}
                                            // onChange={(e) =>
                                            //   setData((prev) => ({ ...prev, cityName: e.target.value }))
                                            // }
                                            size="small"
                                            multiline={true}
                                            row={10}
                                            placeholder="Description"
                                            style={{
                                                width: "100%", fontSize: 14, borderRadius: "5px", padding: "3px 10px 3px 10px", marginTop: 1, fontFamily: "Poppins", height: 100, fontWeight: editorState?.bold ? "bold" : "normal",
                                                fontStyle: editorState?.italic ? "italic" : "normal",
                                                textDecoration: editorState?.underline ? "underline" : "none",
                                                textAlign: editorState?.alignment,
                                                borderBottomLeftRadius: "5px",
                                                borderBottomRightRadius: "5px",
                                            }}
                                        />
                                    </Box>

                                    <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection: { xs: "column", sm: "row" } }} >
                                        <CustomButton style={{ backgroundColor: Colors.white, border: `1px solid ${Colors.button}`, color: Colors.button, width: { xs: "100%", sm: "32%", xl: "25%" }, marginTop: { xs: "20px", sm: "20px" } }} text="Save" />
                                        <CustomButton style={{ backgroundColor: Colors.button, border: `1px solid ${Colors.button}`, color: Colors.white, width: { xs: "100%", sm: "40%", xl: "30%", md: "48%" }, marginLeft: { xs: "0px", sm: "5px" }, marginTop: { xs: "20px", sm: "20px" } }} text="Send to Supplier" />

                                    </Box>
                                </Box>







                            </Box>








                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default ProductArrangementClaim
