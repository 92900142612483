
import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { InputAdornment, TextField } from '@mui/material';
import Checkbox from './checkbox';
import { RingLoader } from 'react-spinners';
import CloseIcon from '@mui/icons-material/Close';




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { lg: "25%", md: "35%", sm: "50%", xs: "100%" },
    borderRadius: "10px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

export default function ChequeModal({ loading, onChangeDay, onChangeMonth, onChangeYear, text2, onChange1, inputStyle, buttonText, heading, onChange, text, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, text1 }) {

    const [selected, setSelected] = useState(false)

    return (
        <div>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[style, { containerStyle }]}>

                <Box onClick={() => closeModal()} style={{ position: "absolute", top: 10, right: 10 }} >
                        <CloseIcon fontSize="large" />
                    </Box>


                    <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                        {heading}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                        <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                            {text}
                        </Typography>

                        <TextField
                            onChange={onChange}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            // fullWidth
                            InputProps={{
                                // startAdornment: <AccountCircle />, // <== adjusted this
                                disableUnderline: true,
                                fontSize: "12px",


                                // <== added this
                                // fontSize: "2px"
                            }}
                            placeholder=""
                            sx={{ width: "75%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 0, fontFamily: "Poppins", fontWeight: "bold", ml: 1, margin: 0, ...inputStyle }}
                        />

                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                        <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                            {text1}
                        </Typography>

                        <TextField
                            onChange={onChange1}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            // fullWidth
                            InputProps={{
                                // startAdornment: <AccountCircle />, // <== adjusted this
                                disableUnderline: true,
                                fontSize: "12px",


                                // <== added this
                                // fontSize: "2px"
                            }}
                            placeholder=""
                            sx={{ width: "75%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 0, fontFamily: "Poppins", fontWeight: "bold", ml: 1, margin: 0, ...inputStyle }}
                        />

                    </Box>


                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                        <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                            {text2}
                        </Typography>

                        <TextField
                            onChange={onChangeDay}
                            variant="standard" // <== changed this

                            type='number'
                            required
                            // fullWidth

                            InputProps={{
                                // startAdornment: <AccountCircle />, // <== adjusted this
                                disableUnderline: true,
                                fontSize: "12px",
                                sx: {
                                    "& input": {
                                        textAlign: "center"
                                    }
                                }
                            }}

                            placeholder="DD"
                            sx={{ width: "15%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 0, fontFamily: "Poppins", fontWeight: "bold", ...inputStyle }}
                        />


                        <TextField
                            onChange={onChangeMonth}
                            variant="standard" // <== changed this

                            type='number'
                            required
            
                            InputProps={{
                                // startAdornment: <AccountCircle />, // <== adjusted this
                                disableUnderline: true,
                                fontSize: "12px",
                                sx: {
                                    "& input": {
                                        textAlign: "center"
                                    }
                                }


                                // <== added this
                                // fontSize: "2px"
                            }}
                            placeholder="MM"
                            sx={{ width: "15%", fontSize: "12px", border: `1px solid ${Colors.border}`, ml: 1, padding: 0, fontFamily: "Poppins", fontWeight: "bold", ...inputStyle }}
                        />


                        <TextField
                            onChange={onChangeYear}
                            variant="standard" // <== changed this
                            type='number'
                            required
                            // fullWidth
                            InputProps={{
                                // startAdornment: <AccountCircle />, // <== adjusted this
                                disableUnderline: true,
                                fontSize: "12px",
                                sx: {
                                    "& input": {
                                        textAlign: "center"
                                    }
                                }



                                // <== added this
                                // fontSize: "2px"
                            }}
                            placeholder="YY"
                            sx={{ width: "15%", fontSize: "12px", border: `1px solid ${Colors.border}`, ml: 1, padding: 0, fontFamily: "Poppins", fontWeight: "bold", textAlign: "center", ...inputStyle }}
                        />

                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }} >

                        <Box sx={{ width: "25%" }} >
                       
                       
                        </Box>

                    </Box>

                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", ...buttonContainerStyle }} >



                        <Button onClick={confirm} sx={{ backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", ...buttonStyle2 }} >
                            {loading ? <RingLoader loading={loading} size={30} /> : buttonText}
                        </Button>


                    </Box>



                </Box>



            </Modal>
        </div>
    );
}