




import { Box, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Colors } from '../../../Constant/Color';
import { Add } from '@mui/icons-material';
import { ArrowBackIosNewOutlined } from '@mui/icons-material';
import { Image_Uri } from '../../../Constant/BaseUri';
import { useNavigate } from 'react-router-dom';
import { updateCart } from '../../../Redux/LoginReduces';
import CustomButton from '../../../Components/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function Quicksearch() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    let products = useSelector((state) => state.loginReducer.products)
    let cart = useSelector((state) => state.loginReducer.cart)



    const [hover, setHover] = useState(null)

    const [paginationData, setPaginationData] = useState([])
    const [productCategories, setProductCategories] = useState([])
    const [allProducts, setAllProducts] = useState([])
    const [selectedCategory, setSelectedCategory] = useState("")
    const [searchProducts, setSearchProducts] = useState([])
    const [page, setPage] = useState(0)
    const [searchData, setSearchData] = useState([])
    const [text, setText] = useState("")
    const [timeoutId, setTimeoutId] = useState(null);


    const [addQty,setAddQty] = useState("")

    const [showToast,setShowToast] = useState(false)

    const getPaginationData = () => {


        let dataFrom;
        let dataTo;

        if (page == 0) {

            dataFrom = 0
            dataTo = 10

        }

        else {


            dataFrom = page * 10
            dataTo = dataFrom + 10

        }


        setPaginationData(products && products.length > 0 && products.filter((e, i) => {

            if (i >= dataFrom && i < dataTo) {

                return e


            }


        }))



    }


    const notify = (qty) => toast(`${qty} qty has been added`);


    useEffect(() => {


        setAllProducts(products)

        let categories = products && products.length > 0 && products.map((e, i) => e.category)
        let uniqueCategories = [...new Set([...categories])]
        setProductCategories(uniqueCategories)


    }, [page, products])

    

    const handleSearchData = (input) => {

        setText(input)

        if (input == "") {

            setSearchData([])
            return

        }

        setSearchProducts(allProducts && allProducts?.length > 0 && allProducts.filter((e, i) => {

            if (e?.productName.toLowerCase().includes(input.toLowerCase()) || e?.category.toLowerCase().includes(input.toLowerCase())) {
                return e
            }

        }))

    }



    const handleSelectProduct = (product) => {
        if (cart && cart.length > 0 && cart.some((e, i) => e._id == product._id)) {

            let saleQty = 0


            let newCart = cart.map((e, i) => {


                if (e._id == product._id) {

                    saleQty = Number(e?.saleQty) + 1

                    return {
                        ...e,
                        saleQty: e.saleQty + 1,
                    };
                } else {
                    return e;
                }
            });

            // notify(saleQty)
            setAddQty(saleQty)
            setShowToast(true)

            if (timeoutId) {
                clearTimeout(timeoutId);
              }
            
            const newTimeoutId = setTimeout(() => {
                setShowToast(false);
              }, 2000);

            setTimeoutId(newTimeoutId);

            dispatch(updateCart(newCart));

            setText("");
            setSearchData([]);
        } else {
            let newProduct = { ...product };
            newProduct.saleQty = 1;
            let updatedCart = [...cart, newProduct];
            setAddQty(1)
            setShowToast(true)

            if (timeoutId) {
                clearTimeout(timeoutId);
              }
            
            const newTimeoutId = setTimeout(() => {
                setShowToast(false);
              }, 5000);

            setTimeoutId(newTimeoutId);

            dispatch(updateCart(updatedCart));
            setText("");
            setSearchData([]);
        }
    };



    const handleSelectCategory = (cat) => {

        let categoryProducts = allProducts && allProducts.length > 0 && allProducts.filter((e, i) => e.category == cat)

        setSearchProducts(categoryProducts)
        setSelectedCategory(cat)
    }




    return (

        <Box>

            <Grid
                container
                xs={12}
                spacing={2}
                style={{ width: "100%", padding: "10px 0" }}
            >


                {/* <ToastContainer /> */}

                {showToast && <Box sx={{width:"400px",height:"70px",backgroundColor:Colors?.buttonLight,position:"absolute",top:90,right:10,zIndex:1000,border:"1px solid black",padding:"0px 10px",display:"flex",alignItems:"center",borderRadius:"10px"}} >

                    <Typography sx={{fontSize:"14px",fontFamily:"Poppins",fontWeight:"bold",textAlign:"left"}} >
                        {addQty} qty has been added
                    </Typography>



                </Box>}

                <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>



                    <Box
                        sx={{
                            width: "80%",
                            alignSelf: "center",
                            boxSizing: "border-box",
                            padding: "10px",

                            marginTop: { xs: "0px", sm: "10px", md: "10px" },
                            border: `1px solid ${Colors.button}`,
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "10px 10px 10px 15px",
                            backgroundColor: Colors.white,
                        }}
                    >


                        <ArrowBackIosNewOutlined onClick={() => navigate("/PointOfSale")} />

                        <TextField

                            onChange={(e) => handleSearchData(e.target.value)}
                            value={text}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            fullWidth
                            InputProps={{
                                // startAdornment: <AccountCircle />, // <== adjusted this
                                disableUnderline: true,
                                fontSize: "12px",
                            }}
                            placeholder="Search Product"
                            sx={{
                                width: "90%",
                                fontSize: "12px",
                                padding: 0,
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                margin: 0,
                            }}
                        />
                        <img
                            src={require("../.././../Images/search.png")}
                            style={{ height: "20px" }}
                        />


                    </Box>
                </Box>



                <Grid item md={5}>

                    <Typography style={{ width: "100%", fontWeight: "bold", fontSize: "22px", fontFamily: "Poppins", border: `1px solid ${Colors.border}`, padding: 10, backgroundColor: Colors.red }} >Categories</Typography>


                    <Box style={{ height: window.innerHeight - 250, overflow: "auto", display: "flex", flexDirection: "column" }} >
                        {productCategories && productCategories.length > 0 && productCategories.map((e, i) => {

                            return (
                                <CustomButton onClick={() => handleSelectCategory(e)} text={e} style={{ width: "100%", backgroundColor: selectedCategory == e ? Colors.button : Colors.white, color: selectedCategory == e ? Colors.white : Colors.black, marginBottom: "5px", borderRadius: "5px", padding: 1 }} />
                            )

                        })}
                    </Box>

                </Grid>

                <Grid item md={7}    >

                    <Typography style={{ width: "100%", fontWeight: "bold", fontSize: "22px", fontFamily: "Poppins", border: `1px solid ${Colors.border}`, padding: 10, backgroundColor: Colors.button }} >Products</Typography>

                    <Box style={{ height: window.innerHeight - 250, overflow: "auto", display: "flex", flexDirection: "column" }} >
                        {searchProducts && searchProducts.length > 0 ? searchProducts.map((e, i) => {

                            return (


                                <CustomButton onClick={() => handleSelectProduct(e)} text={e?.productName} style={{ width: "100%", backgroundColor: selectedCategory == e.productName ? Colors.button : Colors.white, color: selectedCategory == e.productName ? Colors.white : Colors.black, marginBottom: "5px", borderRadius: "5px", padding: 1 }} />


                            )

                        })

                            :

                            allProducts && allProducts.length > 0 && allProducts.map((e, i) => {

                                return (

                                    <CustomButton onClick={() => handleSelectProduct(e)} text={e?.productName} style={{ width: "100%", backgroundColor: selectedCategory == e.productName ? Colors.button : Colors.white, color: selectedCategory == e.productName ? Colors.white : Colors.black, marginBottom: "5px", borderRadius: "5px", padding: 1 }} />

                                )

                            })


                        }
                    </Box>




                </Grid>




                {/* {searchData && searchData.length > 0 ? searchData.map((item, i) => {
                    return (
                        <Grid
                            key={item.id}
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            lg={2.8}
                            // onMouseEnter={() => {
                            //     setHover(item.id);
                            // }}
                            // onMouseLeave={() => {
                            //     setHover(null);
                            // }}
                            sx={{

                                cursor: "pointer",
                                display: { xs: "flex", sm: "block" },
                                flexDirection: "column",
                                alignItems: "center"
                            }}

                        >



                            <Box

                                onMouseEnter={() => {
                                    setHover(item.id);
                                }}
                                onClick={() => handleSelectProduct(item)}
                                onMouseLeave={() => {
                                    setHover(null);
                                }}

                                sx={{
                                    backgroundColor: "#F0F0F0",
                                    opacity: hover == item.id ? 0.5 : 1,
                                    border: "0.5px solid #C8C8C8",
                                    // border: "1px solid red",
                                    alignSelf: "center",
                                    width: { xs: "80%", sm: "100%" },
                                    borderRadius: "8px",
                                    height: "110px",
                                }}
                            >
                                {item?.image1_url && <img
                                    style={{ width: "100%", height: "100%" }}
                                    src={`${Image_Uri}products/${item.image1_url}`}
                                    alt="Product Image"
                                />}


                                <Box

                                    onMouseEnter={() => {
                                        setHover(item.id);
                                    }}
                                    onMouseLeave={() => {
                                        setHover(null);
                                    }}

                                    sx={{ opacity: hover == item.id ? 0.5 : 1, width: "20px", height: "20px", backgroundColor: "green", borderRadius: "5px", position: "relative", top: -35, left: 5 }} >

                                    <Add onClick={() => handleSelectProduct(item)} style={{ color: Colors.white }} color={Colors.white} fontSize="small" />

                                </Box>

                            </Box>
                            <div
                                style={{
                                    color: "#636363",
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                }}
                            >
                                {item.productName}
                            </div>
                        </Grid>
                    );
                })
                    :


                    paginationData && paginationData?.length > 0 && paginationData.map((item, i) => {
                        return (
                            <Grid
                                key={item.id}
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                lg={2.8}
                                // onMouseEnter={() => {
                                //     setHover(item.id);
                                // }}
                                // onMouseLeave={() => {
                                //     setHover(null);
                                // }}
                                sx={{

                                    cursor: "pointer",
                                    display: { xs: "flex", sm: "block" },
                                    flexDirection: "column",
                                    alignItems: "center"
                                }}
                            >



                                <Box

                                    onMouseEnter={() => {
                                        setHover(item.id);
                                    }}

                                    onClick={() => handleSelectProduct(item)}
                                    onMouseLeave={() => {
                                        setHover(null);
                                    }}
                                    sx={{
                                        backgroundColor: "#F0F0F0",
                                        opacity: hover == item.id ? 0.5 : 1,
                                        border: "0.5px solid #C8C8C8",
                                        alignSelf: "center",
                                        width: { xs: "80%", sm: "100%" },
                                        borderRadius: "8px",
                                        height: "110px",

                                    }}
                                >
                                    {item?.image1_url ? <img
                                        style={{ width: "100%", height: "100%", borderRadius: "8px" }}
                                        src={`${Image_Uri}products/${item.image1_url}`}
                                        // resizeMode="cover"
                                        alt="Product Image"
                                    /> : <Box sx={{ height: "100%", width: "100%", marginTop: 1, padding: 0 }} ></Box>}

                                    <Box

                                        onMouseEnter={() => {
                                            setHover(item.id);
                                        }}
                                        onMouseLeave={() => {
                                            setHover(null);
                                        }}

                                        sx={{ opacity: hover == item.id ? 0.5 : 1, width: "20px", height: "20px", backgroundColor: "green", borderRadius: "5px", position: "relative", top: -35, left: 5 }} >

                                        <Add
                                            onClick={() => handleSelectProduct(item)} style={{ color: Colors.white }} color={Colors.white} fontSize="small" />

                                    </Box>

                                </Box>
                                <div
                                    style={{
                                        color: "#636363",
                                        fontSize: 14,
                                        fontFamily: "Poppins",
                                        fontWeight: "500",
                                    }}
                                >
                                    {item.product_name}
                                </div>
                            </Grid>
                        );
                    }) */}

            </Grid>
            {/* 



            {searchData?.length == 0 && <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }} >

                {page > 0 && <button onClick={() => setPage(0)} className='start' style={{ border: `1px solid ${Colors.border}`, padding: "10px", marginRight: 10 }} >
                    start
                </button>}


                {products?.length > ((page * 10) + 10) && <button onClick={() => setPage(page + 1)} className='next' style={{ border: `1px solid ${Colors.border}`, padding: "10px" }} >
                    Next
                </button>}

                {page > 0 && <button onClick={() => setPage(page - 1)} className='next' style={{ border: `1px solid ${Colors.border}`, padding: "10px" }} >
                    Back
                </button>}

            </Box>} */}

        </Box>

    )

}

export default Quicksearch
