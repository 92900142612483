import { Box, Button, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Colors } from '../../Constant/Color'
import CustomButton from '../../Components/Button'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BasicSelect from '../../Components/Dropdown';
import { DataGrid } from "@mui/x-data-grid"
import Checkbox from '../../Components/checkbox';
import { CheckBox } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Base_Uri } from '../../Constant/BaseUri';
import { updateDemandedProducts } from '../../Redux/LoginReduces';
import CustomModal from '../../Components/modal';




function DemandedProduct() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const demandedProducts = useSelector(state => state.loginReducer.demandedProducts)

    const [gridKey, setGridKey] = useState(0)
    const [searchProducts, setSearchProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)

    const [product, setProducts] = useState([
        {
            productName: "Realme C45",
            id: 1,
            category: "mobile",
            sku: "CR-4698",
            quantity: 2,
            customerReach: 3,
            cost: "€50",
            price: "€90",
            status: 'Out Of Stock',
            action: "action"
        },
        {
            productName: "Realme C45",
            id: 2,
            category: "mobile",
            sku: "CR-4698",
            quantity: 2,
            customerReach: 3,
            cost: "€50",
            price: "€90",
            status: 'Out Of Stock',
            action: "action"
        },
        {
            productName: "Realme C45",
            id: 3,
            category: "mobile",
            sku: "CR-4698",
            quantity: 2,
            customerReach: 3,
            cost: "€50",
            price: "€90",
            status: 'Out Of Stock',
            action: "action"

        },
        {
            productName: "Realme C45",
            id: 4,
            category: "mobile",
            sku: "CR-4698",
            quantity: 2,
            customerReach: 3,
            cost: "€50",
            price: "€90",
            status: 'Out Of Stock',
            action: "action"
        },
        {
            productName: "Realme C45",
            id: 5,
            category: "mobile",
            sku: "CR-4698",
            quantity: 2,
            customerReach: 3,
            cost: "€50",
            price: "€90",
            status: 'Out Of Stock',
            action: "action"
        },


    ])


    console.log(demandedProducts, "products")

    const getDemandedProducts = async () => {

        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        if (token) {
            axios
                .get(`${Base_Uri}getDemandedProduct`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json", // Include other headers as needed
                    },
                })
                .then((res) => {
                    let data = res.data;

                    if (data.status) {
                        let myData = res.data;

                        let { data } = myData;

                        if (data && data.length > 0) {
                            let allProducts = [];

                            for (var i = 0; i < data.length; i++) {
                                let product = data[i];

                                product.id = i + 1;
                                product["productName"] = product?.product_name;

                                allProducts.push(product);
                            }

                            setProducts(allProducts);
                            setGridKey(gridKey + 1);
                            dispatch(updateDemandedProducts(allProducts));
                        }
                    }
                });
        }

    }



    useEffect(() => {

        getDemandedProducts()

    }, [])


    const column = [
        {
            field: "id",
            headerName: "ID",
            headerClassName: 'super-app-theme--header',
            width: "50",

        },
        {
            field: "productName",
            headerName: "Product Name",
            headerClassName: 'super-app-theme--header',
            width: "300",
        },

        {
            field: "count",
            headerName: "Customer Reach",
            headerClassName: 'super-app-theme--header',
            width: 200,
        },
        {
            field: "full_name",
            headerName: "Employee Name",
            headerClassName: 'super-app-theme--header',
            width: 200,
            renderCell: (cellValues) => {

                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: Colors.white,
                        }}
                    >
                        {cellValues?.row?.employeeDetails?.full_name}
                    </Box>
                );
            }

        },
        {
            field: "createdAt",
            headerName: "Created Date",
            headerClassName: 'super-app-theme--header',
            width: 200,
            renderCell: (cellValues) => {

                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: Colors.white,
                        }}
                    >
                        {new Date(cellValues?.row?.createdAt)?.toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                        })}
                    </Box>
                );
            }

        },
        {
            field: "updatedAt",
            headerName: "Updated Date",
            headerClassName: 'super-app-theme--header',
            width: 350,
            renderCell: (cellValues) => {

                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: Colors.white,
                        }}
                    >
                        {new Date(cellValues?.row?.updatedAt)?.toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                        })}
                    </Box>
                );
            }

        },



    ]

    const [selectedCategory, setSelectedCategory] = useState("select")



    const handleSelectionModelChange = (selectionModel) => {

        if (selectionModel.length > 0) {
            let allProducts =
                demandedProducts && demandedProducts.length > 0
                    ? product.map((e) => {
                        if (selectionModel.includes(e.id)) {
                            return {
                                ...e,
                                selected: true,
                            };
                        } else {
                            return {
                                ...e,
                                selected: false,
                            };
                        }
                    })
                    : [];
            // setGridKey(gridKey + 1);
            dispatch(updateDemandedProducts(allProducts));
        } else {
            let allProducts =
                DemandedProduct &&
                DemandedProduct?.length > 0 &&
                DemandedProduct.map((e, i) => {
                    return {
                        ...e,
                        selected: false,
                    };
                });
            // setGridKey(gridKey + 1);
            dispatch(updateDemandedProducts(allProducts));
        }
    };


    const handleSearchProducts = (text) => {

        if (!text) {
            setSearchProducts([])
        }

        setSearchProducts(demandedProducts && demandedProducts.length > 0 && demandedProducts.filter((e, i) => {

            if (e?.productName?.toLowerCase().includes(text.toLowerCase()) || e?.count == text) {
                return e
            }

        }))


    }

    const handleDeleteProduct = async () => {


        let deleteProducts = demandedProducts && demandedProducts.length > 0 && demandedProducts.filter((e) => e.selected)

        setLoading(true)

        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        if (token) {
            axios
                .delete(`${Base_Uri}deleteDemandedProducts`, {
                    data: deleteProducts,
                    // Corrected: Pass deleteProducts as data in the configuration
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json", // Include other headers as needed
                    },
                }).then((res) => {

                    let data = res.data

                    if (!data.status) {

                        alert(data.message)
                        setLoading(false)
                        return

                    } else {


                        getDemandedProducts()
                        setGridKey(gridKey + 1)
                        setDeleteModalVisible(false)
                        setLoading(false)
                        alert(data.message)
                    }

                }).catch((error) => {

                    alert(error?.message)
                    setLoading(false)
                    return

                })

        }



    }

    return (
        <Box sx={{ padding: "20px", backgroundColor: Colors.white, marginTop: "20px" }} >

            <Box sx={{ display: "flex" }} >
                <Grid container md={12} xs={12} sm={12} >
                    <Grid item xs={12} sm={12} md={6} >


                        {demandedProducts && demandedProducts.length > 0 && demandedProducts.some((e, i) => e.selected) && <CustomButton
                            text={"Delete"}
                            onClick={() => setDeleteModalVisible(true)}
                            style={{
                                width: "30%",
                                padding: "5px 5px 5px 5px",
                                fontSize: {
                                    xl: "12px",
                                    lg: "12px",
                                    md: "10px",
                                    sm: "10px",
                                    xs: "8px",
                                },
                                display: "flex",
                                color: Colors.white,
                                justifyContent: "space-around",
                                marginRight: "5px",
                                alignItems: "center",
                                backgroundColor: Colors.red,
                            }}
                        />}


                    </Grid>
                    <Grid item xs={12} sm={12} md={6} >
                        <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >

                            <Grid md={11} xs={10} sm={10} >
                                <Box sx={{ width: "100%", marginTop: { xs: "0px", sm: "0px", md: "0px" }, border: `1px solid ${Colors.button}`, borderRadius: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px 10px 5px 10px", margin: 0 }} >
                                    <TextField
                                        variant="standard" // <== changed this
                                        margin="normal"
                                        required
                                        fullWidth
                                        InputProps={{
                                            // startAdornment: <AccountCircle />, // <== adjusted this
                                            disableUnderline: true,
                                            fontSize: "12px"
                                            // <== added this
                                            // fontSize: "2px"
                                        }}
                                        onChange={(e) => handleSearchProducts(e.target.value)}
                                        placeholder="Search Name ,Id , Phone & Bar Code"
                                        sx={{ width: "90%", fontSize: "12px", padding: 0, fontFamily: "Poppins", fontWeight: "bold", margin: 0 }}
                                    />
                                    <img src={require("../../Images/search.png")} style={{ height: "20px" }} />
                                </Box>
                            </Grid>


                            <Box sx={{ margin: 0, height: "35px", width: "35px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: Colors.button }} >
                                <img src={require("../../Images/qrcode.png")} style={{ height: "20px" }} />
                            </Box>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ height: "500px", backgroundColor: "white", marginTop: 5 }} >
                {demandedProducts && <DataGrid key={gridKey} sx={{
                    '& .MuiDataGrid-cell': {
                        fontFamily: 'Poppins',
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#000"
                        // Change to your desired cell text color
                    },

                    // '& .MuiDataGrid-columnHeader': {
                    //     backgroundColor: Colors.black,
                    //     color: "white",
                    //     fontWeight: 700,
                    //     border: "2px solid white",
                    //     margin: 0,
                    //     padding: '8px', // Adjust this value as needed
                    //     alignItems: "center"
                    // },
                    '& .MuiDataGrid-colCell, & .MuiDataGrid-colCellTitle': {
                        padding: '0px', // Adjust this value as needed,
                        fontFamily: "Poppins"
                    },
                    '& .MuiDataGrid-columnHeaderCheckbox': {
                        backgroundColor: Colors.black, // Change this to your desired checkbox background color
                        color: 'white', // Change this to your desired checkbox color
                    },
                    // '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked':{
                    //     color:"blue" 
                    // },

                    '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
                        color: "gray"
                    }
                    // '& .MuiDataGrid-columnHeaderCheckbox': {
                    //     backgroundColor: Colors.white
                    // },
                    ,
                    '& .MuiDataGrid-columnHeader': {
                        fontFamily: "Poppins",
                        // color: "black",
                        fontWeight: "bold",


                    },
                    // '& .MuiDataGrid-columnHeaderCheckbox': {
                    //     backgroundColor: Colors.white
                    // },
                    // '& .MuiDataGrid-columnHeader': {
                    //     backgroundColor: Colors.white,
                    //     color: "black",
                    //     fontWeight: 700,
                    //     border: "2px solid white",
                    //     margin: 0
                    // },
                    // '& .MuiDataGrid-columnHeaderCheckbox': {
                    //     backgroundColor: Colors.white
                    // },


                }} checkboxSelection onRowSelectionModelChange={handleSelectionModelChange} rows={(searchProducts && searchProducts.length > 0) ? searchProducts : demandedProducts} columns={column} />}
            </Box>

            {deleteModalVisible && (
                <CustomModal
                    open={deleteModalVisible}
                    closeModal={() => setDeleteModalVisible(false)}
                    heading={"Delete Product"}

                    loading={loading}
                    confirm={() => handleDeleteProduct()}
                    text={"Are your sure you want to delete this product from here."}
                />
            )}

        </Box>
    )
}

export default DemandedProduct
