import { createSlice } from '@reduxjs/toolkit';

const AuthenticationSlice = createSlice({
    name: 'authenticationReducer',
    
    initialState: {
        authentication: false,
        
    },
    reducers: {
        updateAuth(state, action) {
            state.authentication = action.payload;
        },
    },
});

export const { updateAuth } = AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;