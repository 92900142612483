import { Box, Button, Grid, Typography } from "@mui/material";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useNavigate,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import { Colors } from "../../Constant/Color";
import AddNewStock from "../addProduct/addNewStock";
import CustomerAccountDetails from "./innerPages/customerAccountDetails";
import SingleAccountDetails from "./innerPages/singleAccountDetails";
import CustomerPriceDetails from "./innerPages/PriceDetails";
import CreditNoteDetails from "./innerPages/CreditNoteDetails";
import Order from "./innerPages/Order";
import { useSelector } from "react-redux";

function CustomerAccount() {
    const navigate = useNavigate();
    const [customerAccountAccess, setCustomerAccountAccess] = useState("");
    const userData = useSelector((state) => state.loginReducer.updatedState);

    const [options, setOptions] = useState([
        {
            name: "Customer Account Details",
            selected: true,
            routeName: "/CustomerAccount",
        },
        {
            name: "Transaction",
            selected: false,
            routeName: "Transaction",
        },
    ]);

    const onScreenChange = (item, ind) => {
        setOptions((prevOptions) =>
            prevOptions.map((e, i) => ({
                ...e,
                selected: ind === i,
            }))
        );
        navigate(item?.routeName);
    };

    let location = window.location.href;

    useEffect(() => {
        let split = location.toString().split("/");
        let homeScreen = split[3];
        let above = split[4];


        setOptions(

            options?.map((e) => {

                return {

                    ...e,
                    selected:
                        homeScreen === "CustomerAccount" &&
                        e.routeName === "/CustomerAccount" &&
                        !above ? true : false,

                }
            })




        )

    }, [location]);

    useEffect(() => {
        let roles = userData?.role_access;

        let CustomerAccountRoles =
            roles &&
            roles.find((e) => e.name.toLowerCase() === "customeraccount");

        let customerAccountSub = CustomerAccountRoles?.subcategory;



        let option = customerAccountSub && customerAccountSub?.length > 0 && customerAccountSub?.find((e, i) => e?.selected)

        let allOptions = options && options.length > 0 && options?.map((e, i) => {

            const name = e?.name.replace(/\s+/g, "");

            if (name?.toLowerCase() == option?.name?.toLowerCase()) {
                return {
                    ...e,
                    routeName: "/CustomerAccount",
                    selected : true
                }
            } else {
                return e
            }

        })

        setOptions(
            allOptions.filter((e) => {
                const name = e.name.replace(/\s+/g, "");

                return (
                    userData?.role?.toLowerCase() === "admin" ||
                    (customerAccountSub &&
                        customerAccountSub.some(
                            (j) =>
                                j.name.toLowerCase() === name.toLowerCase() && j.selected
                        ))
                );
            })
        );

        setCustomerAccountAccess(customerAccountSub);
    }, [userData]);

    const isAdmin = userData?.role?.toLowerCase() === "admin";

    const hasAccess = (name) =>
        isAdmin ||
        (customerAccountAccess &&
            customerAccountAccess.some(
                (e) => e.name.toLowerCase() === name.toLowerCase() && e.selected
            ));

    const defaultRoute =
        options.find((option) => {
            let name = option?.name.replace(/\s+/g, "");

            let isAccess = hasAccess(name);

            return isAccess

        })?.routeName || "/CustomerAccount";

    return (
        <Box style={{ backgroundColor: Colors.main, minHeight: "100vh" }}>
            <Navbar currentPage={"Customer Account"} />

            <Box style={{ padding: 20, marginTop: 0, paddingTop: 0, paddingBottom: 0 }}>
                <Box
                    style={{
                        width: "100%",
                        backgroundColor: Colors.white,
                        padding: 20,
                        borderRadius: 10,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "flex-around",
                    }}
                >
                    <Grid container sm={12} md={12} lg={12}>
                        {options.map((e, i) => (
                            <Grid item xs={12} sm={6} md={4} lg={2.5} xl={2} key={i}>
                                <Button
                                    onClick={() => onScreenChange(e, i)}
                                    sx={{
                                        borderRadius: "10px",
                                        marginRight: "5px",
                                        backgroundColor: e.selected ? Colors.button : Colors.main,
                                        marginTop: "5px",
                                        width: { xl: "99%", xs: "98%" },
                                        padding: "10px 15px",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: e.selected ? Colors.white : Colors.text,
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {e.name}
                                    </Typography>
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Routes>
                    <Route path="/CustomerAccount" element={<Navigate to={defaultRoute == "/CustomerAccount" ? '/' : defaultRoute} replace />} />

                    {options.map((option, i) => {
                        const name = option.name.replace(/\s+/g, "");

                        if (hasAccess(name)) {
                            return (
                                <Route
                                    key={i}
                                    path={option?.routeName == "/CustomerAccount" ? "/" : option?.routeName}
                                    element={
                                        option.name == "Customer Account Details" ? (
                                            <CustomerAccountDetails />
                                        ) : option.name.toLowerCase() == "transaction" ? (
                                            <SingleAccountDetails />
                                        ) : null
                                    }
                                />
                            );
                        }
                        return null;
                    })}
                </Routes>
            </Box>
        </Box>
    );
}

export default CustomerAccount;
