






import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import DatePickers from './DatePicker';
import CustomButton from './Button';
import BasicSelect from './Dropdown';
import CheckBox from './checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { Base_Uri } from '../Constant/BaseUri';
import { updateCustomers } from '../Redux/LoginReduces';
import axios from 'axios';
import { RingLoader } from 'react-spinners';
import { SettingsOverscanSharp } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';


import CustomModal from './modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xl: "30%", md: "40%", sm: "60%", xs: "90%" },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    // height: "800px",
    overflow: "auto",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
};

export default function RefundModal({ open, closeModal, setRefundModal, customerDetails, setCustomerLedger, setSelectedCustomer, customerLedger }) {





    const dispatch = useDispatch()


    const userData = useSelector((state) => state.loginReducer.updatedState);


    const [loading, setLoading] = useState(false)
    const [openConfirmModal, setOpenConfirmModal] = useState(false)




    const [paymentMethod, setPaymentMethod] = useState([
        "Cash",
        "Card",
        "Bank Transfer",
        "Cheque",
        "OnAccount"
    ])

    const customers = useSelector(state => state.loginReducer.customers)

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("select")

    const [refundType, setRefundType] = useState([
        "Invoice",
        "Quotation",

    ])



    const [chequeDetails, setChequeDetails] = useState({
        chequeNo: "",
        bankName: "",
        day: "",
        month: "",
        year: "",
    });

    const [transactionType, setTransactionType] = useState(["Credit", "Refund"])
    const [selectedTransactionType, setSelectedTransactionType] = useState("select")

    const [selectedRefundType, setSelectedRefundType] = useState("select")

    const [amount, setAmount] = useState(null)
    const [referenceId, setReferenceId] = useState("")
    const [reason, setReason] = useState("")
    const [transactionId, setTrasactionId] = useState("")




    const getCustomers = async () => {
        let token = await localStorage.getItem("userData");
        token = JSON.parse(token);

        axios
            .get(`${Base_Uri}getCustomers`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Include other headers as needed
                },
            })
            .then((res) => {
                let { data } = res;

                if (data.status) {
                    let customers = data.data;

                    dispatch(
                        updateCustomers(customers && customers.length > 0 ? customers : [])
                    );
                }
            })
            .catch((error) => {
                console.log(error, "error");
            });
    };





    const handleRefundClick = async () => {




        let dataToSend = {

            amount: amount,
            type: (!selectedRefundType || selectedRefundType == "select") ? "" : selectedRefundType,
            paymentMethod: selectedPaymentMethod,
            transactionId: transactionId,
            customerDetails: customerDetails,
            reason: reason,
            amountType: selectedTransactionType,
            chequeDetails: chequeDetails,
            employeeDetails: userData
        }

        setLoading(true)



        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;


        axios.put(`${Base_Uri}refundBalance`, dataToSend, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }).then((res) => {

            let data = res?.data

            if (!data?.status) {
                alert(data?.message)
                setLoading(false)
                return
            }

            let customer = data.data
            let Ledger = data?.customerLedger



            let allCustomer = [...customers]

            let otherCustomers = allCustomer.filter((e, i) => e?.id !== customer?.id)

            otherCustomers = otherCustomers?.length > 0 ? otherCustomers : []

            let dataToDispatch = [...otherCustomers, customer]

            dispatch(updateCustomers(dataToDispatch))

            setCustomerLedger([...customerLedger, Ledger])
            // getCustomers()
            setSelectedCustomer(customer)
            setReferenceId("")
            setTrasactionId("")
            setAmount("")
            setReason("")
            setOpenConfirmModal(false)
            setSelectedPaymentMethod("select")
            setSelectedRefundType("select")
            setSelectedTransactionType("select")
            alert(data.message)
            setLoading(false)
            setRefundModal(false)


        }).catch((error) => {

            alert(error?.message)
            setLoading(false)
            return

        })




    }



    const handleOpenConfirmModal = () => {

        if (!amount) {

            alert("Kindly Enter Amount")
            return
        }

        if (selectedPaymentMethod.toLowerCase() == "card" && !transactionId) {
            alert("Transaction Id is missing")
            return
        }

        if (selectedPaymentMethod?.toLowerCase() == "cheque" && Object.values(chequeDetails)?.some((e, i) => !e)) {
            alert("Cheque Detail is missing")
            return
        }

        if (!selectedTransactionType || selectedTransactionType == "select") {

            alert("Kindly Select Transaction Type")
            return
        }

        if ((!selectedPaymentMethod || selectedPaymentMethod == "select")) {
            alert("Kindly Select Payment Method")
            return
        }

        if ((!selectedRefundType || selectedRefundType == "select")) {


            if (selectedTransactionType?.toLowerCase() == "refund" && (selectedPaymentMethod?.toLowerCase() == "card" || selectedPaymentMethod?.toLowerCase() == "cash")) {

            } else {

                alert("Kindly Select Balance Type")
                return
            }

        }



        setOpenConfirmModal(true)

    }

    return (
        <div>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Box onClick={() => closeModal()} style={{ position: "absolute", top: 10, right: 10 }} >
                        <CloseIcon fontSize="large" />
                    </Box>

                    <Grid item xs={12} sm={12} md={12}>
                        <Box style={{ padding: 10 }}>


                            <Box style={{ marginTop: "10px", padding: 15, backgroundColor: Colors.white, borderTopRightRadius: "5px", borderTopLeftRadius: "5px", marginBottom: "0px" }} >

                                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", }} >


                                    <Typography sx={{ fontSize: "20px", fontWeight: "bold", fontFamily: "Poppins" }} >
                                        Credit/Refund
                                    </Typography>
                                </Box>

                                <Box style={{ marginTop: 10 }} >
                                    <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between" }} >
                                        <Grid xs={12} md={12} >
                                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                Amount
                                            </Typography>
                                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                                <TextField
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    value={amount}
                                                    variant="standard"
                                                    margin="normal"
                                                    placeholder="Enter Amount"
                                                    required
                                                    fullWidth
                                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true, // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                />
                                            </Box>


                                        </Grid>

                                    </Grid>





                                    <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                            Select Transaction Type
                                        </Typography>


                                    </Box>
                                    <BasicSelect style={{ marginTop: 0.5 }} value={selectedTransactionType} status="Select" id={transactionType} width={"100%"} onChange={(e) => setSelectedTransactionType(e)} />




                                    {(selectedTransactionType && selectedTransactionType !== "select") ?
                                        <>
                                            <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                                    Select Payment Method
                                                </Typography>

                                            </Box>


                                            <BasicSelect style={{ marginTop: 0.5 }} value={selectedPaymentMethod} status="Select" id={selectedTransactionType?.toLowerCase() == "credit" ? paymentMethod?.filter((e) => e?.toLowerCase() !== "onaccount") : paymentMethod?.filter((e) => e?.toLowerCase() == "cash" || e?.toLowerCase() == "card" || e?.toLowerCase() == "onaccount")} width={"100%"} onChange={(e) => setSelectedPaymentMethod(e)} />
                                        </>
                                        : ""}







                                    {selectedPaymentMethod?.toLowerCase() == "card" && <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }} >
                                        <Grid xs={12} md={12} >
                                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                Transaction Id
                                            </Typography>
                                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                                <TextField
                                                    onChange={(e) => setTrasactionId(e.target.value)}
                                                    value={transactionId}
                                                    variant="standard"
                                                    margin="normal"
                                                    placeholder="Enter Transaction Id"
                                                    required
                                                    fullWidth
                                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true, // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                />
                                            </Box>


                                        </Grid>

                                    </Grid>}


                                    {selectedPaymentMethod?.toLowerCase() == "cheque" && <Box>





                                        <Box>

                                            <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                                                <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize", }} id="modal-modal-description" >
                                                    Cheque No
                                                </Typography>

                                                <TextField

                                                    onChange={(e) => setChequeDetails({ ...chequeDetails, chequeNo: e.target?.value })}
                                                    value={chequeDetails?.chequeNo}
                                                    variant="standard" // <== changed this
                                                    margin="normal"
                                                    required
                                                    // fullWidth
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true,
                                                        fontSize: "12px",


                                                        // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                    placeholder=""
                                                    sx={{ width: "75%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 0, fontFamily: "Poppins", fontWeight: "bold", ml: 1, margin: 0, }}
                                                />

                                            </Box>

                                            <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                                                <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize", }} id="modal-modal-description" >
                                                    Bank Name
                                                </Typography>

                                                <TextField

                                                    onChange={(e) => setChequeDetails({ ...chequeDetails, bankName: e.target?.value })}
                                                    variant="standard" // <== changed this
                                                    margin="normal"
                                                    required
                                                    value={chequeDetails?.bankName}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        fontSize: "12px",
                                                    }}
                                                    placeholder=""
                                                    sx={{ width: "75%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 0, fontFamily: "Poppins", fontWeight: "bold", ml: 1, margin: 0 }}
                                                />

                                            </Box>


                                            <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                                                <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize" }} id="modal-modal-description" >
                                                    Clear Date
                                                </Typography>

                                                <TextField

                                                    onChange={(e) => setChequeDetails({ ...chequeDetails, day: e.target?.value })}
                                                    variant="standard" // <== changed this

                                                    type='number'
                                                    value={Number(chequeDetails?.day)}
                                                    required
                                                    // fullWidth

                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true,
                                                        fontSize: "12px",
                                                        sx: {
                                                            "& input": {
                                                                textAlign: "center"
                                                            }
                                                        }
                                                    }}

                                                    placeholder="DD"
                                                    sx={{ width: "15%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 0, fontFamily: "Poppins", fontWeight: "bold", }}
                                                />


                                                <TextField

                                                    onChange={(e) => setChequeDetails({ ...chequeDetails, month: e.target?.value })}
                                                    variant="standard" // <== changed this
                                                    value={Number(chequeDetails?.month)}
                                                    type='number'
                                                    required

                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true,
                                                        fontSize: "12px",
                                                        sx: {
                                                            "& input": {
                                                                textAlign: "center"
                                                            }
                                                        }


                                                        // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                    placeholder="MM"
                                                    sx={{ width: "15%", fontSize: "12px", border: `1px solid ${Colors.border}`, ml: 1, padding: 0, fontFamily: "Poppins", fontWeight: "bold", }}
                                                />


                                                <TextField
                                                    onChange={(e) => setChequeDetails({ ...chequeDetails, year: e.target?.value })}
                                                    variant="standard" // <== changed this
                                                    type='number'
                                                    required
                                                    value={Number(chequeDetails?.year)}
                                                    // fullWidth
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true,
                                                        fontSize: "12px",
                                                        sx: {
                                                            "& input": {
                                                                textAlign: "center"
                                                            }
                                                        }



                                                        // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                    placeholder="YY"
                                                    sx={{ width: "15%", fontSize: "12px", border: `1px solid ${Colors.border}`, ml: 1, padding: 0, fontFamily: "Poppins", fontWeight: "bold", textAlign: "center", }}
                                                />

                                            </Box>

                                        </Box>



                                    </Box>}



                                    {selectedTransactionType?.toLowerCase() == "refund" && (selectedPaymentMethod?.toLowerCase() == "cash" || selectedPaymentMethod?.toLowerCase() == "card") ? ""
                                        :
                                        <>
                                            <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                                    Select Balance Type
                                                </Typography>
                                            </Box>
                                            <BasicSelect style={{ marginTop: 0.5 }} value={selectedRefundType} status="Select" id={refundType} width={"100%"} onChange={(e) => setSelectedRefundType(e)} />

                                        </>}


                                    <Grid xs={12} md={12} style={{ marginTop: 10 }} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Reason
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                            <TextField
                                                onChange={(e) => setReason(e.target.value)}
                                                value={reason}
                                                multiline={true}
                                                numberOfLines={5}
                                                variant="standard"
                                                margin="normal"
                                                placeholder="Enter Reason..."
                                                required
                                                fullWidth
                                                sx={{ width: "100%", height: "100px", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>


                                    </Grid>



                                </Box>





                            </Box>




                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", flexWrap: "wrap", marginTop: 2, marginBottom: { xs: 2, sm: 2, md: 0 }, backgroundColor: Colors.white, padding: "20px", borderRadius: "5px" }} >
                                <Box sx={{ width: "100%", display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "flex-end", flexWrap: "wrap" }} >


                                    <CustomButton onClick={closeModal} style={{ backgroundColor: Colors.white, border: `1px solid ${Colors.button}`, color: Colors.button, width: { xs: "100%", sm: 120 }, marginTop: { xs: "10px", sm: "10px" }, marginRight: "10px", fontSize: { md: "10px", lg: "12px" } }} text="Exit" />

                                    <CustomButton onClick={() => handleOpenConfirmModal()} style={{ backgroundColor: Colors.button, border: `1px solid ${Colors.button}`, color: Colors.white, width: { xs: "100%", sm: 120 }, marginTop: { xs: "10px", sm: "10px" }, fontSize: { md: "10px", lg: "12px" } }} text={"Proceed"} />

                                    {/* <Button onClick={() => navigate("./PriceDetails")} sx={{ backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: 5, padding: "10px 25px 10px 25px", fontSize: "14px", fontFamily: "Poppins", fontWeight: "600", width: "48%" }} >
                                        Next
                                    </Button> */}
                                </Box>

                            </Box>


                        </Box>
                    </Grid>


                    <CustomModal
                        heading={('Credit/Refund')}
                        loading={loading}
                        text={
                            "Are you sure you want to proceed with this transaction"}
                        open={openConfirmModal}
                        closeModal={() => setOpenConfirmModal(false)}
                        confirm={handleRefundClick}
                    />


                </Box>
            </Modal>
        </div>
    );
}
