import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { RingLoader } from 'react-spinners';
import { Table, TableContainer, TableHead, TableRow, TextField, TableCell, Paper, TableBody } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from './Button';
import { Grid } from '@mui/material';
import axios from 'axios';
import { Base_Uri } from '../Constant/BaseUri';
import { updatePurchaseOrders, updateSuppliers } from '../Redux/LoginReduces';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';



export default function CreatePurchaseOrderModal({ barcodeData, setBarcodeRead, headingTextStyle, containerStyle, open, closeModal, buttonContainerStyle, }) {


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        maxHeight: "90vh",
        overflow: "auto",
        transform: 'translate(-50%, -50%)',
        width: { lg: "90%", md: "90%", sm: "95%", xs: "100%" },
        borderRadius: "10px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    };

    const dispatch = useDispatch()

    const products = useSelector(state => state.loginReducer.products)
    const suppliers = useSelector(state => state.loginReducer?.suppliers)


    const [searchProducts, setSearchProducts] = useState([])
    const [searchSuppliers, setSearchSuppliers] = useState([])
    const [addNewSupplier, setAddNewSupplier] = useState(false)
    const [supplierDetails, setSupplierDetails] = useState({
        supplierName: "",
        shopDetails: "",
        mobileNumber: "",
    })

    const [loading, setLoading] = useState(false)


    const [productText, setProductText] = useState("")
    const [supplierText, setSupplierText] = useState("")


    const [purchaseOrderDetails, setPurchaseOrderDetails] = React.useState({

        productDetails: [],
        supplierDetails: {},
        total_cost: null,
        total_items: null,
    })


    const getPurchaseOrders = async () => {
        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        axios
            .get(`${Base_Uri}getAllPurchaseOrders`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Include other headers as needed
                },
            })
            .then((res) => {
                let myData = res.data;

                let { data } = myData;

                data =
                    data &&
                    data?.length > 0 &&
                    data?.map((e, i) => {
                        return {
                            ...e,
                            id: i + 1,
                            supplier_name: e?.supplierDetails?.[0]?.supplier_name,
                            supplier_address: e?.supplierDetails?.[0]?.supplier_address,
                            supplier_mobile_number:
                                e?.supplierDetails?.[0]?.supplier_mobile_number,
                            date: e?.created_at,
                        };
                    });


                if (!data || data.length == 0) {
                } else {

                    dispatch(updatePurchaseOrders(data))

                }
            })
            .catch((error) => {
                console.log(error, "error");
            });
    };

    const getAllSuppliers = async () => {
        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        axios
            .get(`${Base_Uri}getSuppliers`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Include other headers as needed
                },
            })
            .then((res) => {
                let data = res.data;

                if (data.status) {
                    dispatch(updateSuppliers(data.data));
                }
            })
            .catch((error) => {
                console.log(error, "error");
            });
    };

    const handleSearchProducts = (value) => {

        setProductText(value)

        if (!value) {
            setSearchProducts([])
            return
        }

        setSearchProducts(products && products.length > 0 && products?.filter((e, i) => {




            if (e?.productName?.toLowerCase().includes(value.toLowerCase()) || e?.IMEI?.includes(value.toLowerCase()) ||
                e?.barcode?.toString()?.includes(value)
            ) {
                return e
            }
        }))

    }


    const handleSearchSuppliers = (value) => {

        setSupplierText(value)


        if (!value) {
            setSearchSuppliers([])
            return
        }

        setSearchSuppliers(suppliers && suppliers.length > 0 && suppliers?.filter((e, i) => {




            if (e?.supplierName?.toLowerCase().includes(value.toLowerCase()) || e?.shopDetails?.includes(value.toLowerCase()) ||
                e?.mobileNumber?.toString()?.includes(value)
            ) {
                return e
            }
        }))

    }


    const handleSelectProduct = (product) => {


        let { productDetails } = purchaseOrderDetails

        if (productDetails?.some((e, i) => e?.barcode == product.barcode)) {
            alert("This product has already added")
            return
        }

        productDetails.push(product)

        setPurchaseOrderDetails({
            ...purchaseOrderDetails,
            productDetails: productDetails
        })
        setSearchProducts([])
        setProductText("")
    };

    const handleAddSupplier = async () => {

        if (!supplierDetails.supplierName) {
            alert("supplier name is missing")
            return
        }
        if (!supplierDetails.mobileNumber) {
            alert("mobile Number is missing")
            return
        }
        if (!supplierDetails.shopDetails) {
            alert("Shop details is missing")
            return
        }


        setLoading(true)

        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;


        axios.post(`${Base_Uri}addSupplier`, supplierDetails, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json", // Include other headers as needed
            },
        }).then((res) => {

            let data = res.data


            setLoading(false)



            if (data.status) {

                alert(data?.message)
                setLoading(false)
                getAllSuppliers()
                setAddNewSupplier(false)

            }
        }).catch((error) => {

            setLoading(false)

        })

    }

    const handleSelectSuppliers = (supplier) => {



        let { supplierDetails } = purchaseOrderDetails

        supplierDetails = {
            supplier_name: supplier?.supplierName,
            supplier_address: supplier?.shopDetails,
            supplier_mobile_number: supplier?.mobileNumber,
            supplier_id: supplier?._id

        }

        setPurchaseOrderDetails({
            ...purchaseOrderDetails,
            supplierDetails: supplierDetails
        })

        setSearchSuppliers([])
        setSupplierText("")

    }

    const handleAddQuantity = (qty, ind) => {

        let { productDetails } = purchaseOrderDetails

        productDetails = productDetails && productDetails?.length > 0 && productDetails?.map((e, i) => {

            if (ind == i) {
                return {
                    ...e,
                    orderQty: qty
                }
            } else {
                return e
            }

        })


        setPurchaseOrderDetails({
            ...purchaseOrderDetails,
            productDetails: productDetails
        })

    }

    const handleEnterCost = (cost, ind) => {

        let { productDetails } = purchaseOrderDetails

        productDetails = productDetails && productDetails?.length > 0 && productDetails?.map((e, i) => {

            if (ind == i) {
                return {
                    ...e,
                    cost_price: cost
                }
            } else {
                return e
            }

        })


        setPurchaseOrderDetails({
            ...purchaseOrderDetails,
            productDetails: productDetails
        })

    }

    const handleSavePurchaseOrders = async () => {



        let totalItems = purchaseOrderDetails?.productDetails?.length

        if (totalItems == 0) {

            alert("Kindly Add Products")
            return
        }

        if (purchaseOrderDetails && !purchaseOrderDetails?.productDetails?.every((e, i) => e.orderQty)) {

            alert("Kindly Add Quantity")
            return
        }


        let totalCost = purchaseOrderDetails?.productDetails?.reduce((pre, curr) => {

            return (pre ? Number(pre) : 0) + (Number(curr?.cost_price) * Number(curr?.orderQty))


        }, [0])

        let dataToSend = {

            ...purchaseOrderDetails,
            total_cost: totalCost,
            total_items: totalItems
        }


        setLoading(true)

        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        axios
            .post(`${Base_Uri}createPurchaseOrder`, dataToSend, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Include other headers as needed
                },
            })
            .then((res) => {

                let data = res.data;


                if (!data?.status) {

                    alert(data?.message)
                    setLoading(false)
                    return

                }

                if (data.status) {

                    alert(data?.message)
                    getPurchaseOrders()
                    setPurchaseOrderDetails({
                        ...purchaseOrderDetails,
                        supplierDetails: {},
                        productDetails: [],
                        total_cost: null,
                        total_items: null
                    })
                    setLoading(false)
                    // closeModal()

                }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error, "error");
            });





    }

    const handleDeleteProduct = (ind) => {


        let { productDetails } = purchaseOrderDetails

        productDetails = productDetails?.length > 0 && productDetails?.filter((e, i) => {
            return ind !== i
        })


        setPurchaseOrderDetails({
            ...purchaseOrderDetails,
            productDetails: productDetails
        })
    }


    useEffect(() => {
        if (barcodeData?.length > 0) {

            let product =
                products &&
                products?.length > 0 &&
                products?.find((e) => e?.barcode == barcodeData);

            if (product) {
                handleSelectProduct(product);
                setBarcodeRead("");
            }
        }
    }, [barcodeData]);


    return (
        <div>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {loading ? <Box style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} >
                    <RingLoader loading={loading} size={100} />
                </Box> :
                    <Box sx={[style, { containerStyle }]}>
                        <Box onClick={() => closeModal()} style={{ position: "absolute", top: 10, right: 10 }} >
                            <CloseIcon fontSize="large" />
                        </Box>
                        <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                            Create Purchase Order
                        </Typography>

                        <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0, margin: 0, alignItems: "center" }} >

                            <TextField
                                variant="standard"
                                margin="normal"
                                required
                                onChange={(e) => handleSearchSuppliers(e.target.value)}
                                value={supplierText}
                                fullWidth
                                InputProps={{
                                    disableUnderline: true,
                                    fontSize: "12px",
                                    style: {
                                        textAlign: "center",
                                        fontSize: "16px",
                                        alignSelf: "center",
                                    },
                                }}
                                inputProps={{
                                    disableUnderline: true,
                                    fontSize: "12px",

                                }}
                                placeholder={"Search Supplier"}
                                sx={{
                                    width: "50%",
                                    // height: width < 600 ? "25px" : "35px",
                                    fontSize: "12px",
                                    padding: 1,
                                    border: `1px solid ${Colors.border}`,
                                    borderRadius: "5px",
                                    margin: 0,

                                    textAlign: "center", // Move textAlign to sx
                                    fontFamily: "Poppins",
                                    fontWeight: "bold",

                                }}
                            />

                            <CustomButton onClick={() => setAddNewSupplier(!addNewSupplier)} text={addNewSupplier ? "Close Supplier" : "Add New Supplier"} style={{ borderRadius: 2, width: 200, padding: 0, height: 60 }} />



                        </Box>

                        <Box style={{ display: "flex", flexDirection: "column", margin: 0 }} >
                            {searchSuppliers &&
                                searchSuppliers.length > 0 &&
                                searchSuppliers.map((e, i) => {
                                    return (
                                        <Button
                                            onClick={() => handleSelectSuppliers(e)}
                                            sx={{
                                                width: "50%",
                                                mt: 0.5,
                                                backgroundColor: "lightGray",
                                                // backgroundColor: Colors.white,
                                                p: "0px 10px",
                                                fontFamily: "Poppins",
                                                fontWeight: "bold",
                                                borderRadius: 1,
                                                border: `1px solid ${Colors.border}`,
                                            }}
                                        >
                                            <p
                                                style={{
                                                    fontFamily: "Poppins",
                                                    textAlign: "left",
                                                }}
                                            >
                                                {e.supplierName}
                                            </p>

                                        </Button>
                                    );
                                })}
                        </Box>



                        {addNewSupplier && <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }} >

                            <Grid xs={12} md={5.5} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                    Supplier Name:
                                </Typography>
                                <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                    <TextField
                                        variant="standard" // <== changed this
                                        margin="normal"
                                        required

                                        type="text"
                                        onChange={(e) => setSupplierDetails({ ...supplierDetails, supplierName: e.target.value })}
                                        fullWidth
                                        sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                        placeholder="Enter Supplier Name..."
                                        InputProps={{
                                            // startAdornment: <AccountCircle />, // <== adjusted this
                                            disableUnderline: true, // <== added this
                                            // fontSize: "2px"
                                        }}
                                    />
                                </Box>


                            </Grid>
                            <Grid xs={12} md={5.5} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                    Shop Detail:
                                </Typography>
                                <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                    <TextField
                                        variant="standard" // <== changed this
                                        margin="normal"
                                        required
                                        type="text"
                                        onChange={(e) => setSupplierDetails({ ...supplierDetails, shopDetails: e.target.value })}
                                        fullWidth
                                        sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                        placeholder="Enter Shop Details..."
                                        InputProps={{
                                            // startAdornment: <AccountCircle />, // <== adjusted this
                                            disableUnderline: true, // <== added this
                                            // fontSize: "2px"
                                        }}
                                    />
                                </Box>

                            </Grid>
                            <Grid xs={12} md={5.5} >
                                <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                    Mobile Number:
                                </Typography>
                                <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                    <TextField
                                        variant="standard" // <== changed this
                                        margin="normal"
                                        required
                                        type="number"
                                        onChange={(e) => setSupplierDetails({ ...supplierDetails, mobileNumber: e.target.value })}
                                        fullWidth
                                        sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                        placeholder="Enter Mobile Number..."
                                        InputProps={{
                                            // startAdornment: <AccountCircle />, // <== adjusted this
                                            disableUnderline: true, // <== added this
                                            // fontSize: "2px"
                                        }}
                                    />
                                </Box>
                            </Grid>


                            <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10 }} >

                                <CustomButton onClick={() => handleAddSupplier()} text={loading ? <RingLoader loading={loading} size={30} /> : "Save"} style={{ p: 1, fontSize: "12px", fontWeight: "400" }} />

                            </Box>

                        </Grid>}



                        {purchaseOrderDetails?.supplierDetails && Object.keys(purchaseOrderDetails?.supplierDetails).length > 0 && <Box>

                            <Typography sx={{ fontSize: "24px", fontFamily: "Poppins", fontWeight: "bold" }} >
                                Supplier Details:
                            </Typography>


                            <Typography sx={{ fontSize: "18px", fontFamily: "Poppins" }} >
                                Supplier Name: {purchaseOrderDetails?.supplierDetails?.supplier_name}
                            </Typography>

                            <Typography sx={{ fontSize: "18px", fontFamily: "Poppins" }} >
                                Address: {purchaseOrderDetails?.supplierDetails?.supplier_address}
                            </Typography>

                            <Typography sx={{ fontSize: "18px", fontFamily: "Poppins" }} >
                                Mobile Number: {purchaseOrderDetails?.supplierDetails?.supplier_mobile_number}
                            </Typography>




                        </Box>}



                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            onChange={(e) => handleSearchProducts(e.target.value)}

                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                                fontSize: "12px",
                                style: {
                                    textAlign: "center",
                                    fontSize: "16px",
                                    alignSelf: "center",
                                },
                            }}
                            value={productText}
                            inputProps={{
                                disableUnderline: true,
                                fontSize: "12px",

                            }}
                            placeholder={"Search Product Name or IMEI or Barcode"}
                            sx={{
                                width: "100%",
                                // height: width < 600 ? "25px" : "35px",
                                fontSize: "12px",
                                padding: 1,
                                border: `1px solid ${Colors.border}`,
                                borderRadius: "5px",
                                marginBottom: 0.5,
                                textAlign: "center", // Move textAlign to sx
                                fontFamily: "Poppins",
                                fontWeight: "bold",

                            }}
                        />

                        <Box sx={{ maxHeight: "300px", overflow: "auto" }} >
                            {searchProducts &&
                                searchProducts.length > 0 &&
                                searchProducts.map((e, i) => {
                                    return (
                                        <Button
                                            onClick={() => handleSelectProduct(e)}
                                            sx={{
                                                width: "100%",
                                                mt: 0.5,
                                                backgroundColor: Colors.white,
                                                p: "0px 10px",
                                                fontFamily: "Poppins",
                                                fontWeight: "bold",
                                                borderRadius: 1,
                                                textAlign: "left",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "flex-start",
                                                border: `1px solid ${Colors.border}`,
                                            }}
                                        >
                                            <p
                                                style={{
                                                    fontFamily: "Poppins",
                                                    textAlign: "left",
                                                    marginBottom: 0,
                                                }}
                                            >
                                                {e.product_name}
                                            </p>
                                            {/* <p style={{ fontFamily: "Poppins", textAlign: "left" }}> */}
                                            Available qty: {e.qty}
                                            {/* </p> */}
                                        </Button>
                                    );
                                })}
                        </Box>






                        {purchaseOrderDetails && purchaseOrderDetails?.productDetails && purchaseOrderDetails?.productDetails?.length > 0 &&

                            <Box style={{ width: "100%", marginTop: 10 }}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Barcode</TableCell>
                                                <TableCell style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Product Name</TableCell>
                                                <TableCell style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Make</TableCell>
                                                <TableCell style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Model</TableCell>
                                                <TableCell style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Color</TableCell>
                                                <TableCell style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>IMEI</TableCell>
                                                <TableCell style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Quantity</TableCell>
                                                <TableCell style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Cost Price</TableCell>
                                                <TableCell style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {purchaseOrderDetails && purchaseOrderDetails?.productDetails && purchaseOrderDetails?.productDetails?.length > 0 && purchaseOrderDetails?.productDetails?.map((e, i) => (
                                                <TableRow key={i}>
                                                    <TableCell style={{ border: "1px solid black" }}>
                                                        <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.barcode}</Typography>
                                                    </TableCell>
                                                    <TableCell style={{ border: "1px solid black" }}>
                                                        <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.productName}</Typography>
                                                    </TableCell>
                                                    <TableCell style={{ border: "1px solid black" }}>
                                                        <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.make}</Typography>
                                                    </TableCell>
                                                    <TableCell style={{ border: "1px solid black" }}>
                                                        <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.model}</Typography>
                                                    </TableCell>
                                                    <TableCell style={{ border: "1px solid black" }}>
                                                        <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.product_color}</Typography>
                                                    </TableCell>
                                                    <TableCell style={{ border: "1px solid black" }}>
                                                        <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.IMEI ?? "-"}</Typography>
                                                    </TableCell>
                                                    <TableCell style={{ border: "1px solid black" }}>
                                                        <TextField
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                fontSize: "12px",
                                                                style: {
                                                                    textAlign: "center",
                                                                    fontSize: "16px",
                                                                    alignSelf: "center",
                                                                },
                                                            }}
                                                            type="number"
                                                            onChange={(e) => handleAddQuantity(e.target.value, i)}
                                                            inputProps={{
                                                                disableUnderline: true,
                                                                fontSize: "12px",
                                                            }}
                                                            value={e?.orderQty}
                                                            placeholder="Enter Quantity"
                                                            sx={{
                                                                width: "100%",
                                                                fontSize: "12px",
                                                                border: `1px solid ${Colors.border}`,
                                                                borderRadius: "5px",
                                                                margin: 0,
                                                                padding: 0,
                                                                textAlign: "center",
                                                                fontFamily: "Poppins",
                                                                fontWeight: "bold",
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell style={{ border: "1px solid black" }}>
                                                        <TextField
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                fontSize: "12px",
                                                                style: {
                                                                    textAlign: "center",
                                                                    fontSize: "16px",
                                                                    alignSelf: "center",
                                                                },
                                                            }}
                                                            value={e?.cost_price}
                                                            inputProps={{
                                                                disableUnderline: true,
                                                                fontSize: "12px",
                                                            }}
                                                            onChange={(e) => handleEnterCost(e.target.value, i)}
                                                            placeholder="Enter Cost Price"
                                                            sx={{
                                                                width: "100%",
                                                                fontSize: "12px",
                                                                border: `1px solid ${Colors.border}`,
                                                                borderRadius: "5px",
                                                                margin: 0,
                                                                padding: 0,
                                                                textAlign: "center",
                                                                fontFamily: "Poppins",
                                                                fontWeight: "bold",
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell style={{ border: "1px solid black" }}>

                                                        <DeleteIcon style={{ textAlign: "center" }} onClick={() => handleDeleteProduct(i)} />

                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>


                        }





                        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: 5 }} >


                            <Button onClick={() => handleSavePurchaseOrders()} sx={{ backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "10px", width: "50%", fontFamily: "Poppins", p: 1 }} >
                                Save Purchase Order
                            </Button>


                        </Box>



                    </Box>}



            </Modal>
        </div>
    );
}