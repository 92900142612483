import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { InputAdornment, TextField } from '@mui/material';
import Checkbox from './checkbox';
import { RingLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Base_Uri } from '../Constant/BaseUri';
import { DataGrid } from '@mui/x-data-grid';
import DatePickers from './DatePicker';
import CloseIcon from '@mui/icons-material/Close';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { lg: "80%", md: "95%", sm: "95%", xs: "100%" },
    borderRadius: "10px",
    maxHeight: "80vh",
    overflow: "auto",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

function SupplierLedgerModal({ data, loading, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, text1 }) {


    const [selectedDate, setSelectedDate] = useState(null)
    const [dateFrom, setDateFrom] = useState(null)


    const [searchDate, setSearchDate] = useState({
        selected: false
    })

    const { supplier_ledger } = data


    let supplierLedger = supplier_ledger && supplier_ledger.length > 0 && supplier_ledger.map((e, i) => {

        let balanceAmount = 0; // Initialize balance amount

        // Calculate balance amount by subtracting payAmount from cost price multiplied by quantity
        if (e.qty && e.cost_price) {
            balanceAmount = e.cost_price * e.qty - (e.payAmount || 0);
        } else if (e.payAmount) {
            // If there's no qty and cost_price, but there's a payAmount, set the balanceAmount to the payAmount
            balanceAmount = -e.payAmount;
        }

        // Sum up previous payAmount entries
        if (i > 0) {
            balanceAmount += supplier_ledger.slice(0, i).reduce((acc, curr) => {
                if (curr.qty && curr.cost_price) {
                    return acc + (Number(curr.qty * curr.cost_price)) - (Number(curr.payAmount) || 0);
                } else if (curr.payAmount) {
                    return acc - (Number(curr.payAmount) || 0);
                }
                return acc;
            }, 0);
        }

        return {
            ...e,
            id: i,
            balanceAmount: Number(balanceAmount).toFixed(2)
        };
    });



    supplierLedger = supplierLedger && supplierLedger?.length > 0 && supplierLedger?.sort((a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime())


    const columns = [


        {
            field: "date",
            headerName: "Date",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (cellValues) => {
                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "30%",

                            backgroundColor: Colors.white,
                        }}
                    >
                        {`${new Date(cellValues?.row?.date).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                        })}`}
                    </Box>
                );
            },
        },
        {
            field: "productName",
            headerName: "Product Name",
            width: 250,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: "qty",
            headerName: "Qty",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: "cost_price",
            headerName: "Cost Price",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: "totalAmount",
            headerName: "Total Amount",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (cellValues) => {
                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "30%",
                            color: Colors.black,
                            backgroundColor: Colors.white,
                        }}
                    >
                        {`${cellValues?.row?.totalAmount ? Number(cellValues?.row?.totalAmount).toFixed(2) : 0}`}
                    </Box>
                );
            },
        },
        {
            field: "payAmount",
            headerName: "Payment",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (cellValues) => {
                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "30%",
                            color: Colors.green,
                            backgroundColor: Colors.white,
                        }}
                    >
                        {`${cellValues?.row?.payAmount ? Number(cellValues?.row?.payAmount).toFixed(2) : 0}`}
                    </Box>
                );
            },
        },


        {
            field: "balanceAmount",
            headerName: "Balance",
            width: 100,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (cellValues) => {
                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "30%",
                            color: Colors.red,
                            backgroundColor: Colors.white,
                        }}
                    >
                        {`${Number(cellValues?.row?.balanceAmount).toFixed(2)}`}
                    </Box>
                );
            },
        },

        {
            field: "paymentMethod",
            headerName: "Payment Method",
            width: 150,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (cellValues) => {
                return (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "30%",
                            color: cellValues?.row?.paymentMethod?.toLowerCase() == "credit" ? Colors.red : Colors.green,
                            backgroundColor: Colors.white,
                        }}
                    >
                        {`${cellValues?.row?.paymentMethod}`}
                    </Box>
                );
            },
        },

        {
            field: "status",
            headerName: "Status",
            headerClassName: 'super-app-theme--header',
            width: 220,
            filterable: false,
            color: "green",
        },

        {
            field: "reason",
            headerName: "Reason",
            headerClassName: 'super-app-theme--header',
            width: 220,
            filterable: false,
            color: "red",
        },

    ];


    const [searchData, setSearchData] = useState([])


    React.useEffect(() => {

        if (searchDate?.selected && selectedDate && dateFrom) {
            setSearchData(supplierLedger && supplierLedger.filter((e) => {
                let addDate = new Date(e.date);

                // Parse selectedDate and dateFrom
                let selectedDateParts = selectedDate.split('/');
                let dateFromParts = dateFrom.split('/');

                // Create Date objects from the parsed components
                let selectedDateTime = new Date(
                    parseInt(selectedDateParts[2]),   // Year
                    parseInt(selectedDateParts[1]),   // Month (zero-based)
                    parseInt(selectedDateParts[0]),   // Day
                    23, // Hours
                    59, // Minutes
                    59  // Seconds
                );

                let dateFromTime = new Date(
                    parseInt(dateFromParts[2]),    // Year
                    parseInt(dateFromParts[1]),     // Month (zero-based)
                    parseInt(dateFromParts[0]),     // Day
                    0,  // Hours
                    0,  // Minutes
                    0   // Seconds
                );

                // Check if addDate is between dateFrom and selectedDate
                return addDate >= dateFromTime && addDate <= selectedDateTime;
            }));

        } else {
            setSearchData([]);
        }


    }, [selectedDate, searchData?.selected, dateFrom])

    return (

        <Box>

            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[style, { containerStyle }]}>


                    <Box onClick={() => closeModal()} style={{ position: "absolute", top: 10, right: 10 }} >
                        <CloseIcon fontSize="large" />
                    </Box>

                    <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                        {"Supplier Ledger"}
                    </Typography>

                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
                        <Box>
                            <Typography sx={{ fontSize: "20px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                                Supplier Name : {data?.supplierName}
                            </Typography>


                            <Typography sx={{ fontSize: "20px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                                Address : {data?.shopDetails}
                            </Typography>


                            <Typography sx={{ fontSize: "20px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                                Mobile Number : {data?.mobileNumber}
                            </Typography>
                        </Box>


                        <Box sx={{ width: "40%" }} >
                            <Box sx={{ display: "flex" }} >
                                <Box>
                                    <Typography sx={{ fontSize: "20px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                                        Date From
                                    </Typography>
                                    <DatePickers values={dateFrom} onChange={(e) => setDateFrom(e)} label="Select Date" />
                                </Box>
                                <Box sx={{ ml: 1 }} >
                                    <Typography sx={{ fontSize: "20px", color: Colors.red, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                                        Date To
                                    </Typography>
                                    <DatePickers values={selectedDate} onChange={(e) => setSelectedDate(e)} label="Select Date" />
                                </Box>

                            </Box>


                            <Box sx={{ display: "flex", marginTop: 1 }} >


                                <Typography sx={{ fontSize: "14px", fontFamily: "Poppins", mr: 1, color: Colors.text }} >
                                    Search By Date
                                </Typography>
                                <Checkbox
                                    data={searchDate}
                                    onClick={() => setSearchDate(prevState => ({ ...prevState, selected: !prevState.selected }))}
                                />

                            </Box>

                        </Box>


                    </Box>

                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: 1, ...buttonContainerStyle }} >


                        <div style={{ width: "100%" }}>
                            <DataGrid
                                rows={selectedDate && searchDate?.selected && searchData.length == 0 ? [] : (searchData && searchData?.length > 0) ? searchData : (supplierLedger && supplierLedger?.length > 0) ? supplierLedger : []}
                                columns={columns}

                                sx={{
                                    minHeight: 500, width: "100%",


                                    '& .MuiDataGrid-cell': {
                                        fontFamily: 'Poppins',
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        color: "#000"
                                        // Change to your desired cell text color
                                    },

                                    // '& .MuiDataGrid-columnHeader': {
                                    //     backgroundColor: Colors.black,
                                    //     color: "white",
                                    //     fontWeight: 700,
                                    //     border: "2px solid white",
                                    //     margin: 0,
                                    //     padding: '8px', // Adjust this value as needed
                                    //     alignItems: "center"
                                    // },
                                    '& .MuiDataGrid-colCell, & .MuiDataGrid-colCellTitle': {
                                        padding: '0px', // Adjust this value as needed
                                    },
                                    '& .MuiDataGrid-columnHeaderCheckbox': {
                                        backgroundColor: Colors.black, // Change this to your desired checkbox background color
                                        color: 'white', // Change this to your desired checkbox color
                                    },
                                    // '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked':{
                                    //     color:"blue" 
                                    // },

                                    '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
                                        color: "gray"
                                    }
                                    // '& .MuiDataGrid-columnHeaderCheckbox': {
                                    //     backgroundColor: Colors.white
                                    // },
                                    ,
                                    '& .MuiDataGrid-columnHeader': {
                                        fontFamily: "Poppins",
                                        // color: "black",
                                        fontWeight: "bold",
                                    },

                                }}
                            />
                        </div>


                    </Box>



                </Box>



            </Modal>
        </Box>

    )
}

export default SupplierLedgerModal