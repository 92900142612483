import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import BasicSelect from './Dropdown';
import CustomButton from './Button';
import Checkbox from './checkbox';
import axios from 'axios';
import { Base_Uri } from '../Constant/BaseUri';
import { updateProducts, updateSuppliers } from '../Redux/LoginReduces';
import { useDispatch, useSelector } from 'react-redux';
import { AlignVerticalCenterTwoTone, SupportOutlined } from '@mui/icons-material';
import { RingLoader } from 'react-spinners';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    overflow: 'auto',
    maxHeight: '80vh',
    transform: 'translate(-50%, -50%)',
    width: { lg: "40%", md: "35%", sm: "50%", xs: "95%" },
    borderRadius: "10px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

export default function ProductArrangementModal({ barcodeData,setBarcodeRead,inputStyle, setProductArrangementModal, buttonText, heading, onChange, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm }) {


    const dispatch = useDispatch()



    const suppliers = useSelector(state => state.loginReducer.suppliers)
    const products = useSelector((state) => state.loginReducer.products);
    const userData = useSelector((state) => state.loginReducer.updatedState);



    const [loading, setLoading] = useState(false)
    const [supplierDetails, setSupplierDetails] = useState({
        supplierName: "",
        shopDetails: "",
        mobileNumber: "",
    })
    const [allSuppliers, setAllSuppliers] = useState([])
    const [text, setText] = useState("")
    const [searchProducts, setSearchProducts] = useState([])


    const getProducts = async () => {
        // setLoading(true)
        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        if (token) {
            axios
                .get(`${Base_Uri}getProducts`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json", // Include other headers as needed
                    },
                })
                .then((res) => {
                    let myData = res.data;

                    let { data } = myData;

                    if (data && data.length > 0) {
                        let allProducts = [];

                        for (var i = 0; i < data.length; i++) {
                            let product = data[i];

                            product.id = i + 1;
                            product["productName"] = product?.product_name;

                            if (product.status == "active") {
                                product.status = "Active";
                            }

                            allProducts.push(product);
                        }

                        dispatch(updateProducts(allProducts));
                    }
                })
                .catch((error) => {
                    console.log(error, "error");
                    // setLoading(false)
                });
        }
    };

    const getAllSuppliers = async () => {


        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        axios.get(`${Base_Uri}getSuppliers`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json", // Include other headers as needed
            },
        }).then((res) => {

            let data = res.data


            if (data.status) {

                dispatch(updateSuppliers(data.data));

            }



        }).catch((error) => {

            console.log(error, "error")

        })

    }


    React.useEffect(() => {

        setAllSuppliers(suppliers)

    }, [suppliers])

    const supplierName = suppliers && suppliers.length > 0 && suppliers.map((e, i) => {
        return e.supplierName
    })

    const [compatilityData, setCompatibilityData] = useState([
        'A', 'B', 'C'
    ])

    const [addNewSupplier, setAddNewSupplier] = useState(false)

    const [selectedCompatibility, setSelectedCompatibility] = useState("select")

    const [paymentMethod, setPaymentMethod] = useState([
        {
            type: "Cash",
            selected: false
        },
        {
            type: "Credit",
            selected: false
        },
    ])



    const initialData = {
        product_name: "",
        qty: "",
        cost_price: "",
        trade_price: "",
        retail_price: "",
        warehouse_price: "",
        make: "",
        model: "",
        category: "",
        sub_category: "",
        department: "",
        status: "Active",
        barcode: "",
    }


    

    const [productDetails, setProductDetails] = useState(initialData)


    const handleSelectProduct = (product) => {

        setProductDetails(product)
        setSearchProducts([])
        setText("")


        return
    }


    const selectPaymentMethod = (ind) => {

        setPaymentMethod(paymentMethod.map((e, i) => {

            if (i == ind) {
                return {
                    ...e,
                    selected: !e.selected
                }
            } else {
                return {
                    ...e,
                    selected: false
                }
            }

        }))

    }

    const handleAddSupplier = async () => {

        if (!supplierDetails.supplierName) {
            alert("supplier name is missing")
            return
        }
        if (!supplierDetails.mobileNumber) {
            alert("mobile Number is missing")
            return
        }
        if (!supplierDetails.shopDetails) {
            alert("Shop details is missing")
            return
        }


        setLoading(true)

        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;


        axios.post(`${Base_Uri}addSupplier`, supplierDetails, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json", // Include other headers as needed
            },
        }).then((res) => {

            let data = res.data

            setLoading(false)



            if (data.status) {

                alert(data.message)
                setLoading(false)
                getAllSuppliers()
                setAddNewSupplier(false)

            }
        }).catch((error) => {

            setLoading(false)

        })


    }

    const selectSupplier = (supplierName) => {

        setAllSuppliers(allSuppliers.map((e, i) => {

            if (e.supplierName == supplierName) {
                return {
                    ...e,
                    selected: true
                }
            } else {
                return {
                    ...e,
                    selected: false
                }
            }

        }))

    }


    const handleAddProducts = async () => {

        let supplier = allSuppliers.filter((e, i) => e.selected)

        if (supplier.length == 0) {

            alert("Kindly select supplier")
            return
        }


        let dataToCheck = {

            product_name: productDetails.product_name,
            qty: productDetails.qty,
            cost_price: productDetails.cost_price,
            trade_price: productDetails.trade_price,
            retail_price: productDetails.retail_price,
            warehouse_price: productDetails.warehouse_price,
            make: productDetails.make,
            model: productDetails.model,
            category: productDetails.category,
            sub_category: productDetails.sub_category,
            department: productDetails.department,
            status: "Active",
            barcode: productDetails.barcode,

        }

        let flag = Object.values(dataToCheck).some((e, i) => !e)

        if (flag) {

            alert("Required fields are missing")
            return

        }

        if (paymentMethod.every((e, i) => !e.selected)) {
            alert("kindly select payment method")
            return
        }

        setLoading(true)

        let dataToSend = {
            productDetails: productDetails,
            supplierDetails: supplier[0],
            employeeDetails: userData,
            employeeId: userData?.id,
            paymentMethod: paymentMethod.filter(e => e.selected).map((e, i) => e.type.toLowerCase() == "cash" ? "cash" : "credit")[0]
        }





        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;


        axios.post(`${Base_Uri}addArrangeProduct`, dataToSend, {

            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json", // Include other headers as needed
            },

        }).then((res) => {

            let data = res.data

            if (data.status) {

                alert(data.message)
                getProducts()
                getAllSuppliers()
                setProductDetails(initialData)
                setProductArrangementModal(false)
                setLoading(false)
            }
            else {
                alert("Internal server error")
                setLoading(false)
            }

        }).catch((error) => {

            alert(error.message)
            setLoading(false)

        })



    }


    const handleSearch = (alpha) => {

        setText(alpha)

        if (!alpha) {
            setSearchProducts([])
            return
        }


        setSearchProducts(products && products.length > 0 && products.filter((e, i) => {

            if (e.productName.toLowerCase().includes(alpha.toLowerCase())
                ||
                e.category.toLowerCase().includes(alpha.toLowerCase())
                ||
                e.sub_category.toLowerCase().includes(alpha.toLowerCase())

            ) {
                return e
            }

        }))





    }


    
    React.useEffect(() => {
        if (barcodeData?.length > 0) {

            let product =
                products &&
                products?.length > 0 &&
                products?.find((e) => e?.barcode == barcodeData);

            if (product) {
                handleSelectProduct(product);
                setBarcodeRead("");
            }
        }
    }, [barcodeData]);

    return (
        <div>
            <Modal
                open={open}
                // onClose={() => setProductArrangementModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[style, { containerStyle }]}>

                <Box onClick={() => setProductArrangementModal(false)} style={{ position: "absolute", top: 10, right: 10 }} >
                        <CloseIcon fontSize="large" />
                    </Box>


                    <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                        {heading}
                    </Typography>
                    <Box style={{ display: "flex", flexDirection: "column", width: "100%", p: 0, alignItems: "center", marginTop: 10 }} >

                        <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                Supplier
                            </Typography>

                            <CustomButton onClick={() => setAddNewSupplier(!addNewSupplier)} text={addNewSupplier ? "Close Supplier Form" : "Add New Supplier"} style={{ p: 1, fontSize: "12px", fontWeight: "400" }} />

                        </Box>

                    </Box>

                    <Box
                        sx={{
                            width: "100%",
                            marginTop: { xs: "0px", sm: "10px", md: "10px" },
                            border: `1px solid ${Colors.button}`,
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "5px 10px 5px 10px",
                            margin: 0,
                            backgroundColor: Colors.white,
                        }}
                    >

                        <TextField
                            variant="standard" // <== changed this
                            margin="normal"
                            onChange={(e) => handleSearch(e.target.value)}
                            required
                            value={text}
                            fullWidth
                            InputProps={{
                                // startAdornment: <AccountCircle />, // <== adjusted this
                                disableUnderline: true,
                                fontSize: "12px",

                                // <== added this
                                // fontSize: "2px"
                            }}
                            placeholder="Search Product"
                            sx={{
                                width: "90%",
                                fontSize: "12px",
                                padding: 0,
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                margin: 0,
                            }}
                        />
                        <img
                            src={require("../Images/search.png")}
                            style={{ height: "20px" }}
                        />
                    </Box>

                    {searchProducts &&
                        searchProducts.length > 0 &&
                        searchProducts.map((e, i) => {
                            return (
                                <Button
                                    onClick={() => handleSelectProduct(e)}
                                    sx={{
                                        width: "100%",
                                        mt: 1,
                                        backgroundColor: Colors.white,
                                        p: "0px 10px",
                                        fontFamily: "Poppins",
                                        fontWeight: "bold",
                                        borderRadius: 1,
                                        textAlign: "left",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "flex-start",
                                        border: `1px solid ${Colors.border}`,
                                    }}
                                >
                                    <p
                                        style={{
                                            fontFamily: "Poppins",
                                            textAlign: "left",
                                            marginBottom: 0,
                                        }}
                                    >
                                        {e.product_name}
                                    </p>
                                    {/* <p style={{ fontFamily: "Poppins", textAlign: "left" }}> */}
                                    Available qty: {e.qty}
                                    {/* </p> */}
                                </Button>
                            );
                        })}


                    {addNewSupplier && <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }} >

                        <Grid xs={12} md={5.5} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                Supplier Name:
                            </Typography>
                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    type="text"
                                    onChange={(e) => setSupplierDetails({ ...supplierDetails, supplierName: e.target.value })}
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Enter Supplier Name..."
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true, // <== added this
                                        // fontSize: "2px"
                                    }}
                                />
                            </Box>


                        </Grid>
                        <Grid xs={12} md={5.5} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                Shop Detail:
                            </Typography>
                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    type="text"
                                    onChange={(e) => setSupplierDetails({ ...supplierDetails, shopDetails: e.target.value })}
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Enter Shop Details..."
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true, // <== added this
                                        // fontSize: "2px"
                                    }}
                                />
                            </Box>

                        </Grid>
                        <Grid xs={12} md={5.5} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                Mobile Number:
                            </Typography>
                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    type="number"
                                    onChange={(e) => setSupplierDetails({ ...supplierDetails, mobileNumber: e.target.value })}
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Enter Mobile Number..."
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true, // <== added this
                                        // fontSize: "2px"
                                    }}
                                />
                            </Box>
                        </Grid>


                        <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10 }} >

                            <CustomButton onClick={() => handleAddSupplier()} text={loading ? <RingLoader loading={loading} size={30} /> : "Save"} style={{ p: 1, fontSize: "12px", fontWeight: "400" }} />

                        </Box>

                    </Grid>}


                    <BasicSelect style={{ marginTop: 1 }} value={allSuppliers.some((e, i) => e.selected) ? allSuppliers.filter((e, i) => e.selected).map((e, i) => e.supplierName) : "select"} status="Select Supplier" id={supplierName} width={"100%"} onChange={(e) => selectSupplier(e)} />


                    <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }} >
                        <Grid item xs={12} md={5.8} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                Product Name:
                            </Typography>
                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    value={productDetails.product_name}
                                    onChange={(e) => setProductDetails({ ...productDetails, product_name: e.target.value })}
                                    type="text"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Enter Product Name..."
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true, // <== added this
                                        // fontSize: "2px"
                                    }}
                                />
                            </Box>


                        </Grid>
                        <Grid item xs={12} md={5.8} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                Qty:
                            </Typography>
                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    value={productDetails.qty}
                                    onChange={(e) => setProductDetails({ ...productDetails, qty: e.target.value })}
                                    type="number"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Enter Product Qty..."
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true, // <== added this
                                        // fontSize: "2px"
                                    }}
                                />
                            </Box>
                        </Grid>


                        <Grid item xs={12} md={5.8} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                Make:
                            </Typography>
                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    value={productDetails.make}
                                    onChange={(e) => setProductDetails({ ...productDetails, make: e.target.value })}
                                    required
                                    type="text"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Enter Make..."
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true, // <== added this
                                        // fontSize: "2px"
                                    }}
                                />
                            </Box>

                        </Grid>

                        <Grid item xs={12} md={5.8} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                Model:
                            </Typography>
                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    value={productDetails.model}
                                    onChange={(e) => setProductDetails({ ...productDetails, model: e.target.value })}
                                    required
                                    type="text"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Enter Model..."
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true, // <== added this
                                        // fontSize: "2px"
                                    }}
                                />
                            </Box>


                        </Grid>

                        <Grid item xs={12} md={5.8} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                Deparment:
                            </Typography>
                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    value={productDetails.department}
                                    type="text"
                                    onChange={(e) => setProductDetails({ ...productDetails, department: e.target.value })}
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Enter Department..."
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true, // <== added this
                                        // fontSize: "2px"
                                    }}
                                />
                            </Box>


                        </Grid>
                        <Grid item xs={12} md={5.8} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                Category:
                            </Typography>
                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    value={productDetails.category}
                                    required
                                    type="text"
                                    onChange={(e) => setProductDetails({ ...productDetails, category: e.target.value })}
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Enter Category..."
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true, // <== added this
                                        // fontSize: "2px"
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={5.8} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                Sub Category:
                            </Typography>
                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    value={productDetails.sub_category}
                                    onChange={(e) => setProductDetails({ ...productDetails, sub_category: e.target.value })}
                                    required
                                    type="text"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Enter Subcategory..."
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true, // <== added this
                                        // fontSize: "2px"
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={5.8} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                Cost Price:
                            </Typography>
                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    value={productDetails.cost_price}
                                    required
                                    onChange={(e) => setProductDetails({ ...productDetails, cost_price: e.target.value })}
                                    type="number"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Enter Cost Price..."
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true, // <== added this
                                        // fontSize: "2px"
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5.8} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                Trade Price:
                            </Typography>
                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    value={productDetails.trade_price}
                                    onChange={(e) => setProductDetails({ ...productDetails, trade_price: e.target.value })}
                                    required
                                    type="number"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Enter Trade Price..."
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true, // <== added this
                                        // fontSize: "2px"
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5.8} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                Warehouse Price:
                            </Typography>
                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    value={productDetails.warehouse_price}
                                    required
                                    type="number"
                                    onChange={(e) => setProductDetails({ ...productDetails, warehouse_price: e.target.value })}
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Enter Warehouse Price..."
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true, // <== added this
                                        // fontSize: "2px"
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5.8} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                Retail Price:
                            </Typography>
                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    value={productDetails.retail_price}
                                    onChange={(e) => setProductDetails({ ...productDetails, retail_price: e.target.value })}
                                    required
                                    type="number"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Enter Retail Price..."
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true, // <== added this
                                        // fontSize: "2px"
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5.8} >
                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                Barcode Number:
                            </Typography>
                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    value={productDetails.barcode}
                                    onChange={(e) => setProductDetails({ ...productDetails, barcode: e.target.value })}
                                    required
                                    type="number"
                                    fullWidth
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                    placeholder="Enter Barcode..."
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true, // <== added this
                                        // fontSize: "2px"
                                    }}
                                />
                            </Box>
                        </Grid>

                    </Grid>



                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", ...buttonContainerStyle }} >


                        <Box style={{ width: "50%", display: "flex", flexDirection: "row" }} >
                            {paymentMethod && paymentMethod.length > 0 && paymentMethod?.map((e, i) => {
                                return (

                                    <Grid xl={4} xs={6} sm={4} lg={4} md={4} >

                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: { xs: "flex-start", sm: "flex-start" }, marginTop: "5px" }} >
                                            <Checkbox onClick={() => selectPaymentMethod(i)} data={e} />
                                            <Typography sx={{ fontFamily: "Poppins", fontWeight: "600", fontSize: { xs: "9px", sm: "10px", md: "14px" }, color: Colors.text, marginLeft: "5px" }} >
                                                {e?.type}
                                            </Typography>

                                        </Box>

                                    </Grid>

                                )
                            })}

                        </Box>


                        <Button onClick={handleAddProducts} sx={{
                            backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", '&:hover': {
                                color: Colors.button, // Set your desired hover color
                            }, ...buttonStyle2
                        }} >
                            {loading ? <RingLoader loading={loading} size={30} /> : buttonText}
                        </Button>

                    </Box>



                </Box>



            </Modal>
        </div>
    );
}