import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./LoginReduces";
import AuthenticationSlice from "./authenticationReducer"

const store = configureStore({
    reducer: {
        loginReducer: loginSlice,
        authenticationReducer: AuthenticationSlice
    }
})

export default store