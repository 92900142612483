import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Colors } from "../Constant/Color";
import { RingLoader } from "react-spinners";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import BasicSelect from "./Dropdown";

export default function MakeModal({

    selectedMake,
    setSelectedMake,
    showMake,
    heading,
    placeholder,
    headingTextStyle,showOnlyModel,
    containerStyle,
    open,
    closeModal,
    buttonContainerStyle,
    buttonStyle,
    buttonStyle2,
    confirm,
    loading,
    onChange,
    emailbutton,
}) {
    const make = useSelector(
        (state) => state.loginReducer.make
    );

    let model = [];

    if (selectedMake) {
        let getMake =
            make &&
            make.length > 0 &&
            make.filter((e, i) => {
                return e.make == selectedMake;
            });

        if (getMake && getMake.length > 0) {
            let dept = getMake[0];

            model =
                dept.model &&
                dept.model.length > 0 &&
                dept.model.map((e, i) => {
                    return e?.model;
                });
        }
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: {
            lg: emailbutton ? "35%" : "30%",
            md: "40%",
            sm: "55%",
            xs: "100%",
        },
        borderRadius: "10px",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[style, { containerStyle }]}>
                    <Typography
                        sx={{
                            fontSize: "20px",
                            color: Colors.button,
                            fontWeight: "bold",
                            fontFamily: "Poppins",
                            textAlign: "center",
                            ...headingTextStyle,
                        }}
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        {heading}
                    </Typography>

                    {(showMake && !showOnlyModel) && (
                        <BasicSelect
                            style={{ marginTop: 0.5 }}
                            value={selectedMake}
                            status="Select Make"
                            id={
                                make &&
                                make.length > 0 &&
                                make.map((e, i) => e.make)
                            }
                            width={"100%"}
                            onChange={(e) => setSelectedMake(e)}
                        />
                    )}


                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        onChange={onChange}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            fontSize: "12px",
                            style: {
                                textAlign: "center",
                                fontSize: "16px",
                                alignSelf: "center",
                            },
                        }}
                        inputProps={{
                            disableUnderline: true,
                            fontSize: "12px",
                        }}
                        placeholder={placeholder}
                        sx={{
                            width: "100%",
                            // height: width < 600 ? "25px" : "35px",
                            fontSize: "12px",
                            padding: 1,
                            border: `1px solid ${Colors.border}`,
                            borderRadius: "5px",
                            marginBottom: 2,
                            textAlign: "center", // Move textAlign to sx
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                        }}
                    />



                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            ...buttonContainerStyle,
                        }}
                    >
                        <Button
                            onClick={closeModal}
                            sx={{
                                backgroundColor: Colors.white,
                                color: Colors.button,
                                border: `1px solid ${Colors.button}`,
                                borderRadius: "20px",
                                width: "150px",
                                fontFamily: "Poppins",
                                ...buttonStyle,
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            onClick={() => confirm()}
                            sx={{
                                backgroundColor: Colors.button,
                                color: Colors.white,
                                border: `1px solid ${Colors.button}`,
                                borderRadius: "20px",
                                width: "150px",
                                fontFamily: "Poppins",
                                "&:hover": {
                                    color: Colors.button, // Set your desired hover color
                                },
                                ...buttonStyle2,
                            }}
                        >
                            {loading ? <RingLoader size={30} loading={loading} /> : "Confirm"}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
