import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "../../Constant/Color";
import CustomButton from "../../Components/Button";
import DepartmentModal from "../../Components/departmentModal";
import { LabelTwoTone } from "@mui/icons-material";
import axios from "axios";
import { Base_Uri } from "../../Constant/BaseUri";
import {
  updateProductDepartment,
  updateProducts,
} from "../../Redux/LoginReduces";
import CustomModal from "../../Components/modal";

function Departments() {
  const dispatch = useDispatch();

  const departments = useSelector(
    (state) => state?.loginReducer?.productDepartments
  );

  const [allDepartment, setAllDepartments] = useState(departments);

  const [editDepartmentModal, setEditDepartmentModal] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [categoryClicked, setCategoryClicked] = useState("");
  const [departmentClicked, setDepartmentClicked] = useState("");
  const [loading, setLoading] = useState(false);
  const [departmentName, setDepartmentName] = useState(false);

  const [editSubcategoryModal, setEditSubcategoryModal] = useState(false);
  const [subcategoryName, setSubcategoryName] = useState("");
  const [subcategoryClicked, setSubcategoryClicked] = useState("");

  const [categoryName, setCategoryName] = useState("");
  const [categoryPer, setCategoryPer] = useState({
    a: "",
    b: "",
    c: "",
  });

  const [openDeleteDepartmentModal, setOpenDeleteDepartmentModal] =
    useState(false);

  const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false);
  const [openDeleteSubcategoryModal, setOpenDeleteSubcategoryModal] =
    useState(false);

  useEffect(() => {
    setAllDepartments(departments);
  }, [departments?.length]);

  const getProducts = async () => {
    // setLoading(true)
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getProducts`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let myData = res.data;

          let { data } = myData;

          if (data && data.length > 0) {
            let allProducts = [];

            for (var i = 0; i < data.length; i++) {
              let product = data[i];

              product.id = i + 1;
              product["productName"] = product?.product_name;

              if (product.status == "active") {
                product.status = "Active";
              }

              allProducts.push(product);
            }

            dispatch(updateProducts(allProducts));
          }
        })
        .catch((error) => {
          console.log(error, "error");
          // setLoading(false)
        });
    }
  };

  const getDepartments = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getProductDepartment`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (data.status) {
            dispatch(updateProductDepartment(data.data));
            setAllDepartments(data?.data);
          }
        });
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const handleShowCategory = (ind) => {
    
    setAllDepartments(
      allDepartment &&
        allDepartment?.length > 0 &&
        allDepartment?.map((e, i) => {
          if (i == ind) {
            return {
              ...e,
              showCategory: !e?.showCategory,
            };
          } else {
            return e;
          }
        })
    );
  };

  const handleShowSubcategory = (department, departmentind, ind) => {
    let { categories } = department;

    categories =
      categories &&
      categories?.length > 0 &&
      categories?.map((e, i) => {
        if (ind == i) {
          return {
            ...e,
            showSubcategory: !e?.showSubcategory,
          };
        } else {
          return e;
        }
      });

    setAllDepartments(
      allDepartment &&
        allDepartment?.length > 0 &&
        allDepartment?.map((department, i) => {
          if (departmentind == i) {
            return {
              ...department,
              categories: categories,
            };
          } else {
            return department;
          }
        })
    );
  };

  const handleChooseDepartment = (department, index) => {

    setDepartmentClicked(department);
    setEditDepartmentModal(true);
  };

  const handleEditDepartment = async () => {
    if (!departmentName) {
      alert("Department Name is missing");
      return;
    }

    let dataToSend = {
      department: departmentClicked,
      editedName: departmentName,
    };

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .put(`${Base_Uri}editProductDepartment`, dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res?.data;

          if (!data?.status) {
            alert(data?.message);
            setLoading(false);
            return;
          }

          let department = data?.data;
          let otherDepartments =
            allDepartment &&
            allDepartment?.length > 0 &&
            allDepartment?.filter((e, i) => e?._id !== department?._id);
          otherDepartments =
            otherDepartments && otherDepartments?.length > 0
              ? otherDepartments
              : [];
          let all = [...otherDepartments, department];

          dispatch(updateProductDepartment(all));
          getProducts();
          setAllDepartments(all);
          alert(data.message);
          setLoading(false);
          setEditDepartmentModal(false);
        })
        .catch((error) => {
          alert(error?.message);
          setLoading(false);
          return;
        });
    }
  };

  const handleEditCategory = async () => {
    if (!categoryName) {
      alert("Category name is missing");
      return;
    }

    let oldCategoryName;

    let category = { ...categoryClicked };

    oldCategoryName = category?.categoryName;
    category.categoryName = categoryName;
    category.categoryPer = categoryPer;

    let { categories } = departmentClicked;
    let otherCategories = categories?.filter(
      (e, i) => e?._id !== category?._id
    );

    otherCategories =
      otherCategories && otherCategories?.length > 0 ? otherCategories : [];

    let allCategories = [...otherCategories, category];

    let departmentToSend = { ...departmentClicked };

    departmentToSend.categories = allCategories;
    departmentToSend.oldCategoryName = oldCategoryName;
    departmentToSend.newCategoryName = categoryName;

    setLoading(true);

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .put(`${Base_Uri}editProductCategory`, departmentToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res?.data;

          if (!data?.status) {
            alert(data?.message);
            setLoading(false);
            return;
          }

          let department = data?.data;

          let otherDepartments =
            allDepartment &&
            allDepartment?.length > 0 &&
            allDepartment?.filter((e, i) => e?._id !== department?._id);
          otherDepartments =
            otherDepartments && otherDepartments?.length > 0
              ? otherDepartments
              : [];
          let all = [...otherDepartments, department];

          getProducts();
          dispatch(updateProductDepartment(all));
          setAllDepartments(all);
          alert(data.message);
          setLoading(false);
          setEditCategoryModal(false);
        })
        .catch((error) => {
          alert(error?.message);
          setLoading(false);
          return;
        });
    }
  };

  const handleChooseCategory = (department, category) => {
    setDepartmentClicked(department);
    setCategoryClicked(category);
    setCategoryPer(category?.categoryPer);
    setCategoryName(category?.categoryName);
    setEditCategoryModal(true);
  };

  const handleEditSubcategory = async () => {
    let { subcategories } = categoryClicked;

    let oldSubcategory;

    subcategories = subcategories.map((e, i) => {
      if (e?._id == subcategoryClicked?._id) {
        oldSubcategory = e?.subcategoryName;

        return {
          ...e,
          subcategoryName: subcategoryName,
        };
      } else {
        return e;
      }
    });

    let { categories } = departmentClicked;

    let allCategories = [...categories];

    allCategories = allCategories?.map((e, i) => {
      if (e?._id == categoryClicked?._id) {
        return {
          ...e,
          subcategories: subcategories,
        };
      } else {
        return e;
      }
    });

    let departmentToEdit = { ...departmentClicked };

    departmentToEdit.categories = allCategories;
    departmentToEdit.oldSubcategoryName = oldSubcategory;
    departmentToEdit.newSubcategoryName = subcategoryName;

    // departmentClicked.categories = categories;

    setLoading(true);

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .put(`${Base_Uri}editProductCategory`, departmentToEdit, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res?.data;

          if (!data?.status) {
            alert(data?.message);
            setLoading(false);
            return;
          }

          let department = data?.data;

          let otherDepartments =
            allDepartment &&
            allDepartment?.length > 0 &&
            allDepartment?.filter((e, i) => e?._id !== department?._id);
          otherDepartments =
            otherDepartments && otherDepartments?.length > 0
              ? otherDepartments
              : [];
          let all = [...otherDepartments, department];

          getProducts();
          dispatch(updateProductDepartment(all));
          setAllDepartments(all);
          alert("Subcategory Successfully Edited");
          setLoading(false);
          setEditSubcategoryModal(false);
        })
        .catch((error) => {
          alert(error?.message);
          setLoading(false);
          return;
        });
    }
  };

  const handleChooseSubcategory = (department, category, subcategory) => {
    setDepartmentClicked(department);
    setCategoryClicked(category);
    setSubcategoryClicked(subcategory);
    // setCategoryPer(category?.categoryPer);
    setSubcategoryName(subcategory?.subcategoryName);
    setEditSubcategoryModal(true);
  };

  const handleOpenDeleteDepModal = async (department, index) => {
    
    setOpenDeleteDepartmentModal(true);
    setDepartmentClicked(department);
  };

  const handleDeleteDepartment = async () => {
    setLoading(true);

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .delete(`${Base_Uri}deleteProductDepartment/${departmentClicked?._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let data = res.data;

        if (!data?.status) {
          alert(data?.message);
          setLoading(false);
          return;
        }

        let otherDepartments =
          departments &&
          departments?.length > 0 &&
          departments?.filter((e, i) => e?._id !== departmentClicked?._id);

        dispatch(
          updateProductDepartment(
            otherDepartments && otherDepartments?.length > 0
              ? otherDepartments
              : []
          )
        );
        setAllDepartments(otherDepartments);
        alert(data?.message);
        setOpenDeleteDepartmentModal(false);
        setLoading(false);
      })
      .catch((error) => {
        alert(error?.message);
        setLoading(false);
      });
  };

  const handleOpenDeleteCategoryModal = (department, category) => {
    setDepartmentClicked(department);
    setCategoryClicked(category);
    setOpenDeleteCategoryModal(true);
  };

  const handleDeleteCategory = async () => {
    setLoading(true);

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .delete(
        `${Base_Uri}deleteProductCategory/${departmentClicked?._id}/${categoryClicked?._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        }
      )
      
      .then((res) => {
        let data = res.data;
        if (!data?.status) {
          alert(data?.message);
          setLoading(false);
          return;
        }

        let otherDepartments =
          departments &&
          departments?.length > 0 &&
          departments?.filter((e, i) => e?._id !== departmentClicked?._id);

        let deptCategories = departmentClicked?.categories;

        deptCategories =
          deptCategories &&
          deptCategories?.length > 0 &&
          deptCategories?.filter((e, i) => e?._id !== categoryClicked?._id);

        let updatedDept = { ...departmentClicked };

        updatedDept.categories = deptCategories;

        let allDept = [...otherDepartments, updatedDept];

        dispatch(
          updateProductDepartment(allDept && allDept?.length > 0 ? allDept : [])
        );
        setAllDepartments(allDept);
        alert(data?.message);
        setOpenDeleteCategoryModal(false);
        setLoading(false);
      })
      .catch((error) => {
        alert(error?.message);
        setLoading(false);
      });
  };

  const handleOpenDeleteSubcategoryModal = (
    department,
    category,
    subcategory
  ) => {
    setOpenDeleteSubcategoryModal(true);
    setDepartmentClicked(department);
    setCategoryClicked(category);
    setSubcategoryClicked(subcategory);
  };

  const handleDeleteSubcategory = async () => {
    setLoading(true);

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .delete(
        `${Base_Uri}deleteProductSubcategory/${departmentClicked?._id}/${categoryClicked?._id}/${subcategoryClicked?._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        }
      )
      .then((res) => {
        let data = res.data;
        if (!data?.status) {
          alert(data?.message);
          setLoading(false);
          return;
        }

        let otherDepartments =
          departments &&
          departments?.length > 0 &&
          departments?.filter((e, i) => e?._id !== departmentClicked?._id);

        let subcategories = categoryClicked?.subcategories;
        let otherSubcategories =
          subcategories &&
          subcategories?.length > 0 &&
          subcategories?.filter((e, i) => e?._id !== subcategoryClicked?._id);

        let selectedCat = { ...categoryClicked };
        selectedCat.subcategories = otherSubcategories;

        let deptCategories = departmentClicked?.categories;
        deptCategories =
          deptCategories &&
          deptCategories?.length > 0 &&
          deptCategories?.filter((e, i) => e?._id !== categoryClicked?._id);

        deptCategories =
          deptCategories && deptCategories?.length > 0 ? deptCategories : [];
        let allCategories = [...deptCategories, selectedCat];

        let updatedDept = { ...departmentClicked };

        updatedDept.categories = allCategories;

        let allDept = [...otherDepartments, updatedDept];

        dispatch(
          updateProductDepartment(allDept && allDept?.length > 0 ? allDept : [])
        );
        setAllDepartments(allDept);
        alert(data?.message);
        setOpenDeleteCategoryModal(false);
        setLoading(false);
      })
      .catch((error) => {
        alert(error?.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <Box style={{ padding: 10, width: "100%" }}>
        <Typography
          style={{
            fontFamily: "Poppins",
            fontSize: 24,
            fontWeight: "bold",
            color: Colors.black,
            textAlign: "left",
            // borderBottom:"1px solid"
          }}
        >
          Departments:
        </Typography>

        <Box style={{ marginTop: 20 }}>
          {allDepartment &&
            allDepartment?.length > 0 &&
            allDepartment?.map((e, i) => {
              return (
                <Box style={{ borderBottom: "1px solid" }} key={i}>
                  <Box
                    style={{
                      marginBottom: 20,
                      marginTop: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 20,
                        fontWeight: "bold",
                        color: Colors.red,
                        textAlign: "left",
                        flex: 1, // Make the department name occupy remaining space
                      }}
                    >
                      {e?.departmentName}
                    </Typography>

                    <Box style={{ display: "flex" }}>
                      <CustomButton
                        onClick={() => handleShowCategory(i)}
                        text="show Category"
                        style={{
                          width: "150px",
                          borderRadius: 1,
                          padding: 1,
                          marginRight: 10,
                        }}
                      />
                      <CustomButton
                        onClick={() => handleChooseDepartment(e, i)}
                        text="Edit Department"
                        style={{
                          width: "150px",
                          borderRadius: 1,
                          padding: 1,
                          backgroundColor: Colors.red,
                        }}
                      />

                      <CustomButton
                        onClick={() => handleOpenDeleteDepModal(e, i)}
                        text="Delete Department"
                        style={{
                          width: "200px",
                          borderRadius: 1,
                          padding: 1,
                          backgroundColor: Colors.red,
                        }}
                      />
                    </Box>
                  </Box>

                  {e?.showCategory && (
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 24,
                        fontWeight: "bold",
                        color: Colors.black,
                        textAlign: "left",
                        flex: 1, // Make the department name occupy remaining space
                      }}
                    >
                      Categories:
                    </Typography>
                  )}

                  {e?.showCategory &&
                    e?.categories?.map((category, ind) => {
                      return (
                        <Box>
                          <Box
                            style={{
                              marginBottom: 20,
                              // borderBottom: "1px solid",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: "Poppins",
                                fontSize: 20,
                                fontWeight: "bold",
                                color: Colors.button,
                                textAlign: "left",
                                flex: 1, // Make the department name occupy remaining space
                              }}
                            >
                              {category?.categoryName}
                            </Typography>

                            <Box style={{ display: "flex" }}>
                              <CustomButton
                                onClick={() => handleShowSubcategory(e, i, ind)}
                                text="Show SubCategory"
                                style={{
                                  width: "200px",
                                  borderRadius: 1,
                                  padding: 1,
                                  marginRight: 10,
                                }}
                              />
                              <CustomButton
                                onClick={() =>
                                  handleChooseCategory(e, category)
                                }
                                text="Edit Category"
                                style={{
                                  width: "200px",
                                  borderRadius: 1,
                                  padding: 1,
                                  backgroundColor: Colors.red,
                                }}
                              />

                              <CustomButton
                                onClick={() =>
                                  handleOpenDeleteCategoryModal(e, category)
                                }
                                text="Delete Category"
                                style={{
                                  width: "200px",
                                  borderRadius: 1,
                                  padding: 1,
                                  backgroundColor: Colors.red,
                                }}
                              />
                            </Box>
                          </Box>

                          {category?.showSubcategory && (
                            <Typography
                              style={{
                                fontFamily: "Poppins",
                                fontSize: 24,
                                fontWeight: "bold",
                                color: Colors.black,
                                textAlign: "left",
                                flex: 1, // Make the department name occupy remaining space
                              }}
                            >
                              SubCategory:
                            </Typography>
                          )}

                          {category?.showSubcategory &&
                            category?.subcategories?.map(
                              (subcategory, index) => {
                                return (
                                  <Box>
                                    <Box
                                      style={{
                                        marginBottom: 20,
                                        marginTop: 10,
                                        // borderBottom: "1px solid",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontFamily: "Poppins",
                                          fontSize: 20,
                                          fontWeight: "bold",
                                          color: Colors.button,
                                          textAlign: "left",
                                          flex: 1, // Make the department name occupy remaining space
                                        }}
                                      >
                                        {subcategory?.subcategoryName}
                                      </Typography>

                                      <Box style={{ display: "flex" }}>
                                        {/* <CustomButton
                                        onClick={() => handleShowSubcategory(e, i, ind)}
                                        text="Show SubCategory"
                                        style={{
                                          width: "200px",
                                          borderRadius: 1,
                                          padding: 1,
                                          marginRight: 10,
                                        }}
                                      /> */}
                                        <CustomButton
                                          onClick={() =>
                                            handleChooseSubcategory(
                                              e,
                                              category,
                                              subcategory
                                            )
                                          }
                                          text="Edit Subcategory"
                                          style={{
                                            width: "200px",
                                            borderRadius: 1,
                                            padding: 1,
                                            backgroundColor: Colors.red,
                                          }}
                                        />

                                        <CustomButton
                                          onClick={() =>
                                            handleOpenDeleteSubcategoryModal(
                                              e,
                                              category,
                                              subcategory
                                            )
                                          }
                                          text="Delete Subcategory"
                                          style={{
                                            width: "200px",
                                            borderRadius: 1,
                                            padding: 1,
                                            backgroundColor: Colors.red,
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                );
                              }
                            )}
                        </Box>
                      );
                    })}
                </Box>
              );
            })}
        </Box>

        {editDepartmentModal && (
          <DepartmentModal
            open={editDepartmentModal}
            placeholder={
              departmentClicked?.departmentName
                ? departmentClicked?.departmentName
                : "Enter Department Name..."
            }
            loading={loading}
            onChange={(e) => setDepartmentName(e.target.value)}
            closeModal={() => setEditDepartmentModal(false)}
            heading={"Edit Department"}
            confirm={() => handleEditDepartment()}
          />
        )}

        {editCategoryModal && (
          <DepartmentModal
            open={editCategoryModal}
            //   selectedDepartment={selectedDepartment}
            //   setSelectedDepartment={setSelectedDepartment}
            placeholder={categoryName}
            loading={loading}
            showDeparment={true}
            showCategoryOnly={true}
            categoryPer={categoryPer}
            categoryName={categoryName}
            onChange1={(e) =>
              setCategoryPer({ ...categoryPer, a: e.target.value })
            }
            onChange2={(e) =>
              setCategoryPer({ ...categoryPer, b: e.target.value })
            }
            onChange3={(e) =>
              setCategoryPer({ ...categoryPer, c: e.target.value })
            }
            onChange={(e) => setCategoryName(e.target.value)}
            closeModal={() => setEditCategoryModal(false)}
            heading={"Edit Category"}
            confirm={() => handleEditCategory()}
          />
        )}

        {editSubcategoryModal && (
          <DepartmentModal
            open={editSubcategoryModal}
            //   selectedDepartment={selectedDepartment}
            //   setSelectedDepartment={setSelectedDepartment}
            //   selectedCategory={selectedCategory}
            //   setSelectedCategory={setSelectedCategory}
            placeholder={
              subcategoryName ? subcategoryName : "Enter Subcategory Name..."
            }
            loading={loading}
            showDeparment={true}
            showSubcategoryOnly={true}
            showCategory={true}
            onChange={(e) => setSubcategoryName(e.target.value)}
            closeModal={() => setEditSubcategoryModal(false)}
            heading={"Edit Subcategory"}
            confirm={() => handleEditSubcategory()}
          />
        )}

        {openDeleteDepartmentModal && (
          <CustomModal
            heading={`Delete Department`}
            loading={loading}
            text={"Are you sure you want to delete this department?"}
            open={openDeleteDepartmentModal}
            closeModal={() => setOpenDeleteDepartmentModal(false)}
            confirm={handleDeleteDepartment}
          />
        )}
        {openDeleteCategoryModal && (
          <CustomModal
            heading={`Delete Category`}
            loading={loading}
            text={"Are you sure you want to delete this category?"}
            open={openDeleteCategoryModal}
            closeModal={() => setOpenDeleteCategoryModal(false)}
            confirm={handleDeleteCategory}
          />
        )}

        {openDeleteSubcategoryModal && (
          <CustomModal
            heading={`Delete Subcategory`}
            loading={loading}
            text={"Are you sure you want to delete this Subcategory?"}
            open={openDeleteSubcategoryModal}
            closeModal={() => setOpenDeleteSubcategoryModal(false)}
            confirm={handleDeleteSubcategory}
          />
        )}
      </Box>
    </div>
  );
}

export default Departments;
