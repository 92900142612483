import './App.css';
import AppRouter from './appRouter';
import { Provider } from 'react-redux';
import store from './Redux/store';
// import useScanDetection from 'use-scan-detection';
import { useState } from 'react';


function App() {

  const [barcode, setBarcode] = useState('')


  // useScanDetection({
  //   onComplete: (code) => {
      
  //     setBarcode(code)


  //   }
    
  // })




  return (
    <Provider store={store} >
      <div className="App">
        <AppRouter />
      </div>
    </Provider>
  );
}

export default App;
