import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { Grid, TextField } from '@mui/material';
import CustomButton from './Button';
import DatePickers from './DatePicker';
import { ArrowBackIosNewOutlined, ConstructionOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from './checkbox';
import { useDispatch, useSelector } from 'react-redux';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { updateAllExchangeInvoice, updateAllInvoices, updateAllReturnProducts, updateCustomers, updateDamageItemCustomer, updateDayReturnInvoiceEmp, updateExhangeProducts, updateProducts, updateSaleReturnCart } from '../Redux/LoginReduces';
import { Base_Uri, Image_Uri } from '../Constant/BaseUri';
import { daDK } from '@mui/x-data-grid';
import axios from 'axios';
import CustomModal from './modal';
import { RingLoader } from 'react-spinners';
import BasicSelect from './Dropdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Document,
    Page,
    Text,
    View,
    PDFViewer,
    PDFDownloadLink,
    Image,
    Font,
} from "@react-pdf/renderer";
import MyFontRegular from "../fonts/Poppins-Medium.ttf";
import MyFontBold from "../fonts/Poppins-Bold.ttf";
import useScanDetection from 'use-scan-detection';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    overflow: 'auto',
    maxHeight: '80vh',
    transform: 'translate(-50%, -50%)',
    width: { xl: "70%", lg: "80%", md: "95%", sm: "95%", xs: "98%" },
    borderRadius: "10px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

export default function MultipleReturnModal({ barcodeData, setBarcodeRead, heading, text1, headingTextStyle, setReturnModal, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm }) {


    Font.register({
        family: "MyFont",
        fonts: [
            { src: MyFontBold, fontWeight: "bold" },
            { src: MyFontRegular, fontWeight: "normal" },
        ],
    });

    const dispatch = useDispatch()

    let textInputRef = useRef(null)


    const allReturnProducts = useSelector(state => state?.loginReducer?.allReturnProducts)


    const products = useSelector(state => state.loginReducer.products)
    const customers = useSelector(state => state.loginReducer.customers)
    const selectedCustomer = useSelector(state => state.loginReducer.selectedDamageItemCustomer)
    const cart = useSelector(state => state.loginReducer.saleReturnCart)
    const saleinvoices = useSelector(state => state.loginReducer.allInvoices)
    const exchangeInvoices = useSelector(state => state?.loginReducer?.allExchangeInvoice)


    const [showIMEI, setShowIMEI] = useState(false)


    const invoices = [...saleinvoices, ...exchangeInvoices]


    const vatRate = useSelector(state => state.loginReducer.vat)?.vatRate
    const vatNumber = useSelector(state => state.loginReducer.vat)?.vatNumber

    const vat = useSelector((state) => state.loginReducer.vat)
    const companyNumber = useSelector(state => state.loginReducer.vat)?.companyNumber
    const userData = useSelector(state => state.loginReducer.updatedState)

    const [searchProducts, setSearchProducts] = useState([])
    const [searchCustomers, setSearchCustomers] = useState([])
    const [searchInvoices, setSearchInvoices] = useState([])
    const [customerInvoice, setCustomerInvoices] = useState("")
    const [searchCustomerInvoice, setSearchCustomerInvoice] = useState("");
    const [selectedInvoice, setSelectedInvoice] = useState("")
    const [loading, setLoading] = useState(false)
    const [confirmModalVisible, setConfirmModalVisible] = useState(false)
    const [emailLoading, setEmailLoading] = useState(false)
    const [successfulTransactionModal, setSuccessfulTransactionModal] = useState(false)
    const [refHover, setRefHover] = useState(false)
    const [text, setText] = useState("")
    const [selectedSearch, setSelectedSearch] = useState("customers")
    const [returnInvoice, setReturnInvoice] = useState("")
    const [lessAmount, setLessAmount] = useState(false)
    const [deductCreditBalance, setDeductCreditBalance] = useState({
        selected: false
    })
    const [productStatus, setProductStatus] = useState(['Unused/Change of mind', 'Faulty', 'Damaged'])
    const [invoiceLoading, setInvoiceLoading] = useState(false)

    const [deductBalanceOptions, setDeductBalanceOptions] = useState(["Quotation", "Invoice"])

    const [selectedDeductBalanceOption, setSelectedDeductBalanceOption] = useState("select")

    const [pdfDownloadView, setPdfDownloadView] = useState(false);


    const [exchangeLoading, setExchangeLoading] = useState(false)

    const [refundCash, setRefundCash] = useState({
        selected: true
    })

    const [refundCard, setRefundCard] = useState({
        selected: false
    })


    const [exchangeProduct, setExchangeProduct] = useState({
        selected: false
    })

    const [byCurrentPrice, setByCurrentPrice] = useState({
        selected: false
    })

    const width = window.innerWidth

    const getAllInvoices = async () => {

        setInvoiceLoading(true);

        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;


        axios
            .get(`${Base_Uri}getAllInvoices`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Include other headers as needed
                },
            })
            .then((res) => {
                if (!res?.status) {
                    setInvoiceLoading(false);
                    alert(res.message);
                    return;
                }

                let { data } = res.data;
                data =
                    data &&
                    data.length > 0 &&
                    data.map((e, i) => {
                        return {
                            ...e,
                            id: e._id,
                        };
                    });

                setInvoiceLoading(false);
                dispatch(updateAllInvoices(data && data.length > 0 ? data : []));
                // resolve(data)
            })
            .catch((error) => {
                // reject(error)

                setInvoiceLoading(false);
                console.log(error, "error");
            });
        // });
    };

    const getAllExchangeInvoices = async () => {


        setExchangeLoading(true)
        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        axios
            .get(`${Base_Uri}getAllExchangeInvoices`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Include other headers as needed
                },
            })
            .then((res) => {
                if (!res?.status) {
                    alert(res.message);
                    setExchangeLoading(false)
                    return;
                }

                let { data } = res.data;
                data =
                    data &&
                    data.length > 0 &&
                    data.map((e, i) => {
                        return {
                            ...e,
                            id: e._id,
                        };
                    });
                setExchangeLoading(false)
                dispatch(updateAllExchangeInvoice(data && data.length > 0 ? data : []));
            })
            .catch((error) => {
                setExchangeLoading(false)
                console.log(error, "error");
            });
    };

    useEffect(() => {
        getAllInvoices();
        getAllExchangeInvoices()
    }, [open]);



    const getCurrentPrice = async () => {
        let { productDetails } = selectedInvoice;

        let selectedProducts = [];
        let returnProducts = [...productDetails]

        let promise = returnProducts && returnProducts.length > 0 && returnProducts.map(async (e, index) => {
            products && products.length > 0 && products.map((product) => {
                if (product._id == e?._id) {
                    selectedProducts.push(product);
                }
            });
        });

        await Promise.all(promise);

        returnProducts && returnProducts.length > 0 && returnProducts?.map((e, i) => {
            selectedProducts && selectedProducts.length > 0 && selectedProducts.map((product) => {
                // Create a new object to ensure it's extensible
                if (e?._id == product?._id) {
                    let updatedDetail = { ...e };
                    updatedDetail.current_costprice = Number(product.cost_price).toFixed(2);
                    updatedDetail.current_retailprice = Number(product.retail_price).toFixed(2);
                    updatedDetail.current_tradeprice = Number(product.trade_price).toFixed(2);
                    updatedDetail.current_warehouseprice = Number(product.warehouse_price).toFixed(2);
                    returnProducts[i] = updatedDetail; // Replace the old object with the new one
                }
            });
        });

        setSelectedInvoice({
            ...selectedInvoice,
            productDetails: returnProducts
        })

    };


    useEffect(() => {

        if (selectedInvoice?._id) {
            getCurrentPrice()
        }

    }, [selectedInvoice?._id, products?.length])

    const notify = (text) => toast(text);


    // useEffect(() => {

    //     setTimeout(() => {

    //         textInputRef?.current?.focus();

    //     }, [3000]);

    // }, [textInputRef?.current])


    const getTodayReturnInvoices = async () => {
        if (userData) {
            let data = await localStorage.getItem("userData");
            data = JSON.parse(data);
            let token = data;

            axios
                .get(`${Base_Uri}getReturnInvoices/${userData.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json", // Include other headers as needed
                    },
                })
                .then((res) => {

                    if (!res?.status) {
                        alert(res.message);
                        return;
                    }

                    let { data } = res.data;

                    data =
                        data &&
                        data.length > 0 &&
                        data.map((e, i) => {
                            return {
                                ...e,
                                id: e._id,
                            };
                        });

                    dispatch(updateDayReturnInvoiceEmp(data));
                })
                .catch((error) => {
                    console.log(error, "error");
                });
        }
    };


    const getCustomers = async () => {


        let token = await localStorage.getItem('userData')
        token = JSON.parse(token)

        axios.get(`${Base_Uri}getCustomers`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json', // Include other headers as needed
            },
        }).then((res) => {

            let { data } = res


            if (data.status) {


                dispatch(updateCustomers(data.data))


            }


        }).catch((error) => {

            console.log(error, "error")



        })


    }


    const getProducts = async () => {
        // setLoading(true)
        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        if (token) {
            axios
                .get(`${Base_Uri}getProducts`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json", // Include other headers as needed
                    },
                })
                .then((res) => {
                    let myData = res.data;

                    let { data } = myData;

                    if (data && data.length > 0) {
                        let allProducts = [];

                        for (var i = 0; i < data.length; i++) {
                            let product = data[i];

                            product.id = i + 1;
                            product["productName"] = product?.product_name;

                            if (product.status == "active") {
                                product.status = "Active";
                            }

                            allProducts.push(product);
                        }


                        dispatch(updateProducts(allProducts));
                    }
                })
                .catch((error) => {
                    console.log(error, "error");
                    // setLoading(false)
                });
        }
    };

    const handleDecreaseProduct = (product, type) => {


        if (type == "exchange") {


            let updateProducts = selectedInvoice?.returnProductDetails?.map((e, i) => {

                if (e.id == product.id) {
                    return {
                        ...e,
                        DamageQty: product.DamageQty ? product.DamageQty - 1 : product.saleQty - 1
                    }
                } else {
                    return e
                }
            })


            let invoice = { ...selectedInvoice, returnProductDetails: updateProducts }

            setSelectedInvoice(invoice)


            return
        }

        let updateProducts = selectedInvoice.productDetails.map((e, i) => {

            if (e.id == product.id) {
                return {
                    ...e,
                    DamageQty: product.DamageQty ? product.DamageQty - 1 : product.saleQty - 1
                }
            } else {
                return e
            }
        })


        let invoice = { ...selectedInvoice, productDetails: updateProducts }

        setSelectedInvoice(invoice)


    };

    const handleDeleteProduct = (product) => {
        let newCart = cart.filter((e, i) => {
            return e._id !== product._id;
        });
        dispatch(updateSaleReturnCart(newCart));
        setText("");
        setSearchProducts([]);
    };

    const handleSelectCustomer = (customer) => {
        let getCustomerInvoice =
            invoices &&
            invoices.length > 0 &&
            invoices.filter((e, i) => {
                if (e.customerDetails[0].id == customer.id) {
                    return e;
                }
            });

        getCustomerInvoice =
            getCustomerInvoice &&
            getCustomerInvoice.length > 0 &&
            getCustomerInvoice.sort(

                (a, b) => new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime()
            );

        setCustomerInvoices(getCustomerInvoice);
        setSelectedSearch("products")
        dispatch(updateDamageItemCustomer(customer));
        setText("");
        setSearchCustomerInvoice("");
        setSearchCustomers([]);
    };


    const handleSearch = (text) => {
        setText(text);

        if (!text) {
            setSearchCustomers([]);
            setSearchInvoices([]);
            setSearchCustomerInvoice("");
            setSearchProducts([]);
            return;
        }

        if (selectedSearch == "customers") {
            setSearchProducts([]);
            setSearchInvoices([]);

            setSearchCustomers(
                customers &&
                customers.length > 0 &&
                customers.filter((e, i) => {
                    if (

                        e?.customerName?.toLowerCase().includes(text.toLowerCase()) ||
                        e?.businessName?.toLowerCase().includes(text.toLowerCase()) ||
                        e?.email?.toLowerCase().includes(text.toLowerCase()) ||
                        e?.accountNo == text
                    ) {
                        return e;
                    }
                })
            );
        }

        if (selectedSearch == "invoices") {
            setSearchProducts([]);
            setSearchCustomers([]);

            setSearchInvoices(
                invoices &&
                invoices.length > 0 &&
                invoices.filter((e, i) => {
                    if (
                        e?.invoiceNumber?.toLowerCase().includes(text.toLowerCase()) ||
                        e?.businessName?.toLowerCase().includes(text.toLowerCase())
                    ) {
                        return e;
                    }
                })
            );
        }

        if (selectedSearch == "products") {
            setSearchCustomers([]);
            setSearchInvoices([]);



            let allProducts = invoices && invoices.length > 0
                ? invoices.flatMap(e => e.productDetails)
                : [];

            setSearchProducts(
                allProducts &&
                allProducts.length > 0 &&
                allProducts.filter((e, i) => {

                    if (
                        e?.productName?.toLowerCase().includes(text.toLowerCase()) && searchProducts.filter((ec)=>ec._id==e._id)[0] ||
                        e?.category?.toLowerCase().includes(text.toLowerCase()) ||
                        e?.sub_category?.toLowerCase().includes(text.toLowerCase())
                    ) {
                        return e;
                    }
                })
            );
        }

        if (selectedSearch === "imei") {
            console.log ("hello");
            setSearchCustomers([]);
            setSearchInvoices([]);
        
            // Flatten product details from invoices
            const allProducts = invoices?.flatMap(invoice => invoice.productDetails) || [];
        
            // Filter products based on IMEI
            const filteredProducts = allProducts.filter(product => {
                const imei = product?.IMEI;
        
                if (imei && (typeof imei === "object" || typeof imei === "string")) {
                    // console.log("hel", imei);
        
                    // Check if any IMEI matches the search text
                    return Array.isArray(imei)
                        ? imei.some(j => j?.toString().toLowerCase() === text?.toLowerCase())
                        : imei.toString().toLowerCase() === text?.toLowerCase();
                }
                
                return false; // Return false if IMEI doesn't meet conditions
            });
            console.log(filteredProducts,"filter")
            setSearchProducts(filteredProducts);
        }
    };


    const handleDiscountPrice = (price, product) => {
        let newInvoice =
            selectedInvoice &&
            selectedInvoice.productDetails.length > 0 &&
            selectedInvoice.productDetails.map((e, i) => {


                if (e._id == product._id) {
                    return {
                        ...e,
                        discountPrice: 0,
                        trade_price: selectedInvoice?.customerDetails[0].priceLevel[0].id == 1 ? Number(price) : e?.trade_price,
                        warehouse_price: selectedInvoice?.customerDetails[0].priceLevel[0].id == 2 ? Number(price) : e?.warehouse_price,
                        retail_price: selectedInvoice?.customerDetails[0].priceLevel[0].id == 3 ? Number(price) : e?.retail_price,
                    };







                    // if (e._id == product._id) {
                    //     return {
                    //         ...e,
                    //         discountPrice: (e?.discountPrice && (Number(price) <= (selectedInvoice?.customerDetails[0].priceLevel[0].id == 1 ? e?.trade_price
                    //             : selectedInvoice?.customerDetails[0].priceLevel[0].id == 2 ? e?.warehouse_price : e?.retail_price))) ? Number(price) : 0,
                    //         trade_price: (e?.discountPrice &&
                    //             (Number(price) <= (selectedInvoice?.customerDetails[0].priceLevel[0].id == 1 ? e?.trade_price
                    //                 : selectedInvoice?.customerDetails[0].priceLevel[0].id == 2 ? e?.warehouse_price : e?.retail_price))
                    //         ) ? e?.trade_price : selectedInvoice?.customerDetails[0].priceLevel[0].id == 1 ? Number(price) : e?.trade_price,
                    //         warehouse_price: (e?.discountPrice && (Number(price) <= (selectedInvoice?.customerDetails[0].priceLevel[0].id == 1 ? e?.trade_price
                    //             : selectedInvoice?.customerDetails[0].priceLevel[0].id == 2 ? e?.warehouse_price : e?.retail_price)))
                    //             ? e?.warehouse_price : selectedInvoice?.customerDetails[0].priceLevel[0].id == 2 ? Number(price) : e?.warehouse_price,
                    //         retail_price: (e?.discountPrice && (Number(price) <= (selectedInvoice?.customerDetails[0].priceLevel[0].id == 1 ? e?.trade_price
                    //             : selectedInvoice?.customerDetails[0].priceLevel[0].id == 2 ? e?.warehouse_price : e?.retail_price))) ? e?.retail_price : selectedInvoice?.customerDetails[0].priceLevel[0].id == 3 ? Number(price) : e?.retail_price,


                    //     };
                }
                else {
                    return e;
                }
            });


        let invoice = { ...selectedInvoice, productDetails: newInvoice }

        setSelectedInvoice(invoice)

    };

    const handleSelectInvoice = (invoice) => {


        setSelectedInvoice(invoice)

        setLessAmount(false)

        let selectCustomer = customers && customers.length > 0 && customers.filter((e, i) => {
            return e?.id == invoice?.customerDetails[0]?.id
        })

        dispatch(updateDamageItemCustomer(selectCustomer && selectCustomer.length > 0 ? selectCustomer[0] : ""))
        setText("");
        setSearchInvoices([]);
    }


    const handleSelectProduct = (product) => {
        let { productDetails } = selectedInvoice;

        if (productDetails?.some((e, i) => e._id == product?._id)) {

            let productSelected = productDetails?.find((e, i) => e?._id == product?._id)

            let qtyReturn = product?.DamageQty ? product?.DamageQty : product?.saleQty

            let remainingQtyToReturn = (productSelected.saleQty) - (productSelected?.returnQty ? productSelected?.returnQty : 0)

            if (Number(qtyReturn) > Number(remainingQtyToReturn) && !productSelected?.selected) {
                alert(`Not enough qty to return the customer has already return ${productSelected?.returnQty} qty`)
                return
            }

            else {
                productDetails = productDetails.map((e, i) => {
                    if (product.id == e.id) {
                        return {
                            ...e,
                            selected: !e.selected,
                            discountPrice: 0,
                            trade_price:
                                selectedInvoice?.customerDetails[0].priceLevel[0].id == 1
                                    ? Number(e?.discountPrice)
                                        ? e?.discountPrice
                                        : e?.trade_price
                                    : e?.trade_price,


                            warehouse_price:
                                selectedInvoice?.customerDetails[0].priceLevel[0].id == 2
                                    ? Number(e?.discountPrice)
                                        ? e?.discountPrice
                                        : e?.warehouse_price
                                    : e?.warehouse_price,
                            retail_price:
                                selectedInvoice?.customerDetails[0].priceLevel[0].id == 3
                                    ? Number(e?.discountPrice)
                                        ? e?.discountPrice
                                        : e?.retail_price
                                    : e?.retail_price,
                        };
                    } else {
                        return e;
                    }
                });
                setSelectedInvoice({ ...selectedInvoice, productDetails: productDetails });

            }


        }

    };

    const handleChooseProducts = (product) => {


        if (selectedSearch == "imei") {

            let searchinvoice = invoices && invoices.length > 0 && invoices?.filter((e) => e.productDetails?.some((j) => j.IMEI == product.IMEI))

            searchinvoice = searchinvoice.map((e, i) => {
                let productDetails = e.productDetails.map((j, ind) => {
                    if (j._id == product._id) {
                        return {
                            ...j,
                            selected: true,
                            discountPrice: 0,
                            trade_price: e?.customerDetails[0].priceLevel[0].id == 1 ? Number(j?.discountPrice ? j?.discountPrice : j?.trade_price) : j?.trade_price,
                            warehouse_price: e?.customerDetails[0].priceLevel[0].id == 2 ? Number(j?.discountPrice ? j?.discountPrice : j?.warehouse_price) : j?.warehouse_price,
                            retail_price: e?.customerDetails[0].priceLevel[0].id == 3 ? Number(j?.discountPrice ? j?.discountPrice : j?.retail_price) : j?.retail_price,
                        };
                    } else {
                        return j;
                    }
                });

                return {
                    ...e,
                    productDetails: productDetails,
                };
            });

            searchinvoice =
                searchinvoice &&
                searchinvoice.length > 0 &&
                searchinvoice.sort(
                    (a, b) =>
                        new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime()
                );

            setSearchCustomerInvoice(searchinvoice.length > 0 ? searchinvoice : []);
            setText("");
            setSearchProducts([]);
            return
        }


        if (customerInvoice) {
            let searchinvoice =
                customerInvoice &&
                customerInvoice.length > 0 &&
                customerInvoice.filter((e, i) => {
                    if (e.productDetails.some((j, ind) => j?._id == product?._id)) {
                        return e;
                    }
                });

            if (searchinvoice && searchinvoice.length > 0) {
                searchinvoice = searchinvoice.map((e, i) => {
                    let productDetails = e.productDetails.map((j, ind) => {
                        if (j._id == product._id) {
                            return {
                                ...j,
                                selected: true,
                                discountPrice: 0,
                                trade_price: e?.customerDetails[0].priceLevel[0].id == 1 ? Number(j?.discountPrice ? j?.discountPrice : j?.trade_price) : j?.trade_price,
                                warehouse_price: e?.customerDetails[0].priceLevel[0].id == 2 ? Number(j?.discountPrice ? j?.discountPrice : j?.warehouse_price) : j?.warehouse_price,
                                retail_price: e?.customerDetails[0].priceLevel[0].id == 3 ? Number(j?.discountPrice ? j?.discountPrice : j?.retail_price) : j?.retail_price,
                            };
                        } else {
                            return j;
                        }
                    });

                    return {
                        ...e,
                        productDetails: productDetails,
                    };
                });

                searchinvoice =
                    searchinvoice &&
                    searchinvoice.length > 0 &&
                    searchinvoice.sort(
                        (a, b) =>
                            new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime()
                    );

                setSearchCustomerInvoice(searchinvoice.length > 0 ? searchinvoice : []);
                setText("");
                setSearchProducts([]);
            } else {
                setSearchCustomerInvoice("");
                setSearchProducts([]);
                setText("");
            }
        }
    };




    useEffect(() => {
        if (barcodeData?.length > 0 && selectedSearch?.toLowerCase() == "products") {
            textInputRef?.current?.blur();


            let product =
                products &&
                products?.length > 0 &&
                products?.find((e) => e?.barcode == barcodeData);


            if (product) {
                handleChooseProducts(product);
                setBarcodeRead("");
            }
        }
        else if (barcodeData?.length > 0 && selectedSearch?.toLowerCase() == "invoices") {
            textInputRef?.current?.blur();


            let invoice =
                invoices &&
                invoices?.length > 0 &&
                invoices?.find((e) => e?.barcodeNumber == barcodeData);


            if (invoice) {
                handleSelectInvoice(invoice);
                setBarcodeRead("");
            }
        }
    }, [barcodeData]);









    let damagetotal;
    let damageDiscount;
    let damageVat;
    let damageSubtotal;
    let damageQty;
    let damageItems;
    let damageCost;



    if (selectedInvoice && selectedInvoice.productDetails) {

        damageItems = selectedInvoice.productDetails.reduce((previous, current) => previous + (current.selected ? 1 : 0), 0)

        damageQty = selectedInvoice.productDetails.reduce((previous, current) => previous + (current.selected ? (current?.DamageQty ? current?.DamageQty : current.saleQty) : 0), 0)

        damagetotal = selectedInvoice.productDetails.reduce((previous, current) => previous + (current.selected ? ((selectedInvoice?.customerDetails[0]?.priceLevel[0].id == 1 ? current.trade_price : selectedInvoice?.customerDetails[0]?.priceLevel[0].id == 2 ? current.warehouse_price : current.retail_price) * (current?.DamageQty ? Number(current?.DamageQty) : Number(current.saleQty))) : 0), 0)

        damageCost = selectedInvoice.productDetails.reduce((previous, current) => previous + (current.selected ? (current?.cost_price * (current?.DamageQty ? Number(current?.DamageQty) : Number(current.saleQty))) : 0), 0)

        damageDiscount = selectedInvoice.productDetails
            ? selectedInvoice.productDetails.reduce((total, current) => {
                if (current.selected && current.discountPrice) {
                    const price =
                        selectedInvoice.customerDetails[0].priceLevel[0].id === 1
                            ? current.trade_price
                            : selectedInvoice.customerDetails[0].priceLevel[0].id === 2
                                ? current.warehouse_price
                                : current.retail_price;

                    const discountAdjustedPrice = (price - (current.discountPrice ? current.discountPrice : price)) * (current.DamageQty ? current.DamageQty : current.saleQty);
                    return total + discountAdjustedPrice;
                } else {
                    return total;
                }
            }, 0)
            : 0
        if (selectedInvoice.vatAmount) {
            damageVat = ((damagetotal - (damageDiscount ? damageDiscount : 0)) * vatRate) / 100
        } else {
            damageVat = 0
        }

        damageSubtotal = damagetotal - damageDiscount + damageVat

    }



    const Header = () => {
        return (
            <View fixed>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: 10,
                        alignItems: "flex-start",
                    }}
                >
                    <View>
                        {returnInvoice?.vatAmount && (
                            <View>
                                <View>
                                    <Image
                                        style={{ height: 50, borderRadius: 10 }}
                                        source={{
                                            uri: `${Image_Uri}companyImage/${vat?.companyLogo}`,
                                        }}
                                    />

                                    <Text
                                        style={{
                                            color: "black",
                                            fontSize: "14px",
                                            marginTop: "10px",
                                            fontFamily: "MyFont",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {vat?.companyName}
                                    </Text>
                                    <Text
                                        style={{
                                            color: "black",
                                            fontSize: "10px",
                                            fontFamily: "MyFont",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        {vat?.companyAddress}
                                    </Text>

                                    <Text
                                        style={{
                                            color: "black",
                                            fontSize: "10px",
                                            fontFamily: "MyFont",
                                            fontWeight: "normal",
                                        }}
                                    >{vat?.city}</Text>

                                    <Text

                                        style={{
                                            color: "black",
                                            fontSize: "10px",
                                            fontFamily: "MyFont",
                                            fontWeight: "normal",
                                        }}
                                    >{vat?.postCode}</Text>
                                </View>
                                <View>
                                    <Text
                                        style={{
                                            color: "black",
                                            fontSize: "10px",
                                            marginTop: "3px",
                                            fontFamily: "MyFont",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        Tel: {vat?.mobileNumber}
                                    </Text>
                                    <Text
                                        style={{
                                            color: "black",
                                            fontSize: "10px",
                                            fontFamily: "MyFont",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        Email: {vat?.companyEmail}
                                    </Text>
                                </View>
                                <View>
                                    <Text
                                        style={{
                                            color: "black",
                                            fontSize: "10px",
                                            fontFamily: "MyFont",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        VAT Number: {vat?.vatNumber}
                                    </Text>
                                    <Text
                                        style={{
                                            color: "black",
                                            fontSize: "10px",
                                            fontFamily: "MyFont",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        Company No: {vat?.companyNumber}
                                    </Text>

                                </View>
                            </View>
                        )}
                    </View>


                    <View>
                        <View style={{ alignItems: "center" }} >
                            <Image
                                src={`${Image_Uri}products/${returnInvoice?.barcodeImagePath}`}
                                style={{
                                    height: "35px",
                                    width: "150px"
                                }}
                            />
                        </View>

                        <View style={{ alignItems: "center", marginTop: "10px" }}>
                            <Text
                                style={{
                                    color: "black",
                                    fontSize: "16px",
                                    fontFamily: "MyFont",
                                    // marginTop:"10px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                }}
                            >
                                {returnInvoice?.vatAmount ? "Invoice" : "Quotation"}
                            </Text>
                        </View>

                        <View>
                            {returnInvoice?.vatAmount && <Text
                                style={{
                                    color: "black",
                                    fontSize: "10px",
                                    fontFamily: "MyFont",
                                    marginTop: "10px",
                                    fontWeight: "normal",
                                    textAlign: "center",
                                }}
                            >
                                Invoice no: {returnInvoice?.invoiceNumber}
                            </Text>}

                            <Text
                                style={{
                                    color: "black",
                                    fontSize: "10px",
                                    fontFamily: "MyFont",
                                    // marginTop:"10px",
                                    fontWeight: "normal",
                                    textAlign: "center",
                                }}
                            >
                                Date:{" "}
                                {new Date(
                                    returnInvoice?.saleReturnDate
                                ).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                })}
                            </Text>

                            <Text
                                style={{
                                    color: "black",
                                    fontSize: "10px",
                                    fontFamily: "MyFont",
                                    fontWeight: "normal",
                                    textAlign: "center",
                                }}
                            >
                                Time:{" "}
                                {new Date(
                                    returnInvoice?.saleReturnDate
                                ).toLocaleTimeString()}
                            </Text>

                            {(returnInvoice?.saleReturnDate || returnInvoice?.exchangeDate) && returnInvoice?.vatAmount && <View style={{ marginTop: "10px" }} >

                                {returnInvoice?.vatAmount && <Text
                                    style={{
                                        color: "black",
                                        fontSize: "10px",
                                        fontFamily: "MyFont",
                                        // marginTop:"10px",
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    }}
                                >
                                    Return Inv Ref.:

                                    {returnInvoice?.returnInvoiceRef?.map((e, i) => {
                                        return (

                                            <Text
                                                style={{
                                                    color: "black",
                                                    fontSize: "8px",
                                                    fontFamily: "MyFont",
                                                    // marginTop:"10px",
                                                    fontWeight: "normal",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {e},
                                            </Text>
                                        )
                                    })}


                                </Text>}





                            </View>}


                            <View style={{ marginTop: "10px" }}>
                                <Text
                                    style={{
                                        color: "black",
                                        fontSize: "12px",
                                        fontFamily: "MyFont",
                                        // marginTop:"10px",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}
                                >
                                    Bill to:
                                </Text>

                                <Text
                                    style={{
                                        color: "black",
                                        fontSize: "10px",
                                        fontFamily: "MyFont",
                                        // marginTop:"10px",
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    }}
                                >
                                    Account No: {selectedCustomer?.accountNo}
                                </Text>

                                {returnInvoice?.vatAmount && <Text
                                    style={{
                                        color: "black",
                                        fontSize: "10px",
                                        fontFamily: "MyFont",
                                        // marginTop:"10px",
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    }}
                                >
                                    Business Name: {selectedCustomer?.businessName}
                                </Text>}

                                {returnInvoice?.vatAmount && <Text
                                    style={{
                                        color: "black",
                                        fontSize: "10px",
                                        fontFamily: "MyFont",
                                        // marginTop:"10px",
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    }}
                                >
                                    Email: {selectedCustomer?.email}
                                </Text>}

                                {returnInvoice?.vatAmount && <Text
                                    style={{
                                        color: "black",
                                        fontSize: "10px",
                                        fontFamily: "MyFont",
                                        marginTop: "10px",
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    }}
                                >
                                    Payment Method:{" "}
                                    {Array?.isArray(returnInvoice?.paymentMethod)
                                        ? returnInvoice?.paymentMethod
                                            ?.map((e, i) => e)
                                            .join(",")
                                        : returnInvoice?.paymentMethod}
                                </Text>}

                                {returnInvoice?.vatAmount && <Text
                                    style={{
                                        color: "black",
                                        fontSize: "10px",
                                        fontFamily: "MyFont",
                                        marginTop: "10px",
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    }}
                                >
                                    Status: {returnInvoice?.status}
                                </Text>}


                                {/* {returnInvoice?.creditDays && (
                                    <Text
                                        style={{
                                            color: "black",
                                            fontSize: "10px",
                                            fontFamily: "MyFont",
                                            marginTop: "10px",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                        }}
                                    >
                                        Credit Days: {returnInvoice?.creditDays}
                                    </Text>
                                )} */}

                                {/* {returnInvoice?.referenceId && (
                                    <Text
                                        style={{
                                            color: "black",
                                            fontSize: "10px",
                                            fontFamily: "MyFont",
                                            // marginTop: "10px",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                        }}
                                    >
                                        Reference Id: {returnInvoice?.referenceId}
                                    </Text>
                                )} */}

                                {returnInvoice?.vatAmount && returnInvoice?.transactionId && (
                                    <Text
                                        style={{
                                            color: "black",
                                            fontSize: "10px",
                                            fontFamily: "MyFont",
                                            // marginTop: "10px",
                                            fontWeight: "normal",
                                            textAlign: "center",
                                        }}
                                    >
                                        Transaction Id: {returnInvoice?.transactionId}
                                    </Text>
                                )}

                                {returnInvoice?.vatAmount && Array?.isArray(returnInvoice?.paymentMethod) &&
                                    returnInvoice?.paymentMethod?.some(
                                        (e) => typeof e == "string" && e?.toLowerCase() == "cheque"
                                    ) && (
                                        <View>
                                            <Text
                                                style={{
                                                    color: "black",
                                                    fontSize: "10px",
                                                    fontFamily: "MyFont",
                                                    // marginTop:"10px",
                                                    fontWeight: "normal",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Cheque No: {returnInvoice?.cheque_no}
                                            </Text>

                                            <Text
                                                style={{
                                                    color: "black",
                                                    fontSize: "10px",
                                                    fontFamily: "MyFont",
                                                    // marginTop:"10px",
                                                    fontWeight: "normal",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Bank Name: {returnInvoice?.bank_name}
                                            </Text>

                                            <Text
                                                style={{
                                                    color: "black",
                                                    fontSize: "10px",
                                                    fontFamily: "MyFont",
                                                    // marginTop:"10px",
                                                    fontWeight: "normal",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Cheque Date:{" "}
                                                {new Date(
                                                    returnInvoice.clear_date
                                                ).toLocaleDateString("en-GB", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                })}
                                            </Text>
                                        </View>
                                    )}
                            </View>
                        </View>
                    </View>
                </View>

                <View
                    style={{
                        flexDirection: "row",
                        borderBottomWidth: 1,
                        borderColor: "#000",
                        paddingBottom: 5,
                        paddingTop: 5,
                        border: "1px solid black",
                        backgroundColor: "gray",
                        alignItems: "center",
                        padding: 10,
                        marginTop: "20px",
                    }}
                >
                    <View style={{ flex: 0.5 }}>
                        <Text
                            style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
                        >
                            Item
                        </Text>
                    </View>

                    <View style={{ flex: 1.5 }}>
                        <Text
                            style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
                        >
                            Barcode
                        </Text>
                    </View>

                    <View style={{ flex: 4 }}>
                        <Text
                            style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
                        >
                            Product Description
                        </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                        <Text
                            style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
                        >
                            Qty
                        </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                        <Text
                            style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
                        >
                            Price
                        </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                        <Text
                            style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
                        >
                            Disc
                        </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                        <Text
                            style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
                        >
                            Amount
                        </Text>
                    </View>
                </View>
            </View>
        );
    };


    const MyDocument = () => (
        <Document size="A4">
            {/* <Header /> */}

            <Page style={{ padding: "0px 10px" }}>
                <Header />
                {/* Data Rows */}
                <View>
                    {returnInvoice &&
                        returnInvoice?.productDetails &&
                        returnInvoice?.productDetails?.length > 0 &&
                        returnInvoice?.productDetails?.map((item, index) => (
                            <View
                                key={index}
                                style={{
                                    flexDirection: "row",
                                    borderBottomWidth: 1,
                                    borderColor: "#000",
                                    borderLeftWidth: 1,
                                    borderRightWidth: 1,
                                    padding: "3px 10px",
                                }}
                            >
                                <View style={{ flex: 0.5 }}>
                                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>-1</Text>
                                </View>

                                <View style={{ flex: 1.5 }}>
                                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                                        {item.barcode}
                                    </Text>
                                </View>

                                <View style={{ flex: 4 }}>
                                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                                        {item.productName}
                                    </Text>
                                    {item?.IMEI && item?.IMEI?.length > 0 &&
                                        item?.IMEI?.map((e, i) => {
                                            return (
                                                <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                                                    {e}
                                                </Text>
                                            );
                                        })}
                                </View>

                                <View style={{ flex: 1 }}>
                                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                                        -{item?.DamageQty ? item?.DamageQty : item?.saleQty}
                                    </Text>
                                </View>

                                <View style={{ flex: 1 }}>
                                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                                        £
                                        -{returnInvoice?.customerDetails[0]?.priceLevel[0]?.id ==
                                            1
                                            ? Number(item.trade_price).toFixed(2)
                                            : returnInvoice?.customerDetails[0]?.priceLevel[0]
                                                ?.id == 2
                                                ? Number(item.warehouse_price).toFixed(2)
                                                : Number(item.retail_price).toFixed(2)}
                                    </Text>
                                </View>

                                <View style={{ flex: 1 }}>
                                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                                        £
                                        {item.discountPrice
                                            ? Number(
                                                (returnInvoice?.customerDetails[0]?.priceLevel[0]
                                                    ?.id == 1
                                                    ? item.trade_price
                                                    : returnInvoice?.customerDetails[0]
                                                        ?.priceLevel[0]?.id == 2
                                                        ? item.warehouse_price
                                                        : item.retail_price) - item.discountPrice
                                            ).toFixed(2)
                                            : 0}
                                    </Text>
                                </View>

                                <View style={{ flex: 1 }}>
                                    <Text
                                        style={{
                                            fontFamily: "MyFont",
                                            fontSize: 8,
                                            color: "black",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        £
                                        -{item.discountPrice
                                            ? Number(item.discountPrice * (item?.DamageQty ? item?.DamageQty : item.saleQty)).toFixed(2)
                                            : Number(
                                                (returnInvoice?.customerDetails[0]?.priceLevel[0]
                                                    ?.id == 1
                                                    ? item.trade_price
                                                    : returnInvoice?.customerDetails[0]
                                                        ?.priceLevel[0]?.id == 2
                                                        ? item.warehouse_price
                                                        : item.retail_price) * (item?.DamageQty ? item?.DamageQty : item.saleQty)
                                            ).toFixed(2)}
                                    </Text>
                                </View>
                            </View>
                        ))}


                    <View
                        // key={index}
                        style={{
                            flexDirection: "row",
                            // borderWidth: 1,
                            borderColor: "#000",
                            padding: "5px 10px",
                            borderLeftWidth: 1,
                            borderRightWidth: 1,
                        }}
                    >
                        <View style={{ flex: 0.5, }}>
                            <Text
                                style={{
                                    fontFamily: "MyFont",
                                    fontSize: 10,
                                    fontWeight: "bold",
                                }}
                            >
                                -{returnInvoice?.totalItems}
                            </Text>
                        </View>

                        <View style={{ flex: 5.5, alignItems: "center", }}>
                            <Text
                                style={{
                                    fontFamily: "MyFont",
                                    fontSize: 10,

                                    fontWeight: "bold",
                                }}
                            >
                                Subtotal
                            </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                            <Text
                                style={{
                                    fontFamily: "MyFont",
                                    fontSize: 10,
                                    fontWeight: "bold",
                                }}
                            >
                                -{returnInvoice?.totalQty}
                            </Text>
                        </View>

                        <View style={{ flex: 1, }}>

                            price

                        </View>

                        <View style={{ flex: 1, }}>
                            <Text
                                style={{
                                    fontFamily: "MyFont",
                                    fontSize: 10,
                                    fontWeight: "bold",
                                }}
                            >
                                £{Number(returnInvoice?.discount).toFixed(2)}
                            </Text>
                        </View>

                        <View style={{ flex: 1, }}>
                            <Text
                                style={{
                                    fontFamily: "MyFont",
                                    fontSize: 10,
                                    fontWeight: "bold",
                                }}
                            >
                                £-{Number(returnInvoice?.total - returnInvoice?.discount).toFixed(2)}
                            </Text>
                        </View>
                    </View>


                    {returnInvoice?.vatAmount && <View
                        // key={index}
                        style={{
                            flexDirection: "row",
                            // borderWidth: 1,
                            borderLeftWidth: 1,
                            borderRightWidth: 1,
                            borderColor: "#000",
                            borderTopWidth: 1,
                            padding: "5px 10px",
                        }}
                    >
                        <View style={{ flex: 9, alignItems: "center" }}>
                            <Text
                                style={{
                                    fontFamily: "MyFont",
                                    fontSize: 10,
                                    fontWeight: "bold",
                                }}
                            >
                                Vat
                            </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                            <Text
                                style={{
                                    fontFamily: "MyFont",
                                    fontSize: 10,

                                    fontWeight: "bold",
                                }}
                            >
                                £ -{Number(returnInvoice?.vatAmount).toFixed(2)}
                            </Text>
                        </View>


                    </View>}


                    <View
                        // key={index}
                        style={{
                            flexDirection: "row",
                            // borderWidth: 1,
                            borderColor: "#000",
                            borderWidth: 1,
                            padding: "5px 10px",
                            // borderBottomWidth:1,
                            backgroundColor: "#DDD",
                            opacity: 0.7
                        }}
                    >
                        <View style={{ flex: 9, alignItems: "center" }}>
                            <Text
                                style={{
                                    fontFamily: "MyFont",
                                    fontSize: 10,
                                    color: "red",
                                    fontWeight: "bold",
                                }}
                            >
                                Total
                            </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                            <Text
                                style={{
                                    fontFamily: "MyFont",
                                    fontSize: 10,
                                    color: "red",
                                    fontWeight: "bold",
                                }}
                            >
                                £ -{Number(returnInvoice?.subtotal).toFixed(2)}
                            </Text>
                        </View>


                    </View>


                </View>
            </Page>
        </Document>
    );


    const handleGenerateNewInvoice = async () => {


        if (allReturnProducts && allReturnProducts?.length > 0) {




            if (!refundCash?.selected && !deductCreditBalance?.selected && !refundCard?.selected) {
                alert("Kindly Select Payment Option")
                return
            }

            if (deductCreditBalance?.selected && (!selectedDeductBalanceOption || selectedDeductBalanceOption == "select")) {
                alert("Kindly enter credit deduct option")
                return
            }

            let allProducts = allReturnProducts?.flatMap((e) => {
                return e?.productDetails || [];
            });

            let customerDetails = allReturnProducts[0]?.customerDetails

            let returnInvoiceRef = allReturnProducts?.map((e) => {

                return e?.returnInvoiceRef


            })

            let returnInvoiceDate = allReturnProducts?.map((e) => {

                return e?.invoiceRefDate

            })

            let total = Number(allReturnProducts?.reduce((pre, curr) => {
                return (pre || 0) + (Number(curr?.total) || 0)
            }, 0)).toFixed(2)


            let subtotal = allReturnProducts?.reduce((pre, curr) => {
                return (pre || 0) + (Number(curr?.subtotal) || 0)
            }, 0)

            let discount = allReturnProducts?.reduce((pre, curr) => {
                return (pre || 0) + (Number(curr?.discount) || 0)
            }, 0)

            let totalItems = allReturnProducts?.reduce((pre, curr) => {
                return (pre || 0) + (Number(curr?.totalItems) || 0)
            }, 0)

            let totalQty = allReturnProducts?.reduce((pre, curr) => {
                return (pre || 0) + (Number(curr?.totalQty) || 0)
            }, 0)


            let vatAmount = allReturnProducts?.reduce((pre, curr) => {
                return (pre || 0) + (Number(curr?.vatAmount) || 0)
            }, 0)

            let totalCost = allProducts?.reduce((pre, curr) => {
                return (pre || 0) + ((Number(curr?.cost_price) * (curr?.DamageQty ? Number(curr?.DamageQty) : Number(curr?.saleQty))) || 0)
            }, 0)

            let dataToSend = {
                productDetails: allProducts,
                customerDetails: customerDetails,
                total: total,
                discount: discount,
                subtotal: subtotal,
                totalItems: totalItems,
                totalQty: totalQty,
                vatAmount: vatAmount,
                costPrice: totalCost,
                customerName: customerDetails?.customerName,
                saleReturnDate: new Date(),
                status: "Return",
                deductCash: refundCash?.selected,
                deductCreditBalance: deductCreditBalance.selected,
                deductCreditOption: selectedDeductBalanceOption,
                returnInvoiceRef: returnInvoiceRef,
                invoiceRefDate: returnInvoiceDate,
                paymentMethod: deductCreditBalance?.selected ? "Credit Note" : refundCash?.selected ? "refund cash" : "refund card",
                vatNumber: vatAmount ? vat?.vatNumber : "",
                companyNumber: vatAmount ? vat?.companyNumber : "",
                employeeDetails: userData,
                employeeId: userData?.id,

            }


            setLoading(true)

            let data = await localStorage.getItem("userData");
            data = JSON.parse(data);
            let token = data;

            axios.post(`${Base_Uri}SaleReturnDamage`, dataToSend, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Include other headers as needed
                },
            }).then((res) => {
                let data = res.data
                if (data.status) {
                    setLoading(false)

                    let allData = data?.data

                    setReturnInvoice(allData)
                    getProducts()
                    getCustomers()
                    getTodayReturnInvoices()
                    dispatch(updateAllReturnProducts([]))
                    setSuccessfulTransactionModal(true)
                }
            }).catch((error) => {
                setLoading(false)
            })

        }
    }


    const handleGoForExchange = async () => {

        if (allReturnProducts && allReturnProducts?.length > 0) {


            let allProducts = allReturnProducts?.flatMap((e) => {
                return e?.productDetails || [];
            });

            let customerDetails = allReturnProducts[0]?.customerDetails

            let returnInvoiceRef = allReturnProducts?.map((e) => {

                return e?.returnInvoiceRef


            })

            let returnInvoiceDate = allReturnProducts?.map((e) => {

                return e?.invoiceRefDate

            })

            let total = Number(allReturnProducts?.reduce((pre, curr) => {
                return (pre || 0) + (Number(curr?.total) || 0)
            }, 0)).toFixed(2)


            let subtotal = allReturnProducts?.reduce((pre, curr) => {
                return (pre || 0) + (Number(curr?.subtotal) || 0)
            }, 0)

            let discount = allReturnProducts?.reduce((pre, curr) => {
                return (pre || 0) + (Number(curr?.discount) || 0)
            }, 0)

            let totalItems = allReturnProducts?.reduce((pre, curr) => {
                return (pre || 0) + (Number(curr?.totalItems) || 0)
            }, 0)

            let totalQty = allReturnProducts?.reduce((pre, curr) => {
                return (pre || 0) + (Number(curr?.totalQty) || 0)
            }, 0)


            let vatAmount = allReturnProducts?.reduce((pre, curr) => {
                return (pre || 0) + (Number(curr?.vatAmount) || 0)
            }, 0)

            let totalCost = allProducts?.reduce((pre, curr) => {
                return (pre || 0) + ((Number(curr?.cost_price) * (curr?.DamageQty ? Number(curr?.DamageQty) : Number(curr?.saleQty))) || 0)
            }, 0)

            let dataToSend = {
                productDetails: allProducts,
                customerDetails: customerDetails,
                total: total,
                discount: discount,
                subtotal: subtotal,
                totalItems: totalItems,
                totalQty: totalQty,
                vatAmount: vatAmount,
                costPrice: totalCost,
                customerName: customerDetails?.customerName,
                saleReturnDate: new Date(),
                status: "Return",
                returnInvoiceRef: returnInvoiceRef,
                invoiceRefDate: returnInvoiceDate,
                paymentMethod: deductCreditBalance?.selected ? "deduct credit" : "refund cash",
                vatNumber: vatAmount ? vat?.vatNumber : "",
                companyNumber: vatAmount ? vat?.companyNumber : "",
                employeeDetails: userData,
                employeeId: userData?.id,

            }


            dispatch(updateExhangeProducts(dataToSend))
            cancelModal()

        }

    }

    // function printAndClose() {
    //     // Open a new tab for printing
    //     var printWindow = window.open("Invoice", "_blank");

    //     // Add your content to the new tab (you can replace this with your actual content)
    //     printWindow.document.write(
    //         `<!DOCTYPE html>
    //       <html lang="en">
    //         <head>
    //           <meta charset="UTF-8" />
    //           <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    //           <title>Sale Return Invoice</title>
    //         </head>
    //         <body>
    //         <div
    //         class="container"
    //         style="

    //           display: flex;
    //           justify-content: ${returnInvoice.vatAmount ? `space-between` : `flex-end`
    //         }; /* Changed to space-between */
    //           flex-direction: row; /* Changed to row */
    //           margin-right: 10px;
    //           margin-left:10px;
    //           /* Added margin to the right */
    //         "
    //     >
    //         <!-- Left side details -->

    //         ${returnInvoice?.vatAmount
    //             ? `
    //           <div>
    //             <h1>${vat?.companyName}</h1>
    //             <p>${vat?.companyAddress}
    //             </p>


    //             <div>
    //             <p>Tel: ${vat?.mobileNumber} </br>
    //             Email: ${vat?.companyEmail}
    //             </p>

    //           </div>


    //             ${returnInvoice?.vatAmount &&
    //             `<div >
    //                 ${vat?.vatNumber
    //                 ? `<p>Vat Number: ${vat?.vatNumber}
    //                     </br>
    //                     Company Number: ${vat?.companyNumber}
    //                     </p>`
    //                 : ""
    //             }

    //               </div>`
    //             }

    //           </div>



    //           `
    //             : ""
    //         }

    //         <!-- Right side details -->
    //         <div style="text-align: right;">

    //             <div>


    //             <img style="height:35px;" src="${Image_Uri}products/${returnInvoice?.barcodeImagePath
    //         }" />

    //                 <p style="font-size: 32px; font-weight: bold; margin-bottom: 5px;margin-top:0px">
    //                     ${returnInvoice?.vatAmount
    //             ? "Return Invoice"
    //             : "Return Quotation"
    //         }
    //                 </p>
    //                 <div style="margin-bottom: 5px;">
    //                     ${returnInvoice?.vatAmount
    //             ? `<p>Return Invoice #: ${returnInvoice?.invoiceNumber}</br>`
    //             : ""
    //         } 
    //                     Return Date: ${new Date(
    //             returnInvoice.saleReturnDate
    //         ).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}</br>
    //                     Return Time: ${new Date(
    //             returnInvoice.saleReturnDate
    //         ).toLocaleTimeString()}</p>
    //                 </div>


    //                 ${returnInvoice.saleReturnDate
    //             ? returnInvoice.vatAmount
    //                 ? `<p>Invoice Ref #: ${returnInvoice?.returnInvoiceRef
    //                 }<br>
    //                            Invoice Ref Date: ${new Date(
    //                     returnInvoice.invoiceRefDate
    //                 ).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}<br>
    //                            Invoice Ref Time: ${new Date(
    //                     returnInvoice.invoiceRefDate
    //                 ).toLocaleTimeString()}</p>`
    //                 : `Invoice Ref Date: ${new Date(
    //                     returnInvoice.invoiceRefDate
    //                 ).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}<br>
    //                         Invoice Ref Time: ${new Date(
    //                     returnInvoice.invoiceRefDate
    //                 ).toLocaleTimeString()}</p>`
    //             : ""
    //         }


    //                 <p style="margin-bottom: 5px; font-weight: bold; font-size: 16px;">
    //                 Bill to:
    //             <br/>
    //             Account Number: ${selectedCustomer?.accountNo}<br/>
    //               ${returnInvoice?.vatAmount
    //             ? `Business Name: ${selectedCustomer?.businessName}<br/>`
    //             : ""
    //         } 
    //               ${returnInvoice?.vatAmount
    //             ? `Customer Email: ${selectedCustomer?.email}<br/>`
    //             : ""
    //         }
    //               </p>



    //                   ${returnInvoice?.vatAmount
    //             ? `Payment Method: ${Array.isArray(returnInvoice?.paymentMethod) ? returnInvoice?.paymentMethod?.map((e, i) => e) : returnInvoice?.paymentMethod}`
    //             : ""
    //         }

    //             </div>

    //             ${returnInvoice.status
    //             ? `<p>Status: ${returnInvoice.status}</p>`
    //             : ""
    //         }

    //             ${returnInvoice.referenceId
    //             ? `<p>Reference Id: ${returnInvoice.referenceId}</p>`
    //             : ""
    //         }
    //             ${returnInvoice.transactionId
    //             ? `<p>Transaction Id: ${returnInvoice.transactionId}</p>`
    //             : ""
    //         }
    //             ${Array.isArray(returnInvoice?.paymentMethod) ? returnInvoice?.paymentMethod?.map((e, i) => e?.toLowerCase() == "cheque") : returnInvoice?.paymentMethod.toLowerCase() == "cheque"
    //             ? `<p>Cheque No: ${returnInvoice.cheque_no}</br>
    //                   Bank Name: ${returnInvoice.bank_name}</br>
    //                   Cheque Date: ${new Date(
    //                 returnInvoice.clear_date
    //             ).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}</p>
    //                   `
    //             : ""
    //         }
    //         </div>
    //     </div>
    //             </div>
    //             <div style="display:flex;justify-content:center" >
    //             <table style="margin-top:10px;border-collapse: collapse;" >
    //               <thead style="background-color:lightGray;" >
    //                 <tr style="background-color:lightGray;" >


    //                 <th
    //                 style="
    //                   font-size: 16px;  
    //                   font-weight: bold;
    //                   margin:0px;
    //                   font-family: Arial, Helvetica, sans-serif;
    //                   border: 1px solid black;
    //                   margin-bottom: 10px;
    //                   "
    //               >
    //                 Item
    //               </th>

    //                 <th
    //                 style="
    //                   font-size: 16px;  
    //                   font-weight: bold;
    //                   margin:0px;
    //                   font-family: Arial, Helvetica, sans-serif;
    //                   border: 1px solid black;
    //                   margin-bottom: 10px;
    //                   "
    //               >
    //                 Barcode
    //               </th>

    //                 <th
    //                     colspan="2"
    //                     style="
    //                       font-size: 16px;
    //                       font-weight: bold;
    //                       margin:0px;
    //                       font-family: Arial, Helvetica, sans-serif;
    //                       border: 1px solid black;
    //                       margin-bottom: 10px;
    //                     "
    //                   >
    //                     Description
    //                   </th>

    //                   <th
    //                     style="
    //                       font-size: 16px;
    //                       font-weight: bold;
    //                       margin:0px;
    //                       font-family: Arial, Helvetica, sans-serif;
    //                       border: 1px solid black;
    //                       margin-bottom: 10px;
    //                     "
    //                   >
    //                     Quantity
    //                   </th>

    //                   <th
    //                     style="
    //                       font-size: 16px;
    //                       font-weight: bold;
    //                       margin:0px;
    //                       font-family: Arial, Helvetica, sans-serif;
    //                       border: 1px solid black;
    //                       margin-bottom: 10px;
    //                     "
    //                   >
    //                     Price
    //                   </th>

    //                   <th
    //                     style="
    //                       font-size: 16px;
    //                       font-weight: bold;
    //                       font-family: Arial, Helvetica, sans-serif;
    //                       border: 1px solid black;
    //                       margin-bottom: 10px;
    //                     "
    //                   >
    //                     Discount
    //                   </th>
    //                   <th
    //                     style="
    //                       font-size: 16px;
    //                       font-weight: bold;
    //                       margin:0px;
    //                       font-family: Arial, Helvetica, sans-serif;
    //                       border: 1px solid black;
    //                       margin-bottom: 10px;
    //                     "
    //                   >
    //                     Amount
    //                   </th>
    //                 </tr>
    //               </thead>


    //               ${returnInvoice.productDetails &&
    //         returnInvoice.productDetails.length > 0 &&
    //         returnInvoice.productDetails.map(
    //             (e, i) =>
    //                 `<tbody>
    //                 <tr>

    //                 <td
    //                 style="
    //                   font-size: 16px;
    //                   border-top: 0px solid black;
    //                   border-right: 1px solid black;
    //                   border-left: 1px solid black;
    //                   border-bottom:0px;
    //                   width:100px;
    //                   font-family: Arial, Helvetica, sans-serif;

    //                 "
    //               >
    //                 -1
    //               </td>

    //                 <td
    //                 style="
    //                   font-size: 16px;
    //                   border-top: 0px solid black;
    //                   border-right: 1px solid black;
    //                   border-left: 1px solid black;
    //                   border-bottom:0px;
    //                   width:150px;
    //                   font-family: Arial, Helvetica, sans-serif;
    //                   "
    //               >
    //                 ${e.barcode}
    //               </td>
    //                 <td
    //                     colspan="2"
    //                     style="
    //                       font-size: 16px;
    //                       border-top: 0px solid black;
    //                       border-right: 1px solid black;
    //                       border-left: 1px solid black;
    //                       border-bottom:0px;
    //                       width:250px;
    //                       font-family: Arial, Helvetica, sans-serif;

    //                     "
    //                   >
    //                     ${e.productName}
    //                   </td>

    //                   <td
    //                     style="
    //                       font-size: 16px;
    //                       border-top: 0px solid black;
    //                       border-right: 1px solid black;
    //                       border-left: 1px solid black;
    //                       border-bottom:0px;
    //                       width:100px;
    //                       font-family: Arial, Helvetica, sans-serif;

    //                     "
    //                   >
    //                     -${e.saleQty}
    //                   </td>


    //                   <td
    //                     style="
    //                       font-size: 16px;
    //                       border-top: 0px solid black;
    //                       border-right: 1px solid black;
    //                       border-left: 1px solid black;
    //                       border-bottom:0px;
    //                       width:100px;
    //                       font-family: Arial, Helvetica, sans-serif;

    //                     "
    //                   >
    //                   £-${returnInvoice?.customerDetails[0]?.priceLevel[0]?.id == 1
    //                     ? e.trade_price
    //                     : returnInvoice?.customerDetails[0]?.priceLevel[0]?.id ==
    //                         2
    //                         ? e.warehouse_price
    //                         : e.retail_price
    //                 }
    //                   </td>

    //                   <td
    //                     style="

    //                     border-top: 0px solid black;
    //                     border-right: 1px solid black;
    //                     border-left: 1px solid black;
    //                     border-bottom:0px;
    //                     width:100px;
    //                     font-size: 16px;
    //                     font-family: Arial, Helvetica, sans-serif;

    //                     "
    //                   >
    //                   £${e.discountPrice
    //                     ? (returnInvoice?.customerDetails[0]?.priceLevel[0]?.id ==
    //                         1
    //                         ? e.trade_price
    //                         : returnInvoice?.customerDetails[0]?.priceLevel[0]
    //                             ?.id == 2
    //                             ? e.warehouse_price
    //                             : e.retail_price) - e.discountPrice
    //                     : 0
    //                 }
    //                   </td>

    //                   <td
    //                     style="

    //                     border-top: 0px solid black;
    //                     border-right: 1px solid black;
    //                     border-left: 1px solid black;
    //                     border-bottom:0px;
    //                     width:100px;
    //                       font-size: 16px;
    //                       font-family: Arial, Helvetica, sans-serif;

    //                     "
    //                   >
    //                   £-${e.discountPrice
    //                     ? e.discountPrice * e.saleQty
    //                     : (returnInvoice?.customerDetails[0]?.priceLevel[0]?.id ==
    //                         1
    //                         ? e.trade_price
    //                         : returnInvoice?.customerDetails[0]?.priceLevel[0]
    //                             ?.id == 2
    //                             ? e.warehouse_price
    //                             : e.retail_price) * e.saleQty
    //                 }
    //                   </td>
    //                 </tr>
    //                 </tbody>`
    //         )
    //         }

    //               <tfoot>
    //                 <tr>

    //                 <td
    //                      style="
    //                       font-size: 20px;
    //                       font-weight: bold;
    //                       text-align : center;
    //                       border: 1px solid black;
    //                       font-family: Arial, Helvetica, sans-serif;
    //                     "
    //                   >
    //                     -${returnInvoice?.productDetails?.length}
    //                   </td>

    //                 <td
    //                   colspan="3"
    //                     style="
    //                       font-size: 20px;
    //                       font-weight: bold;
    //                       text-align : center;
    //                       border: 1px solid black;
    //                       font-family: Arial, Helvetica, sans-serif;
    //                     "
    //                   >
    //                     Subtotal
    //                   </td>
    //                   <td
    //                     style="
    //                       font-size: 20px;
    //                       font-weight: bold;
    //                       text-align : center;
    //                       border: 1px solid black;
    //                       font-family: Arial, Helvetica, sans-serif;

    //                     "
    //                   >
    //                     (${returnInvoice.productDetails.reduce(
    //             (previous, current) => {
    //                 return previous + current.saleQty;
    //             },
    //             0
    //         )})
    //                   </td>
    //                   <td
    //                     style="
    //                       font-size: 20px;
    //                       font-weight: bold;
    //                       font-family: Arial, Helvetica, sans-serif;
    //                       border: 1px solid black;
    //                     "
    //                   ></td>
    //                   <td
    //                     style="
    //                       font-size: 20px;
    //                       font-weight: bold;
    //                       text-align : center;
    //                       font-family: Arial, Helvetica, sans-serif;
    //                       border-top: 1px solid black;
    //                     "
    //                   >
    //                   £${returnInvoice.discount ?? 0}

    //                   </td>
    //                   <td
    //                     style="
    //                       font-size: 20px;
    //                       font-weight: bold;
    //                       text-align : center;
    //                       font-family: Arial, Helvetica, sans-serif;
    //                       border: 1px solid black;
    //                     "
    //                   >
    //                   £-${returnInvoice.vatAmount
    //             ? returnInvoice.total - returnInvoice.discount
    //             : returnInvoice.subtotal
    //         }
    //                   </td>
    //                 </tr>

    //                 ${returnInvoice.vatAmount
    //             ? `
    //                 <tr>
    //                   <td
    //                     colspan="7"
    //                     style="
    //                       font-size: 20px;
    //                       font-weight: bold;
    //                       text-align : center;
    //                       border: 1px solid black;
    //                       font-family: Arial, Helvetica, sans-serif;
    //                     "
    //                   >
    //                     Vat
    //                   </td>
    //                   <td
    //                     style="
    //                       font-size: 20px;
    //                       font-weight: bold;
    //                       text-align : center;
    //                       border: 1px solid black;
    //                       font-family: Arial, Helvetica, sans-serif;
    //                     "
    //                   >
    //                   £ -${returnInvoice.vatAmount}
    //                   </td>
    //                 </tr>`
    //             : ""
    //         }

    //                 <tr>
    //                   <td
    //                     colspan="7"
    //                     style="
    //                       font-size: 20px;
    //                       font-weight: bold;
    //                       color: red;
    //                       text-align : center;
    //                       font-family: Arial, Helvetica, sans-serif;
    //                       border: 1px solid black; "
    //                   >
    //                     Total
    //                   </td>
    //                   <td
    //                     style="
    //                       font-size: 20px;
    //                       font-weight: bold;
    //                       text-align : center;
    //                       border: 1px solid black;
    //                       font-family: Arial, Helvetica, sans-serif;
    //                       color: red;
    //                     "
    //                   >
    //                   £ -${returnInvoice.subtotal}
    //                   </td>
    //                 </tr>
    //               </tfoot>
    //             </table>

    //           </div>
    //         </body>
    //       </html>
    //       `
    //     );
    //     // printWindow.document.close();
    //     setTimeout(() => {
    //         printWindow.print();
    //     }, 100);

    //     printWindow.onafterprint = function () {
    //         try {
    //             dispatch(updateDamageItemCustomer(""));
    //             dispatch(updateSaleReturnCart([]));
    //             setSelectedInvoice("");
    //             setSearchCustomers([]);
    //             setSearchInvoices([]);
    //             setSearchProducts([]);
    //             setDeductCreditBalance({
    //                 ...deductCreditBalance,
    //                 selected: false,
    //             });
    //             setCustomerInvoices("");
    //             setSearchCustomerInvoice("");
    //             setConfirmModalVisible(false);
    //             setSelectedSearch("customers");
    //             setSuccessfulTransactionModal(false);
    //             setReturnInvoice("");
    //             setReturnModal(false);
    //         } catch (error) {
    //             console.error("Error in clearing data:", error);
    //         } finally {
    //             // Close the print window
    //             printWindow.close();
    //         }
    //     };
    // }
    const emailInvoice = async () => {



        let { customerDetails } = returnInvoice

        let dataToSend = {
            printInvoiceData: returnInvoice
        }

        setEmailLoading(true);

        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        axios
            .post(`${Base_Uri}pdfSendToCustomer`, dataToSend, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Include other headers as needed
                },
            })
            .then((res) => {
                let data = res.data;

                setEmailLoading(false);

                if (data && data.status) {
                    alert("Invoice Send Succesfully");
                }
                cancelModal();
            })
            .catch((error) => {
                console.log(error, "errorrrr");
                setEmailLoading(false);
                alert("There is a server error in email sending");
            });
    };
    const cancelModal = () => {


        dispatch(updateDamageItemCustomer(""))
        dispatch(updateSaleReturnCart([]))
        setSelectedInvoice("")
        setLessAmount(false)
        setPdfDownloadView(false)
        setSearchCustomers([])
        setDeductCreditBalance({
            ...deductCreditBalance,
            selected: false
        })
        setSearchInvoices([])
        setSearchProducts([])
        setConfirmModalVisible(false)
        setCustomerInvoices("")
        setSearchCustomerInvoice("")
        dispatch(updateAllReturnProducts([]))
        setSelectedSearch("customers")
        setSuccessfulTransactionModal(false)
        setReturnModal(false)

    }
    const handleGoBack = () => {
        if (selectedSearch == "customers" || selectedSearch == "products") {
            setSelectedInvoice("");
        }
        else {
            setSelectedInvoice("");
            setCustomerInvoices("");
            setSearchCustomerInvoice("");
            dispatch(updateDamageItemCustomer(""));

        }
    };
    const changeDamageQty = (qty, ind, type) => {
        let { productDetails } = selectedInvoice;


        let productSelected = productDetails?.filter((e, i) => i == ind)


        productSelected = productSelected?.[0]


        let remainingQtyToReturn = (productSelected.saleQty) - (productSelected?.returnQty ? productSelected?.returnQty : 0)

        if (Number(qty) > Number(remainingQtyToReturn)) {
            alert(`Not enough qty to return the customer has already return ${productSelected?.returnQty} qty`)
            return
        }

        let { returnProductDetails } = selectedInvoice;

        if (type == "exchange") {
            returnProductDetails = returnProductDetails.map((e, i) => {
                if (ind == i) {
                    return {
                        ...e,
                        DamageQty: Number(qty),
                    };
                } else {
                    return e;
                }
            });

            setSelectedInvoice({
                ...selectedInvoice,
                returnProductDetails: productDetails,
            });

            return;
        }

        productDetails = productDetails.map((e, i) => {
            if (ind == i) {
                return {
                    ...e,
                    DamageQty: Number(qty),
                };
            } else {
                return e;
            }
        });

        setSelectedInvoice({
            ...selectedInvoice,
            productDetails: productDetails,
        });
    };

    const handleSelectReturnOption = (option) => {

        if (option == "credit") {

            setDeductCreditBalance({ ...deductCreditBalance, selected: !deductCreditBalance.selected })
            setRefundCash({ ...refundCash, selected: false })
            setExchangeProduct({ ...exchangeProduct, selected: false })
        }

        else if (option == "cash") {

            setDeductCreditBalance({ ...deductCreditBalance, selected: false })
            setRefundCash({ ...refundCash, selected: !refundCash?.selected })
            setExchangeProduct({ ...exchangeProduct, selected: false })
        }

        else if (option == "card") {

            setDeductCreditBalance({ ...deductCreditBalance, selected: false })
            setRefundCash({ ...refundCash, selected: false })
            setRefundCard({ ...refundCard, selected: !refundCard?.selected })
            setExchangeProduct({ ...exchangeProduct, selected: false })
        }

        else {

            setDeductCreditBalance({ ...deductCreditBalance, selected: false })
            setRefundCash({ ...refundCash, selected: false })
            setExchangeProduct({ ...exchangeProduct, selected: !exchangeProduct?.selected })

        }


    }


    const handleShowDescription = (ind) => {
        setSearchProducts(
            searchProducts &&
            searchProducts?.length > 0 &&
            searchProducts?.map((e, i) => {
                if (ind == i) {
                    return {
                        ...e,
                        showDescription: !e?.showDescription,
                    };
                } else {
                    return e;
                }
            })
        );
    };

    const handleOpenConfimModal = () => {


        if (!damageQty) {
            alert("Kindly select Damage Products")
            return
        }

        let selectedProducts = selectedInvoice?.productDetails?.filter((e, i) => e?.selected)


        if (selectedProducts && selectedProducts?.length > 0 && selectedProducts?.some((e, i) => e?.selected && !e?.returnStatus)) {
            alert("Kindly Enter Status")
            return
        }

        let allReturns = allReturnProducts && allReturnProducts?.length > 0 ? [...allReturnProducts] : []



        allReturns = allReturns && allReturns?.length > 0 && allReturns?.filter((e) => {

            return e?.returnInvoiceRef !== selectedInvoice?.invoiceNumber

        })

        allReturns = allReturns && allReturns?.length > 0 ? allReturns : []

        let returnData = {
            productDetails: selectedProducts,
            returnInvoiceRef: selectedInvoice?.invoiceNumber,
            total: damagetotal,
            customerDetails: selectedInvoice?.customerDetails[0],
            discount: damageDiscount,
            subtotal: damageSubtotal,
            costPrice: damageCost,
            totalItems: damageItems,
            totalQty: damageQty,
            invoiceRefDate: selectedInvoice?.saleDate,
            vatAmount: damageVat,
            employeeDetails: userData,
            employeeId: userData?.id,
        }


        dispatch(updateAllReturnProducts([...allReturns, returnData]))
        notify(allReturnProducts && allReturnProducts?.length > 0 && allReturnProducts?.some((e, i) => e?.returnInvoiceRef == selectedInvoice?.invoiceNumber) ? "Successfully Overwrite" : "Successfully added in return")

        // const modal = document.getElementById("returnModal"); // Replace "your-modal-id" with the actual ID of your modal
        // modal.scrollTop = 0;

    }

    const changeProductStatus = (status, product) => {

        let { productDetails } = selectedInvoice

        productDetails = productDetails && productDetails?.length > 0 && productDetails?.map((e, i) => {


            if (product?._id == e?._id) {
                return {
                    ...e,
                    returnStatus: status
                }
            } else {
                return e
            }



        })

        setSelectedInvoice({ ...selectedInvoice, productDetails: productDetails })

        // dispatch(updateCart(cartData))




    }


    const handleShowIMEI = (data) => {

        console.log(data, "dataaaa")


    }


    return (



        <div>
            <Modal
                open={open}

                // onClose={cancelModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {(invoiceLoading || exchangeLoading) ? (
                    <Box sx={[style, { containerStyle, justifyContent: "center", alignItems: "center", display: "flex" }]}>
                        <RingLoader
                            loading={invoiceLoading}
                            size={50}
                            color={Colors.black}
                        />
                    </Box>
                ) :

                    pdfDownloadView ? (
                        <Box
                            style={{
                                wodth: "100%",
                                height: "100%",
                                // border: "1px solid red",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >

                            <PDFViewer width={850} height={700} style={{ marginTop: 20 }}>
                                <MyDocument />
                            </PDFViewer>
                            <CloseOutlinedIcon fontSize="large" style={{ color: Colors.black, marginTop: "20px", cursor: "pointer", }} onClick={() => cancelModal()} />

                        </Box>
                    ) : <Box sx={[style, { containerStyle }]} >


                        <ToastContainer />

                        <Box
                            onClick={() => cancelModal()}
                            style={{ position: "absolute", top: 10, right: 10 }}
                        >
                            <CloseIcon fontSize="large" />
                        </Box>


                        {selectedInvoice && customerInvoice && selectedSearch !== "checkout" && (
                            <ArrowBackIosNewOutlined style={{ position: "absolute", top: 20 }} onClick={() => handleGoBack()} />
                        )}


                        <Typography
                            sx={{
                                fontSize: "20px",
                                color: Colors.button,
                                fontWeight: "bold",
                                fontFamily: "Poppins",
                                textAlign: "center",
                                ...headingTextStyle,
                            }}
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            {heading}
                        </Typography>

                        <Grid md={12} xs={12} sm={12}>
                            <Box
                                sx={{
                                    width: "100%",
                                    marginTop: { xs: "10px", sm: "10px", md: "10px" },
                                    border: `1px solid ${Colors.button}`,
                                    borderRadius: "5px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "5px 10px 5px 10px",
                                    margin: 0,
                                }}
                            >


                                <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    required
                                    value={text}
                                    fullWidth
                                    inputRef={textInputRef}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    InputProps={{
                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                        disableUnderline: true,
                                        fontSize: "12px",

                                        // <== added this
                                        // fontSize: "2px"
                                    }}
                                    // onChange={handleSearchProducts}
                                    placeholder={
                                        selectedSearch == "products"
                                            ? "Search Products"
                                            : selectedSearch == "invoices"
                                                ? "Search Invoice"
                                                : "Search Customer"
                                    }
                                    sx={{
                                        width: "90%",
                                        fontSize: "12px",
                                        padding: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: "bold",
                                        margin: 0,
                                    }}
                                />

                                <img
                                    src={require("../Images/search.png")}
                                    style={{ height: "20px" }}
                                />
                            </Box>

                            <Box>
                                {searchInvoices && searchInvoices.length > 0
                                    ? searchInvoices.map((e, i) => {
                                        return (
                                            <Button
                                                onClick={() => handleSelectInvoice(e)}
                                                sx={{
                                                    width: "100%",
                                                    mt: 1,
                                                    backgroundColor: Colors.white,
                                                    p: "0px 10px",
                                                    fontFamily: "Poppins",
                                                    fontWeight: "bold",
                                                    borderRadius: 1,
                                                    textAlign: "left",
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    border: `1px solid ${Colors.border}`,
                                                    "&:hover": {
                                                        color: "#5DC002", // Set your desired hover color
                                                    },
                                                }}
                                            >
                                                <p
                                                    style={{ fontFamily: "Poppins", textAlign: "left" }}
                                                >
                                                    {e.invoiceNumber}
                                                </p>
                                            </Button>
                                        );
                                    })
                                    : searchCustomers && searchCustomers.length > 0
                                        ? searchCustomers.map((e, i) => {
                                            return (
                                                <Button
                                                    onClick={() => handleSelectCustomer(e)}
                                                    sx={{
                                                        width: "100%",
                                                        mt: 1,
                                                        backgroundColor: Colors.white,
                                                        p: "0px 10px",
                                                        fontFamily: "Poppins",
                                                        fontWeight: "bold",
                                                        borderRadius: 1,
                                                        textAlign: "left",
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        border: `1px solid ${Colors.border}`,
                                                    }}
                                                >
                                                    <p
                                                        style={{ fontFamily: "Poppins", textAlign: "left" }}
                                                    >
                                                        {e.businessName}
                                                    </p>
                                                </Button>
                                            );
                                        })
                                        :
                                        searchProducts &&
                                        searchProducts.length > 0 &&
                                        searchProducts.map((e, i) => {



                                            return (
                                                <Button
                                                    onClick={() => handleChooseProducts(e)}

                                                    sx={{
                                                        width: "100%",
                                                        // mt: 1,
                                                        backgroundColor: Colors.white,
                                                        p: "10px 10px",
                                                        fontFamily: "Poppins",
                                                        fontWeight: "bold",
                                                        cursor: "pointer",
                                                        borderRadius: 1,
                                                        textAlign: "left",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "flex-start",
                                                        border: `1px solid ${Colors.border}`,
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontFamily: "Poppins",
                                                            textAlign: "left",
                                                            marginBottom: 0,
                                                            color: Colors.button,
                                                        }}
                                                    >
                                                        {e.product_name}
                                                        <br />
                                                        {/* Available qty: {e.qty} */}
                                                    </p>

                                                </Button>
                                            );
                                        })}
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
                                <CustomButton
                                    text="Customers"
                                    onClick={() => setSelectedSearch("customers")}
                                    style={{
                                        width: "120px",
                                        borderRadius: "5px",

                                        backgroundColor:
                                            selectedSearch == "customers"
                                                ? Colors.button
                                                : Colors.white,
                                        color:
                                            selectedSearch == "customers"
                                                ? Colors.white
                                                : Colors.button,
                                    }}
                                />

                                <CustomButton
                                    text="Products"
                                    onClick={() => setSelectedSearch("products")}
                                    style={{
                                        width: "120px",
                                        ml: 1,
                                        borderRadius: "5px",
                                        backgroundColor:
                                            selectedSearch == "products"
                                                ? Colors.button
                                                : Colors.white,
                                        color:
                                            selectedSearch == "products"
                                                ? Colors.white
                                                : Colors.button,
                                    }}
                                />

                                <CustomButton
                                    text="IMEI"
                                    onClick={() => setSelectedSearch("imei")}
                                    style={{
                                        width: "120px",
                                        ml: 1,
                                        borderRadius: "5px",
                                        backgroundColor:
                                            selectedSearch == "imei"
                                                ? Colors.button
                                                : Colors.white,
                                        color:
                                            selectedSearch == "imei"
                                                ? Colors.white
                                                : Colors.button,
                                    }}
                                />

                                <CustomButton
                                    text="Invoices"
                                    onClick={() => setSelectedSearch("invoices")}
                                    style={{
                                        width: "120px",
                                        borderRadius: "5px",
                                        ml: 1,
                                        backgroundColor:
                                            selectedSearch == "invoices"
                                                ? Colors.button
                                                : Colors.white,
                                        color:
                                            selectedSearch == "invoices"
                                                ? Colors.white
                                                : Colors.button,
                                    }}
                                />

                                {allReturnProducts && allReturnProducts?.length > 0 && selectedCustomer && <CustomButton
                                    text="Checkout"
                                    onClick={() => setSelectedSearch("checkout")}
                                    style={{
                                        width: "120px",
                                        borderRadius: "5px",
                                        ml: 1,
                                        backgroundColor:
                                            selectedSearch == "checkout"
                                                ? Colors.button
                                                : Colors.white,
                                        color:
                                            selectedSearch == "checkout"
                                                ? Colors.white
                                                : Colors.button,
                                    }}
                                />}


                            </Box>

                            {selectedInvoice ? (
                                <Box
                                    sx={{
                                        width: "100%",
                                        backgroundColor: Colors.white,
                                        marginTop: "10px",

                                        borderRadius: "5px",
                                    }}
                                >
                                    <Grid xs={12}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                fontFamily: "Poppins",
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                            }}
                                        >

                                            <Box style={{ display: "flex", flexDirection: "row" }}>
                                                {" "}
                                                Account No :{" "}
                                                <span style={{ marginLeft: 5 }}>
                                                    {" "}
                                                    {selectedInvoice?.customerDetails?.[0]?.accountNo}
                                                </span>
                                            </Box>

                                            <Box style={{ display: "flex", flexDirection: "row" }}>
                                                {" "}
                                                Business Name :{" "}
                                                <span style={{ marginLeft: 5 }}>
                                                    {" "}
                                                    {selectedInvoice?.customerDetails?.[0]?.businessName}
                                                </span>
                                            </Box>

                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                }}
                                            >
                                                <Box>
                                                    {" "}
                                                    Customer Name :{" "}
                                                    <span style={{ marginLeft: 5 }}>
                                                        {" "}
                                                        {selectedInvoice?.customerDetails[0].customerName}
                                                    </span>
                                                </Box>

                                                <Box>
                                                    Payment Method :{" "}
                                                    <span
                                                        style={{
                                                            marginLeft: 5,
                                                            color: Array.isArray(selectedInvoice?.paymentMethod) ? selectedInvoice?.paymentMethod?.some((e, i) => typeof e == "string" && e.toLowerCase() == "credit") ? Colors.red : Colors.green :
                                                                selectedInvoice?.paymentMethod?.toLowerCase() ==
                                                                    "credit"
                                                                    ? Colors.red
                                                                    : Colors.green,
                                                        }}
                                                    >
                                                        {" "}
                                                        {Array.isArray(selectedInvoice?.paymentMethod) ? selectedInvoice?.paymentMethod?.map((e, i) => e).join(",") : selectedInvoice?.paymentMethod}
                                                    </span>
                                                </Box>
                                            </Box>

                                            <Box
                                                style={{ display: "flex", flexDirection: "row", m: 0 }}
                                            >
                                                {" "}
                                                Customer Email :{" "}
                                                <span style={{ marginLeft: 5 }}>
                                                    {" "}
                                                    {selectedInvoice?.customerDetails[0].email}
                                                </span>
                                            </Box>

                                            <Box
                                                style={{ display: "flex", flexDirection: "row", m: 0 }}
                                            >
                                                {" "}
                                                Invoice Balance :{" "}
                                                <span style={{ marginLeft: 5 }}>
                                                    {" "}
                                                    £
                                                    {selectedCustomer?.creditBalance
                                                        ? Number(selectedCustomer?.creditBalance).toFixed(2)
                                                        : 0}
                                                </span>
                                            </Box>

                                            <Box
                                                style={{ display: "flex", flexDirection: "row", m: 0 }}
                                            >
                                                {" "}
                                                Quotation Balance :{" "}
                                                <span style={{ marginLeft: 5 }}>
                                                    {" "}
                                                    £
                                                    {selectedCustomer?.quotationBalance
                                                        ? Number(
                                                            selectedCustomer?.quotationBalance
                                                        ).toFixed(2)
                                                        : 0}
                                                </span>
                                            </Box>

                                            {selectedCustomer?.comment && (
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        m: 0,
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {" "}
                                                    Comment:{" "}
                                                    <span
                                                        style={{
                                                            marginLeft: 5,
                                                            fontSize: "12px",
                                                            fontWeight: "800",
                                                            fontFamily: "Poppins",
                                                            textAlign: "left",
                                                            color: "red",
                                                        }}
                                                    >
                                                        {" "}
                                                        {selectedCustomer?.comment}
                                                    </span>
                                                </Box>
                                            )}

                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    m: 0,
                                                    alignItems: "center",
                                                    textAlign: "left",
                                                }}
                                            >
                                                {" "}
                                                Delivery Address:{" "}
                                                <span
                                                    style={{
                                                        marginLeft: 5,
                                                        fontSize: "12px",
                                                        fontWeight: "800",
                                                        fontFamily: "Poppins",
                                                        textAlign: "left",
                                                        color: "purple",
                                                    }}
                                                >
                                                    {" "}
                                                    {selectedCustomer?.deliveryAddress
                                                        ? selectedCustomer?.deliveryAddress
                                                        : selectedCustomer?.address}
                                                </span>
                                            </Box>

                                            {selectedInvoice?.exchangeDate ? (
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        m: 0,
                                                        alignItems: "center",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {" "}
                                                    Date:
                                                    <span
                                                        style={{
                                                            marginLeft: 5,
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            fontFamily: "Poppins",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {" "}
                                                        {new Date(
                                                            selectedInvoice.exchangeDate
                                                        ).toLocaleDateString("en-GB", {
                                                            day: "2-digit",
                                                            month: "2-digit",
                                                            year: "numeric",
                                                        })}
                                                    </span>
                                                </Box>
                                            ) : (
                                                selectedInvoice?.saleDate && (
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            m: 0,
                                                            alignItems: "center",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {" "}
                                                        Date:
                                                        <span
                                                            style={{
                                                                marginLeft: 5,
                                                                fontSize: "14px",
                                                                fontWeight: "400",
                                                                fontFamily: "Poppins",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {" "}
                                                            {new Date(
                                                                selectedInvoice.saleDate
                                                            ).toLocaleDateString("en-GB", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric",
                                                            })}
                                                        </span>
                                                    </Box>
                                                )
                                            )}

                                            {selectedInvoice?.exchangeDate ? (
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        m: 0,
                                                        alignItems: "center",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {" "}
                                                    Time:
                                                    <span
                                                        style={{
                                                            marginLeft: 5,
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            fontFamily: "Poppins",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {" "}
                                                        {new Date(
                                                            selectedInvoice.exchangeDate
                                                        ).toLocaleTimeString()}
                                                    </span>
                                                </Box>
                                            ) : (
                                                selectedInvoice?.saleDate && (
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            m: 0,
                                                            alignItems: "center",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {" "}
                                                        Time:
                                                        <span
                                                            style={{
                                                                marginLeft: 5,
                                                                fontSize: "14px",
                                                                fontWeight: "400",
                                                                fontFamily: "Poppins",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {" "}
                                                            {new Date(
                                                                selectedInvoice.saleDate
                                                            ).toLocaleTimeString()}
                                                        </span>
                                                    </Box>
                                                )
                                            )}
                                        </Box>
                                    </Grid>
                                </Box>
                            ) :
                                searchCustomerInvoice && searchCustomerInvoice.length > 0 ? (
                                    <Box
                                        sx={{
                                            width: "100%",
                                            backgroundColor: Colors.white,
                                            marginTop: "10px",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        <Grid xs={12}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-start",
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                <Box style={{ display: "flex", flexDirection: "row" }}>
                                                    {" "}
                                                    Account No:{" "}
                                                    <span style={{ marginLeft: 5 }}>
                                                        {" "}
                                                        {selectedInvoice?.customerDetails?.[0]?.accountNo}
                                                    </span>
                                                </Box>
                                                <Box style={{ display: "flex", flexDirection: "row" }}>
                                                    {" "}
                                                    Business Name :{" "}
                                                    <span style={{ marginLeft: 5 }}>
                                                        {" "}
                                                        {selectedInvoice?.customerDetails?.[0]?.businessName}
                                                    </span>
                                                </Box>
                                                <Box style={{ display: "flex", flexDirection: "row" }}>
                                                    {" "}
                                                    Customer Name :{" "}
                                                    <span style={{ marginLeft: 5 }}>
                                                        {" "}
                                                        {selectedCustomer.customerName}
                                                    </span>
                                                </Box>

                                                <Box
                                                    style={{ display: "flex", flexDirection: "row", m: 0 }}
                                                >
                                                    {" "}
                                                    Customer Email :{" "}
                                                    <span style={{ marginLeft: 5 }}>
                                                        {" "}
                                                        {selectedCustomer.email}
                                                    </span>
                                                </Box>

                                                <Box
                                                    style={{ display: "flex", flexDirection: "row", m: 0 }}
                                                >
                                                    {" "}
                                                    Invoice Balance :{" "}
                                                    <span style={{ marginLeft: 5 }}>
                                                        {" "}
                                                        £
                                                        {selectedCustomer?.creditBalance
                                                            ? Number(selectedCustomer?.creditBalance).toFixed(2)
                                                            : 0}
                                                    </span>
                                                </Box>

                                                <Box
                                                    style={{ display: "flex", flexDirection: "row", m: 0 }}
                                                >
                                                    {" "}
                                                    Quotation Balance :{" "}
                                                    <span style={{ marginLeft: 5 }}>
                                                        {" "}
                                                        £
                                                        {selectedCustomer?.quotationBalance
                                                            ? Number(
                                                                selectedCustomer?.quotationBalance
                                                            ).toFixed(2)
                                                            : 0}
                                                    </span>
                                                </Box>

                                                {selectedCustomer?.comment && (
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            m: 0,
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {" "}
                                                        Comment:{" "}
                                                        <span
                                                            style={{
                                                                marginLeft: 5,
                                                                fontSize: "12px",
                                                                fontWeight: "800",
                                                                fontFamily: "Poppins",
                                                                textAlign: "left",
                                                                color: "red",
                                                            }}
                                                        >
                                                            {" "}
                                                            {selectedCustomer?.comment}
                                                        </span>
                                                    </Box>
                                                )}

                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        m: 0,
                                                        alignItems: "center",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {" "}
                                                    Delivery Address:{" "}
                                                    <span
                                                        style={{
                                                            marginLeft: 5,
                                                            fontSize: "12px",
                                                            fontWeight: "800",
                                                            fontFamily: "Poppins",
                                                            textAlign: "left",
                                                            color: "purple",
                                                        }}
                                                    >
                                                        {" "}
                                                        {selectedCustomer?.deliveryAddress
                                                            ? selectedCustomer?.deliveryAddress
                                                            : selectedCustomer?.address}
                                                    </span>
                                                </Box>

                                                {searchCustomerInvoice && selectedSearch?.toLowerCase() !== "checkout" &&
                                                    searchCustomerInvoice.length > 0 &&
                                                    searchCustomerInvoice?.map((invoice, ind) => {


                                                        return (
                                                            <Button
                                                                onClick={() => setSelectedInvoice(invoice)}
                                                                sx={{
                                                                    borderBottom: `1px solid ${Colors.border}`,
                                                                    width: "100%",
                                                                    alignItems: "center",
                                                                    p: 0,
                                                                    m: 0,
                                                                }}
                                                            >
                                                                <Grid
                                                                    md={12}
                                                                    xs={12}
                                                                    sm={12}
                                                                    container
                                                                    sx={{ mt: 1 }}
                                                                >
                                                                    <Grid item xs={6} sx={{ p: 1 }}>
                                                                        <Box sx={{ width: "100%" }}>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    fontFamily: "Poppins",
                                                                                    color: Colors.black,
                                                                                    fontWeight: "bold",
                                                                                    textAlign: "left",
                                                                                }}
                                                                            >
                                                                                Invoice No:{" "}
                                                                                <span>{invoice?.invoiceNumber} </span>
                                                                            </Typography>

                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "12px",
                                                                                    fontFamily: "Poppins",
                                                                                    color: Colors.text,
                                                                                    fontWeight: "500",
                                                                                    textAlign: "left",
                                                                                }}
                                                                            >
                                                                                Date/Time:{" "}
                                                                                <span>
                                                                                    {" "}
                                                                                    {invoice?.exchangeDate
                                                                                        ? new Date(
                                                                                            invoice?.exchangeDate
                                                                                        ).toLocaleDateString("en-GB", {
                                                                                            day: "2-digit",
                                                                                            month: "2-digit",
                                                                                            year: "numeric",
                                                                                        })
                                                                                        : new Date(
                                                                                            invoice?.saleDate
                                                                                        ).toLocaleDateString("en-GB", {
                                                                                            day: "2-digit",
                                                                                            month: "2-digit",
                                                                                            year: "numeric",
                                                                                        })}{" "}
                                                                                    {invoice?.exchangeDate
                                                                                        ? new Date(
                                                                                            invoice?.exchangeDate
                                                                                        ).toLocaleTimeString()
                                                                                        : new Date(
                                                                                            invoice?.saleDate
                                                                                        ).toLocaleTimeString()}{" "}
                                                                                </span>
                                                                            </Typography>

                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "12px",
                                                                                    fontFamily: "Poppins",
                                                                                    color: Colors.text,
                                                                                    fontWeight: "500",
                                                                                    textAlign: "left",
                                                                                }}
                                                                            >
                                                                                product Name:{" "}
                                                                                <span>
                                                                                    {invoice.productDetails
                                                                                        .filter((e) => e.selected)
                                                                                        .map((selectedProduct, index) => (
                                                                                            <span key={index}>
                                                                                                {selectedProduct.productName}
                                                                                            </span>
                                                                                        ))}
                                                                                </span>
                                                                            </Typography>

                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "12px",
                                                                                    fontFamily: "Poppins",
                                                                                    color: Colors.text,
                                                                                    fontWeight: "500",
                                                                                    textAlign: "left",
                                                                                }}
                                                                            >
                                                                                Quantity:{" "}
                                                                                <span>
                                                                                    {invoice.productDetails
                                                                                        .filter((e) => e.selected)
                                                                                        .map((selectedProduct, index) => (
                                                                                            <span key={index}>
                                                                                                {selectedProduct.saleQty}
                                                                                            </span>
                                                                                        ))}
                                                                                </span>
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={3}
                                                                        sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontFamily: "Poppins",
                                                                                fontWeight: "bold",

                                                                                color: Array.isArray(invoice?.paymentMethod) ? invoice?.paymentMethod?.some((e, i) => typeof e == "string" && e.toLowerCase() == "credit") ? Colors.red : Colors.green :
                                                                                    invoice?.paymentMethod?.toLowerCase() ==
                                                                                        "credit"
                                                                                        ? Colors.red
                                                                                        : Colors.green,
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {Array.isArray(invoice?.paymentMethod) ? invoice?.paymentMethod?.map((e, i) => e).join(",") : invoice?.paymentMethod}
                                                                        </Typography>
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={3}
                                                                        sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontFamily: "Poppins",
                                                                                color: Colors.black,
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            {Number(invoice.subtotal).toFixed(2)}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Button>
                                                        );
                                                    })}
                                            </Box>
                                        </Grid>
                                    </Box>
                                ) :
                                    customerInvoice ? (
                                        <Box
                                            sx={{
                                                width: "100%",
                                                backgroundColor: Colors.white,
                                                marginTop: "10px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Grid xs={12}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "flex-start",
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                    }}
                                                >

                                                    <Box style={{ display: "flex", flexDirection: "row" }}>
                                                        {" "}
                                                        Account No :{" "}
                                                        <span style={{ marginLeft: 5 }}>
                                                            {" "}
                                                            {selectedCustomer?.accountNo}
                                                        </span>
                                                    </Box>
                                                    <Box style={{ display: "flex", flexDirection: "row" }}>
                                                        {" "}
                                                        Business Name :{" "}
                                                        <span style={{ marginLeft: 5 }}>
                                                            {" "}
                                                            {selectedCustomer?.businessName}
                                                        </span>
                                                    </Box>

                                                    <Box style={{ display: "flex", flexDirection: "row" }}>
                                                        {" "}
                                                        Customer Name :{" "}
                                                        <span style={{ marginLeft: 5 }}>
                                                            {" "}
                                                            {selectedCustomer.customerName}
                                                        </span>
                                                    </Box>

                                                    <Box
                                                        style={{ display: "flex", flexDirection: "row", m: 0 }}
                                                    >
                                                        {" "}
                                                        Customer Email :{" "}
                                                        <span style={{ marginLeft: 5 }}>
                                                            {" "}
                                                            {selectedCustomer.email}
                                                        </span>
                                                    </Box>

                                                    <Box
                                                        style={{ display: "flex", flexDirection: "row", m: 0 }}
                                                    >
                                                        {" "}
                                                        Invoice Balance :{" "}
                                                        <span style={{ marginLeft: 5 }}>
                                                            {" "}
                                                            £
                                                            {selectedCustomer?.creditBalance
                                                                ? Number(selectedCustomer?.creditBalance).toFixed(2)
                                                                : 0}
                                                        </span>
                                                    </Box>

                                                    <Box
                                                        style={{ display: "flex", flexDirection: "row", m: 0 }}
                                                    >
                                                        {" "}
                                                        Quotation Balance :{" "}
                                                        <span style={{ marginLeft: 5 }}>
                                                            {" "}
                                                            £
                                                            {selectedCustomer?.quotationBalance
                                                                ? Number(
                                                                    selectedCustomer?.quotationBalance
                                                                ).toFixed(2)
                                                                : 0}
                                                        </span>
                                                    </Box>

                                                    {selectedCustomer?.comment && (
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                m: 0,
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {" "}
                                                            Comment:{" "}
                                                            <span
                                                                style={{
                                                                    marginLeft: 5,
                                                                    fontSize: "12px",
                                                                    fontWeight: "800",
                                                                    fontFamily: "Poppins",
                                                                    textAlign: "left",
                                                                    color: "red",
                                                                }}
                                                            >
                                                                {" "}
                                                                {selectedCustomer?.comment}
                                                            </span>
                                                        </Box>
                                                    )}

                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            m: 0,
                                                            alignItems: "center",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {" "}
                                                        Delivery Address:{" "}
                                                        <span
                                                            style={{
                                                                marginLeft: 5,
                                                                fontSize: "12px",
                                                                fontWeight: "800",
                                                                fontFamily: "Poppins",
                                                                textAlign: "left",
                                                                color: "purple",
                                                            }}
                                                        >
                                                            {" "}
                                                            {selectedCustomer?.deliveryAddress
                                                                ? selectedCustomer?.deliveryAddress
                                                                : selectedCustomer?.address}
                                                        </span>
                                                    </Box>

                                                    {customerInvoice && selectedSearch?.toLowerCase() !== "checkout" &&
                                                        customerInvoice.length > 0 &&
                                                        customerInvoice?.map((invoice, ind) => {
                                                            return (
                                                                <Button
                                                                    onClick={() => setSelectedInvoice(invoice)}
                                                                    sx={{
                                                                        borderBottom: `1px solid ${Colors.border}`,
                                                                        width: "100%",
                                                                        alignItems: "center",
                                                                        p: 0,
                                                                        m: 0,
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        md={12}
                                                                        xs={12}
                                                                        sm={12}
                                                                        container
                                                                        sx={{ mt: 1 }}
                                                                    >
                                                                        <Grid item xs={6} sx={{ p: 1 }}>
                                                                            <Box sx={{ width: "100%" }}>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontFamily: "Poppins",
                                                                                        color: Colors.black,
                                                                                        fontWeight: "bold",
                                                                                        textAlign: "left",
                                                                                    }}
                                                                                >
                                                                                    {(invoice?.saleDate && invoice?.vatAmount) ? "Invoice No"
                                                                                        : (invoice?.saleDate && !invoice?.vatAmount) ? "Quotation No"
                                                                                            : (invoice?.exchangeDate && Number(Math.abs(invoice?.vatAmount))) ? "Exchange Invoice No" : "Exchange Quotation No"
                                                                                    }:{" "}
                                                                                    <span>{invoice?.invoiceNumber} </span>
                                                                                </Typography>

                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: "12px",
                                                                                        fontFamily: "Poppins",
                                                                                        color: Colors.text,
                                                                                        fontWeight: "500",
                                                                                        textAlign: "left",
                                                                                    }}
                                                                                >
                                                                                    Date/Time:{" "}
                                                                                    <span>
                                                                                        {invoice?.exchangeDate
                                                                                            ? new Date(
                                                                                                invoice?.exchangeDate
                                                                                            ).toLocaleDateString("en-GB", {
                                                                                                day: "2-digit",
                                                                                                month: "2-digit",
                                                                                                year: "numeric",
                                                                                            })
                                                                                            : new Date(
                                                                                                invoice?.saleDate
                                                                                            ).toLocaleDateString("en-GB", {
                                                                                                day: "2-digit",
                                                                                                month: "2-digit",
                                                                                                year: "numeric",
                                                                                            })}{" "}
                                                                                        {invoice?.exchangeDate
                                                                                            ? new Date(
                                                                                                invoice?.exchangeDate
                                                                                            ).toLocaleTimeString()
                                                                                            : new Date(
                                                                                                invoice?.saleDate
                                                                                            ).toLocaleTimeString()}{" "}
                                                                                    </span>
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={3}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    fontFamily: "Poppins",
                                                                                    fontWeight: "bold",

                                                                                    color: Array.isArray(invoice?.paymentMethod) ? invoice?.paymentMethod?.some((e, i) => typeof e == "string" && e.toLowerCase() == "credit") ? Colors.red : Colors.green :
                                                                                        invoice?.paymentMethod?.toLowerCase() ==
                                                                                            "credit"
                                                                                            ? Colors.red
                                                                                            : Colors.green,
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                {Array.isArray(invoice?.paymentMethod) ? invoice?.paymentMethod?.map((e, i) => e).join(",") : invoice?.paymentMethod}
                                                                            </Typography>
                                                                        </Grid>

                                                                        <Grid
                                                                            item
                                                                            xs={3}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    fontFamily: "Poppins",
                                                                                    color: Colors.black,
                                                                                    fontWeight: "bold",
                                                                                }}
                                                                            >
                                                                                {Number(invoice.subtotal).toFixed(2)}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Button>
                                                            );
                                                        })}
                                                </Box>
                                            </Grid>
                                        </Box>
                                    ) : (
                                        ""
                                    )}
                        </Grid>

                        {selectedSearch?.toLowerCase() == "checkout" && allReturnProducts?.length > 0 ?

                            allReturnProducts?.map((item, index) => {





                                return (

                                    <Box
                                        sx={{
                                            backgroundColor: Colors.white,
                                            border: `1px solid ${Colors.border}`,
                                            width: "100%",
                                            borderRadius: "5px",
                                            boxSizing: "border-box",
                                            padding: "0px 0px 0px 0px",
                                        }}
                                    >

                                        <Box style={{ margin: "5px" }} >

                                            <Typography sx={{ fontFamily: "Poppins", fontWeight: "bold", color: Colors.red, fontSize: "14px" }} >

                                                Invoice Number : {item?.returnInvoiceRef}

                                            </Typography>

                                        </Box>

                                        <Box sx={{ marginTop: "0px", width: "100%" }}>
                                            <Grid
                                                container
                                                xs={12}

                                                sx={{
                                                    backgroundColor: Colors.black,
                                                }}
                                            >
                                                <Grid item xl={0.5} lg={0.5} md={1} sm={0.8} xs={0.5} >
                                                    <Typography
                                                        sx={{
                                                            color: Colors.white,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                            fontSize: { xs: "10px", sm: "16px" },
                                                        }}
                                                    >
                                                        item
                                                    </Typography>
                                                </Grid>

                                                <Grid item xl={3} lg={2.5} md={2.5} sm={3} xs={2.5} >
                                                    <Typography
                                                        sx={{
                                                            color: Colors.white,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "500",
                                                            fontSize: {
                                                                lg: "16px",
                                                                md: "12px",
                                                                sm: "10px",
                                                                xs: "8px",
                                                            },
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        Product Description
                                                    </Typography>
                                                </Grid>

                                                <Grid item xl={1} lg={2} md={2} sm={1.5} xs={1.5} >
                                                    <Typography
                                                        sx={{
                                                            color: Colors.white,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "500",
                                                            fontSize: {
                                                                lg: "16px",
                                                                md: "12px",
                                                                sm: "10px",
                                                                xs: "8px",
                                                            },
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        Quantity
                                                    </Typography>
                                                </Grid>

                                                <Grid item xl={1.5} lg={1.5} md={1} sm={1} xs={1.5}  >
                                                    <Typography
                                                        sx={{
                                                            color: Colors.white,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "500",
                                                            width: "100%",
                                                            fontSize: {
                                                                lg: "16px",
                                                                md: "12px",
                                                                sm: "10px",
                                                                xs: "8px",
                                                            },
                                                            textAlign: { md: "center", lg: "left" },
                                                        }}
                                                    >
                                                        Return Qty
                                                    </Typography>
                                                </Grid>

                                                <Grid item xl={1} lg={1} md={1} sm={1.5} xs={2} >
                                                    <Typography
                                                        sx={{
                                                            color: Colors.white,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "500",
                                                            fontSize: {
                                                                lg: "16px",
                                                                md: "12px",
                                                                sm: "10px",
                                                                xs: "8px",
                                                            },
                                                            textAlign: { md: "center", lg: "left" },
                                                        }}
                                                    >
                                                        Price(£)
                                                    </Typography>
                                                </Grid>

                                                <Grid item xl={4} lg={3} md={3} sm={4} xs={4} >
                                                    <Typography
                                                        sx={{
                                                            color: Colors.white,

                                                            fontFamily: "Poppins",
                                                            fontWeight: "500",
                                                            fontSize: {
                                                                lg: "16px",
                                                                md: "12px",
                                                                sm: "10px",
                                                                xs: "8px",
                                                            },
                                                            textAlign: "center",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        Action
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box sx={{ marginTop: "0px", backgroundColor: Colors.white }}>
                                            {item?.productDetails &&
                                                item?.productDetails?.length > 0 ? (
                                                item?.productDetails?.map((e, i) => {



                                                    return (
                                                        <Grid
                                                            container
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            sx={{
                                                                backgroundColor: Colors.white,
                                                                padding: "10px 0px 10px 0px",
                                                            }}
                                                        >
                                                            <Grid
                                                                item
                                                                xl={0.5}
                                                                lg={0.5}
                                                                md={1}
                                                                sm={0.8}
                                                                xs={0.5}

                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    // border:"1px solid red"
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        color: Colors.text,
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: "600",
                                                                        fontSize: { xs: "10px", sm: "16px" },
                                                                    }}
                                                                >
                                                                    {i + 1}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xl={3} lg={2.5} md={2.5} sm={3} xs={2.5}  >
                                                                <Box
                                                                    sx={{ display: "flex", justifyContent: "row" }}
                                                                >

                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                color: "background: #000",
                                                                                fontFamily: "Poppins",
                                                                                fontWeight: "600",
                                                                                fontSize: {
                                                                                    lg: "16px",
                                                                                    md: "12px",
                                                                                    sm: "10px",
                                                                                    xs: "8px",
                                                                                },
                                                                                textAlign: "left",
                                                                            }}
                                                                        >
                                                                            {e?.productName}
                                                                        </Typography>

                                                                        <Typography
                                                                            sx={{
                                                                                color: "background: #939598",
                                                                                fontFamily: "Poppins",
                                                                                fontWeight: "500",
                                                                                fontSize: {
                                                                                    lg: "12px",
                                                                                    md: "10px",
                                                                                    sm: "8px",
                                                                                    xs: "6px",
                                                                                },
                                                                                textAlign: "left",
                                                                            }}
                                                                        >
                                                                            Category: {e?.category}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xl={1}
                                                                lg={2}
                                                                md={2}
                                                                sm={1.5}
                                                                xs={1.5}
                                                                sx={{
                                                                    display: "flex",
                                                                    //   border:"1px solid red",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        marginRight: "10px",
                                                                        // border:"1px solid red",
                                                                        //   justifyContent: "space-between",
                                                                        width: "100%",
                                                                        //   border: "1px solid red",
                                                                    }}
                                                                >

                                                                    <TextField
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        required

                                                                        value={e?.DamageQty ? e?.DamageQty : e?.saleQty}
                                                                        fullWidth
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            fontSize: "12px",
                                                                            style: {
                                                                                textAlign: "center",
                                                                                fontSize: "16px",
                                                                                alignSelf: "center",
                                                                            },
                                                                        }}
                                                                        inputProps={{
                                                                            disableUnderline: true,
                                                                            fontSize: "12px",
                                                                            style: {
                                                                                textAlign: "center",
                                                                                fontSize: "16px",
                                                                                alignSelf: "center",
                                                                            },
                                                                        }}
                                                                        placeholder="Price"
                                                                        sx={{
                                                                            width: width < 600 ? "25px" : "35px",
                                                                            height: width < 600 ? "25px" : "35px",
                                                                            fontSize: "12px",
                                                                            padding: 0,
                                                                            border: `1px solid ${Colors.border}`,
                                                                            borderRadius: "5px",
                                                                            textAlign: "center", // Move textAlign to sx

                                                                            fontFamily: "Poppins",
                                                                            fontWeight: "bold",
                                                                            margin: 0,
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xl={1.5}
                                                                lg={1.5}
                                                                md={1}
                                                                sm={1.5}
                                                                xs={2}
                                                                sx={{
                                                                    display: "flex",
                                                                    // border:"1px solid red",
                                                                    // justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        color: Colors.text,
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: "600",
                                                                        fontSize: width < 600 ? "10px" : "12px",
                                                                        // border: `1px solid ${Colors.border}`,
                                                                        padding: "4px",
                                                                        // width: width < 600 ? "25px" : "35px",
                                                                        padding: "0px 0px 0px 0px",
                                                                        // height: width < 600 ? "25px" : "35px",
                                                                        display: "flex",
                                                                    }}
                                                                >
                                                                    <TextField
                                                                        variant="standard" // <== changed this
                                                                        margin="normal"
                                                                        value={e?.returnQty ? e?.returnQty : 0}
                                                                        required
                                                                        fullWidth
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            fontSize: "12px",
                                                                            style: {
                                                                                textAlign: "center",
                                                                                fontSize: "16px",
                                                                                alignSelf: "center",
                                                                            },
                                                                        }}
                                                                        inputProps={{
                                                                            disableUnderline: true,
                                                                            fontSize: "12px",
                                                                            style: {
                                                                                textAlign: "center",
                                                                                fontSize: "16px",
                                                                                alignSelf: "center",
                                                                            },
                                                                        }}
                                                                        placeholder="Price"
                                                                        sx={{
                                                                            width: width < 600 ? "25px" : "35px",
                                                                            height: width < 600 ? "25px" : "35px",
                                                                            fontSize: "12px",
                                                                            padding: 0,
                                                                            border: `1px solid ${Colors.border}`,
                                                                            borderRadius: "5px",
                                                                            textAlign: "center", // Move textAlign to sx

                                                                            fontFamily: "Poppins",
                                                                            fontWeight: "bold",
                                                                            margin: 0,
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={1}
                                                                lg={1}
                                                                md={1}
                                                                sm={1.5}
                                                                xs={2}
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    // border:"1px solid red"
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        color: Colors.text,
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: "600",
                                                                        fontSize: width < 600 ? "10px" : "12px",
                                                                        border: `1px solid ${Colors.border}`,
                                                                        padding: "4px",
                                                                        width: width < 600 ? "70px" : "100px",
                                                                        display: "flex",
                                                                    }}
                                                                >
                                                                    <TextField
                                                                        variant="standard" // <== changed this
                                                                        margin="normal"
                                                                        value={
                                                                            e?.discountPrice
                                                                                ? Number(e?.discountPrice).toFixed(2)
                                                                                : item.customerDetails
                                                                                    ?.priceLevel[0]?.id == 1
                                                                                    ? Number(e?.trade_price).toFixed(2)
                                                                                    : item.customerDetails
                                                                                        ?.priceLevel[0]?.id == 2
                                                                                        ? Number(e?.warehouse_price).toFixed(2)
                                                                                        : Number(e?.retail_price).toFixed(2)
                                                                        }

                                                                        required
                                                                        fullWidth
                                                                        InputProps={{
                                                                            // startAdornment: <AccountCircle />, // <== adjusted this
                                                                            disableUnderline: true,
                                                                            fontSize: "12px",

                                                                            // <== added this
                                                                            // fontSize: "2px"
                                                                        }}
                                                                        placeholder="Search Product/Customer/Invoice"
                                                                        sx={{
                                                                            width: "90%",
                                                                            fontSize: "12px",
                                                                            padding: 0,
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: "bold",
                                                                            margin: 0,
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={4}
                                                                lg={3}
                                                                md={3}
                                                                sm={4}
                                                                xs={3.5}
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "space-around",
                                                                    alignItems: "center",
                                                                    width: "100%"
                                                                    // border:"1px solid red"
                                                                }}
                                                            >

                                                                {(userData?.role?.toLowerCase() == "admin" ||
                                                                    userData?.role_access?.some(
                                                                        (e, i) =>
                                                                            e.name.toLowerCase() == "showcostprice" &&
                                                                            e?.selected
                                                                    )) && (
                                                                        <Box>
                                                                            {refHover?.hover && refHover.ind == i && (
                                                                                <Typography sx={{ textAlign: "center" }}>
                                                                                    {e?.cost_price}.000
                                                                                </Typography>
                                                                            )}

                                                                            <Button


                                                                                onClick={() => {
                                                                                    setRefHover({
                                                                                        hover: !refHover?.hover,
                                                                                        ind: i,
                                                                                    });
                                                                                }}
                                                                                sx={{
                                                                                    fontFamily: "Poppins",
                                                                                    fontSize: "12px",
                                                                                    marginLeft: 1,
                                                                                    display: { xs: "none", sm: "block" },
                                                                                    border: `1px solid ${Colors.border}`,
                                                                                    borderRadius: 1,
                                                                                }}
                                                                            >
                                                                                Ref
                                                                            </Button>
                                                                        </Box>
                                                                    )}

                                                                <BasicSelect
                                                                    value={e?.returnStatus ? e?.returnStatus : "select"}
                                                                    status="Select"
                                                                    id={productStatus}
                                                                    width={"120px"}
                                                                    onChange={(j) => console.log(j)}
                                                                />

                                                                {/* 
                                                                {e?.IMEI && Array?.isArray(e?.IMEI) && e?.IMEI?.length > 0 && <Button
                                                                    sx={{
                                                                        fontFamily: "Poppins",
                                                                        fontSize: "12px",
                                                                        marginLeft: 1,
                                                                        display: { xs: "none", sm: "block" },
                                                                        border: `1px solid ${Colors.border}`,
                                                                        borderRadius: 1,
                                                                    }}
                                                                >
                                                                    Show Imei
                                                                </Button>} */}


                                                                <Checkbox
                                                                    onClick={() => handleSelectProduct(e)}
                                                                    color={e.selected ? Colors.white : Colors.black}
                                                                    style={{
                                                                        backgroundColor: e.selected
                                                                            ? Colors.button
                                                                            : Colors.white,
                                                                        // marginLeft: 10,
                                                                    }}
                                                                    data={e}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })
                                            ) : (
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        padding: 10,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "24px",
                                                            fontFamily: "Poppins",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        No Product Added
                                                    </Typography>
                                                </Box>
                                            )}

                                            {selectedInvoice?.returnProductDetails &&
                                                selectedInvoice?.returnProductDetails?.length > 0
                                                ? selectedInvoice?.returnProductDetails?.map((e, i) => {
                                                    return (
                                                        <Grid
                                                            container
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            sx={{
                                                                backgroundColor: Colors.white,
                                                                padding: "10px 0px 10px 0px",
                                                            }}
                                                        >
                                                            <Grid
                                                                item
                                                                xl={0.8}
                                                                lg={0.8}
                                                                md={1}
                                                                sm={0.8}
                                                                xs={0.5}
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        color: Colors.text,
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: "600",
                                                                        fontSize: { xs: "10px", sm: "16px" },
                                                                    }}
                                                                >
                                                                    {i +
                                                                        1 +
                                                                        selectedInvoice?.productDetails?.length}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xl={3} lg={2.5} md={2.5} sm={3} xs={2.5}>
                                                                <Box
                                                                    sx={{ display: "flex", justifyContent: "row" }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                color: "background: #000",
                                                                                fontFamily: "Poppins",
                                                                                fontWeight: "600",
                                                                                fontSize: {
                                                                                    lg: "16px",
                                                                                    md: "12px",
                                                                                    sm: "10px",
                                                                                    xs: "8px",
                                                                                },
                                                                                textAlign: "left",
                                                                            }}
                                                                        >
                                                                            {e?.productName}
                                                                        </Typography>

                                                                        <Typography
                                                                            sx={{
                                                                                color: "background: #939598",
                                                                                fontFamily: "Poppins",
                                                                                fontWeight: "500",
                                                                                fontSize: {
                                                                                    lg: "12px",
                                                                                    md: "10px",
                                                                                    sm: "8px",
                                                                                    xs: "6px",
                                                                                },
                                                                                textAlign: "left",
                                                                            }}
                                                                        >
                                                                            Category: {e?.category}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xl={1.5}
                                                                lg={2}
                                                                md={2}
                                                                sm={1.5}
                                                                xs={1.5}
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        //   justifyContent: "space-between",
                                                                        width: "100%",
                                                                        //   border: "1px solid red",
                                                                    }}
                                                                >

                                                                    <TextField
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        required
                                                                        // onChange={(e) =>
                                                                        //     changeDamageQty(e.target.value, i, "exchange")
                                                                        // }
                                                                        value={
                                                                            e?.DamageQty
                                                                                ? `-${e?.DamageQty}`
                                                                                : `-${e?.saleQty}`
                                                                        }
                                                                        fullWidth
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            fontSize: "12px",
                                                                            style: {
                                                                                textAlign: "center",
                                                                                fontSize: "16px",
                                                                                alignSelf: "center",
                                                                            },
                                                                        }}
                                                                        inputProps={{
                                                                            disableUnderline: true,
                                                                            fontSize: "12px",
                                                                            style: {
                                                                                textAlign: "center",
                                                                                fontSize: "16px",
                                                                                alignSelf: "center",
                                                                            },
                                                                        }}
                                                                        placeholder="Quantity"
                                                                        sx={{
                                                                            width: width < 600 ? "25px" : "35px",
                                                                            height: width < 600 ? "25px" : "35px",
                                                                            fontSize: "12px",
                                                                            padding: 0,
                                                                            border: `1px solid ${Colors.border}`,
                                                                            borderRadius: "5px",
                                                                            textAlign: "center", // Move textAlign to sx

                                                                            fontFamily: "Poppins",
                                                                            fontWeight: "bold",
                                                                            margin: 0,
                                                                        }}
                                                                    />

                                                                </Box>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={1.5}
                                                                lg={1.5}
                                                                md={1}
                                                                sm={1.5}
                                                                xs={2}
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <TextField
                                                                    variant="standard" // <== changed this
                                                                    margin="normal"
                                                                    value={
                                                                        item.customerDetails[0]
                                                                            ?.priceLevel[0]?.id == 1
                                                                            ? e?.current_tradeprice
                                                                            : item.customerDetails[0]
                                                                                ?.priceLevel[0]?.id == 2
                                                                                ? e?.current_warehouseprice
                                                                                : e?.current_retailprice
                                                                    }
                                                                    required
                                                                    fullWidth
                                                                    InputProps={{
                                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                                        disableUnderline: true,
                                                                        fontSize: "12px",

                                                                        // <== added this
                                                                        // fontSize: "2px"
                                                                    }}
                                                                    placeholder="Search Current Price..."
                                                                    sx={{
                                                                        width: "90%",
                                                                        fontSize: "12px",
                                                                        padding: 0,
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: "bold",
                                                                        margin: 0,
                                                                    }}
                                                                />
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xl={1.5}
                                                                lg={1.5}
                                                                md={1}
                                                                sm={1.5}
                                                                xs={2}
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        color: Colors.text,
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: "600",
                                                                        fontSize: width < 600 ? "10px" : "12px",
                                                                        border: `1px solid ${Colors.border}`,
                                                                        padding: "4px",
                                                                        width: width < 600 ? "70px" : "100px",
                                                                        display: "flex",
                                                                    }}
                                                                >
                                                                    <TextField
                                                                        variant="standard" // <== changed this
                                                                        margin="normal"
                                                                        value={`-${Number(e?.discountPrice)
                                                                            ? e?.discountPrice
                                                                            : item.customerDetails[0]
                                                                                ?.priceLevel[0]?.id == 1
                                                                                ? e?.trade_price
                                                                                : item.customerDetails[0]
                                                                                    ?.priceLevel[0]?.id == 2
                                                                                    ? e?.warehouse_price
                                                                                    : e?.retail_price
                                                                            }`}
                                                                        onChange={(text) =>
                                                                            handleDiscountPrice(text.target.value, e)
                                                                        }
                                                                        required
                                                                        fullWidth
                                                                        InputProps={{
                                                                            // startAdornment: <AccountCircle />, // <== adjusted this
                                                                            disableUnderline: true,
                                                                            fontSize: "12px",

                                                                            // <== added this
                                                                            // fontSize: "2px"
                                                                        }}
                                                                        placeholder="Search Product/Customer/Invoice"
                                                                        sx={{
                                                                            width: "90%",
                                                                            fontSize: "12px",
                                                                            padding: 0,
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: "bold",
                                                                            margin: 0,
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xl={3}
                                                                lg={3}
                                                                md={3}
                                                                sm={4}
                                                                xs={3.5}
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                    }}
                                                                    onClick={() => handleDeleteProduct(e)}
                                                                >
                                                                    <img
                                                                        src={require("../Images/delete.png")}
                                                                        style={{ height: "25px" }}
                                                                    />
                                                                </Box>
                                                                {userData?.show_price && (
                                                                    <Box>
                                                                        {refHover?.hover &&
                                                                            refHover.ind ==
                                                                            i +
                                                                            item?.productDetails
                                                                                ?.length && (
                                                                                <Typography sx={{ textAlign: "center" }}>
                                                                                    {e?.current_costprice}.00
                                                                                </Typography>
                                                                            )}

                                                                        <Button
                                                                            onMouseEnter={() =>
                                                                                setRefHover({
                                                                                    hover: true,
                                                                                    ind:
                                                                                        i +
                                                                                        item?.productDetails
                                                                                            ?.length,
                                                                                })
                                                                            }
                                                                            onMouseLeave={() =>
                                                                                setRefHover({
                                                                                    hover: false,
                                                                                })
                                                                            }
                                                                            sx={{
                                                                                fontFamily: "Poppins",
                                                                                fontSize: "12px",
                                                                                marginLeft: 1,
                                                                                display: { xs: "none", sm: "block" },
                                                                                border: `1px solid ${Colors.border}`,
                                                                                borderRadius: 1,
                                                                            }}
                                                                        >
                                                                            Ref
                                                                        </Button>
                                                                    </Box>
                                                                )}

                                                                {/* <Checkbox onClick={() => handleSelectProduct(e)} color={e.selected ? Colors.white : Colors.black} style={{ backgroundColor: e.selected ? Colors.button : Colors.white, marginLeft: 10 }} data={e} /> */}
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })
                                                : ""}
                                        </Box>
                                    </Box>

                                )

                            })

                            :
                            selectedInvoice && (
                                <Box
                                    sx={{
                                        backgroundColor: Colors.white,
                                        border: `1px solid ${Colors.border}`,
                                        width: "100%",
                                        borderRadius: "5px",
                                        boxSizing: "border-box",
                                        padding: "0px 0px 0px 0px",
                                    }}
                                >
                                    <Box sx={{ marginTop: "0px", width: "100%" }}>
                                        <Grid
                                            container
                                            xs={12}

                                            sx={{
                                                backgroundColor: Colors.black,
                                                //   padding: "10px 0px 10px 0px",
                                                // border:"2px solid purple"
                                            }}
                                        >
                                            <Grid item xl={0.5} lg={0.5} md={1} sm={0.8} xs={0.5} >
                                                <Typography
                                                    sx={{
                                                        color: Colors.white,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "500",
                                                        textAlign: "center",
                                                        fontSize: { xs: "10px", sm: "16px" },
                                                    }}
                                                >
                                                    item
                                                </Typography>
                                            </Grid>

                                            <Grid item xl={3} lg={2.5} md={2.5} sm={3} xs={2.5} >
                                                <Typography
                                                    sx={{
                                                        color: Colors.white,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "500",
                                                        fontSize: {
                                                            lg: "16px",
                                                            md: "12px",
                                                            sm: "10px",
                                                            xs: "8px",
                                                        },
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Product Description
                                                </Typography>
                                            </Grid>

                                            <Grid item xl={1} lg={2} md={2} sm={1.5} xs={1.5} >
                                                <Typography
                                                    sx={{
                                                        color: Colors.white,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "500",
                                                        fontSize: {
                                                            lg: "16px",
                                                            md: "12px",
                                                            sm: "10px",
                                                            xs: "8px",
                                                        },
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Quantity
                                                </Typography>
                                            </Grid>

                                            <Grid item xl={1.5} lg={1.5} md={1} sm={1} xs={1.5}  >
                                                <Typography
                                                    sx={{
                                                        color: Colors.white,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "500",
                                                        width: "100%",
                                                        fontSize: {
                                                            lg: "16px",
                                                            md: "12px",
                                                            sm: "10px",
                                                            xs: "8px",
                                                        },
                                                        textAlign: { md: "center", lg: "left" },
                                                    }}
                                                >
                                                    Return Qty
                                                </Typography>
                                            </Grid>

                                            <Grid item xl={1} lg={1.5} md={1} sm={1.5} xs={1.5}  >
                                                <Typography
                                                    sx={{
                                                        color: Colors.white,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "500",
                                                        fontSize: {
                                                            lg: "16px",
                                                            md: "12px",
                                                            sm: "10px",
                                                            xs: "8px",
                                                        },
                                                        textAlign: { md: "center", lg: "left" },
                                                    }}
                                                >
                                                    Current Price(£)
                                                </Typography>
                                            </Grid>

                                            <Grid item xl={1} lg={1} md={1} sm={1.5} xs={2} >
                                                <Typography
                                                    sx={{
                                                        color: Colors.white,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "500",
                                                        fontSize: {
                                                            lg: "16px",
                                                            md: "12px",
                                                            sm: "10px",
                                                            xs: "8px",
                                                        },
                                                        textAlign: { md: "center", lg: "left" },
                                                    }}
                                                >
                                                    Price(£)
                                                </Typography>
                                            </Grid>

                                            <Grid item xl={4} lg={3} md={3} sm={4} xs={4} >
                                                <Typography
                                                    sx={{
                                                        color: Colors.white,

                                                        fontFamily: "Poppins",
                                                        fontWeight: "500",
                                                        fontSize: {
                                                            lg: "16px",
                                                            md: "12px",
                                                            sm: "10px",
                                                            xs: "8px",
                                                        },
                                                        textAlign: "center",
                                                        width: "100%",
                                                    }}
                                                >
                                                    Action
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box sx={{ marginTop: "0px", backgroundColor: Colors.white }}>
                                        {selectedInvoice?.productDetails &&
                                            selectedInvoice?.productDetails?.length > 0 ? (
                                            selectedInvoice?.productDetails?.map((e, i) => {


                                                return (
                                                    <Grid
                                                        container
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        sx={{
                                                            backgroundColor: Colors.white,
                                                            padding: "10px 0px 10px 0px",
                                                        }}
                                                    >
                                                        <Grid
                                                            item
                                                            xl={0.5}
                                                            lg={0.5}
                                                            md={1}
                                                            sm={0.8}
                                                            xs={0.5}

                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                // border:"1px solid red"
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: Colors.text,
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "600",
                                                                    fontSize: { xs: "10px", sm: "16px" },
                                                                }}
                                                            >
                                                                {i + 1}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xl={3} lg={2.5} md={2.5} sm={3} xs={2.5}  >
                                                            <Box
                                                                sx={{ display: "flex", justifyContent: "row" }}
                                                            >
                                                                {e?.image1_url && (
                                                                    <img
                                                                        src={`${Image_Uri}products/${e?.image1_url}`}
                                                                        style={{
                                                                            height:
                                                                                width < 600
                                                                                    ? "30px"
                                                                                    : width < 800
                                                                                        ? "50px"
                                                                                        : width < 1300
                                                                                            ? "60px"
                                                                                            : "80px",
                                                                            marginRight: "10px",
                                                                        }}
                                                                    />
                                                                )}

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            color: "background: #000",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: "600",
                                                                            fontSize: {
                                                                                lg: "16px",
                                                                                md: "12px",
                                                                                sm: "10px",
                                                                                xs: "8px",
                                                                            },
                                                                            textAlign: "left",
                                                                        }}
                                                                    >
                                                                        {e?.productName}
                                                                    </Typography>

                                                                    <Typography
                                                                        sx={{
                                                                            color: "background: #939598",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: "500",
                                                                            fontSize: {
                                                                                lg: "12px",
                                                                                md: "10px",
                                                                                sm: "8px",
                                                                                xs: "6px",
                                                                            },
                                                                            textAlign: "left",
                                                                        }}
                                                                    >
                                                                        Category: {e?.category}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            xl={1}
                                                            lg={2}
                                                            md={2}
                                                            sm={1.5}
                                                            xs={1.5}
                                                            sx={{
                                                                display: "flex",
                                                                //   border:"1px solid red",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    marginRight: "10px",
                                                                    // border:"1px solid red",
                                                                    //   justifyContent: "space-between",
                                                                    width: "100%",
                                                                    //   border: "1px solid red",
                                                                }}
                                                            >
                                                                <button
                                                                    onClick={() => handleDecreaseProduct(e)}
                                                                    style={{
                                                                        width: width < 600 ? "25px" : "35px",
                                                                        padding: "0px 0px 0px 0px",
                                                                        height: width < 600 ? "25px" : "35px",
                                                                        borderRadius: "5px",
                                                                        marginRight: "10px",
                                                                        backgroundColor: Colors.white,
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: "bold",
                                                                        border: `1px solid ${Colors?.border}`,
                                                                    }}
                                                                >
                                                                    -
                                                                </button>
                                                                <TextField
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    required
                                                                    onChange={(e) =>
                                                                        changeDamageQty(e.target.value, i)
                                                                    }
                                                                    value={e?.DamageQty ? e?.DamageQty : e?.saleQty}
                                                                    fullWidth
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                        fontSize: "12px",
                                                                        style: {
                                                                            textAlign: "center",
                                                                            fontSize: "16px",
                                                                            alignSelf: "center",
                                                                        },
                                                                    }}
                                                                    inputProps={{
                                                                        disableUnderline: true,
                                                                        fontSize: "12px",
                                                                        style: {
                                                                            textAlign: "center",
                                                                            fontSize: "16px",
                                                                            alignSelf: "center",
                                                                        },
                                                                    }}
                                                                    placeholder="Price"
                                                                    sx={{
                                                                        width: width < 600 ? "25px" : "35px",
                                                                        height: width < 600 ? "25px" : "35px",
                                                                        fontSize: "12px",
                                                                        padding: 0,
                                                                        border: `1px solid ${Colors.border}`,
                                                                        borderRadius: "5px",
                                                                        textAlign: "center", // Move textAlign to sx

                                                                        fontFamily: "Poppins",
                                                                        fontWeight: "bold",
                                                                        margin: 0,
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            xl={1.5}
                                                            lg={1.5}
                                                            md={1}
                                                            sm={1.5}
                                                            xs={2}
                                                            sx={{
                                                                display: "flex",
                                                                // border:"1px solid red",
                                                                // justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    color: Colors.text,
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "600",
                                                                    fontSize: width < 600 ? "10px" : "12px",
                                                                    // border: `1px solid ${Colors.border}`,
                                                                    padding: "4px",
                                                                    // width: width < 600 ? "25px" : "35px",
                                                                    padding: "0px 0px 0px 0px",
                                                                    // height: width < 600 ? "25px" : "35px",
                                                                    display: "flex",
                                                                }}
                                                            >
                                                                <TextField
                                                                    variant="standard" // <== changed this
                                                                    margin="normal"
                                                                    value={e?.returnQty ? e?.returnQty : 0}
                                                                    required
                                                                    fullWidth
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                        fontSize: "12px",
                                                                        style: {
                                                                            textAlign: "center",
                                                                            fontSize: "16px",
                                                                            alignSelf: "center",
                                                                        },
                                                                    }}
                                                                    inputProps={{
                                                                        disableUnderline: true,
                                                                        fontSize: "12px",
                                                                        style: {
                                                                            textAlign: "center",
                                                                            fontSize: "16px",
                                                                            alignSelf: "center",
                                                                        },
                                                                    }}
                                                                    placeholder="Price"
                                                                    sx={{
                                                                        width: width < 600 ? "25px" : "35px",
                                                                        height: width < 600 ? "25px" : "35px",
                                                                        fontSize: "12px",
                                                                        padding: 0,
                                                                        border: `1px solid ${Colors.border}`,
                                                                        borderRadius: "5px",
                                                                        textAlign: "center", // Move textAlign to sx

                                                                        fontFamily: "Poppins",
                                                                        fontWeight: "bold",
                                                                        margin: 0,
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            xl={1}
                                                            lg={1.5}
                                                            md={1}
                                                            sm={1.5}
                                                            xs={2}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                // border:"1px solid red"
                                                            }}
                                                        >
                                                            <TextField
                                                                variant="standard" // <== changed this
                                                                margin="normal"
                                                                value={
                                                                    selectedInvoice.customerDetails[0]
                                                                        ?.priceLevel[0]?.id == 1
                                                                        ? e?.current_tradeprice
                                                                        : selectedInvoice.customerDetails[0]
                                                                            ?.priceLevel[0]?.id == 2
                                                                            ? e?.current_warehouseprice
                                                                            : e?.current_retailprice
                                                                }
                                                                required
                                                                fullWidth
                                                                InputProps={{
                                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                                    disableUnderline: true,
                                                                    fontSize: "12px",

                                                                    // <== added this
                                                                    // fontSize: "2px"
                                                                }}
                                                                placeholder="Search Current Price..."
                                                                sx={{
                                                                    width: "90%",
                                                                    fontSize: "12px",
                                                                    padding: 0,
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "bold",
                                                                    margin: 0,
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            xl={1}
                                                            lg={1}
                                                            md={1}
                                                            sm={1.5}
                                                            xs={2}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                // border:"1px solid red"
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    color: Colors.text,
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "600",
                                                                    fontSize: width < 600 ? "10px" : "12px",
                                                                    border: `1px solid ${Colors.border}`,
                                                                    padding: "4px",
                                                                    width: width < 600 ? "70px" : "100px",
                                                                    display: "flex",
                                                                }}
                                                            >
                                                                <TextField
                                                                    variant="standard" // <== changed this
                                                                    margin="normal"
                                                                    value={
                                                                        e?.discountPrice
                                                                            ? Number(e?.discountPrice)
                                                                            : selectedInvoice.customerDetails[0]
                                                                                ?.priceLevel[0]?.id == 1
                                                                                ? Number(e?.trade_price)
                                                                                : selectedInvoice.customerDetails[0]
                                                                                    ?.priceLevel[0]?.id == 2
                                                                                    ? Number(e?.warehouse_price)
                                                                                    : Number(e?.retail_price)
                                                                    }
                                                                    onChange={(text) =>
                                                                        handleDiscountPrice(text.target.value, e)
                                                                    }
                                                                    required
                                                                    fullWidth
                                                                    InputProps={{
                                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                                        disableUnderline: true,
                                                                        fontSize: "12px",

                                                                        // <== added this
                                                                        // fontSize: "2px"
                                                                    }}
                                                                    placeholder="Search Product/Customer/Invoice"
                                                                    sx={{
                                                                        width: "90%",
                                                                        fontSize: "12px",
                                                                        padding: 0,
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: "bold",
                                                                        margin: 0,
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xl={4}
                                                            lg={3}
                                                            md={3}
                                                            sm={4}
                                                            xs={3.5}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "space-around",
                                                                alignItems: "center",
                                                                width: "100%"
                                                                // border:"1px solid red"
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                                onClick={() => handleDeleteProduct(e)}
                                                            >
                                                                <img
                                                                    src={require("../Images/delete.png")}
                                                                    style={{ height: "25px" }}
                                                                />
                                                            </Box>
                                                            {(userData?.role?.toLowerCase() == "admin" ||
                                                                userData?.role_access?.some(
                                                                    (e, i) =>
                                                                        e.name.toLowerCase() == "showcostprice" &&
                                                                        e?.selected
                                                                )) && (
                                                                    <Box>
                                                                        {refHover?.hover && refHover.ind == i && (
                                                                            <Typography sx={{ textAlign: "center" }}>
                                                                                {e?.cost_price}.000
                                                                            </Typography>
                                                                        )}

                                                                        <Button


                                                                            onClick={() => {
                                                                                setRefHover({
                                                                                    hover: !refHover?.hover,
                                                                                    ind: i,
                                                                                });
                                                                            }}
                                                                            sx={{
                                                                                fontFamily: "Poppins",
                                                                                fontSize: "12px",
                                                                                marginLeft: 1,
                                                                                display: { xs: "none", sm: "block" },
                                                                                border: `1px solid ${Colors.border}`,
                                                                                borderRadius: 1,
                                                                            }}
                                                                        >
                                                                            Ref
                                                                        </Button>
                                                                    </Box>
                                                                )}

                                                            <BasicSelect
                                                                value={e?.returnStatus ? e?.returnStatus : "select"}
                                                                status="Select"
                                                                id={productStatus}
                                                                width={"120px"}
                                                                onChange={(j) => changeProductStatus(j, e)}
                                                            />


                                                            {/* {e?.IMEI && Array?.isArray(e?.IMEI) && e?.IMEI?.length > 0 && <Button
                                                            onClick={()=>handleShowIMEI(e)}
                                                                sx={{
                                                                    fontFamily: "Poppins",
                                                                    fontSize: "12px",
                                                                    marginLeft: 1,
                                                                    display: { xs: "none", sm: "block" },
                                                                    border: `1px solid ${Colors.border}`,
                                                                    borderRadius: 1,
                                                                }}
                                                            >
                                                                Show Imei
                                                            </Button>} */}

                                                            <Checkbox
                                                                onClick={() => handleSelectProduct(e)}
                                                                color={e.selected ? Colors.white : Colors.black}
                                                                style={{
                                                                    backgroundColor: e.selected
                                                                        ? Colors.button
                                                                        : Colors.white,
                                                                    // marginLeft: 10,
                                                                }}
                                                                data={e}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })
                                        ) : (
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    padding: 10,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "24px",
                                                        fontFamily: "Poppins",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    No Product Added
                                                </Typography>
                                            </Box>
                                        )}

                                        {selectedInvoice?.returnProductDetails &&
                                            selectedInvoice?.returnProductDetails?.length > 0
                                            ? selectedInvoice?.returnProductDetails?.map((e, i) => {
                                                return (
                                                    <Grid
                                                        container
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        sx={{
                                                            backgroundColor: Colors.white,
                                                            padding: "10px 0px 10px 0px",
                                                        }}
                                                    >
                                                        <Grid
                                                            item
                                                            xl={0.8}
                                                            lg={0.8}
                                                            md={1}
                                                            sm={0.8}
                                                            xs={0.5}
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: Colors.text,
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "600",
                                                                    fontSize: { xs: "10px", sm: "16px" },
                                                                }}
                                                            >
                                                                {i +
                                                                    1 +
                                                                    selectedInvoice?.productDetails?.length}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xl={3} lg={2.5} md={2.5} sm={3} xs={2.5}>
                                                            <Box
                                                                sx={{ display: "flex", justifyContent: "row" }}
                                                            >
                                                                {/* {e?.image1_url && (
                                                        <img
                                                            src={`${Image_Uri}products/${e?.image1_url}`}
                                                            style={{
                                                                height:
                                                                    width < 600
                                                                        ? "30px"
                                                                        : width < 800
                                                                            ? "50px"
                                                                            : width < 1300
                                                                                ? "60px"
                                                                                : "80px",
                                                                marginRight: "10px",
                                                            }}
                                                        />
                                                    )} */}

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            color: "background: #000",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: "600",
                                                                            fontSize: {
                                                                                lg: "16px",
                                                                                md: "12px",
                                                                                sm: "10px",
                                                                                xs: "8px",
                                                                            },
                                                                            textAlign: "left",
                                                                        }}
                                                                    >
                                                                        {e?.productName}
                                                                    </Typography>

                                                                    <Typography
                                                                        sx={{
                                                                            color: "background: #939598",
                                                                            fontFamily: "Poppins",
                                                                            fontWeight: "500",
                                                                            fontSize: {
                                                                                lg: "12px",
                                                                                md: "10px",
                                                                                sm: "8px",
                                                                                xs: "6px",
                                                                            },
                                                                            textAlign: "left",
                                                                        }}
                                                                    >
                                                                        Category: {e?.category}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            xl={1.5}
                                                            lg={2}
                                                            md={2}
                                                            sm={1.5}
                                                            xs={1.5}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    //   justifyContent: "space-between",
                                                                    width: "100%",
                                                                    //   border: "1px solid red",
                                                                }}
                                                            >
                                                                {/* <button
                                                        onClick={() => handleDecreaseProduct(e, "exchange")}
                                                        style={{
                                                            width: width < 600 ? "25px" : "35px",
                                                            padding: "0px 0px 0px 0px",
                                                            height: width < 600 ? "25px" : "35px",
                                                            borderRadius: "5px",
                                                            marginRight: "10px",
                                                            backgroundColor: Colors.white,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "bold",
                                                            border: `1px solid ${Colors?.border}`,
                                                        }}
                                                    >
                                                        -
                                                    </button> */}

                                                                {/* <Box
                                                        sx={{
                                                            width: width < 600 ? "25px" : "35px",

                                                            height: width < 600 ? "25px" : "35px",
                                                            marginRight: "10px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            border: `1px solid ${Colors?.border}`,
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {e?.DamageQty ? e?.DamageQty : e?.saleQty}
                                                    </Box> */}

                                                                <TextField
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    required
                                                                    // onChange={(e) =>
                                                                    //     changeDamageQty(e.target.value, i, "exchange")
                                                                    // }
                                                                    value={
                                                                        e?.DamageQty
                                                                            ? `-${e?.DamageQty}`
                                                                            : `-${e?.saleQty}`
                                                                    }
                                                                    fullWidth
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                        fontSize: "12px",
                                                                        style: {
                                                                            textAlign: "center",
                                                                            fontSize: "16px",
                                                                            alignSelf: "center",
                                                                        },
                                                                    }}
                                                                    inputProps={{
                                                                        disableUnderline: true,
                                                                        fontSize: "12px",
                                                                        style: {
                                                                            textAlign: "center",
                                                                            fontSize: "16px",
                                                                            alignSelf: "center",
                                                                        },
                                                                    }}
                                                                    placeholder="Quantity"
                                                                    sx={{
                                                                        width: width < 600 ? "25px" : "35px",
                                                                        height: width < 600 ? "25px" : "35px",
                                                                        fontSize: "12px",
                                                                        padding: 0,
                                                                        border: `1px solid ${Colors.border}`,
                                                                        borderRadius: "5px",
                                                                        textAlign: "center", // Move textAlign to sx

                                                                        fontFamily: "Poppins",
                                                                        fontWeight: "bold",
                                                                        margin: 0,
                                                                    }}
                                                                />
                                                                {/* <button
                                                        style={{
                                                            width: width < 600 ? "25px" : "35px",
                                                            height: width < 600 ? "25px" : "35px",
                                                            padding: "0px 0px 0px 0px",
                                                            borderRadius: "5px",
                                                            color: Colors.button,
                                                            backgroundColor: Colors.white,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "bold",
                                                            border: `1px solid ${Colors?.button}`,
                                                        }}
                                                        onClick={() => handleIncreaseProduct(e)}
                                                    >
                                                        +
                                                    </button> */}
                                                            </Box>
                                                        </Grid>

                                                        {/* <Grid
                        item
                        xl={1.5}
                        lg={1.5}
                        md={1}
                        sm={2}
                        xs={2}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <BasicSelect
                          value={e?.warranty ? "Yes" : "No"}
                          status="Select"
                          id={warrantyData}
                          width={{ lg: "80%", md: "60%", xs: "50%" }}
                          onChange={(e) => changeWarranty(e, i)}
                        />
                      </Grid> */}

                                                        <Grid
                                                            item
                                                            xl={1.5}
                                                            lg={1.5}
                                                            md={1}
                                                            sm={1.5}
                                                            xs={2}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <TextField
                                                                variant="standard" // <== changed this
                                                                margin="normal"
                                                                value={
                                                                    selectedInvoice.customerDetails[0]
                                                                        ?.priceLevel[0]?.id == 1
                                                                        ? e?.current_tradeprice
                                                                        : selectedInvoice.customerDetails[0]
                                                                            ?.priceLevel[0]?.id == 2
                                                                            ? e?.current_warehouseprice
                                                                            : e?.current_retailprice
                                                                }
                                                                required
                                                                fullWidth
                                                                InputProps={{
                                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                                    disableUnderline: true,
                                                                    fontSize: "12px",

                                                                    // <== added this
                                                                    // fontSize: "2px"
                                                                }}
                                                                placeholder="Search Current Price..."
                                                                sx={{
                                                                    width: "90%",
                                                                    fontSize: "12px",
                                                                    padding: 0,
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "bold",
                                                                    margin: 0,
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            xl={1.5}
                                                            lg={1.5}
                                                            md={1}
                                                            sm={1.5}
                                                            xs={2}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    color: Colors.text,
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "600",
                                                                    fontSize: width < 600 ? "10px" : "12px",
                                                                    border: `1px solid ${Colors.border}`,
                                                                    padding: "4px",
                                                                    width: width < 600 ? "70px" : "100px",
                                                                    display: "flex",
                                                                }}
                                                            >
                                                                <TextField
                                                                    variant="standard" // <== changed this
                                                                    margin="normal"
                                                                    value={`-${Number(e?.discountPrice)
                                                                        ? e?.discountPrice
                                                                        : selectedInvoice.customerDetails[0]
                                                                            ?.priceLevel[0]?.id == 1
                                                                            ? e?.trade_price
                                                                            : selectedInvoice.customerDetails[0]
                                                                                ?.priceLevel[0]?.id == 2
                                                                                ? e?.warehouse_price
                                                                                : e?.retail_price
                                                                        }`}
                                                                    onChange={(text) =>
                                                                        handleDiscountPrice(text.target.value, e)
                                                                    }
                                                                    required
                                                                    fullWidth
                                                                    InputProps={{
                                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                                        disableUnderline: true,
                                                                        fontSize: "12px",

                                                                        // <== added this
                                                                        // fontSize: "2px"
                                                                    }}
                                                                    placeholder="Search Product/Customer/Invoice"
                                                                    sx={{
                                                                        width: "90%",
                                                                        fontSize: "12px",
                                                                        padding: 0,
                                                                        fontFamily: "Poppins",
                                                                        fontWeight: "bold",
                                                                        margin: 0,
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xl={3}
                                                            lg={3}
                                                            md={3}
                                                            sm={4}
                                                            xs={3.5}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                                onClick={() => handleDeleteProduct(e)}
                                                            >
                                                                <img
                                                                    src={require("../Images/delete.png")}
                                                                    style={{ height: "25px" }}
                                                                />
                                                            </Box>
                                                            {userData?.show_price && (
                                                                <Box>
                                                                    {refHover?.hover &&
                                                                        refHover.ind ==
                                                                        i +
                                                                        selectedInvoice?.productDetails
                                                                            ?.length && (
                                                                            <Typography sx={{ textAlign: "center" }}>
                                                                                {e?.current_costprice}.00
                                                                            </Typography>
                                                                        )}

                                                                    <Button
                                                                        onMouseEnter={() =>
                                                                            setRefHover({
                                                                                hover: true,
                                                                                ind:
                                                                                    i +
                                                                                    selectedInvoice?.productDetails
                                                                                        ?.length,
                                                                            })
                                                                        }
                                                                        onMouseLeave={() =>
                                                                            setRefHover({
                                                                                hover: false,
                                                                            })
                                                                        }
                                                                        sx={{
                                                                            fontFamily: "Poppins",
                                                                            fontSize: "12px",
                                                                            marginLeft: 1,
                                                                            display: { xs: "none", sm: "block" },
                                                                            border: `1px solid ${Colors.border}`,
                                                                            borderRadius: 1,
                                                                        }}
                                                                    >
                                                                        Ref
                                                                    </Button>
                                                                </Box>
                                                            )}

                                                            {/* <Checkbox onClick={() => handleSelectProduct(e)} color={e.selected ? Colors.white : Colors.black} style={{ backgroundColor: e.selected ? Colors.button : Colors.white, marginLeft: 10 }} data={e} /> */}
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })
                                            : ""}
                                    </Box>
                                </Box>
                            )}


                        {selectedInvoice && selectedSearch?.toLowerCase() !== "checkout" && (
                            <Box
                                sx={{
                                    width: "100%",
                                    backgroundColor: Colors.white,
                                    marginTop: "10px",
                                    padding: "20px",
                                    borderRadius: "5px",
                                }}
                            >
                                <Grid container xs={12} sm={12} md={12}>
                                    <Grid item xs={12} sm={12} md={7}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                padding: "10px",
                                                borderBottom: `1px solid ${Colors.border}`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    color: Colors.text,
                                                }}
                                            >
                                                Invoice Number
                                            </Typography>

                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    color: Colors.text,
                                                }}
                                            >
                                                #{selectedInvoice.invoiceNumber}
                                            </Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                padding: "10px",
                                                borderBottom: `1px solid ${Colors.border}`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    color: Colors.text,
                                                }}
                                            >
                                                Total Products
                                            </Typography>

                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    color: Colors.text,
                                                }}
                                            >
                                                {selectedInvoice.totalItems}
                                            </Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                padding: "10px",
                                                borderBottom: `1px solid ${Colors.border}`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    color: Colors.text,
                                                }}
                                            >
                                                Total Quantity
                                            </Typography>

                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    color: Colors.text,
                                                }}
                                            >
                                                {selectedInvoice.productDetails.reduce(
                                                    (previous, current) =>
                                                        previous + Number(current.saleQty),
                                                    0
                                                )}
                                            </Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                padding: "10px",
                                                borderBottom: `1px solid ${Colors.border}`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    color: Colors.text,
                                                }}
                                            >
                                                Subtotal
                                            </Typography>

                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    color: Colors.text,
                                                }}
                                            >
                                                £{Number(selectedInvoice.total).toFixed(2)}
                                            </Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                padding: "10px",
                                                borderBottom: `1px solid ${Colors.border}`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    color: Colors.text,
                                                }}
                                            >
                                                Discount
                                            </Typography>

                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    color: Colors.text,
                                                }}
                                            >
                                                £{selectedInvoice.discount ? Number(selectedInvoice.discount).toFixed(2) : 0}
                                            </Typography>
                                        </Box>

                                        {selectedInvoice?.discount && selectedInvoice?.vatAmount ?

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "10px",
                                                    borderBottom: `1px solid ${Colors.border}`,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.text,
                                                    }}
                                                >
                                                    Subtotal After Discount
                                                </Typography>

                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.text,
                                                    }}
                                                >
                                                    £{Number(Number(selectedInvoice?.total) - Number(selectedInvoice?.discount)?.toFixed(2))}
                                                </Typography>
                                            </Box>
                                            : ""

                                        }

                                        {selectedInvoice.vatAmount ? (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "10px",
                                                    borderBottom: `1px solid ${Colors.border}`,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.text,
                                                    }}
                                                >
                                                    VAT
                                                </Typography>

                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.text,
                                                    }}
                                                >
                                                    £{Number(selectedInvoice.vatAmount).toFixed(2)}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            ""
                                        )}

                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                padding: "10px",
                                                borderBottom: `1px solid ${Colors.border}`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    color: Colors.button,
                                                }}
                                            >
                                                Total
                                            </Typography>

                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    color: Colors.button,
                                                }}
                                            >
                                                £{Number(selectedInvoice.subtotal).toFixed(2)}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={5}>
                                        {/* <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "180px",
                                    width: "100%",
                                }}
                            >


                                <Button
                                    onClick={() => handleGenerateNewInvoice()}
                                    sx={{
                                        backgroundColor: Colors.button,
                                        color: Colors.white,
                                        marginTop: "15px",
                                        fontFamily: "Poppins",
                                        fontSize: "12px",
                                        padding: "5px",
                                        borderRadius: "20px",
                                        border: `1px solid ${Colors.button}`,
                                        width: "150px",
                                    }}
                                >
                                    Generate Sale Return Invoice
                                </Button>
                            </Box> */}
                                    </Grid>
                                </Grid>
                            </Box>
                        )}

                        {selectedInvoice &&
                            selectedInvoice.productDetails.some((e, i) => e.selected) && (
                                <Box
                                    sx={{
                                        width: "100%",
                                        backgroundColor: Colors.white,
                                        marginTop: "10px",
                                        padding: "20px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    {selectedSearch?.toLowerCase() !== "checkout" && <Grid container xs={12} sm={12} md={12}>
                                        <Grid item xs={12} sm={12} md={7}>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                        color: Colors.text,
                                                        marginBottom: 2,
                                                    }}
                                                >
                                                    Sale Return Details:
                                                </Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "10px",
                                                    borderBottom: `1px solid ${Colors.border}`,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.text,
                                                    }}
                                                >
                                                    Total Products
                                                </Typography>

                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.text,
                                                    }}
                                                >
                                                    {damageItems}
                                                </Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "10px",
                                                    borderBottom: `1px solid ${Colors.border}`,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.text,
                                                    }}
                                                >
                                                    Total Quantity
                                                </Typography>

                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.text,
                                                    }}
                                                >
                                                    {damageQty}
                                                </Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "10px",
                                                    borderBottom: `1px solid ${Colors.border}`,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.text,
                                                    }}
                                                >
                                                    Subtotal
                                                </Typography>

                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.text,
                                                    }}
                                                >
                                                    £ {Number(damagetotal).toFixed(2)}
                                                </Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "10px",
                                                    borderBottom: `1px solid ${Colors.border}`,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.text,
                                                    }}
                                                >
                                                    Discount
                                                </Typography>

                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.text,
                                                    }}
                                                >
                                                    £{damageDiscount ? Number(damageDiscount)?.toFixed(2) : 0}{" "}
                                                </Typography>
                                            </Box>

                                            {damageDiscount && damageVat ? <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "10px",
                                                    borderBottom: `1px solid ${Colors.border}`,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.text,
                                                    }}
                                                >
                                                    Subtotal After Discount
                                                </Typography>

                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.text,
                                                    }}
                                                >
                                                    £{Number((damagetotal - damageDiscount).toFixed(2))}{" "}
                                                </Typography>
                                            </Box> : ""}

                                            {selectedInvoice.vatAmount ? (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        padding: "10px",
                                                        borderBottom: `1px solid ${Colors.border}`,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontFamily: "Poppins",
                                                            fontSize: "14px",
                                                            fontWeight: "bold",
                                                            color: Colors.text,
                                                        }}
                                                    >
                                                        VAT
                                                    </Typography>

                                                    <Typography
                                                        sx={{
                                                            fontFamily: "Poppins",
                                                            fontSize: "14px",
                                                            fontWeight: "bold",
                                                            color: Colors.text,
                                                        }}
                                                    >
                                                        £{Number(damageVat).toFixed(2)}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                ""
                                            )}

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "10px",
                                                    borderBottom: `1px solid ${Colors.border}`,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.button,
                                                    }}
                                                >
                                                    Total
                                                </Typography>

                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: Colors.button,
                                                    }}
                                                >
                                                    £{Number(damageSubtotal).toFixed(2)}
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={5}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "180px",
                                                    width: "100%",
                                                }}
                                            >

                                                <CustomButton
                                                    onClick={() => handleOpenConfimModal()}
                                                    sx={{
                                                        backgroundColor: Colors.button,
                                                        color: Colors.white,
                                                        marginTop: "15px",
                                                        fontFamily: "Poppins",
                                                        fontSize: "12px",
                                                        padding: "8px",
                                                        borderRadius: "5px",
                                                        border: `1px solid ${Colors.button}`,
                                                        width: "150px",

                                                    }}

                                                    text={allReturnProducts && allReturnProducts?.length > 0 && allReturnProducts?.some((e, i) => e?.returnInvoiceRef == selectedInvoice?.invoiceNumber) ? "Overwrite" : "Add In Return"}
                                                />
                                                {/* </CustomButton> */}
                                            </Box>
                                        </Grid>
                                    </Grid>}
                                </Box>
                            )}


                        {selectedSearch?.toLowerCase() == "checkout" && allReturnProducts && allReturnProducts?.length > 0 && <Grid container xs={12} sm={12} md={12}>
                            <Grid item xs={12} sm={12} md={7}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "18px",
                                            fontWeight: "bold",
                                            color: Colors.text,
                                            marginBottom: 2,
                                        }}
                                    >
                                        Sale Return Details:
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px",
                                        borderBottom: `1px solid ${Colors.border}`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: Colors.text,
                                        }}
                                    >
                                        Total Products
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: Colors.text,
                                        }}
                                    >
                                        {allReturnProducts && allReturnProducts?.length > 0 && allReturnProducts?.reduce((pre, curr) => {
                                            return (Number(pre) || 0) + Number(curr?.totalItems || 0)

                                        }, 0)}
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px",
                                        borderBottom: `1px solid ${Colors.border}`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: Colors.text,
                                        }}
                                    >
                                        Total Quantity
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: Colors.text,
                                        }}
                                    >
                                        {allReturnProducts && allReturnProducts?.length > 0 && allReturnProducts?.reduce((pre, curr) => {
                                            return (Number(pre) || 0) + Number(curr?.totalQty || 0)

                                        }, 0)}
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px",
                                        borderBottom: `1px solid ${Colors.border}`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: Colors.text,
                                        }}
                                    >
                                        Total
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: Colors.text,
                                        }}
                                    >
                                        £
                                        {Number(allReturnProducts && allReturnProducts?.length > 0 && allReturnProducts?.reduce((pre, curr) => {
                                            return (Number(pre) || 0) + Number(curr?.total || 0)

                                        }, 0)).toFixed(2)}
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px",
                                        borderBottom: `1px solid ${Colors.border}`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: Colors.text,
                                        }}
                                    >
                                        Discount
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: Colors.text,
                                        }}
                                    >
                                        £
                                        {Number(allReturnProducts && allReturnProducts?.length > 0 && allReturnProducts?.reduce((pre, curr) => {
                                            return (Number(pre) || 0) + Number(curr?.discount || 0)

                                        }, 0)).toFixed(2)}
                                    </Typography>
                                </Box>

                                {selectedInvoice.vatAmount ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "10px",
                                            borderBottom: `1px solid ${Colors.border}`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Poppins",
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                color: Colors.text,
                                            }}
                                        >
                                            VAT
                                        </Typography>

                                        <Typography
                                            sx={{
                                                fontFamily: "Poppins",
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                color: Colors.text,
                                            }}
                                        >
                                            £
                                            {Number(allReturnProducts && allReturnProducts?.length > 0 && allReturnProducts?.reduce((pre, curr) => {
                                                return (Number(pre) || 0) + Number(curr?.vatAmount || 0)

                                            }, 0)).toFixed(2)}
                                        </Typography>
                                    </Box>
                                ) : (
                                    ""
                                )}

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px",
                                        borderBottom: `1px solid ${Colors.border}`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: Colors.button,
                                        }}
                                    >
                                        Subtotal
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: Colors.button,
                                        }}
                                    >
                                        £
                                        {Number(allReturnProducts && allReturnProducts?.length > 0 && allReturnProducts?.reduce((pre, curr) => {
                                            return (Number(pre) || 0) + Number(curr?.subtotal || 0)

                                        }, 0)).toFixed(2)}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={5} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} >



                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // height: "200px",
                                        width: "100%",
                                        // border: "1px solid red"
                                    }}
                                >



                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontFamily: "Poppins",
                                                width: 200,
                                            }}
                                        >
                                            Refund Cash
                                        </Typography>

                                        <Checkbox
                                            data={refundCash}
                                            onClick={() => handleSelectReturnOption("cash")}
                                            style={{
                                                marginLeft: "5px",
                                                backgroundColor: refundCash.selected
                                                    ? Colors.button
                                                    : Colors.white,
                                            }}
                                            color={
                                                refundCash.selected ? Colors.white : Colors.black
                                            }
                                        />
                                    </Box>

                                    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontFamily: "Poppins",
                                                width: 200,
                                            }}
                                        >
                                            Refund Card
                                        </Typography>

                                        <Checkbox
                                            data={refundCard}
                                            onClick={() => handleSelectReturnOption("card")}
                                            style={{
                                                marginLeft: "5px",
                                                backgroundColor: refundCard.selected
                                                    ? Colors.button
                                                    : Colors.white,
                                            }}
                                            color={
                                                refundCard.selected ? Colors.white : Colors.black
                                            }
                                        />
                                    </Box>



                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            mt: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontFamily: "Poppins",
                                                width: 200,
                                            }}
                                        >
                                            Credit Note on Account
                                        </Typography>

                                        <Checkbox
                                            data={deductCreditBalance}
                                            onClick={() => handleSelectReturnOption("credit")}
                                            style={{
                                                marginLeft: "5px",
                                                backgroundColor: deductCreditBalance.selected
                                                    ? Colors.button
                                                    : Colors.white,
                                            }}
                                            color={
                                                deductCreditBalance.selected
                                                    ? Colors.white
                                                    : Colors.black
                                            }
                                        />

                                    </Box>

                                    {deductCreditBalance?.selected &&

                                        <BasicSelect id={deductBalanceOptions} value={selectedDeductBalanceOption} width={"150px"} style={{ marginBottom: "10px", marginTop: "8px" }} onChange={(e) => setSelectedDeductBalanceOption(e)} />}




                                </Box>



                                <Box

                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        // justifyContent: "center",
                                        alignItems: "center",

                                    }}
                                >


                                    <CustomButton
                                        onClick={() => setConfirmModalVisible(true)}
                                        style={{
                                            backgroundColor: Colors.button,
                                            color: Colors.white,
                                            // marginTop: "15px",
                                            fontFamily: "Poppins",
                                            marginBottom: "10px",
                                            // mt: 3,
                                            // border:"1px solid red",
                                            fontSize: "12px",
                                            padding: "8px",
                                            borderRadius: "5px",
                                            border: `1px solid ${Colors.button}`,
                                            // width: "150px",

                                        }}

                                        text={"Return Invoice"}
                                    />

                                    <CustomButton
                                        onClick={() => handleGoForExchange()}
                                        style={{
                                            backgroundColor: Colors.button,
                                            color: Colors.white,
                                            // marginTop: "15px",
                                            fontFamily: "Poppins",
                                            fontSize: "12px",
                                            padding: "8px",
                                            borderRadius: "5px",
                                            border: `1px solid ${Colors.button}`,
                                            // width: "150px",

                                        }}

                                        text={"Exchange Products"}
                                    />

                                </Box>


                            </Grid>
                        </Grid>}

                        {successfulTransactionModal && (
                            <CustomModal
                                heading={`Transaction Successfull`}
                                loading={loading}
                                emailbutton={true}
                                emailInvoice={emailInvoice}
                                emailLoading={emailLoading}
                                text={"Do You want to print the Return invoice?"}
                                open={successfulTransactionModal}
                                closeModal={() => cancelModal()}
                                confirm={() => setPdfDownloadView(true)}
                            />
                        )}

                        <CustomModal
                            heading={`Sale Return`}
                            loading={loading}
                            text={

                                refundCash?.selected ? "Are you sure you want to refund cash?" : refundCard?.selected ? "Are you sure you want to refund by card?" : "Are you sure you want to Credit Note on Account?"
                            }
                            open={confirmModalVisible}
                            closeModal={() => setConfirmModalVisible(false)}
                            confirm={() => !loading && handleGenerateNewInvoice()}
                        />
                    </Box>


                }





            </Modal>
        </div>
    );
}