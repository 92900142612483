const Colors = {
    white: "white",
    red: "red",
    black: "black",
    green: "green",
    main: "#E9E9E9",
    button: "#0F64B3",
    buttonLight: "#D3EAFF",
    text: "#000000",
    border: "#C8C8C8"
}

export { Colors }