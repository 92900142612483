import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Colors } from "../Constant/Color";
import { RingLoader } from "react-spinners";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "../Redux/LoginReduces";

export default function CartProductEditModal({
  product,
  heading,
  placeholder,
  text,
  emailLoading,
  headingTextStyle,
  textStyle,
  containerStyle,
  open,
  closeModal,
  buttonContainerStyle,
  buttonStyle,
  buttonStyle2,
  confirm,
  credit,
  onChange,
  emailbutton,
  emailInvoice,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      lg: emailbutton ? "35%" : "30%",
      md: "40%",
      sm: "55%",
      xs: "100%",
    },
    maxHeight: "80vh",
    overflow: "auto",
    borderRadius: "10px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  const selectedCustomer = useSelector(
    (state) => state.loginReducer.selectedCustomer
  );

  const cart = useSelector((state) => state.loginReducer.cart);

  const [cartProduct, setCartProduct] = React.useState(false);

  const [priceLevel, setPriceLevel] = React.useState([
    {
      name: "Trade Price",
      selected: false,
      type: "A",
      id: 1,
    },
    {
      name: "Warehouse Price",
      selected: false,
      type: "B",
      id: 2,
    },
    {
      name: "Retail Price",
      selected: false,
      type: "C",
      id: 3,
    },
  ]);
  const [imei, setImei] = React.useState("");
  const [allImei, setAllImei] = React.useState([]);

  React.useEffect(() => {
    if (selectedCustomer) {
      setCartProduct(product);

      setAllImei(
        product?.IMEI && Array.isArray(product?.IMEI) ? product?.IMEI : []
      );

      setPriceLevel(
        priceLevel?.map((e, i) => {
          if (selectedCustomer?.priceLevel[0]?.id == e?.id) {
            return {
              ...e,
              selected: true,
            };
          } else {
            return {
              ...e,
              selected: false,
            };
          }
        })
      );
    }
  }, [open]);

  const handleEditCartProductDetails = async () => {
    try {
      setLoading(true);

      if (!cartProduct?.saleQty) {
        alert("write qty");
        return;
      }

      let product = { ...cartProduct };

      product.IMEI = allImei;

      let newCart =
        cart &&
        cart.length > 0 &&
        cart.map((e, i) => {
          if (e._id == cartProduct._id) {
            return product;
          } else {
            return e;
          }
        });

      dispatch(updateCart(newCart));
      setLoading(false);
      closeModal();
    } catch (err) {
      alert(err?.message);
      setLoading(false);
    }
  };

  const handleChangePriceLevel = (level) => {
    setPriceLevel(
      priceLevel?.map((e, i) => {
        if (e?.id == level?.id) {
          return {
            ...e,
            selected: true,
          };
        } else {
          return {
            ...e,
            selected: false,
          };
        }
      })
    );

    setCartProduct({
      ...cartProduct,
      discountPrice:
        level?.id == 1
          ? cartProduct?.trade_price
          : level?.id == 2
          ? cartProduct?.warehouse_price
          : cartProduct?.retail_price,
    });
  };

  const handleDeleteImei = (i) => {
    setAllImei(
      allImei &&
        allImei?.length > 0 &&
        allImei?.filter((e, index) => index !== i)
    );
  };

  const handleAddimei = () => {
    if (!imei) {
      alert("kindly enter IMEI");
      return;
    }

    setAllImei([...allImei, imei]);
    setImei("");
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[style, { containerStyle }]}>
          <Box
            onClick={() => closeModal()}
            style={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon fontSize="large" />
          </Box>

          <Typography
            sx={{
              fontSize: "20px",
              color: Colors.button,
              fontWeight: "bold",
              fontFamily: "Poppins",
              textAlign: "center",
              ...headingTextStyle,
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {heading}
          </Typography>
          {/* <Typography sx={{ fontSize: "14px", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", mt: 1, padding: "5px 10px 5px 10px ", textAlign: "center", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                        {text}
                    </Typography> */}

          {/* {credit && */}
          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                color: Colors.black,
                fontWeight: "600",
                fontFamily: "Poppins",
                mt: 1,
                padding: "5px 10px 5px 10px ",
                textAlign: "left",
                textTransform: "capitalize",
                p: 0,
                ...textStyle,
              }}
              id="modal-modal-description"
            >
              Description:
            </Typography>

            <TextField
              variant="standard"
              margin="none"
              required
              multiline={true}
              onChange={(e) =>
                setCartProduct({ ...cartProduct, productName: e.target.value })
              }
              value={cartProduct?.productName}
              fullWidth
              InputProps={{
                disableUnderline: true,
                fontSize: "12px",
                style: {
                  textAlign: "center",
                  fontSize: "16px",
                  alignSelf: "center",
                },
              }}
              inputProps={{
                disableUnderline: true,
                fontSize: "12px",
              }}
              placeholder={"Product Name"}
              sx={{
                width: "100%",
                // height: width < 600 ? "25px" : "35px",
                fontSize: "12px",
                padding: 1,
                border: `1px solid ${Colors.border}`,
                borderRadius: "5px",
                marginBottom: 2,
                textAlign: "center", // Move textAlign to sx
                fontFamily: "Poppins",
                fontWeight: "bold",
              }}
            />
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                color: Colors.black,
                fontWeight: "600",
                fontFamily: "Poppins",
                mt: 1,
                padding: "5px 10px 5px 10px ",
                textAlign: "left",
                textTransform: "capitalize",
                p: 0,
                ...textStyle,
              }}
              id="modal-modal-description"
            >
              Price:
            </Typography>

            <TextField
              variant="standard"
              margin="none"
              required
              onChange={(e) =>
                setCartProduct({
                  ...cartProduct,
                  discountPrice: e.target.value,
                })
              }
              value={
                cartProduct?.discountPrice
                  ? Number(cartProduct?.discountPrice)
                  : selectedCustomer && selectedCustomer?.priceLevel[0]?.id == 1
                  ? Number(cartProduct?.trade_price).toFixed(2)
                  : selectedCustomer && selectedCustomer?.priceLevel[0]?.id == 2
                  ? Number(cartProduct?.warehouse_price).toFixed(2)
                  : Number(cartProduct?.retail_price).toFixed(2)
              }
              fullWidth
              InputProps={{
                disableUnderline: true,
                fontSize: "12px",
                style: {
                  textAlign: "center",
                  fontSize: "16px",
                  alignSelf: "center",
                },
              }}
              inputProps={{
                disableUnderline: true,
                fontSize: "12px",
              }}
              placeholder={"Product Name"}
              sx={{
                width: "100%",
                // height: width < 600 ? "25px" : "35px",
                fontSize: "12px",
                padding: 1,
                border: `1px solid ${Colors.border}`,
                borderRadius: "5px",
                marginBottom: 2,
                textAlign: "center", // Move textAlign to sx
                fontFamily: "Poppins",
                fontWeight: "bold",
              }}
            />

            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                borderBottom: "1px solid black",
                marginBottom: "5px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  marginRight: "5px",
                }}
              >
                Trade Price : {cartProduct?.trade_price}{" "}
              </Typography>

              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  marginRight: "5px",
                }}
              >
                Warehouse Price : {cartProduct?.warehouse_price}{" "}
              </Typography>

              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  marginRight: "5px",
                }}
              >
                Retail Price : {cartProduct?.retail_price}{" "}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
              {priceLevel &&
                priceLevel?.length > 0 &&
                priceLevel?.map((e, i) => {
                  return (
                    <Box
                      onClick={() => handleChangePriceLevel(e)}
                      sx={{
                        display: "flex",
                        marginRight: "10px",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        sx={{
                          width: "15px",
                          height: "15px",
                          borderRadius: "100%",
                          backgroundColor: e?.selected ? "orange" : "white",
                          marginRight: "5px",
                          border: "1px solid black",
                        }}
                      ></Box>
                      <Typography
                        style={{ fontFamily: "Poppins", fontSize: "12px" }}
                      >
                        {e?.name}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                color: Colors.black,
                fontWeight: "600",
                fontFamily: "Poppins",
                mt: 1,
                padding: "5px 10px 5px 10px ",
                textAlign: "left",
                textTransform: "capitalize",
                p: 0,
                ...textStyle,
              }}
              id="modal-modal-description"
            >
              Qty:
            </Typography>

            <TextField
              variant="standard"
              margin="none"
              required
              onChange={(e) =>
                setCartProduct({
                  ...cartProduct,
                  saleQty: Number(e?.target?.value),
                })
              }
              value={cartProduct?.saleQty}
              fullWidth
              InputProps={{
                disableUnderline: true,
                fontSize: "12px",
                style: {
                  textAlign: "center",
                  fontSize: "16px",
                  alignSelf: "center",
                },
              }}
              inputProps={{
                disableUnderline: true,
                fontSize: "12px",
              }}
              placeholder={"QTY"}
              sx={{
                width: "100%",
                // height: width < 600 ? "25px" : "35px",
                fontSize: "12px",
                padding: 1,
                border: `1px solid ${Colors.border}`,
                borderRadius: "5px",
                marginBottom: 2,
                textAlign: "center", // Move textAlign to sx
                fontFamily: "Poppins",
                fontWeight: "bold",
              }}
            />
          </Box>

          <Box>
            {allImei &&
              allImei?.length > 0 &&
              allImei?.map((e, i) => {
                return (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid",
                      marginTop: "5px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: Colors.black,
                        fontWeight: "600",
                        fontFamily: "Poppins",
                        mt: 1,
                        padding: "5px 10px 5px 10px ",
                        textAlign: "left",
                        textTransform: "capitalize",
                        p: 0,
                        ...textStyle,
                      }}
                      id="modal-modal-description"
                    >
                      {e}
                    </Typography>
                    <button
                      style={{
                        width: 120,
                        padding: 0,
                        margin: 0,
                        height: 40,
                        backgroundColor: Colors.buttonLight,
                      }}
                      onClick={() => handleDeleteImei(i)}
                    >
                      Delete
                    </button>
                  </Box>
                );
              })}
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                color: Colors.black,
                fontWeight: "600",
                fontFamily: "Poppins",
                mt: 2,
                padding: "5px 10px 5px 10px ",
                textAlign: "left",
                textTransform: "capitalize",
                p: 0,
                ...textStyle,
              }}
              id="modal-modal-description"
            >
              IMEI:
            </Typography>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                variant="standard"
                margin="none"
                required
                onChange={(e) => setImei(e.target.value)}
                value={imei}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  fontSize: "12px",
                  style: {
                    textAlign: "center",
                    fontSize: "16px",
                    alignSelf: "center",
                  },
                }}
                inputProps={{
                  disableUnderline: true,
                  fontSize: "12px",
                }}
                placeholder={"IMEI"}
                sx={{
                  width: "60%",
                  fontSize: "12px",
                  padding: 1,
                  border: `1px solid ${Colors.border}`,
                  borderRadius: "5px",
                  marginBottom: 2,
                  textAlign: "center", // Move textAlign to sx
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                }}
              />

              <button
                style={{
                  width: 150,
                  padding: 0,
                  margin: 0,
                  height: 50,
                  backgroundColor: Colors.buttonLight,
                }}
                onClick={() => handleAddimei()}
              >
                Add
              </button>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              ...buttonContainerStyle,
            }}
          >
            <Button
              onClick={closeModal}
              sx={{
                backgroundColor: Colors.white,
                color: Colors.button,
                border: `1px solid ${Colors.button}`,
                borderRadius: "20px",
                width: "150px",
                fontFamily: "Poppins",
                ...buttonStyle,
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={() => handleEditCartProductDetails()}
              sx={{
                backgroundColor: Colors.button,
                color: Colors.white,
                border: `1px solid ${Colors.button}`,
                borderRadius: "20px",
                width: "150px",
                fontFamily: "Poppins",
                "&:hover": {
                  color: Colors.button, // Set your desired hover color
                },
                ...buttonStyle2,
              }}
            >
              {loading ? <RingLoader size={30} loading={loading} /> : "Confirm"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
