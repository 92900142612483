
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { RingLoader } from 'react-spinners';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from "@mui/icons-material/Download";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
    Document,
    Page,
    Text,
    View,
    PDFViewer,
    PDFDownloadLink,
    Image,
    Font,
} from "@react-pdf/renderer";
import MyFontRegular from "../fonts/Poppins-Medium.ttf";
import MyFontBold from "../fonts/Poppins-Bold.ttf";



export default function TransactionModal({ ledgerData, heading, placeholder, text, emailLoading, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, loading, credit, onChange, emailbutton, emailInvoice }) {



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { lg: "70%", md: "90%", sm: "90%", xs: "100%" },
        borderRadius: "10px",
        maxHeight: "80vh",
        overflow: "auto",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    };



    Font.register({
        family: "MyFont",
        fonts: [
            { src: MyFontBold, fontWeight: "bold" },
            { src: MyFontRegular, fontWeight: "normal" },
        ],
    });

    const vat = useSelector((state) => state.loginReducer.vat)


    const [pdfDownloadView, setPdfDownloadView] = React.useState(false);


    let otherDetails = { ...ledgerData }



    const Header = () => {
        return (
            <View fixed>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: 10,
                        alignItems: "flex-start",
                    }}
                >
                    <View>

                    </View>

                    <View>


                        <View style={{ alignItems: "center", marginTop: "10px" }}>
                            <Text
                                style={{
                                    color: "black",
                                    fontSize: "16px",
                                    fontFamily: "MyFont",
                                    // marginTop:"10px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                }}
                            >
                                {otherDetails?.transactionType}
                            </Text>
                        </View>

                        <View>


                            <Text
                                style={{
                                    color: "black",
                                    fontSize: "10px",
                                    fontFamily: "MyFont",
                                    // marginTop:"10px",
                                    fontWeight: "normal",
                                    textAlign: "center",
                                }}
                            >
                                Date:{" "}
                                {new Date(
                                    otherDetails?.date
                                ).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                })}
                            </Text>

                            <Text
                                style={{
                                    color: "black",
                                    fontSize: "10px",
                                    fontFamily: "MyFont",
                                    fontWeight: "normal",
                                    textAlign: "center",
                                }}
                            >
                                Time:{" "}
                                {new Date(
                                    otherDetails?.date
                                ).toLocaleTimeString()}
                            </Text>


                            <View style={{ marginTop: "10px" }}>

                                <Text
                                    style={{
                                        color: "black",
                                        fontSize: "10px",
                                        fontFamily: "MyFont",
                                        // marginTop:"10px",
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    }}
                                >
                                    Account Number: {otherDetails?.customerDetails?.accountNo}
                                </Text>

                                <Text
                                    style={{
                                        color: "black",
                                        fontSize: "10px",
                                        fontFamily: "MyFont",
                                        // marginTop:"10px",
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    }}
                                >
                                    Business Name: {otherDetails?.customerDetails?.businessName}
                                </Text>

                                <Text
                                    style={{
                                        color: "black",
                                        fontSize: "10px",
                                        fontFamily: "MyFont",
                                        // marginTop:"10px",
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    }}
                                >
                                    Email: {otherDetails?.customerDetails?.email}
                                </Text>



                            </View>
                        </View>
                    </View>
                </View>

                <View
                    style={{
                        flexDirection: "row",
                        borderBottomWidth: 1,
                        borderColor: "#000",
                        paddingBottom: 5,
                        paddingTop: 5,
                        border: "1px solid black",
                        backgroundColor: "gray",
                        alignItems: "center",
                        padding: 10,
                        marginTop: "20px",
                    }}
                >


                    <View style={{ flex: 1 }}>
                        <Text
                            style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
                        >
                            S.no
                        </Text>
                    </View>

                    <View style={{ flex: 5 }}>
                        <Text
                            style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
                        >
                            Description
                        </Text>
                    </View>


                    <View style={{ flex: 1 }}>
                        <Text
                            style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
                        >
                            Amount
                        </Text>
                    </View>
                </View>
            </View>
        );
    };

    const MyDocument = () => (
        <Document size="A4">
            {/* <Header /> */}

            <Page style={{ padding: "0px 10px" }}>
                <Header />
                {/* Data Rows */}
                <View>

                    <View
                        style={{
                            flexDirection: "row",
                            borderBottomWidth: 1,
                            borderColor: "#000",
                            borderLeftWidth: 1,
                            borderRightWidth: 1,
                            padding: "3px 10px",
                        }}
                    >


                        <View style={{ flex: 1 }}>
                            <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                                1
                            </Text>
                        </View>

                        <View style={{ flex: 5 }}>
                            <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                                {otherDetails.transactionType}
                            </Text>
                        </View>


                        <View style={{ flex: 1 }}>
                            <Text
                                style={{
                                    fontFamily: "MyFont",
                                    fontSize: 8,
                                    color: "black",
                                    fontWeight: "bold",
                                }}
                            >
                                £
                                {otherDetails?.totalAmount}
                            </Text>
                        </View>
                    </View>







                </View>
            </Page>
        </Document>
    );


    return (
        <div>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {pdfDownloadView ? (
                    <Box
                        style={{
                            wodth: "100%",
                            height: "100%",
                            // border: "1px solid red",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >

                        <PDFViewer width={850} height={700} style={{ marginTop: 20 }}>
                            <MyDocument />
                        </PDFViewer>

                        <CloseOutlinedIcon fontSize="large" style={{ color: Colors.black, marginTop: "20px", cursor: "pointer", }} onClick={() => setPdfDownloadView(false)} />

                    </Box>
                ) : <Box sx={[style, { containerStyle }]}>

                    <Box onClick={() => closeModal()} style={{ position: "absolute", top: 10, right: 10 }} >
                        <CloseIcon fontSize="large" />
                    </Box>


                    <Button
                        onClick={() => setPdfDownloadView(true)}
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            // marginLeft: "10px",
                            width: "95%",
                            marginTop: "10px",
                            backgroundColor: Colors.white,
                        }}
                    >
                        <DownloadIcon />
                    </Button>


                    <Grid xs={8} sm={6} >


                        {otherDetails.customerDetails && otherDetails?.customerDetails?.accountNo && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                            Account Number: {otherDetails?.customerDetails?.accountNo}
                        </Typography>}

                        {otherDetails.customerDetails && otherDetails?.customerDetails?.businessName && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                            Business Name: {otherDetails?.customerDetails?.businessName}
                        </Typography>}

                        {otherDetails?.customerDetails && otherDetails?.customerDetails?.email && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                            Customer Email: {otherDetails?.customerDetails?.email}
                        </Typography>}

                    </Grid>



                    <Grid container xs={12} sx={{ marginTop: 2 }} >




                        <Grid xs={1} >


                            <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                                S.no

                            </Typography>



                            <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                                1

                            </Typography>



                        </Grid>

                        <Grid xs={9} >

                            <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                                Description

                            </Typography>



                            <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                                {otherDetails?.transactionType}

                            </Typography>




                        </Grid>

                        {/* <Grid xs={1} >


                            <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                                Quantity

                            </Typography>



                            <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                                0

                            </Typography>



                        </Grid> */}

                        {/* <Grid xs={1} >


                            <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                                Price

                            </Typography>



                            <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >


                            </Typography>




                        </Grid> */}



                        <Grid xs={2} >


                            <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                                Amount

                            </Typography>



                            <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >


                                {otherDetails?.totalAmount}
                            </Typography>





                        </Grid>




                    </Grid>


                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", marginTop: "20px", justifyContent: "center", ...buttonContainerStyle }} >

                        <Button onClick={closeModal} sx={{
                            backgroundColor: "#5DC002", color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "200px", fontSize: "14px", textTransform: "capitalize", mt: 1, fontFamily: "Poppins",
                            '&:hover': {
                                color: '#5DC002', // Set your desired hover color
                            },
                            ...buttonStyle
                        }} >
                            Close
                        </Button>
                    </Box>



                </Box>}



            </Modal>
        </div>
    );
}