import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { RingLoader } from 'react-spinners';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


export default function SubAccountCustomemodal({ status, heading, placeholder, text, emailLoading, buttonText, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, loading, credit, onChange, emailbutton, emailInvoice, data, description }) {


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { lg: emailbutton ? "35%" : "30%", md: "40%", sm: "55%", xs: "100%" },
        borderRadius: "10px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[style, { containerStyle }]}>

                    <Box onClick={() => closeModal()} style={{ position: "absolute", top: 10, right: 10 }} >
                        <CloseIcon fontSize="large" />
                    </Box>


                    <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                        {heading}
                    </Typography>
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        onChange={onChange}
                        value={description}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            fontSize: "12px",
                            style: {
                                textAlign: "center",
                                fontSize: "16px",
                                alignSelf: "center",
                            },
                        }}
                        inputProps={{
                            disableUnderline: true,
                            fontSize: "12px",

                        }}
                        placeholder={placeholder ? placeholder : "Enter Credit Days"}
                        sx={{
                            width: "100%",
                            // height: width < 600 ? "25px" : "35px",
                            fontSize: "12px",
                            padding: 1,
                            border: `1px solid ${Colors.border}`,
                            borderRadius: "5px",
                            marginBottom: 2,
                            textAlign: "center", // Move textAlign to sx
                            fontFamily: "Poppins",
                            fontWeight: "bold",

                        }}
                    />

                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", ...buttonContainerStyle }} >

                        <Button onClick={closeModal} sx={{ backgroundColor: Colors.white, color: Colors.button, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", ...buttonStyle }} >
                            Cancel
                        </Button>



                        <Button onClick={() => confirm()} sx={{
                            backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", '&:hover': {
                                color: Colors.button, // Set your desired hover color
                            }, ...buttonStyle2
                        }} >
                            {loading ? <RingLoader size={30} loading={loading} /> : buttonText ? buttonText : "Confirm"}
                        </Button>


                    </Box>



                </Box>



            </Modal>
        </div>
    );
}