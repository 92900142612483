import React from 'react'
import CheckIcon from '@mui/icons-material/Check';
import { Button } from '@mui/material';
import { Colors } from '../Constant/Color';


function Checkbox({ data, onClick, style,color }) {


    return (
        <button onClick={onClick} style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "20px", width: "20px", border: `1px solid ${Colors.border}`, backgroundColor: Colors.white, padding: 0, margin: 0, borderRadius: 5, ...style }} >

            {data?.selected && <CheckIcon sx={{color:color ? color : Colors.black}} fontSize="16px" />}


        </button>
    )
}

export default Checkbox
