import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField } from '@mui/material'; import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import CustomButton from './Button';
import { useDispatch, useSelector } from 'react-redux';
import { Base_Uri } from '../Constant/BaseUri';
import { updateCustomers, updateEmployeeExpense } from '../Redux/LoginReduces';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';


import CustomModal from './modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xl: "90%", md: "90%", sm: "100%", xs: "100%" },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    // height: "800px",
    overflow: "auto",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
};

export default function ExpenseModal({ open, closeModal, setExpenseModal, customerDetails, setCustomerLedger, setSelectedCustomer, customerLedger }) {





    const dispatch = useDispatch()

    const expenses = useSelector(state => state.loginReducer.employeeExpense)


    const userData = useSelector((state) => state.loginReducer.updatedState);
    const [loading, setLoading] = useState(false)
    const [openConfirmModal, setOpenConfirmModal] = useState(false)

    const [expense, setExpense] = useState({
        amount: "",
        description: ""
    })

    const [allExpenses, setAllExpenses] = useState([])





    useEffect(() => {

        setAllExpenses(expenses)

    }, [open])

    const handleAddExpense = async () => {


        let dataToSend = {

            amount: expense?.amount,
            description: expense?.description,
            date: new Date(),
            employeeId: userData?.id,
            employeeName: userData?.fullName
        }

        setLoading(true)



        let data = await localStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        axios.post(`${Base_Uri}addExpense`, dataToSend, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }).then((res) => {

            let data = res?.data

            if (!data?.status) {
                alert(data?.message)
                setLoading(false)
                return
            }

            alert(data.message)

            let newExpense = data.data

            let updateExpenseData = [...allExpenses, newExpense]

            setAllExpenses(updateExpenseData)

            dispatch(updateEmployeeExpense(updateExpenseData))


            setOpenConfirmModal(false)
            setExpense({
                description: "",
                amount: ""
            })
            setLoading(false)


        }).catch((error) => {

            alert(error?.message)
            setLoading(false)
            return

        })




    }



    const handleOpenConfirmModal = () => {


        if (!expense?.amount) {
            alert("Amount is missing")
            return
        }


        if (!expense?.description) {
            alert("Description is missing")
            return
        }

        setOpenConfirmModal(true)

    }



    return (
        <div>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Box onClick={() => closeModal()} style={{ position: "absolute", top: 10, right: 10 }} >
                        <CloseIcon fontSize="large" />
                    </Box>

                    <Grid item xs={12} sm={12} md={12}>
                        <Box style={{ padding: 10 }}>


                            <Box style={{ marginTop: "10px", padding: 15, backgroundColor: Colors.white, borderTopRightRadius: "5px", borderTopLeftRadius: "5px", marginBottom: "0px" }} >

                                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", }} >


                                    <Typography sx={{ fontSize: "20px", fontWeight: "bold", fontFamily: "Poppins" }} >
                                        Expenses
                                    </Typography>
                                </Box>

                                <Box style={{ marginTop: 10 }} >
                                    <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between" }} >
                                        <Grid xs={12} md={12} >
                                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                Amount
                                            </Typography>
                                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                                <TextField
                                                    onChange={(e) => setExpense({ ...expense, amount: e?.target?.value })}
                                                    value={expense.amount}
                                                    variant="standard"
                                                    type="number"
                                                    margin="normal"
                                                    placeholder="Enter Amount"
                                                    required
                                                    fullWidth
                                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true, // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                />
                                            </Box>


                                        </Grid>

                                        <Grid xs={12} md={12} >
                                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                Description
                                            </Typography>
                                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                                <TextField

                                                    onChange={(e) => setExpense({ ...expense, description: e?.target?.value })}
                                                    value={expense?.description}
                                                    variant="standard"
                                                    margin="normal"
                                                    placeholder="Enter Description"
                                                    required
                                                    fullWidth
                                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true, // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                />
                                            </Box>




                                        </Grid>


                                        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }} >
                                            <CustomButton onClick={() => handleOpenConfirmModal()} style={{ backgroundColor: Colors.button, border: `1px solid ${Colors.button}`, color: Colors.white, width: { xs: "100%", sm: 200 }, marginTop: { xs: "10px", sm: "10px" }, fontSize: { md: "10px", lg: "12px" }, alignSelf: "flex-end" }} text={"Add Expense"} />
                                        </Box>

                                    </Grid>

                                </Box>


                            </Box>



                        </Box>
                    </Grid>




                    <Box sx={{ maxHeight: 300, overflow: "auto", padding: "5px" }} >


                        <Box sx={{ display: "flex", justifyContent: "space-around", width: "100%",backgroundColor:"rgba(235,235,235,1)" }}  >

                            <Box style={{ border: "1px solid black", width: "20%", fontSize: 16, fontFamily: "Poppins", fontWeight: "bold", textAlign: "center",padding:"5px" }} >

                                Date

                            </Box>

                            <Box sx={{ width: "60%", border: "1px solid black", fontSize: 16, fontFamily: "Poppins", fontWeight: "bold", textAlign: "center",padding:"5px" }} >

                                Description
                            </Box>

                            <Box style={{ border: "1px solid black", flexGrow: 1.5, width: "20%", fontSize: 16, fontFamily: "Poppins", fontWeight: "bold", textAlign: "center",padding:"5px" }} >

                                Amount

                            </Box>


                        </Box>


                        {allExpenses && allExpenses?.length > 0 && allExpenses?.map((e, i) => {

                            return (

                                <Box sx={{ display: "flex", justifyContent: "space-around", width: "100%" }}  >

                                    <Box style={{ border: "1px solid black", width: "20%", fontSize: 16, fontFamily: "Poppins" }} >

                                        {new Date(e?.date).toLocaleDateString("en-GB", {
                                            day: "2-digit",
                                            month: "2-digit",
                                            year: "numeric",
                                        })}

                                    </Box>

                                    <Box sx={{ width: "60%", border: "1px solid black", fontSize: 16, fontFamily: "Poppins" }} >

                                        {e?.description}

                                    </Box>

                                    <Box style={{ border: "1px solid black", flexGrow: 1.5, width: "20%", fontSize: 16, fontFamily: "Poppins" }} >

                                        {e?.amount}

                                    </Box>


                                </Box>


                            )



                        })}


                    </Box>



                    <CustomModal
                        heading={(`Amount £${expense?.amount}`)}
                        loading={loading}
                        text={
                            "Are you sure you want add this expense"}
                        open={openConfirmModal}
                        closeModal={() => setOpenConfirmModal(false)}
                        confirm={handleAddExpense}
                    />


                </Box>
            </Modal>
        </div>
    );
}








