import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import Navbar from "../../Components/Navbar";
import CustomButton from "../../Components/Button";
import axios from "axios";
import { updateVat } from "../../Redux/LoginReduces";
import { useDispatch, useSelector } from "react-redux";
import { Base_Uri, Image_Uri } from "../../Constant/BaseUri";
import { RingLoader } from "react-spinners";
import { Colors } from "../../Constant/Color";
import moment from "moment/moment";

function CompanyInformation() {
  const dispatch = useDispatch();

  const Information = useSelector((state) => state.loginReducer.vat);

  const [companyInformation, setCompanyInformation] = useState({
    companyName: Information?.companyName,
    companyLogo: Information?.companyLogo,
    city: Information?.city,
    postCode: Information?.postCode,
    companyAddress: Information?.companyAddress,
    companyEmail: Information?.companyEmail,
    mobileNumber: Information?.mobileNumber,
    companyNumber: Information?.companyNumber,
    vatNumber: Information?.vatNumber,
    vatRate: Information?.vatRate,
    startTime: Information?.startTime,
    endTime: Information?.posEndTime,
    _id: Information?._id,
  });

  const [imagePreview, setImagePreview] = useState("");

  const [selectedFile, setSelectedFile] = useState("");

  useEffect(() => {
    const startTime = Information?.startTime;

    const endTime = Information?.endTime;

    let formattedTime;

    if (startTime) {
      const [hours, minutes] = startTime.split(":");

      // Create a moment object and set the hours and minutes
      const date = moment()
        .hours(parseInt(hours, 10))
        .minutes(parseInt(minutes, 10));


      // Use Moment.js to format the time in the desired format
      formattedTime = date.format("HH:mm");
    }

    let endFormatTime;

    if (endTime) {
      const [hours, minutes] = endTime.split(":");

      // Create a moment object and set the hours and minutes
      const date = moment()
        .hours(parseInt(hours, 10))
        .minutes(parseInt(minutes, 10));
      endFormatTime = date.format("HH:mm");
    }

    setCompanyInformation({
      companyName: Information?.companyName,
      companyAddress: Information?.companyAddress,
      companyEmail: Information?.companyEmail,
      mobileNumber: Information?.mobileNumber,
      companyNumber: Information?.companyNumber,
      vatNumber: Information?.vatNumber,
      vatRate: Information?.vatRate,
      startTime: formattedTime,
      city: Information?.city,
      postCode: Information?.postCode,
      endTime: endFormatTime,
      companyLogo: Information?.companyLogo,
      _id: Information?._id,
    });
  }, [Information?._id]);

  const [loading, setLoading] = useState(false);

  function objectToFormData(obj) {
    const formData = new FormData();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        formData.append(key, obj[key]);
      }
    }

    return formData;
  }


  const handleSaveCompanyInformation = async () => {
    let flag = Object.values(companyInformation).some((e, i) => !e);

    if (flag) {
      alert("Required fields are missing");
      return;
    }

    setLoading(true);

    const formData = await objectToFormData(companyInformation);
    formData.append("companyImage", selectedFile);

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .put(`${Base_Uri}changeCompanyInfomation`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          let data = res.data;

          if (!data?.status) {
            setLoading(false);
            alert(data?.message);
            return;
          }

          alert(data.message);
          setLoading(false);
          dispatch(updateVat(data.data));
        })
        .catch((error) => {
          alert(error?.message);
          setLoading(false);
          return;
        });
    }
  };

  const getVat = async () => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getVat`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let { data } = res;

        if (data.status) {

          dispatch(updateVat(data.data));
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getVat();
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImagePreview(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  return loading ? (
    <Box
      style={{
        width: "100vw",
        height: "80vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <RingLoader loading={loading} size={100} color={Colors.black} />
    </Box>
  ) : (
    <Box>
      <Navbar currentPage="Company Information" />

      <Box
        style={{
          padding: 20,
          width: "100%",
          border: "2px solid",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div>
          <label htmlFor="avatar">
            <img
              src={
                imagePreview
                  ? imagePreview
                  : `${Image_Uri}companyImage/${companyInformation?.companyLogo}`
              }
              className="avatar-image"
              style={{ width: 150, height: 150, borderRadius: 10 }}
            />
          </label>
          <input
            type="file"
            id="avatar"
            accept="image/*"
            style={{
              display: "none",
              border: "2px solid red",
              padding: 10,
            }}
            onChange={handleImageChange}
          />
        </div>

        <Box>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: 18,
              fontWeight: "600",
              textAlign: "left",
            }}
          >
            Company Name:
          </Typography>

          <TextField
            variant="standard" // <== changed this
            margin="normal"
            required
            value={companyInformation?.companyName}
            onChange={(e) =>
              setCompanyInformation({
                ...companyInformation,
                companyName: e?.target?.value,
              })
            }
            InputProps={{
              disableUnderline: true,
              fontSize: "12px",
            }}
            placeholder="Enter Company Name"
            sx={{
              width: "400px",
              fontSize: "12px",
              padding: 1,
              borderRadius: 2,
              border: "1px solid black",
              fontFamily: "Poppins",
              fontWeight: "bold",
              margin: 0,
            }}
          />
        </Box>

        <Box style={{ marginTop: 10 }}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: 18,
              fontWeight: "600",
              textAlign: "left",
            }}
          >
            Mobile Number:
          </Typography>

          <TextField
            variant="standard" // <== changed this
            margin="normal"
            required
            value={companyInformation?.mobileNumber}
            onChange={(e) =>
              setCompanyInformation({
                ...companyInformation,
                mobileNumber: e?.target?.value,
              })
            }
            InputProps={{
              disableUnderline: true,
              fontSize: "12px",
            }}
            placeholder="Enter Mobile Number"
            sx={{
              width: "400px",
              fontSize: "12px",
              padding: 1,
              borderRadius: 2,
              border: "1px solid black",
              fontFamily: "Poppins",
              fontWeight: "bold",
              margin: 0,
            }}
          />
        </Box>

        <Box style={{ marginTop: 10 }}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: 18,
              fontWeight: "600",
              textAlign: "left",
            }}
          >
            Company Address:
          </Typography>

          <TextField
            variant="standard" // <== changed this
            margin="normal"
            required
            value={companyInformation?.companyAddress}
            onChange={(e) =>
              setCompanyInformation({
                ...companyInformation,
                companyAddress: e?.target?.value,
              })
            }
            InputProps={{
              disableUnderline: true,
              fontSize: "12px",
            }}
            placeholder="Enter Company Address"
            sx={{
              width: "400px",
              fontSize: "12px",
              padding: 1,
              borderRadius: 2,
              border: "1px solid black",
              fontFamily: "Poppins",
              fontWeight: "bold",
              margin: 0,
            }}
          />
        </Box>
        <Box style={{ marginTop: 10 }}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: 18,
              fontWeight: "600",
              textAlign: "left",
            }}
          >
            City`:
          </Typography>

          <TextField
            variant="standard" // <== changed this
            margin="normal"
            required
            value={companyInformation?.city}
            onChange={(e) =>
              setCompanyInformation({
                ...companyInformation,
                city: e?.target?.value,
              })
            }
            InputProps={{
              disableUnderline: true,
              fontSize: "12px",
            }}
            placeholder="Enter Company City"
            sx={{
              width: "400px",
              fontSize: "12px",
              padding: 1,
              borderRadius: 2,
              border: "1px solid black",
              fontFamily: "Poppins",
              fontWeight: "bold",
              margin: 0,
            }}
          />
        </Box>
        <Box style={{ marginTop: 10 }}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: 18,
              fontWeight: "600",
              textAlign: "left",
            }}
          >
            Postal Code
          </Typography>

          <TextField
            variant="standard" // <== changed this
            margin="normal"
            required
            value={companyInformation?.postCode}
            onChange={(e) =>
              setCompanyInformation({
                ...companyInformation,
                postCode: e?.target?.value,
              })
            }
            InputProps={{
              disableUnderline: true,
              fontSize: "12px",
            }}
            placeholder="Enter Postal Code"
            sx={{
              width: "400px",
              fontSize: "12px",
              padding: 1,
              borderRadius: 2,
              border: "1px solid black",
              fontFamily: "Poppins",
              fontWeight: "bold",
              margin: 0,
            }}
          />
        </Box>

        <Box style={{ marginTop: 10 }}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: 18,
              fontWeight: "600",
              textAlign: "left",
            }}
          >
            Company Email:
          </Typography>

          <TextField
            variant="standard" // <== changed this
            margin="normal"
            required
            value={companyInformation?.companyEmail}
            onChange={(e) =>
              setCompanyInformation({
                ...companyInformation,
                companyEmail: e?.target?.value,
              })
            }
            InputProps={{
              disableUnderline: true,
              fontSize: "12px",
            }}
            placeholder="Enter Company Email"
            sx={{
              width: "400px",
              fontSize: "12px",
              padding: 1,
              borderRadius: 2,
              border: "1px solid black",
              fontFamily: "Poppins",
              fontWeight: "bold",
              margin: 0,
            }}
          />
        </Box>

        <Box style={{ marginTop: 10 }}>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: 18,
              fontWeight: "600",
              textAlign: "left",
            }}
          >
            Company Number:
          </Typography>

          <TextField
            variant="standard" // <== changed this
            margin="normal"
            required
            value={companyInformation?.companyNumber}
            onChange={(e) =>
              setCompanyInformation({
                ...companyInformation,
                companyNumber: e?.target?.value,
              })
            }
            InputProps={{
              disableUnderline: true,
              fontSize: "12px",
            }}
            placeholder="Enter Company Number"
            sx={{
              width: "400px",
              fontSize: "12px",
              padding: 1,
              borderRadius: 2,
              border: "1px solid black",
              fontFamily: "Poppins",
              fontWeight: "bold",
              margin: 0,
            }}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "400px",
          }}
        >
          <Box style={{ marginTop: 10 }}>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: 18,
                fontWeight: "600",
                textAlign: "left",
              }}
            >
              Vat Number:
            </Typography>

            <TextField
              variant="standard" // <== changed this
              margin="normal"
              required
              value={companyInformation?.vatNumber}
              onChange={(e) =>
                setCompanyInformation({
                  ...companyInformation,
                  vatNumber: e?.target?.value,
                })
              }
              InputProps={{
                disableUnderline: true,
                fontSize: "12px",
              }}
              placeholder="Enter Vat Number"
              sx={{
                width: "190px",
                fontSize: "12px",
                padding: 1,
                borderRadius: 2,
                border: "1px solid black",
                fontFamily: "Poppins",
                fontWeight: "bold",
                margin: 0,
              }}
            />
          </Box>

          <Box style={{ marginTop: 10 }}>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: 18,
                fontWeight: "600",
                textAlign: "left",
              }}
            >
              Vat Rate %:
            </Typography>

            <TextField
              variant="standard" // <== changed this
              margin="normal"
              required
              value={companyInformation?.vatRate}
              onChange={(e) =>
                setCompanyInformation({
                  ...companyInformation,
                  vatRate: e?.target?.value,
                })
              }
              InputProps={{
                disableUnderline: true,
                fontSize: "12px",
              }}
              type="number"
              placeholder="Enter Vat Rate %"
              sx={{
                width: "190px",
                fontSize: "12px",
                padding: 1,
                borderRadius: 2,
                border: "1px solid black",
                fontFamily: "Poppins",
                fontWeight: "bold",
                margin: 0,
              }}
            />
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "400px",
          }}
        >
          <Box style={{ marginTop: 10 }}>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: 18,
                fontWeight: "600",
                textAlign: "left",
              }}
            >
              POS Start Time:
            </Typography>

            <input
              type={"time"}
              value={
                companyInformation?.startTime
                  ? companyInformation.startTime
                  : ""
              }
              onChange={(e) =>
                setCompanyInformation({
                  ...companyInformation,
                  startTime: e?.target?.value,
                })
              }
              placeholder="Start Time"
              style={{
                width: "190px",
                fontSize: "14px",
                padding: 12,
                borderRadius: 10,
                border: "1px solid black",
                fontFamily: "Poppins",
                fontWeight: "bold",
                margin: 0,
              }}
            />
          </Box>

          <Box style={{ marginTop: 10 }}>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: 18,
                fontWeight: "600",
                textAlign: "left",
              }}
            >
              POS End Time:
            </Typography>

            <input
              type={"time"}
              value={companyInformation?.endTime}
              onChange={(e) =>
                setCompanyInformation({
                  ...companyInformation,
                  endTime: e?.target?.value,
                })
              }
              placeholder="End Time"
              style={{
                width: "190px",
                fontSize: "14px",
                padding: 12,
                borderRadius: 10,
                border: "1px solid black",
                fontFamily: "Poppins",
                fontWeight: "bold",
                margin: 0,
              }}
            />
          </Box>
        </Box>

        <CustomButton
          onClick={handleSaveCompanyInformation}
          text="Save Company Information"
          style={{
            width: 400,
            marginTop: 5,
            borderRadius: 2,
            fontSize: "14px",
          }}
        />
      </Box>
    </Box>
  );
}

export default CompanyInformation;
