import { createSlice } from '@reduxjs/toolkit';

const loginSlice = createSlice({
    name: 'loginReducer',
    updatedState: '',
    startShift: false,
    startShiftTime: "",
    paymentMethod: "cash"
    ,
    initialState: {
        fullName: null,
        employeeId: null,
        emailAddress: '',
        id: '',
        token: '',
        products: [],
        employeeExpense : "",
        customers: [],
        paymentMethod: "cash",
        cart: [],
        selectedCustomer: "",
        invoices: [],
        vat: "",
        selectedInvoice: "",
        customerType: "",
        endSaleSummary: "",
        employeeShiftRefund : [],
        todayInvoices: "",
        shiftInvoice : [],
        selectedDamageItemCustomer: "",
        saleReturnCart: [],
        allInvoices: [],
        isShiftEnded : false,
        isShiftStarted : false,
        allReturnInvoices: [],
        dayReturnInvoiceOfEmployee: [],
        todayEmployeeClaimInvoices: [],
        todayEmployeeWarrantyInvoices: [],
        suppliers: [],
        make : [],
        totalRevenue: 0,
        totalOrder: 0,
        walkins: 0,
        trashProducts: [],
        productDepartments: [],
        damageProducts: [],
        demandedProducts: [],
        allDayInvoices :[],
        exchangeProducts: {},
        arrangedProducts : [],
        allEmployees : [],
        allReturnProducts : [],
        allExchangeInvoice: [],
        todayExchangeInvoice: [],
        todayExchangeEmployeeInvoices: [],
        purchaseOrders : [],
        shiftStartTime : "",
        colors: [],
        dayExpenses : [],
        dayRefunds : [],
        dayData : "",
        shiftCreditPayment : [],
        dayCreditPayment : [],
    },
    reducers: {

        updateProducts(state, action) {
            state.products = action.payload
        },
        updateCustomers(state, action) {
            state.customers = action.payload
        },

        edit(state, action) {
            state.updatedState = action.payload;
        },
        startShift(state, action) {

         
            state.startShift = action.payload.startShift
            state.startShiftTime = action.payload.startShiftTime
        },
        paymentMethod(state, action) {
            state.paymentMethod = action.payload
        },
        selectInvoicedCustomer(state, action) {
            state.selectedCustomer = action.payload
        },
        updateCart(state, action) {
            state.cart = action.payload
        },
        updateInvoice(state, action) {
            state.invoices = action.payload
        },
        updateShiftStartTime(state, action) {
            state.shiftStartTime = action.payload
        },
        
        viewSelectedInvoice(state, action) {
            state.selectedInvoice = action.payload
        },
        updateCustomerType(state, action) {
            state.customerType = action.payload
        },
        updateVat(state, action) {
            state.vat = action.payload
        },
        updateEndSaleSummary(state, action) {
            state.endSaleSummary = action.payload
        },
        updateTodayAllInvoices(state, action) {
            state.todayInvoices = action.payload
        },

        updateSaleReturnCart(state, action) {
            state.saleReturnCart = action.payload
        },

        updateDamageItemCustomer(state, action) {
            state.selectedDamageItemCustomer = action.payload
        },

        updateAllInvoices(state, action) {
            state.allInvoices = action.payload
        },
        updateShiftInvoices(state, action) {
            state.shiftInvoice = action.payload
        },
        updateAllDayInvoices(state, action) {
            state.allDayInvoices = action.payload
        },
        updateAllReturnInvoices(state, action) {
            state.allReturnInvoices = action.payload
        },
        updateDayReturnInvoiceEmp(state, action) {
            state.dayReturnInvoiceOfEmployee = action.payload
        },
        updateTodayEmployeeClaimInvoices(state, action) {
            state.todayEmployeeClaimInvoices = action.payload
        },
        updateDayExpenses(state, action) {
            state.dayExpenses = action.payload
        },
        updateDayRefund(state, action) {
            state.dayRefunds = action.payload
        },

        updateTodayEmployeeWarrantyInvoices(state, action) {
            state.todayEmployeeWarrantyInvoices = action.payload
        },
        updateSuppliers(state, action) {
            state.suppliers = action.payload
        },
        updateRevenue(state, action) {
            state.totalRevenue = action.payload
        },
        updateOrders(state, action) {
            state.totalOrder = action.payload
        },
        updateDayData(state, action) {
            state.dayData = action.payload
        },
        updateWalkins(state, action) {
            state.walkins = action.payload
        },
        updateTrashProducts(state, action) {
            state.trashProducts = action.payload
        },
        updateProductDepartment(state, action) {
            state.productDepartments = action.payload
        },
        updateDamageProducts(state, action) {
            state.damageProducts = action.payload
        },
        updateDemandedProducts(state, action) {
            state.demandedProducts = action.payload
        },
        
        updateShift(state, action) {
            state.isShiftStarted = action.payload
        },
        updateShiftEnded(state, action) {
            state.isShiftEnded = action.payload
        },
    

        updateExhangeProducts(state, action) {
            state.exchangeProducts = action.payload
        },
        updateAllExchangeInvoice (state,action){
            state.allExchangeInvoice = action?.payload
        },
        updateTodayExchangeInvoice (state,action){
            state.todayExchangeInvoice = action?.payload
        },
        updatetodayEmployeeExchangeInvoice (state,action){
            state.todayExchangeEmployeeInvoices = action?.payload
        },
        updateArrangedProducts (state,action){
            state.arrangedProducts = action?.payload
        },
        updateAllEmployees (state,action){
            state.allEmployees = action?.payload
        },
        updatePurchaseOrders (state,action){
            state.purchaseOrders = action?.payload
        },
        updateProductMake (state,action){
            state.make = action?.payload
        },
        updateColors (state,action){
            state.colors = action?.payload
        },
        updateAllReturnProducts (state,action){
            state.allReturnProducts = action?.payload
        },
        updateEmployeeExpense (state,action){
            state.employeeExpense = action?.payload
        },
        updateEmployeeShiftRefund (state,action){
            state.employeeShiftRefund = action?.payload
        },
        updateShiftCreditPayment (state,action){
            state.shiftCreditPayment = action?.payload
        },
        updateDayCreditPayment (state,action){
            state.dayCreditPayment = action?.payload
        },
        
    },
});

export const { edit, startShift, paymentMethod, updateProducts, updateCustomers, selectInvoicedCustomer, updateCart, updateInvoice,
    viewSelectedInvoice, updateCustomerType, updateVat, updateEndSaleSummary, updateTodayAllInvoices, updateSaleReturnCart, updateDamageItemCustomer
    , updateAllInvoices, updateDayReturnInvoiceEmp,
    updateTodayEmployeeClaimInvoices,
    updateTodayEmployeeWarrantyInvoices,
    updateSuppliers,
    updateAllReturnProducts,
    updateAllReturnInvoices,
    updateRevenue,
    updateOrders,
    updateWalkins,
    updateTrashProducts,
    updateProductDepartment,
    updateDamageProducts,
    updateDemandedProducts,
    updateExhangeProducts,updateAllExchangeInvoice,updateTodayExchangeInvoice,updatetodayEmployeeExchangeInvoice,
    updateArrangedProducts,updateAllEmployees,updatePurchaseOrders,updateProductMake,updateColors,updateShiftInvoices,
    updateAllDayInvoices,updateEmployeeExpense,updateShift,updateShiftEnded,updateEmployeeShiftRefund,
    updateShiftStartTime,updateDayExpenses,updateDayRefund,updateDayData,updateShiftCreditPayment,
    updateDayCreditPayment

} = loginSlice.actions;


export const selectEdit = (state) => state.edit;
export const selectStartShift = (state) => state.startShift;
export const selectPaymentMethod = (state) => state.paymentMethod;
export const selectProduct = (state) => state.products;
export const selectCustomers = (state) => state.customers;



export default loginSlice.reducer;