import { Box, Button, Grid, TextField, Typography, alpha, colors } from '@mui/material';
import React, { useMemo, useRef, useState, useCallback } from 'react';
import { Colors } from '../../Constant/Color';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import BasicSelect from '../../Components/Dropdown';
import { HexColorPicker } from "react-colorful";
import { useDropzone } from 'react-dropzone'
import { useNavigate, useNavigation } from 'react-router-dom';
import CheckBox from '../../Components/checkbox';
import { DatePicker } from '@mui/x-date-pickers';
import { useStaticRangePicker } from '@mui/x-date-pickers-pro/internals/hooks/useStaticRangePicker';
import DatePickers from '../../Components/DatePicker';
import CustomButton from '../../Components/Button';

function EditProductDetails() {


    const navigate = useNavigate()

    var numberToAlphabet = {
        1: 'A',
        2: 'B',
        3: 'C',
        4: 'D',
        5: 'E',
        6: 'F',
        7: 'G',
        8: 'H',
        9: 'I',
        10: 'J',
        11: 'K',
        12: 'L',
        13: 'M',
        14: 'N',
        15: 'O',
        16: 'P',
        17: 'Q',
        18: 'R',
        19: 'S',
        20: 'T',
        21: 'U',
        22: 'V',
        23: 'W',
        24: 'X',
        25: 'Y',
        26: 'Z'
    };

    const [discountCategory, setDiscountCategory] = useState([
        {
            id: 1,
            category: "A",
            name: "A Category"
        },
        {
            id: 2,
            category: "B",
            name: "B Category"
        },
    ])

    const [warehouses, setWarehouses] = useState([
        {
            id: 1,
            name: "Warehouse"
        },
        {
            id: 2,
            name: "Amazon"
        },
        {
            id: 2,
            name: "Website"
        },
    ])

    const [productCategory, setProductCategory] = useState([
        'Food', 'Clothes', 'Other'
    ])
    const [color, setColor] = useState("");
    const [selectedColor, setSelectedColor] = useState("")

    const [showColorPicker, setShowColorPicker] = useState(false)

    const [selectedCategory, setSelectedCategory] = useState("select")
    const [isWarranty, setIsWarraty] = useState(null)
    const [creditLimit, setCreditLimit] = useState(0)

    const [stockCategory, setStockCategory] = useState([
        {
            name: "Fast Selling",
            selected: false,
            id: 1
        },
        {
            name: "OEM",
            selected: false,
            id: 2
        },
        {
            name: "Genuine",
            selected: false,
            id: 3
        },
        {
            name: "Empty",
            selected: false,
            id: 4
        },
        {
            name: "Compatible",
            selected: false,
            id: 5
        },
    ])
    const [stockDate, setStockDate] = useState("")

    const [compatilityData, setCompatibilityData] = useState([
        'A', 'B', 'C'
    ])

    const [supplierData, setSupplierData] = useState([
        'A Supplier', 'B Supplier', 'C Supplier'
    ])

    const [selectedCompatibility, setSelectedCompatibility] = useState("select")
    const [selectedSupplier, setSelectedSupplier] = useState("select")

    const selectStockCategory = (ind) => {

        setStockCategory(stockCategory.map((e, i) => {
            if (ind == i) {
                return {
                    ...e,
                    selected: e?.selected ? false : true
                }
            } else {
                return e
            }
        }))


    }

    const deleteCategory = (ind) => {

        setDiscountCategory(discountCategory.filter((e, i) => i !== ind))

    }

    const deleteWarehouse = (ind) => {

        setWarehouses(warehouses.filter((e, i) => i !== ind))

    }

    const addCategory = () => {

        let length = discountCategory.length

        let alphabetToGet = length + 1

        let alphabet = numberToAlphabet[alphabetToGet]

        let category = [...discountCategory]

        category.push(
            {
                id: alphabetToGet,
                category: alphabet?.toString().toUpperCase(),
                name: `${alphabet} Category`
            }
        )


        setDiscountCategory(category)



    }




    return (
        <Box style={{ color: 'black' }}>




            <Box
                style={{
                    width: '100%',
                    marginTop: 30,
                    padding: "20px 10px 0px 10px",
                    backgroundColor: Colors?.white,
                }}
            >




                <Grid container spacing={2}>






                    <Grid item xs={12} sm={12} md={6}>
                        <Box style={{ padding: 10 }}>
                            {/* Content for the second column */}




                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: 15 }} >

                                <Typography style={{ fontSize: 20, fontWeight: "bold", fontFamily: "Poppins" }} >
                                    Inventory Details
                                </Typography>


                            </Box>

                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, marginTop: 10, borderRadius: 5, display: "flex", alignItems: "flex-start", flexDirection: "column" }} >
                                <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                    <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                        Make
                                    </Typography>


                                </Box>
                                <TextField
                                    // value={data.cityName}
                                    // onChange={(e) =>
                                    //   setData((prev) => ({ ...prev, cityName: e.target.value }))
                                    // }

                                    // variant="standard"
                                    size="small"
                                    placeholder="Enter Make"
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, marginTop: 0.5, fontFamily: "Poppins", fontWeight: "bold" }}
                                />

                                <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                    <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                        Model/SKU
                                    </Typography>


                                </Box>
                                <TextField
                                    // value={data.cityName}
                                    // onChange={(e) =>
                                    //   setData((prev) => ({ ...prev, cityName: e.target.value }))
                                    // }

                                    // variant="standard"
                                    size="small"
                                    placeholder="Enter Model"
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, marginTop: 0.5, fontFamily: "Poppins", fontWeight: "bold" }}
                                />

                                <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                    <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                        Quantity
                                    </Typography>


                                </Box>
                                <TextField
                                    // value={data.cityName}
                                    // onChange={(e) =>
                                    //   setData((prev) => ({ ...prev, cityName: e.target.value }))
                                    // }

                                    // variant="standard"
                                    size="small"
                                    placeholder="Add Product Quantity"
                                    type="number"
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, marginTop: 0.5, fontFamily: "Poppins", fontWeight: "bold" }}
                                />

                                <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                    <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                        Remind
                                    </Typography>

                                </Box>
                                <TextField
                                    // value={data.cityName}
                                    // onChange={(e) =>
                                    //   setData((prev) => ({ ...prev, cityName: e.target.value }))
                                    // }

                                    // variant="standard"
                                    size="small"
                                    placeholder="Remind me when left"
                                    sx={{ width: "100%", fontSize: "10px", padding: 0, marginTop: 0.5, fontFamily: "Poppins", fontWeight: "bold" }}
                                />

                                <Box sx={{ marginTop: 3, display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }} >
                                    <Typography sx={{ fontSize: "20px", fontWeight: "bold", fontFamily: "Poppins" }} >
                                        Warranty
                                    </Typography>

                                    <Box style={{ display: "flex" }} >

                                        <button onClick={() => setIsWarraty(true)} style={{ border: `1px solid ${Colors.border}`, marginRight: 5, padding: 0, fontSize: "12px", padding: "10px 15px 10px 15px", backgroundColor: isWarranty ? Colors.button : Colors.white, color: isWarranty ? Colors.white : Colors.text, fontFamily: "Poppins", fontWeight: "600" }} >Yes</button>

                                        <button onClick={() => setIsWarraty(false)} style={{ border: `1px solid ${Colors.border}`, marginRight: 5, padding: 0, fontSize: "12px", padding: "10px 15px 10px 15px", backgroundColor: !isWarranty ? Colors.button : Colors.white, color: !isWarranty ? Colors.white : Colors.text, fontFamily: "Poppins", fontWeight: "600" }} >No</button>

                                    </Box>


                                </Box>





                            </Box>



                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: 15 }} >

                                <Typography style={{ fontSize: 20, fontWeight: "bold", fontFamily: "Poppins" }} >
                                    Discount/Offer
                                </Typography>


                            </Box>

                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, marginTop: 10, borderRadius: 5, display: "flex", alignItems: "flex-start", flexDirection: "column" }} >

                                {discountCategory && discountCategory?.length > 0 && discountCategory?.map((e, i) => {
                                    return (

                                        <Box sx={{ width: "100%", marginTop: 2 }} >
                                            <Grid container sm={12} md={12} xl={12} xs={12} sx={{ display: "flex", alignItems: "center" }} >
                                                <Grid item xs={12} sm={3} md={4} lg={3} xl={2} >
                                                    <Typography sx={{ color: Colors.text, fontSize: { xs: "10px", sm: "12px", md: "14px" }, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                        {e?.name}
                                                    </Typography>
                                                </Grid>


                                                <Grid item xs={9} sm={7} md={6} lg={7} xl={9} >
                                                    <TextField
                                                        // value={data.cityName}
                                                        // onChange={(e) =>
                                                        //   setData((prev) => ({ ...prev, cityName: e.target.value }))
                                                        // }

                                                        // variant="standard"
                                                        placeholder={`Enter Discount For ${e?.category} Category`}
                                                        size="small"
                                                        sx={{ width: "100%", fontSize: "10px", padding: 0, marginTop: 0.5, fontFamily: "Poppins", fontWeight: "bold" }}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} sm={2} md={2} lg={2} xl={1} >
                                                    <DeleteIcon onClick={() => deleteCategory(i)} fontSize={"large"} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )
                                })}


                                {/* <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: { xs: "flex-start", sm: "center" }, flexDirection: { xs: "column", sm: "row" }, marginTop: 2 }} >
                                    <Grid xs={4} sm={3} md={4} lg={3} xl={2} >
                                        <Typography sx={{ color: Colors.text, fontSize: { xs: "10px", sm: "12px", md: "14px" }, fontWeight: "bold", fontFamily: "Poppins" }} >
                                            B Category
                                        </Typography>
                                    </Grid>


                                    <Grid xs={8} sm={7} md={6} lg={7} xl={9} >
                                        <TextField
                                            // value={data.cityName}
                                            // onChange={(e) =>
                                            //   setData((prev) => ({ ...prev, cityName: e.target.value }))
                                            // }

                                            // variant="standard"
                                            size="small"
                                            placeholder="Enter Discount For B Category"
                                            sx={{ width: "100%", fontSize: "10px", padding: 0, marginTop: 0.5, fontFamily: "Poppins", fontWeight: "bold" }}
                                        />
                                    </Grid>
                                    <Grid xs={4} sm={2} md={2} lg={2} xl={1} >
                                        <DeleteIcon fontSize={"large"} />
                                    </Grid>
                                </Box> */}

                                <Box style={{ padding: "15px 0px 0px 0px", marginTop: 5, borderRadius: 5, display: "flex", alignItems: "flex-start", flexDirection: "column", width: "100%" }} >
                                    <Button onClick={addCategory} style={{ color: "#141414", fontSize: 14, fontWeight: "600", fontFamily: "Poppins", textAlign: "center", width: "100%", border: `1px solid ${Colors.border}`, backgroundColor: Colors.main, padding: 10 }} >
                                        Another Discount Category
                                    </Button>


                                </Box>
                            </Box>




                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: 15 }} >

                                <Typography style={{ fontSize: 20, fontWeight: "bold", fontFamily: "Poppins" }} >
                                    Credit Limitation
                                </Typography>

                                <input type="number" style={{ width: 120, textAlign: "right", border: `1px solid ${Colors.border}`, borderRadius: 5, color: creditLimit ? Colors.black : Colors.border, padding: 12, fontSize: 14 }} placeholder='$0.00' onChange={(e) => setCreditLimit(e.target.value)} />

                            </Box>




                            <Button style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: 5, border: `2px dotted ${Colors.button}`, padding: 10, marginTop: 15, backgroundColor: Colors.buttonLight }} >

                                <Typography sx={{ fontSize: { sm: "14px", md: "18px" }, color: Colors.button, fontFamily: "Poppins", fontWeight: "600" }} >
                                    List On Other Platform
                                </Typography>

                            </Button>



                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, marginTop: 10, borderRadius: 5, display: "flex", alignItems: "flex-start", flexDirection: "column" }} >

                                {warehouses && warehouses?.length > 0 && warehouses?.map((e, i) => {
                                    return (

                                        <Box sx={{ width: "100%", marginTop: 2 }} >
                                            <Grid container sm={12} md={12} xl={12} xs={12} sx={{ display: "flex", alignItems: "center" }} >
                                                <Grid item xs={12} sm={3} md={4} lg={3} xl={2} >
                                                    <Typography sx={{ color: Colors.text, fontSize: { xs: "10px", sm: "12px", md: "14px" }, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                        {e?.name}
                                                    </Typography>
                                                </Grid>


                                                <Grid item xs={9} sm={7} md={6} lg={7} xl={9} >
                                                    <TextField
                                                        // value={data.cityName}
                                                        // onChange={(e) =>
                                                        //   setData((prev) => ({ ...prev, cityName: e.target.value }))
                                                        // }

                                                        // variant="standard"
                                                        size="small"
                                                        sx={{ width: "100%", fontSize: "10px", padding: 0, marginTop: 0.5, fontFamily: "Poppins", fontWeight: "bold" }}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} sm={2} md={2} lg={2} xl={1} >
                                                    <DeleteIcon onClick={() => deleteWarehouse(i)} fontSize={"large"} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )
                                })}

                            </Box>



                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: 20 }} >

                                <Typography sx={{ fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "22px", xl: "24px" }, fontWeight: "bold", fontFamily: "Poppins" }} >
                                    Supplier Details
                                </Typography>
                                <Button style={{ borderRadius: 20, backgroundColor: Colors.button, color: Colors.white, fontSize: 12, padding: 10, fontFamily: "Poppins", fontWeight: "bold", marginBottom: 5 }} >
                                    Add New Supplier
                                </Button>
                            </Box>



                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, marginTop: 10, borderRadius: 5 }} >

                                <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 0 }} >
                                    <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                        Supplier
                                    </Typography>


                                </Box>
                                <BasicSelect style={{ marginTop: 0.5 }} value={selectedSupplier} status="Select Supplier" id={supplierData} width={"100%"} onChange={(e) => setSelectedSupplier(e)} />



                                <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }} >
                                    <Grid xs={12} md={5.5} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Delivery Not No
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="00.00"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>


                                    </Grid>
                                    <Grid xs={12} md={6} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Minimum Order Quantity
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="00.00"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>

                                    </Grid>
                                </Grid>


                                <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }} >
                                    <Grid xs={12} md={5.5} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Purchase Order Reference
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="Order Reference"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>


                                    </Grid>
                                    <Grid xs={12} md={6} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Quantity
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="00.00"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>

                                    </Grid>
                                </Grid>



                            </Box>




                        </Box>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Box style={{ padding: 10 }}>
                            {/* Content for the first column */}

                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} >

                                <Typography sx={{ fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "22px", xl: "24px" }, fontWeight: "bold", fontFamily: "Poppins" }} >
                                    Cost Price
                                </Typography>
                            </Box>
                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, marginTop: 10, borderRadius: 5 }} >
                                <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between" }} >
                                    <Grid xs={12} md={5.5} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Cost (X VAT)
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >
                                            <Box sx={{ width: "30px", height: "30px", borderRadius: "5px", backgroundColor: Colors.border, fontSize: "16px", color: "#141414", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                €
                                            </Box>
                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="00.00"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>


                                    </Grid>
                                    <Grid xs={12} md={6} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Cost (W VAT)
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >
                                            <Box sx={{ width: "30px", height: "30px", borderRadius: "5px", backgroundColor: Colors.border, fontSize: "16px", color: "#141414", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                €
                                            </Box>
                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="00.00"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>



                                    </Grid>
                                </Grid>
                            </Box>

                            <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px", lg: "15px", xl: "16px" }, marginTop: "5px", color: Colors.text, textAlign: "right", width: "100%", fontFamily: "Poppins", fontWeight: "600" }} >
                                Print
                            </Typography>


                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} >

                                <Typography sx={{ fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "22px", xl: "24px" }, fontWeight: "bold", fontFamily: "Poppins" }} >
                                    Trade Price (A)
                                </Typography>
                            </Box>
                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, marginTop: 10, borderRadius: 5 }} >
                                <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between" }} >
                                    <Grid xs={12} md={5.5} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Cost (X VAT)
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >
                                            <Box sx={{ width: "30px", height: "30px", borderRadius: "5px", backgroundColor: Colors.border, fontSize: "16px", color: "#141414", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                €
                                            </Box>
                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="00.00"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>


                                    </Grid>
                                    <Grid xs={12} md={6} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Cost (W VAT)
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >
                                            <Box sx={{ width: "30px", height: "30px", borderRadius: "5px", backgroundColor: Colors.border, fontSize: "16px", color: "#141414", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                €
                                            </Box>
                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="00.00"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>



                                    </Grid>
                                </Grid>
                            </Box>

                            <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px", lg: "15px", xl: "16px" }, marginTop: "5px", color: Colors.text, textAlign: "right", width: "100%", fontFamily: "Poppins", fontWeight: "600" }} >
                                Print
                            </Typography>

                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} >

                                <Typography sx={{ fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "22px", xl: "24px" }, fontWeight: "bold", fontFamily: "Poppins" }} >
                                    Warehouse Price
                                </Typography>
                            </Box>
                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, marginTop: 10, borderRadius: 5 }} >
                                <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between" }} >
                                    <Grid xs={12} md={5.5} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Cost (X VAT)
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >
                                            <Box sx={{ width: "30px", height: "30px", borderRadius: "5px", backgroundColor: Colors.border, fontSize: "16px", color: "#141414", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                €
                                            </Box>
                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="00.00"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>


                                    </Grid>
                                    <Grid xs={12} md={6} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Cost (W VAT)
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >
                                            <Box sx={{ width: "30px", height: "30px", borderRadius: "5px", backgroundColor: Colors.border, fontSize: "16px", color: "#141414", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                €
                                            </Box>
                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="00.00"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>



                                    </Grid>
                                </Grid>
                            </Box>

                            <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px", lg: "15px", xl: "16px" }, marginTop: "5px", color: Colors.text, textAlign: "right", width: "100%", fontFamily: "Poppins", fontWeight: "600" }} >
                                Print
                            </Typography>


                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} >

                                <Typography sx={{ fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "22px", xl: "24px" }, fontWeight: "bold", fontFamily: "Poppins" }} >
                                    Retail Price (R)
                                </Typography>
                            </Box>
                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, marginTop: 10, borderRadius: 5 }} >
                                <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between" }} >
                                    <Grid xs={12} md={5.5} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Cost (X VAT)
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >
                                            <Box sx={{ width: "30px", height: "30px", borderRadius: "5px", backgroundColor: Colors.border, fontSize: "16px", color: "#141414", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                €
                                            </Box>
                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="00.00"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>


                                    </Grid>
                                    <Grid xs={12} md={6} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Cost (W VAT)
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >
                                            <Box sx={{ width: "30px", height: "30px", borderRadius: "5px", backgroundColor: Colors.border, fontSize: "16px", color: "#141414", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                €
                                            </Box>
                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="00.00"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>



                                    </Grid>
                                </Grid>
                            </Box>

                            <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px", lg: "15px", xl: "16px" }, marginTop: "5px", color: Colors.text, textAlign: "right", width: "100%", fontFamily: "Poppins", fontWeight: "600" }} >
                                Print
                            </Typography>
                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} >

                                <Typography sx={{ fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "22px", xl: "24px" }, fontWeight: "bold", fontFamily: "Poppins" }} >
                                    Transportation
                                </Typography>
                            </Box>
                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, marginTop: 10, borderRadius: 5 }} >
                                <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between" }} >
                                    <Grid xs={12} md={5.5} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Cost (X VAT)
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >
                                            <Box sx={{ width: "30px", height: "30px", borderRadius: "5px", backgroundColor: Colors.border, fontSize: "16px", color: "#141414", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                €
                                            </Box>
                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="00.00"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>


                                    </Grid>
                                    <Grid xs={12} md={6} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Cost (W VAT)
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >
                                            <Box sx={{ width: "30px", height: "30px", borderRadius: "5px", backgroundColor: Colors.border, fontSize: "16px", color: "#141414", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                €
                                            </Box>
                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="00.00"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>



                                    </Grid>
                                </Grid>
                            </Box>

                            <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px", lg: "15px", xl: "16px" }, marginTop: "5px", color: Colors.text, textAlign: "right", width: "100%", fontFamily: "Poppins", fontWeight: "600" }} >
                                Print
                            </Typography>
                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }} >

                                <Typography sx={{ fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "22px", xl: "24px" }, fontWeight: "bold", fontFamily: "Poppins" }} >
                                    Other Expense
                                </Typography>
                            </Box>
                            <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, marginTop: 10, borderRadius: 5 }} >
                                <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between" }} >
                                    <Grid xs={12} md={5.5} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Cost (X VAT)
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >
                                            <Box sx={{ width: "30px", height: "30px", borderRadius: "5px", backgroundColor: Colors.border, fontSize: "16px", color: "#141414", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                €
                                            </Box>
                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="00.00"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>


                                    </Grid>
                                    <Grid xs={12} md={6} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                            Cost (W VAT)
                                        </Typography>
                                        <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >
                                            <Box sx={{ width: "30px", height: "30px", borderRadius: "5px", backgroundColor: Colors.border, fontSize: "16px", color: "#141414", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                €
                                            </Box>
                                            <TextField
                                                variant="standard" // <== changed this
                                                margin="normal"
                                                required
                                                fullWidth
                                                sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                placeholder="00.00"
                                                InputProps={{
                                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                                    disableUnderline: true, // <== added this
                                                    // fontSize: "2px"
                                                }}
                                            />
                                        </Box>



                                    </Grid>
                                </Grid>
                            </Box>

                            <Typography sx={{ fontSize: { xs: "12px", sm: "13px", md: "14px", lg: "15px", xl: "16px" }, marginTop: "5px", color: Colors.text, textAlign: "right", width: "100%", fontFamily: "Poppins", fontWeight: "600" }} >
                                Print
                            </Typography>




                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 20, marginBottom: { xs: 2, sm: 2, md: 0 } }} >
                                <Box sx={{ width: { xl: "45%", lg: "55%", md: "65%", sm: "55%", xs: "80%" }, display: "flex", justifyContent: "space-between" }} >
                                    <Button sx={{ backgroundColor: Colors.white, color: Colors.button, border: `1px solid ${Colors.button}`, borderRadius: 5, padding: "10px 25px 10px 25px", fontSize: "14px", fontFamily: "Poppins", fontWeight: "600", width: "48%" }} >
                                        Discard
                                    </Button>

                                    <Button onClick={() => navigate("./PriceDetails")} sx={{ backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: 5, padding: "10px 25px 10px 25px", fontSize: "14px", fontFamily: "Poppins", fontWeight: "600", width: "48%" }} >
                                        Next
                                    </Button>
                                </Box>

                            </Box>






                        </Box>
                    </Grid>


                </Grid>
            </Box>
        </Box>
    )
}

export default EditProductDetails
