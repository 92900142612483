import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { RingLoader } from 'react-spinners';
import { TextField } from '@mui/material';
import { paymentMethod } from '../Redux/LoginReduces';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from './checkbox';
import CloseIcon from '@mui/icons-material/Close';
import ChequeModal from './CheckModal';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { lg: "40%", md: "50%", sm: "80%", xs: "100%" },
  maxHeight: "80vh",
  overflow: "auto",
  borderRadius: "10px",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function PaymentMethodModal({ invoiceData, subtotal, heading, text, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, loading, credit, onChange }) {

  const dispatch = useDispatch()

  let paymentMethods = useSelector(state => state.loginReducer.paymentMethod)


  const [creditDays, setCreditDays] = useState("")
  const [cashAmount, setCashAmount] = useState("")
  const [creditAmount, setCreditAmount] = useState("")
  const [onlineAmount, setOnlineAmount] = useState("")
  const [cardAmount, setCardAmount] = useState("")
  const [chequeAmount, setChequeAmount] = useState("")
  const [transactionId, setTransactionId] = useState("")
  const [referenceId, setReferenceId] = useState("")

  const [chequeDetails, setChequeDetails] = useState({
    chequeNo: "",
    bankName: "",
    day: "",
    month: "",
    year: "",
  });



  const [check3, setCheck3] = useState([
    {
      name: "Cash",
      selected: false,
    },
    {
      name: "Card",
      selected: false,
    },
    {
      name: "Online",
      selected: false,
    },
    {
      name: "Credit",
      selected: true,
    },
    {
      name: "Cheque",
      selected: false,
    },
  ]);



  useEffect(() => {


    if (invoiceData) {
      setCashAmount(invoiceData?.cash ? Number(invoiceData?.cash) : "")
      setCreditAmount(invoiceData?.credit ? Number(invoiceData?.credit) : "")
      setOnlineAmount(invoiceData?.online ? Number(invoiceData?.online) : "")
      setCardAmount(invoiceData?.card ? Number(invoiceData?.card) : "")
      setChequeAmount(invoiceData?.cheque ? Number(invoiceData?.cheque) : "")
      setTransactionId(invoiceData?.transactionId)
      setReferenceId(invoiceData?.referenceId)
      setCreditDays(invoiceData?.creditDays)
      if (invoiceData?.clear_date) {
        setChequeDetails({
          chequeNo: invoiceData?.cheque_no,
          bankName: invoiceData?.bank_name,
          day: new Date(invoiceData?.clear_date).getDay(),
          month: new Date(invoiceData?.clear_date).getMonth(),
          year: new Date(invoiceData?.clear_date).getFullYear(),
        })
      }

    } else {

      setCashAmount("")
      setOnlineAmount("")
      setCardAmount("")
      setChequeAmount("")
    }

  }, [open, invoiceData])



  const checkPaymentMethod = () => {

    setCheck3(check3.map((e, i) => {

      if (paymentMethods.toLowerCase() == e.name.toLowerCase()) {
        dispatch(paymentMethod(e?.name));
        return {
          ...e,
          selected: true
        }
      } else {
        return {
          ...e,
          selected: false
        }

      }

    }))

  }

  useEffect(() => {
    checkPaymentMethod()
  }, [])


  const selectCheck3 = (item, ind) => {

    dispatch(paymentMethod(item?.name));

    setCheck3(
      check3.map((e, i) => {
        if (i == ind) {
          return {
            ...e,
            selected: true,
          };
        } else {
          return e
        }
      })
    );
  };



  const handleConfirmPaymentMethod = () => {

    // if (((Number(subtotal - cashAmount - onlineAmount - cardAmount - chequeAmount) > 0)&&item?.name == "Credit")&&creditDays=="") {

    // }

    let totalCredit = Number(subtotal - (cashAmount || 0) - (onlineAmount || 0) - (cardAmount || 0) - (chequeAmount || 0))

    totalCredit = Number(totalCredit)?.toFixed(2)


    // if (Number(totalCredit) > 0  && !creditDays) {
    //     alert("Credit Days are missing")
    //     return
    // }

    if (cardAmount && !transactionId) {
      alert("Transaction Id is missing")
      return
    }


    // if (onlineAmount && !referenceId) {
    //     alert("Reference Id is missing")
    //     return
    // }



    if (chequeAmount && Object.values(chequeDetails).some((e, i) => !e)) {
      alert("Cheque details is missing")
      return
    }


    let dataToSend = {
      cash: cashAmount,
      credit: Number(totalCredit) > 0 ? Number(totalCredit) : 0,
      online: onlineAmount,
      card: cardAmount,
      cheque: chequeAmount,
      transactionId: cardAmount ? transactionId : "",
      referenceId: onlineAmount ? referenceId : "",
      creditDays: Number(subtotal - cashAmount - onlineAmount - cardAmount - chequeAmount) > 0 ? creditDays : "",
      chequeDetails: chequeAmount ? chequeDetails : ""
    }

    if (dataToSend?.cash !== "" && Number(dataToSend?.cash) < 0) {
      alert("Cash amount cannot be negative")
      return
    }

    if (dataToSend?.online !== "" && Number(dataToSend?.online) < 0) {
      alert("Online amount cannot be negative")
      return
    }


    if (dataToSend?.card !== "" && Number(dataToSend?.card) < 0) {
      alert("Card amount cannot be negative")
      return
    }


    if (dataToSend?.credit !== "" && Number(dataToSend?.credit) < 0) {
      alert("Credit amount cannot be negative")
      return
    }


    if (dataToSend?.cheque !== "" && Number(dataToSend?.cheque) < 0) {
      alert("Cheque amount cannot be negative")
      return
    }


    confirm(dataToSend)


  }



  return (
    <div>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[style, { containerStyle }]}>


          <Box onClick={() => closeModal()} style={{ position: "absolute", top: 10, right: 10 }} >
            <CloseIcon fontSize="large" />
          </Box>

          <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
            {heading}
          </Typography>
          {/* <Typography sx={{ fontSize: "14px", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", mt: 1, padding: "5px 10px 5px 10px ", textAlign: "center", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                        {text}
                    </Typography> */}

          <div style={{ padding: "0 10px", margin: "10px" }}>
            {check3?.map((item, i) => {
              return (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 5,
                    }}
                  >

                    <Typography sx={{ fontSize: "14px", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", mt: 0, padding: "5px 0px 5px 0px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                      {item?.name?.toLowerCase() == "credit" ? "On Account" : item?.name?.toLowerCase() == "online" ? "Bank Transfer" : item?.name}
                    </Typography>
                    {/* <Checkbox
                                        data={item}
                                        onClick={() => selectCheck3(item, ind)}
                                    /> */}
                  </div>


                  {item?.name == "Cash" ? <TextField
                    variant="standard"
                    margin="none"
                    required

                    onChange={(e) => {
                      setCashAmount(e?.target?.value)
                      console.log(e.target.value, "l")
                    }}
                    value={cashAmount}
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      fontSize: "12px",
                      style: {
                        textAlign: "center",
                        fontSize: "16px",
                        alignSelf: "center",
                      },
                    }}
                    type="number"
                    inputProps={{
                      disableUnderline: true,
                      fontSize: "12px",

                    }}
                    placeholder={"Enter Cash Amount"}
                    sx={{
                      width: "100%",
                      // marginTop: "10px",
                      // height: width < 600 ? "25px" : "35px",
                      fontSize: "12px",
                      padding: 1,
                      border: `1px solid ${Colors.border}`,
                      borderRadius: "5px",
                      // height: 200,
                      marginBottom: 2,
                      textAlign: "center", // Move textAlign to sx
                      fontFamily: "Poppins",
                      fontWeight: "bold",

                    }}
                  /> : ""}

                  {item?.name == "Card" ?



                    <TextField
                      variant="standard"
                      margin="none"
                      required

                      onChange={(e) => setCardAmount(e?.target?.value)}
                      value={cardAmount}
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        fontSize: "12px",
                        style: {
                          textAlign: "center",
                          fontSize: "16px",
                          alignSelf: "center",
                        },
                      }}
                      type="number"
                      inputProps={{
                        disableUnderline: true,
                        fontSize: "12px",

                      }}
                      placeholder={"Enter Card Amount"}
                      sx={{
                        width: "100%",
                        // marginTop: "10px",
                        // height: width < 600 ? "25px" : "35px",
                        fontSize: "12px",
                        padding: 1,
                        border: `1px solid ${Colors.border}`,
                        borderRadius: "5px",
                        // height: 200,
                        marginBottom: 2,
                        textAlign: "center", // Move textAlign to sx
                        fontFamily: "Poppins",
                        fontWeight: "bold",

                      }}
                    /> : ""

                  }


                  {cardAmount && i == 1 ?
                    <Box>

                      <Typography sx={{ fontSize: "14px", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", mt: 0, padding: "5px 0px 5px 0px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                        Transaction Id
                      </Typography>

                      <TextField
                        variant="standard"
                        margin="none"
                        required

                        onChange={(e) => setTransactionId(e?.target?.value)}
                        value={transactionId}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          fontSize: "12px",
                          style: {
                            textAlign: "center",
                            fontSize: "16px",
                            alignSelf: "center",
                          },
                        }}
                        type="text"
                        inputProps={{
                          disableUnderline: true,
                          fontSize: "12px",

                        }}
                        placeholder={"Enter Transaction Id"}
                        sx={{
                          width: "100%",
                          // marginTop: "10px",
                          // height: width < 600 ? "25px" : "35px",
                          fontSize: "12px",

                          padding: 1,
                          border: `1px solid ${Colors.border}`,
                          borderRadius: "5px",
                          // height: 200,
                          marginBottom: 2,
                          textAlign: "center", // Move textAlign to sx
                          fontFamily: "Poppins",
                          fontWeight: "bold",

                        }}
                      />
                    </Box>
                    : ""}


                  {item?.name == "Online" ?
                    <TextField
                      variant="standard"
                      margin="none"
                      required
                      onChange={(e) => setOnlineAmount(e?.target?.value)}
                      value={onlineAmount}
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        fontSize: "12px",
                        style: {
                          textAlign: "center",
                          fontSize: "16px",
                          alignSelf: "center",
                        },
                      }}
                      type="number"
                      inputProps={{
                        disableUnderline: true,
                        fontSize: "12px",

                      }}
                      placeholder={"Enter Online Amount"}
                      sx={{
                        width: "100%",
                        // marginTop: "10px",
                        // height: width < 600 ? "25px" : "35px",
                        fontSize: "12px",
                        padding: 1,
                        border: `1px solid ${Colors.border}`,
                        borderRadius: "5px",
                        // height: 200,
                        marginBottom: 2,
                        textAlign: "center", // Move textAlign to sx
                        fontFamily: "Poppins",
                        fontWeight: "bold",

                      }}

                    /> : ""}

                  {/* {onlineAmount && i == 2 ?

                                        <Box>
                                            <Typography sx={{ fontSize: "14px", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", mt: 0, padding: "5px 0px 5px 0px ", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                                                Reference Id
                                            </Typography>


                                            <TextField
                                                variant="standard"
                                                margin="none"
                                                required

                                                onChange={(e) => setReferenceId(e?.target?.value)}
                                                value={referenceId}
                                                fullWidth
                                                InputProps={{
                                                    disableUnderline: true,
                                                    fontSize: "12px",
                                                    style: {
                                                        textAlign: "center",
                                                        fontSize: "16px",
                                                        alignSelf: "center",
                                                    },
                                                }}
                                                type="text"
                                                inputProps={{
                                                    disableUnderline: true,
                                                    fontSize: "12px",

                                                }}
                                                placeholder={"Enter Reference Id"}
                                                sx={{
                                                    width: "100%",
                                                    // marginTop: "10px",
                                                    // height: width < 600 ? "25px" : "35px",
                                                    fontSize: "12px",
                                                    padding: 1,
                                                    border: `1px solid ${Colors.border}`,
                                                    borderRadius: "5px",
                                                    // height: 200,
                                                    marginBottom: 2,
                                                    textAlign: "center", // Move textAlign to sx
                                                    fontFamily: "Poppins",
                                                    fontWeight: "bold",

                                                }}
                                            />
                                        </Box> : ""} */}


                  {item?.name == "Credit" ?
                    <TextField
                      variant="standard"
                      margin="none"
                      required

                      onChange={(e) => {
                        setCreditAmount(e?.target?.value)
                        console.log(e.target.value)
                      }}
                      value={Number(subtotal - cashAmount - onlineAmount - cardAmount - chequeAmount).toFixed(2)}
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        fontSize: "12px",
                        style: {
                          textAlign: "center",
                          fontSize: "16px",
                          alignSelf: "center",
                        },
                      }}
                      type="number"
                      inputProps={{
                        disableUnderline: true,
                        fontSize: "12px",

                      }}
                      placeholder={"Enter Credit Amount"}
                      sx={{
                        width: "100%",
                        // marginTop: "10px",
                        // height: width < 600 ? "25px" : "35px",
                        fontSize: "12px",
                        padding: 1,
                        border: `1px solid ${Colors.border}`,
                        borderRadius: "5px",
                        // height: 200,
                        marginBottom: 2,
                        textAlign: "center", // Move textAlign to sx
                        fontFamily: "Poppins",
                        fontWeight: "bold",

                      }}
                    /> : ""}
                  {((Number(subtotal - cashAmount - onlineAmount - cardAmount - chequeAmount) > 0) && item?.name == "Credit") && <TextField
                    variant="standard"
                    margin="none"
                    required

                    onChange={(e) => {
                      setCreditDays(e?.target?.value)
                      console.log(e.target.value)
                    }}
                    value={creditDays}
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      fontSize: "12px",
                      style: {
                        textAlign: "center",
                        fontSize: "16px",
                        alignSelf: "center",
                      },
                    }}
                    type="number"
                    inputProps={{
                      disableUnderline: true,
                      fontSize: "12px",

                    }}
                    placeholder={"Enter Credit Days"}
                    sx={{
                      width: "100%",
                      // marginTop: "10px",
                      // height: width < 600 ? "25px" : "35px",
                      fontSize: "12px",
                      padding: 1,
                      border: `1px solid ${Colors.border}`,
                      borderRadius: "5px",
                      // height: 200,
                      marginBottom: 2,
                      textAlign: "center", // Move textAlign to sx
                      fontFamily: "Poppins",
                      fontWeight: "bold",

                    }}
                  />}
                  {item?.name == "Cheque" ?
                    <TextField
                      variant="standard"
                      margin="none"
                      required

                      onChange={(e) => setChequeAmount(e?.target?.value)}
                      value={chequeAmount}
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        fontSize: "12px",
                        style: {
                          textAlign: "center",
                          fontSize: "16px",
                          alignSelf: "center",
                        },
                      }}
                      type="number"
                      inputProps={{
                        disableUnderline: true,
                        fontSize: "12px",

                      }}
                      placeholder={"Enter Cheque Amount"}
                      sx={{
                        width: "100%",
                        // marginTop: "10px",
                        // height: width < 600 ? "25px" : "35px",
                        fontSize: "12px",
                        padding: 1,
                        border: `1px solid ${Colors.border}`,
                        borderRadius: "5px",
                        // height: 200,
                        marginBottom: 2,
                        textAlign: "center", // Move textAlign to sx
                        fontFamily: "Poppins",
                        fontWeight: "bold",

                      }}
                    /> : ""}

                  {chequeAmount && i == 4 ? <Box>

                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                      <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize", }} id="modal-modal-description" >
                        Cheque No
                      </Typography>

                      <TextField

                        onChange={(e) => setChequeDetails({ ...chequeDetails, chequeNo: e.target?.value })}
                        value={chequeDetails?.chequeNo}
                        variant="standard" // <== changed this
                        margin="normal"
                        required
                        // fullWidth
                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true,
                          fontSize: "12px",


                          // <== added this
                          // fontSize: "2px"
                        }}
                        placeholder=""
                        sx={{ width: "75%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 0, fontFamily: "Poppins", fontWeight: "bold", ml: 1, margin: 0, }}
                      />

                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                      <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize", }} id="modal-modal-description" >
                        Bank Name
                      </Typography>

                      <TextField

                        onChange={(e) => setChequeDetails({ ...chequeDetails, bankName: e.target?.value })}
                        variant="standard" // <== changed this
                        margin="normal"
                        required
                        value={chequeDetails?.bankName}
                        InputProps={{
                          disableUnderline: true,
                          fontSize: "12px",
                        }}
                        placeholder=""
                        sx={{ width: "75%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 0, fontFamily: "Poppins", fontWeight: "bold", ml: 1, margin: 0 }}
                      />

                    </Box>


                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                      <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize" }} id="modal-modal-description" >
                        Clear Date
                      </Typography>

                      <TextField

                        onChange={(e) => setChequeDetails({ ...chequeDetails, day: e.target?.value })}
                        variant="standard" // <== changed this

                        type='number'
                        value={Number(chequeDetails?.day)}
                        required
                        // fullWidth

                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true,
                          fontSize: "12px",
                          sx: {
                            "& input": {
                              textAlign: "center"
                            }
                          }
                        }}

                        placeholder="DD"
                        sx={{ width: "15%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 0, fontFamily: "Poppins", fontWeight: "bold", }}
                      />


                      <TextField

                        onChange={(e) => setChequeDetails({ ...chequeDetails, month: e.target?.value })}
                        variant="standard" // <== changed this
                        value={Number(chequeDetails?.month)}
                        type='number'
                        required

                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true,
                          fontSize: "12px",
                          sx: {
                            "& input": {
                              textAlign: "center"
                            }
                          }


                          // <== added this
                          // fontSize: "2px"
                        }}
                        placeholder="MM"
                        sx={{ width: "15%", fontSize: "12px", border: `1px solid ${Colors.border}`, ml: 1, padding: 0, fontFamily: "Poppins", fontWeight: "bold", }}
                      />


                      <TextField
                        onChange={(e) => setChequeDetails({ ...chequeDetails, year: e.target?.value })}
                        variant="standard" // <== changed this
                        type='number'
                        required
                        value={Number(chequeDetails?.year)}
                        // fullWidth
                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true,
                          fontSize: "12px",
                          sx: {
                            "& input": {
                              textAlign: "center"
                            }
                          }



                          // <== added this
                          // fontSize: "2px"
                        }}
                        placeholder="YY"
                        sx={{ width: "15%", fontSize: "12px", border: `1px solid ${Colors.border}`, ml: 1, padding: 0, fontFamily: "Poppins", fontWeight: "bold", textAlign: "center", }}
                      />

                    </Box>

                  </Box> : ""}


                </div>
              );
            })}
          </div>



          <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", ...buttonContainerStyle }} >



            <Button onClick={closeModal} sx={{ backgroundColor: Colors.white, color: Colors.button, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", ...buttonStyle }} >
              Cancel
            </Button>


            <Button onClick={() => handleConfirmPaymentMethod()} sx={{
              backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", '&:hover': {
                color: Colors.button, // Set your desired hover color
              }, ...buttonStyle2
            }} >
              {loading ? <RingLoader size={30} loading={loading} /> : "Confirm"}
            </Button>


          </Box>



        </Box>



      </Modal>
    </div>
  );
}