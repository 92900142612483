import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { RingLoader } from 'react-spinners';
import { TextField } from '@mui/material';
import Checkbox from './checkbox';
import { Image_Uri } from '../Constant/BaseUri';
import JsBarcode from 'jsbarcode';
// import QRCode from 'react-qr-code';
import QRCode from 'qrcode';

export default function BarcodePrintModal({ reload, heading, data, setOpenBarcodePrintModal, placeholder, text, emailLoading, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, loading, credit, onChange, emailbutton, emailInvoice }) {


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { lg: emailbutton ? "35%" : "30%", md: "40%", sm: "55%", xs: "100%" },
    borderRadius: "10px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const [labelHeight, setLabelHeight] = React.useState("")
  const [labelWidth, setLabelWidth] = React.useState("")

  const [priceLevel, setPriceLevel] = React.useState([
    {
      type: "A",
      id: 1,
      selected: true
    },
    {
      type: "B",
      id: 2,
      selected: false
    },
    {
      type: "C",
      id: 3,
      selected: false
    },
  ])

  const [withPrice, setWithPrice] = React.useState(false)



  React.useEffect(() => {

    setLabelWidth(100)
    setLabelHeight(30)

  }, [open])





  const handlePrintBarcode = async () => {


    const selectedPriceLevel = priceLevel.filter((e, i) => e?.selected);

    if ((!selectedPriceLevel || selectedPriceLevel?.length === 0) && withPrice) {
      alert('Kindly select price level');
      return;
    }

    let selectedPrice = selectedPriceLevel?.[0];
    const qrCodeSize = 100; // Size of QR code
    const qrCodeUrl = await QRCode.toDataURL(data?.barcode, { width: qrCodeSize, height: qrCodeSize });
    var printWindow = window.open("Barcode", "_blank");
    console.log(data?.barcodeImage, '0000');
    printWindow.document.write(
      `<!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Barcode Slip</title>
      <style>
       
      * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }
        @page {
            size: ${labelWidth * 3.6}px ${labelHeight * 3.6}px;
            margin: 0;
        }
        body {
            margin: 0;
            padding: 0;
            font-family: Poppins;
            overflow:hidden;
        }
        .label {
            width: ${labelWidth * 3.6}px;
            height: ${(labelHeight * 3.6) - 1}px;
            padding: ${withPrice ? '2mm 0.5mm 0mm 0.5mm' : '1mm 0.5mm 0mm 0.5mm'};
            padding-bottom:0;
            margin-left:${withPrice ? '0px' : '30px'};
        }
        .price {
            border: 2px solid black;
            margin: 0;
            padding:2px;
            margin-top:15px;
        }
            .pricefont {
        font-size: 40px;
        color: black;
        font-weight: bold;
        text-align: center;
        font-family : Arial;
            }
        .detailsmake {
           font-size: ${withPrice ? "20px" : "20px"};
            font-weight: 600;
            font-family:Arial;
            font-style:normal;
            margin: 7px 0 0 0;
            padding: 0 0;
            line-height: 1;
            margin-top:${withPrice ? '0px' : '10px'};
        }
            .detailscategory {
            font-size: ${withPrice ? "18px" : "18px"};
            font-weight: bold;
            font-family:Arial;
            font-style:normal;
            margin: 1mm 0 0 0;
            padding: 0 0;
            line-height: 1;
        }
            .detailsImei {
            font-size: ${withPrice ? "18px" : "16px"};
           font-weight: bold;
            font-family:Arial;
            font-style:normal;
            margin: 1mm 0 0 0;
            padding: 0 0;
            line-height: 1;
        }
            .detailscolor {
            font-size: ${withPrice ? "16px" : "16px"};
            font-weight: bold;
            font-family:Arial;
            font-style:normal;
            margin: 1mm 0 0 0;
            padding: 0 0;
            line-height: 1;
        }
        .barcodenumber {
            font-size: ${withPrice ? "16px" : "16px"};
            font-weight: bold;
            margin: 0 0 0 0;
            font-family:Arial;
            padding: 0 0;
            line-height:0.8;
        }

        .barcode {
            margin:0;
            margin-top: ${withPrice ? 5 : 5}px;
            margin-bottom: ${withPrice ? 5 : 0}px;
            width: ${labelWidth}mm;
            height: auto;
        }
            
        .barcode img {
            width: ${withPrice ? (labelWidth - 2) : 40}mm;
            height: ${withPrice ? "6mm" : '6mm'};
        }
            .qrcode{
            width:20mm;
            height:20mm}

      </style>
    </head>
    <body>
      <div class="label">
        ${withPrice ? `
            <div class="price">
            <p class="pricefont" >
              £${selectedPrice?.id == 1 ? Number(data?.trade_price).toFixed(2) : selectedPrice?.id == 2 ? Number(data?.warehouse_price).toFixed(2) : Number(data?.retail_price).toFixed(2)}
            </p>
              </div>` : ""}
        <p class="detailsmake">
          ${data?.make}   ${data?.model}
        </p>
        <p class="detailscategory"  >
          ${data?.category}   ${data?.sub_category}
          </p>

           <p class="detailscolor" >
          ${data?.product_color} ${data?.IMEI && "IMEI:"} ${data?.IMEI ? data?.IMEI : ""}     
          </p>

        <div class="barcode">
        ${withPrice ? `<p class="barcodenumber" style="margin-top:${withPrice && data.codeKey == "bar" ? 10 : 0}px;" >SP: ${data?.barcode}</p>` : ""}
        ${data.codeKey == "qr" ? `<img src="${qrCodeUrl}" style="width: ${qrCodeSize}px; height: ${qrCodeSize}px; position:absolute; bottom:10px; right:10px;" alt="QR Code" />` : `<img src="${Image_Uri}products/${data?.barcodeImage}" alt="Barcode" />`}
        ${withPrice ? "" : `<p class="barcodenumber" >SP: ${data?.barcode}</p>`}
       </div>
      </div>
    </body>
    </html>`
    );

    printWindow.onafterprint = function () {
      try {

      } catch (error) {
        console.error("Error in clearing data:", error);
      } finally {
        printWindow.close();
      }
    };


    setTimeout(() => {
      printWindow.print();
    }, 1000);

  };




  const handleSelectPriceLevel = (ind) => {

    setPriceLevel(priceLevel && priceLevel.map((e, i) => {
      if (ind == i) {
        return {
          ...e,
          selected: !e?.selected
        }
      } else {
        return {
          ...e,
          selected: false
        }
      }
    }))

  }

  return (
    <div>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[style, { containerStyle }]}>
          <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
            {heading}
          </Typography>

          <Box style={{ fontSize: "22px", fontFamily: "Poppins", fontWeight: "bold" }} >
            Select Price Level:

            {priceLevel?.map((e, i) => {
              return (
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                  <Box sx={{ display: "flex", alignItems: "center", width: "50px", justifyContent: "space-between" }} >
                    <Typography style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "bold" }} >{e.type}</Typography>
                    <Checkbox onClick={() => handleSelectPriceLevel(i)} data={e} />
                  </Box>
                  <Typography style={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "600", marginLeft: "10px" }} >£{e.id == 1 ? data?.trade_price : e?.id == 2 ? data?.warehouse_price : data?.retail_price}</Typography>

                </Box>
              )
            })}

          </Box>


          <Box sx={{ width: "100%", display: "flex", flexDirection: "row", marginTop: 2, justifyContent: "space-around", ...buttonContainerStyle }} >

            <Button onClick={() => (setWithPrice(true), setLabelHeight(60), setLabelWidth(110))} sx={{ backgroundColor: withPrice ? Colors.button : Colors.white, color: withPrice ? Colors.white : Colors.button, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", ...buttonStyle }} >
              With Price
            </Button>


            <Button onClick={() => (setWithPrice(false), setLabelHeight(data.codeKey == "qr"?40:30), setLabelWidth(100))} sx={{ backgroundColor: !withPrice ? Colors.button : Colors.white, color: !withPrice ? Colors.white : Colors.button, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", ...buttonStyle }} >
              Without Price
            </Button>

          </Box>
          <Box sx={{ marginTop: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around" }} >

            <Box sx={{ width: "40%" }}  >
              <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                Height
              </Typography>

              <Box


                sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                <TextField

                  onChange={(e) => setLabelHeight(e.target.value)}
                  value={labelHeight}
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  sx={{ width: "100%", fontSize: "12px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold" }}
                  placeholder="Enter Label Height"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Box>

            </Box>

            <Box sx={{ width: "40%" }} >
              <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                Width
              </Typography>

              <Box


                sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >



                <TextField

                  onChange={(e) => setLabelWidth(e.target.value)}
                  value={labelWidth}
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  sx={{ width: "100%", fontSize: "12px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold" }}
                  placeholder="Enter Label Height"
                  InputProps={{
                    disableUnderline: true,

                  }}
                />
              </Box>
            </Box>


          </Box>
          <Button onClick={() => handlePrintBarcode()} sx={{
            backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "100%", mt: 3, borderRadius: 1, fontFamily: "Poppins", '&:hover': {
              color: Colors.button, // Set your desired hover color
            }, ...buttonStyle2
          }} >
            {loading ? <RingLoader size={30} loading={loading} /> : "Print"}
          </Button>

        </Box>

        {/* <img src={require("../Images/bold.png")} id={"#barcode"} style={{display:"none"}} /> */}

      </Modal>
    </div>
  );
}