import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { RingLoader } from 'react-spinners';
import { TextField } from '@mui/material';
import { paymentMethod } from '../Redux/LoginReduces';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from './checkbox';
import BasicSelect from './Dropdown';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { lg: "25%", md: "35%", sm: "50%", xs: "100%" },
    borderRadius: "10px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function OptionModal({ heading, text, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, loading, credit, onChange }) {


    const selectedCustomer = useSelector(
        (state) => state.loginReducer.selectedCustomer
    );


    const [deductBalanceOptions, setDeductBalanceOptions] = useState(["Quotation", "Invoice"])
    const [selectedDeductBalanceOption, setSelectedDeductBalanceOption] = useState("select")



    const [check3, setCheck3] = useState([
        {
            name: "Cash",
            selected: true,
        },
        {
            name: "on Account",
            selected: false,
        },

    ]);



    const selectCheck3 = (item, ind) => {

        setCheck3(
            check3.map((e, i) => {
                if (i == ind) {
                    return {
                        ...e,
                        selected: true,
                    };
                } else {
                    return {
                        ...e,
                        selected: false,
                    };
                }
            })
        );
    };


    return (
        <div>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[style, { containerStyle }]}>
                    <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                        {heading}
                    </Typography>
                    {/* <Typography sx={{ fontSize: "14px", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", mt: 1, padding: "5px 10px 5px 10px ", textAlign: "center", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                        {text}
                    </Typography> */}

                    <div style={{ padding: "0 10px", margin: "10px" }}>
                        {check3?.map((item, ind) => {
                            return (
                                ((item?.name?.toLowerCase() == "on account") || item.name == "Cash") && <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginTop: 5,
                                    }}
                                >
                                    <div
                                        style={{
                                            color: "#636363",
                                            fontSize: 14,
                                            fontFamily: "Poppins",
                                            fontWeight: "500",
                                        }}
                                    >
                                        {item.name}
                                    </div>
                                    <Checkbox
                                        data={item}
                                        onClick={() => selectCheck3(item, ind)}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    {check3?.some((e,i)=>e?.selected && e?.name?.toLowerCase() == "on account") && <BasicSelect id={deductBalanceOptions} status = "select option" value={selectedDeductBalanceOption} width={"100%"} style={{ marginBottom: "10px", marginTop: "8px" }} onChange={(e) => setSelectedDeductBalanceOption(e)} />}



                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", ...buttonContainerStyle }} >

                        <Button onClick={closeModal} sx={{ backgroundColor: Colors.white, color: Colors.button, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", ...buttonStyle }} >
                            Cancel
                        </Button>


                        <Button onClick={() => confirm(check3,selectedDeductBalanceOption)} sx={{
                            backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", '&:hover': {
                                color: Colors.button, // Set your desired hover color
                            }, ...buttonStyle2
                        }} >
                            {loading ? <RingLoader size={30} loading={loading} /> : "Confirm"}
                        </Button>


                    </Box>



                </Box>



            </Modal>
        </div>
    );
}