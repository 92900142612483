// NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
    };

    const headingStyle = {
        marginBottom: '16px',
        fontSize: '2rem',
    };

    const textStyle = {
        fontSize: '1rem',
    };

    const buttonStyle = {
        marginTop: '16px',
        padding: '10px 20px',
        backgroundColor: '#1976D2',
        color: '#fff',
        textDecoration: 'none',
        borderRadius: '4px',
        fontSize: '1rem',
        cursor: 'pointer',
    };

    return (
        <div style={containerStyle}>
            <h1 style={headingStyle}>404 - Not Found</h1>
            <p style={textStyle}>
                Oops! The page you are looking for might be in another castle.
            </p>
            <Link to="/" style={buttonStyle}>
                Go Home
            </Link>
        </div>
    );
};

export default NotFound;
