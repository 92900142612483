import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { optionClasses } from "@mui/joy";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectInvoicedCustomer,
  updateCart,
  updateCustomerType,
} from "../Redux/LoginReduces";

const drawerWidth = 240;

export default function Navbar(props) {
  let { currentPage } = props;

  const userData = useSelector((state) => state.loginReducer.updatedState);

  const dispatch = useDispatch();


  const navigate = useNavigate();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [navItems, setNavItem] = React.useState([
    {
      name: "Point Of Sale",
      img: require("../Images/nav1.png"),
      routeName: "/PointOfSale",
      role: "any",
      selected: true,
    },
    {
      name:
        userData?.role == "admin" ||
        userData?.role_access?.some(
          (e, i) => e?.name == "AllInvoices" && e?.selected
        )
          ? "All Invoices"
          : userData?.role_access?.some(
              (e, i) => e?.name == "TodayInvoices" && e?.selected
            ) && "Today Invoices",
      img: require("../Images/nav1.png"),
      routeName:
        userData?.role == "admin" ||
        userData?.role_access?.some(
          (e, i) => e?.name == "AllInvoices" && e?.selected
        )
          ? "/AllInvoices"
          : userData?.role_access?.some(
              (e, i) => e?.name == "TodayInvoices" && e?.selected
            ) && "/TodayInvoices",
      role: "any",
      selected: true,
    },
    {
      name: "Customer Account",
      img: require("../Images/nav2.png"),
      routeName: "/CustomerAccount",
      role: "admin",
      selected: false,
    },
    {
      name: "Stock Booking",
      img: require("../Images/nav3.png"),
      routeName: "/Home",
      role: "admin",
      selected: false,
    },
    {
      name: "Employees",
      img: require("../Images/nav3.png"),
      routeName: "/Employees",
      role: "admin",
      selected: false,
    },
    {
      name: "Suppliers",
      img: require("../Images/nav3.png"),
      routeName: "/Suppliers",
      role: "admin",
      selected: false,
    },
    {
      name: "Company Information",
      img: require("../Images/nav3.png"),
      routeName: "/CompanyInformation",
      role: "admin",
      selected: false,
    },
    {
      name: "Reports",
      img: require("../Images/nav3.png"),
      routeName: "/Reports",
      role: "admin",
      selected: false,
    },
    // {
    //   name: "Stock Take",
    //   img: require("../Images/nav3.png"),
    //   routeName: "StockTake",
    //   role: "admin",
    //   selected: false,
    // },
    // {
    //   name: "Stock Levels",
    //   img: require("../Images/nav3.png"),
    //   routeName: "/StockLevels",
    //   role: "admin",
    //   selected: false,
    // },
  ]);

  const rightSideItems = [
    { name: "Logout", img: require("../Images/logout.png") },
    { name: "Settings", img: require("../Images/setting.png") },
  ];

  const handleDrawerToggle = (item, ind) => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Sale
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, ind) => {
          if (item.role == userData?.role || item.role == "any") {
            return (
              <ListItem
                style={{
                  padding: currentPage == item?.name ? "0px 4px 0px 4px" : 0,
                  backgroundColor:
                    currentPage == item?.name ? "#D3EAFF" : "white",
                }}
                key={ind}
                disablePadding
              >
                <ListItemButton
                  onClick={() => selectOptions(item, ind)}
                  sx={{ textAlign: "center" }}
                >
                  <img src={item.img} style={{ height: 20 }} />
                  <ListItemText color={"#000000"} primary={item?.name} />
                </ListItemButton>
              </ListItem>
            );
          }
        })}
        {/* {rightSideItems.map((item) => (
          <ListItem key={item?.name} disablePadding>
            <ListItemButton
              onClick={() =>
                item.name.toLowerCase() == "logout" ? handleLogout() : ""
              }
              sx={{ textAlign: "center" }}
            >
              <img src={item.img} style={{ height: 20 }} />
              <ListItemText color={"#000000"} primary={item?.name} />
            </ListItemButton>
          </ListItem>
        ))} */}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const selectOptions = (item, ind) => {
    navigate(item?.routeName);
    setNavItem(
      navItems.map((e, i) => {
        if (i == ind) {
          return {
            ...e,
            selected: true,
          };
        } else {
          return {
            ...e,
            selected: false,
          };
        }
      })
    );

    setMobileOpen(false);
  };

  const handleLogout = async () => {
    dispatch(updateCart([]));
    dispatch(updateCustomerType(""));
    dispatch(selectInvoicedCustomer(""));

    await localStorage.removeItem("userData");
    await localStorage.removeItem("firstLogin");
    await localStorage.removeItem("shiftStartTime");

    navigate("/");
  };

  return (
    <Box sx={{ display: "flex", height: "80px" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        style={{ backgroundColor: "white", borderBottom: "4px solid #0962B4" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            style={{ color: "black" }}
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={require("../Images/logo.png")} style={{ height: 30 }} />
            <Typography
              variant="body1"
              fontWeight={"bold"}
              color={"black"}
              marginLeft={1}
            >
              Sale
            </Typography>
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                lg: "flex",
                sm: "none",
                md: "flex",
                xl: "flex",
              },
              marginLeft: 5,
              flexDirection: "row",
              width: "80%",
              flexWrap: "wrap",
            }}
          >
            {navItems.map((item, ind) => {
              if (
                userData?.role == "admin" ||
                (item?.name == "Point Of Sale" &&
                  userData?.role_access?.some(
                    (e, i) => e?.name == "PointOfSale" && e?.selected
                  )) ||
                userData?.role == "admin" ||
                (item?.name == "All Invoices" &&
                  userData?.role_access?.some(
                    (e, i) => e?.name == "AllInvoices" && e?.selected
                  )) ||
                (item?.name == "Today Invoices" &&
                  userData?.role_access?.some(
                    (e, i) => e?.name == "TodayInvoices" && e?.selected
                  )) ||
                userData?.role == "admin" ||
                (item?.name == "Customer Account" &&
                  userData?.role_access?.some(
                    (e, i) => e?.name == "CustomerAccount" && e?.selected
                  )) ||
                userData?.role == "admin" ||
                (item?.name == "Stock Booking" &&
                  userData?.role_access?.some(
                    (e, i) => e?.name == "StockBooking" && e?.selected
                  )) ||
                userData?.role == "admin" ||
                (item?.name == "Employees" &&
                  userData?.role_access?.some(
                    (e, i) => e?.name == "Employees" && e?.selected
                  )) ||
                userData?.role == "admin" ||
                (item?.name == "Suppliers" &&
                  userData?.role_access?.some(
                    (e, i) => e?.name == "Suppliers" && e?.selected
                  )) ||
                userData?.role == "admin" ||
                (item?.name == "Company Information" &&
                  userData?.role_access?.some(
                    (e, i) => e?.name == "CompanyInformation" && e?.selected
                  )) ||
                userData?.role == "admin" ||
                (item?.name == "Reports" &&
                  userData?.role_access?.some(
                    (e, i) => e?.name == "Reports" && e?.selected
                  ))
              ) {
                return (
                  <Box
                    marginLeft={3}
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: 10,
                      padding: currentPage == item.name ? "0px 4px 0px 4px" : 0,
                      backgroundColor:
                        currentPage == item.name ? "#D3EAFF" : "white",
                    }}
                  >
                    <img src={item.img} style={{ height: 20 }} />
                    <Button
                      key={ind}
                      onClick={() => selectOptions(item, ind)}
                      sx={{
                        color: "#000000",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      {item?.name}
                    </Button>
                  </Box>
                );
              }
            })}
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                lg: "flex",
                sm: "none",
                md: "flex",
                xl: "flex",
              },
              marginLeft: 5,
              flexDirection: "row",
              width: "20%",
            }}
          >
            {rightSideItems.map((item, ind) => (
              <Box
                marginLeft={3}
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={item?.img} style={{ height: 20 }} />
                <Button
                  onClick={() =>
                    item.name.toLowerCase() == "logout" ? handleLogout() : ""
                  }
                  key={ind}
                  sx={{ color: "#000000", fontSize: 12, fontWeight: "bold" }}
                >
                  {item?.name}
                </Button>
              </Box>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}
