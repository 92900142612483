import { Box, Button, Grid, Typography } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import { Colors } from "../../Constant/Color";
import AddNewStock from "./addNewStock";
import PriceDetails from "./addPriceDetails";
import Products from "../Products";
import EditProductDetails from "../Products/editProduct";
import Damages from "../Damages/damages";
import ClaimDamages from "../Damages/claimDamages";
import ClaimReturn from "../Returns/claimReturn";
import WarrantyClaim from "../WarrantyClaim";
import DemandedProduct from "../DemandedProduct/demandedProduct";
import ProductArrangement from "../ProductArrangement/productArrangement";
import ProductArrangementClaim from "../ProductArrangementClaim/ProductArrangementClaim";
import Trash from "../Trash/trash";
import ProductDetails from "../Products/productDetails";
import PurchaseOrders from "./PurchaseOrders";
import Departments from "../Departments";
import ProductMake from "../productMake";
import ProductColor from "../ProductColor";
import useScanDetection from "use-scan-detection";
import { useDispatch, useSelector } from "react-redux";
import { edit } from "../../Redux/LoginReduces";
import { Base_Uri } from "../../Constant/BaseUri";
import axios from "axios";
import { RingLoader } from "react-spinners";

export default function AddProduct() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state.loginReducer.updatedState);

  const [stockBookingSubCategory, setStockBookingSubCategory] = useState([]);
  const [loading, setLoading] = useState(false);

  const [barcodeRead, setBarcodeRead] = useState(false);

  const [options, setOptions] = useState([
    {
      name: "Add New Stock",
      selected: true,
      routeName: "/Home",
    },
    {
      name: "Products",
      selected: false,
      routeName: "Products",
    },
    {
      name: "Damages",
      selected: false,
      routeName: "Damages",
    },
    {
      name: "Demanded Product",
      selected: false,
      routeName: "DemandedProduct",
    },
    {
      name: "Product Arrangement",
      selected: false,
      routeName: "ProductArrangement",
    },
    {
      name: "Bin",
      selected: false,
      routeName: "Trash",
    },
    {
      name: "Purchase Orders",
      selected: false,
      routeName: "PurchaseOrders",
    },
    {
      name: "Departments",
      selected: false,
      routeName: "departments",
    },

    {
      name: "Product Make",
      selected: false,
      routeName: "productMake",
    },
    {
      name: "Product Color",
      selected: false,
      routeName: "productColor",
    },
  ]);

  let location = window.location.href;

  useEffect(() => {
    // Fetch options or set them in any way you want

    let split = location.toString().split("/");

    let homeScreen = split[3];
    let above = split[4];

    setOptions(
      options &&
        options.length > 0 &&
        options.map((e) => {
          if (homeScreen == "Home" && e?.routeName == "/Home" && !above) {
            return {
              ...e,
              selected: true,
            };
          } else if (
            (above == "ProductDetails" || above == "EditProduct") &&
            e?.routeName == "Products"
          ) {
            return {
              ...e,
              selected: true,
            };
          } else if (above == "ClaimDamages" && e?.routeName == "Damages") {
            return {
              ...e,
              selected: true,
            };
          } else if (
            above == "ProductArrangementClaim" &&
            e?.routeName == "ProductArrangement"
          ) {
            return {
              ...e,
              selected: true,
            };
          } else if (above == "Departments" && e?.routeName == "departments") {
            return {
              ...e,
              selected: true,
            };
          } else if (
            location.includes(e?.routeName) &&
            e?.routeName != "/Home"
          ) {
            return {
              ...e,
              selected: true,
            };
          } else {
            return {
              ...e,
              selected: false,
            };
          }
        })
    );
  }, []); // Emp

  useEffect(() => {
    setLoading(true);

    let roles = userData?.role_access;

    let stockBookingRoles =
      roles &&
      roles?.length > 0 &&
      roles.find((e) => e.name?.toLowerCase() == "stockbooking");

    let stockBookingSub = stockBookingRoles?.subcategory;

    let option =
      stockBookingSub &&
      stockBookingSub?.length > 0 &&
      stockBookingSub?.find((e, i) => e?.selected);

    let allOptions =
      options &&
      options.length > 0 &&
      options?.map((e, i) => {
        const name = e?.name.replace(/\s+/g, "");

        if (name?.toLowerCase() == option?.name?.toLowerCase()) {
          return {
            ...e,
            routeName: "/Home",
            selected: true,
          };
        } else {
          return e;
        }
      });

    setOptions(
      allOptions &&
        allOptions?.length > 0 &&
        allOptions?.filter((e, i) => {
          const name = e?.name.replace(/\s+/g, "");

          if (
            userData?.role?.toLowerCase() == "admin" ||
            (stockBookingSub &&
              stockBookingSub?.length > 0 &&
              stockBookingSub?.some(
                (j) =>
                  j.name?.toLowerCase() == name?.toLowerCase() && j?.selected
              ))
          ) {
            return e;
          }
        })
    );

    setStockBookingSubCategory(stockBookingSub);
    setLoading(false);
  }, [userData.id]);

  
  useScanDetection({
    onComplete: (code) => {
      setBarcodeRead(code);
    },
    minLength: 3,
  });

  const onScreenChange = (item, ind) => {
    setOptions(
      options &&
        options?.length > 0 &&
        options?.map((e, i) => {
          if (ind == i) {
            return {
              ...e,
              selected: true,
            };
          } else {
            return {
              ...e,
              selected: false,
            };
          }
        })
    );

    navigate(item?.routeName);
  };

  const isAdmin = userData?.role?.toLowerCase() === "admin";

  const hasAccess = (name) =>
    isAdmin ||
    stockBookingSubCategory?.some(
      (e) => e?.name?.toLowerCase() === name.toLowerCase() && e?.selected
    );

  // Find the first accessible route for the default route
  const defaultRoute =
    options.find((option) => {
      let name = option?.name.replace(/\s+/g, "");


      let isAccess = hasAccess(name);

      return isAccess;
    })?.routeName || "/Home";

  return loading ? (
    <Box
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.main,
      }}
    >
      <RingLoader loading={loading} color={Colors.black} size={100} />
    </Box>
  ) : (
    <Box style={{ backgroundColor: Colors.main, minHeight: "100vh" }}>
      <Navbar currentPage={"Stock Booking"} />

      <Box
        style={{ padding: 20, marginTop: 0, paddingTop: 0, paddingBottom: 0 }}
      >
        <Box
          style={{
            width: "100%",
            backgroundColor: Colors.white,
            padding: 20,
            borderRadius: 10,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-around",
          }}
        >
          <Grid container sm={12} md={12} lg={12}>
            {options &&
              options.length > 0 &&
              options.map((e, i) => {
                return (
                  <Grid key={i} item xs={12} sm={4} md={3} lg={2.5} xl={1.6}>
                    <Button
                      onClick={() => onScreenChange(e, i)}
                      sx={{
                        borderRadius: "10px",
                        marginRight: "5px",
                        backgroundColor: e?.selected
                          ? Colors.button
                          : Colors.main,
                        marginTop: "5px",
                        width: { xl: "99%", xs: "98%" },
                        padding: "10px 15px 10px 15px",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: e?.selected ? Colors.white : Colors.text,
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                      >
                        {e?.name?.toLowerCase() == "damages" ? "Faulty" : e?.name?.toLowerCase() == "demanded product" ? "Missing Stock" :  
                      e?.name?.toLowerCase() == "product arrangement" ? "Product Sourcing" : e?.name
                      }
                      </Typography>
                    </Button>
                  </Grid>
                );
              })}
          </Grid>
        </Box>

        <Routes>
          <Route
            path="/Home"
            element={
              <Navigate
                to={defaultRoute == "/Home" ? "/" : defaultRoute}
                replace
              />
            }
          />
          {options?.length > 0 &&
            options?.map((option, i) => {
              let name = option?.name.replace(/\s+/g, "");

              if (hasAccess(name)) {
                return (
                  <Route
                    key={i}
                    path={
                      option?.routeName == "/Home" ? "/" : option?.routeName
                    }
                    element={
                      option?.name?.toLowerCase() === "add new stock" ? (
                        <AddNewStock />
                      ) : option?.name?.toLowerCase() === "products" ? (
                        <Products
                          setBarcodeRead={setBarcodeRead}
                          barcodeData={hasAccess("products") ? barcodeRead : ""}
                        />
                      ) : option?.name?.toLowerCase() === "products" ? (
                        <EditProductDetails
                          setBarcodeRead={setBarcodeRead}
                          barcodeData={hasAccess("products") ? barcodeRead : ""}
                        />
                      ) : option?.name?.toLowerCase() === "damages" ? (
                        <Damages
                          setBarcodeRead={setBarcodeRead}
                          barcodeData={hasAccess("damages") ? barcodeRead : ""}
                        />
                      ) : option?.name?.toLowerCase() === "demanded product" ? (
                        <DemandedProduct />
                      ) : option?.name?.toLowerCase() ===
                        "product arrangement" ? (
                        <ProductArrangement
                          setBarcodeRead={setBarcodeRead}
                          barcodeData={
                            hasAccess("product arrangement") ? barcodeRead : ""
                          }
                        />
                      ) : option?.name?.toLowerCase() === "bin" ? (
                        <Trash
                          setBarcodeRead={setBarcodeRead}
                          barcodeData={barcodeRead}
                        />
                      ) : option?.name?.toLowerCase() === "purchase orders" ? (
                        <PurchaseOrders
                          setBarcodeRead={setBarcodeRead}
                          barcodeData={
                            hasAccess("purchase orders") ? barcodeRead : ""
                          }
                        />
                      ) : option?.name?.toLowerCase() === "departments" ? (
                        <Departments />
                      ) : option?.name?.toLowerCase() === "product make" ? (
                        <ProductMake />
                      ) : option?.name?.toLowerCase() === "product color" ? (
                        <ProductColor />
                      ) : null
                    }
                  />
                );
              }
              return null;
            })}
        </Routes>
      </Box>
    </Box>
  );
}
