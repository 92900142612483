import React, { useState, useEffect, useReducer, useRef } from "react";
import { Colors } from "../../Constant/Color";
import Navbar from "../../Components/Navbar";
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import profile from "../../Images/profile.png";
import Checkbox from "../../Components/checkbox";
import OnlineTransactionModal from "../../Components/onlineTransactionModal";
import { Route, Routes, useNavigate } from "react-router-dom";
import MainPage from "./Screens";
import SaleItems from "./Screens/SaleItems";
import CustomModal2 from "../../Components/CustomModal2";
import { useDispatch, useSelector } from "react-redux";
import {
  edit,
  paymentMethod,
  selectInvoicedCustomer,
  updateAllDayInvoices,
  updateCart,
  updateCustomerType,
  updateCustomers,
  updateDayCreditPayment,
  updateDayData,
  updateDayExpenses,
  updateDayRefund,
  updateDayReturnInvoiceEmp,
  updateEmployeeExpense,
  updateEmployeeShiftRefund,
  updateEndSaleSummary,
  updateProducts,
  updateShift,
  updateShiftCreditPayment,
  updateShiftEnded,
  updateShiftInvoices,
  updateShiftStartTime,
  updateTodayAllInvoices,
} from "../../Redux/LoginReduces";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Base_Uri } from "../../Constant/BaseUri";
import axios from "axios";
import Webcam from "react-webcam";
import CustomButton from "../../Components/Button";
import CustomPOSModal from "../../Components/PosModal";
import Quicksearch from "./Screens/Quicksearch";
import ProductArrangementModal from "../../Components/productArrangementModal";
import TrashModal from "../../Components/TrashModal";
import ReturnModal from "../../Components/ReturnModal";
import DamagesModal from "../../Components/DamagesModal";
import ClaimModal from "../../Components/ClaimModal";
import { RingLoader } from "react-spinners";
import MultipleReturnModal from "../../Components/MultipleReturnModal";
import DayEndModal from "../../Components/dayEndModal";
import useScanDetection from "use-scan-detection";
import ExpenseModal from "../../Components/expensesModal";
import CustomModal from "../../Components/modal";
import ShiftInProcessModal from "../../Components/shiftInprocessModal";

function PointOfSale() {
  const webcamRef = useRef(null);

  const [capturedImage, setCapturedImage] = useState(null);
  const captureImage = () => {
    const webcam = webcamRef.current.video;
    const canvas = document.createElement("canvas");

    const zoomFactor = 0.5;
    canvas.width = webcam.videoWidth * zoomFactor;
    canvas.height = webcam.videoHeight * zoomFactor;

    const context = canvas.getContext("2d");
    context.drawImage(
      webcam,
      0,
      0,
      webcam.videoWidth,
      webcam.videoHeight,
      0,
      0,
      canvas.width - 50,
      canvas.height - 50 // destination rectangle
    );

    const imageDataUrl = canvas.toDataURL("image/png");
    setCapturedImage(imageDataUrl);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isShiftStart = useSelector(
    (state) => state.loginReducer.isShiftStarted
  );

  const customerInputRef = useRef(null);

  const [accountModal, setAccountModal] = useState(false);
  const [demandedProductModal, setDemandedProductModal] = useState(false);
  const [productArrangementModal, setProductArrangementModal] = useState(false);
  const [trashModal, setTrashModal] = useState(false);
  const [endSaleLoading, setEndSaleLoading] = useState(false);
  const [returnModal, setReturnModal] = useState(false);
  const [damageModal, setDamageModal] = useState(false);
  const [expenseModal, setExpenseModal] = useState(false);
  const [text, setText] = useState("");
  const [searchCustomers, setSearchCustomers] = useState([]);
  const [claimModal, setClaimModal] = useState(false);
  const [demandedProduct, setDemandedProduct] = useState("");
  const [demandedProductLoading, setDemandedProductLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [barcodeRead, setBarcodeRead] = useState("");
  const [shiftStartModal, setShiftStartModal] = useState(false);
  const [EndingBalanceModalVisible, setEndingBalanceModalVisible] =
    useState(false);
  const [time, setTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [dayEndModal, setDayEndModal] = useState(false);

  const [shiftEndLoading, setShiftEndLoading] = useState(false);

  const [shiftInprocessModal, setShiftInProcessModal] = useState(false);
  const [shiftInprocessUser, setShiftInprocessUser] = useState([]);
  const [shiftStartLoading, setShiftStartLoading] = useState(false);
  const cart = useSelector((state) => state.loginReducer.cart);

  const [isEndShiftConfirmationModalOpen, setIsEndShiftConfirmationModalOpen] =
    useState(false);
  const [isEndDayConfirmationModalOpen, setIsEndDayConfirmationModalOpen] =
    useState(false);

  const selectedCustomer = useSelector(
    (state) => state.loginReducer.selectedCustomer
  );
  const customers = useSelector((state) => state.loginReducer.customers);

  const userData = useSelector((state) => state.loginReducer.updatedState);

  const isShiftEnded = useSelector((state) => state.loginReducer.isShiftEnded);

  let discount = 0;
  let totalAmount = 0;

  useEffect(() => {
    setTimeout(() => {
      customerInputRef?.current?.focus();
    }, 3000);
  }, [customerInputRef?.current]);

  if (cart && cart.length > 0 && selectedCustomer) {
    discount =
      cart && cart.length > 0 && selectedCustomer?.priceLevel[0]?.id === 1
        ? cart.reduce((previous, current) => {
            if (current.discountPrice) {
              return (
                previous +
                (Number(current.trade_price) * Number(current.saleQty) -
                  Number(current.discountPrice) * Number(current.saleQty))
              );
            }
            return previous;
          }, 0)
        : selectedCustomer?.priceLevel[0]?.id === 2
        ? cart.reduce((previous, current) => {
            if (current.discountPrice) {
              return (
                previous +
                (Number(current.warehouse_price) * Number(current.saleQty) -
                  Number(current.discountPrice) * Number(current.saleQty))
              );
            }
            return previous;
          }, 0)
        : cart.reduce((previous, current) => {
            if (current.discountPrice) {
              return (
                previous +
                (Number(current.retail_price) * Number(current.saleQty) -
                  Number(current.discountPrice) * Number(current.saleQty))
              );
            }
            return previous;
          }, 0);

    totalAmount =
      cart &&
      cart.length > 0 &&
      selectedCustomer &&
      selectedCustomer?.priceLevel[0].id === 1
        ? cart.reduce(
            (previous, current) =>
              previous + Number(current.saleQty) * Number(current.trade_price),
            0
          )
        : selectedCustomer?.priceLevel[0]?.id === 2
        ? cart.reduce(
            (previous, current) =>
              previous +
              Number(current.saleQty) * Number(current.warehouse_price),
            0
          )
        : cart.reduce(
            (previous, current) =>
              previous + Number(current.saleQty) * Number(current.retail_price),
            0
          );
  }

  useScanDetection({
    onComplete: (code) => {
      customerInputRef?.current?.blur();
      setBarcodeRead(code);
    },
    minLength: 3,
  });

  const getProducts = async () => {
    // setLoading(true)
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getProducts`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let myData = res.data;

          let { data } = myData;

          if (data && data.length > 0) {
            let allProducts = [];

            for (var i = 0; i < data.length; i++) {
              let product = data[i];

              product.id = i + 1;
              product["productName"] = product?.product_name;

              if (product.status == "active") {
                product.status = "Active";
              }

              allProducts.push(product);
            }

            dispatch(updateProducts(allProducts));
          }
        })
        .catch((error) => {
          console.log(error, "error");
          // setLoading(false)
        });
    }
  };

  const handleShiftCheck = async () => {
    let shiftEnded = await localStorage.getItem("shiftEnded");
    shiftEnded = JSON.parse(shiftEnded);

    if (userData && userData.role !== "admin" && !shiftEnded) {
      let shift = await localStorage.getItem("shiftStarted");

      shift = JSON.parse(shift);

      if (!shift) {
        setShiftStartModal(true);
        dispatch(updateShift(false));
      } else {
        setShiftStartModal(false);
        dispatch(updateShift(true));
      }
    }
  };
  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    handleShiftCheck();
  }, [userData.id]);

  const getLoginData = async () => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);

    if (!data) {
      navigate("/");
      return;
    }

    // if (data) {
    let token = data;

    axios
      .get(`${Base_Uri}getEmployeeDetails`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        if (!res?.data?.status) {
          alert("Your session has expired");
          navigate("/");
          return;
        }

        let allData = res.data.data;

        dispatch(edit(allData));

        let date = new Date(allData.time);

        let totalHours;
        let totalMinutes;
        let totalSeconds;

        let loginGetTime = date.getTime();
        let nowGetTime = new Date().getTime();

        let diff = nowGetTime - loginGetTime;

        let hours = diff / 1000 / 60 / 60;

        if (hours.toString().includes(".")) {
          let hourSplit = hours.toString().split(".");

          totalHours = hourSplit[0];

          let minutes = hourSplit[1];

          minutes = Number(`0.${minutes}` * 60);

          if (hours.toString().includes(".")) {
            let minutesSplit = minutes.toString().split(".");

            totalMinutes = minutesSplit[0];

            let seconds = Number(`0.${minutesSplit[1]}` * 60);

            totalSeconds = Math.round(seconds);

            setTime({
              ...time,
              hours: totalHours,
              minutes: totalMinutes,
              seconds: totalSeconds,
            });
          } else {
            setTime({
              ...time,
              hours: totalHours,
              minutes: minutes,
              seconds: 0,
            });
          }
        } else {
          setTime({
            ...time,
            hours: totalHours,
            minutes: 0,
            seconds: 0,
          });
        }
      })
      .catch((error) => {
        alert("Your session has expired");
        navigate("/");

        console.log(error, "error");
      });
  };

  useEffect(() => {
    getLoginData();
  }, []);

  const closeAccountModal = () => {
    if (check1 && check1.length > 0) {
      setCheck1(
        check1.map((e, i) => {
          if (e.name == "Add Customer") {
            return {
              ...e,
              selected: false,
            };
          } else {
            return e;
          }
        })
      );
    }
    setAccountModal(false);
  };

  const handleSearch = (text) => {
    setText(text);

    if (!text) {
      setSearchCustomers([]);
      return;
    }

    setSearchCustomers(
      customers &&
        customers.length > 0 &&
        customers.filter((e, i) => {
          if (
            e?.customerName?.toLowerCase().includes(text.toLowerCase()) ||
            e?.businessName?.toLowerCase().includes(text.toLowerCase()) ||
            e?.email?.toLowerCase().includes(text.toLowerCase()) ||
            e?.accountNo == text
          ) {
            return e;
          }
        })
    );
  };

  const handleSelectCustomer = (customer) => {
    dispatch(selectInvoicedCustomer(customer));
    setText("");
    setSearchCustomers([]);
  };

  const [ListItems, setListItems] = useState([
    {
      text: "Return",
      route: "/Home/Returns",
    },

    {
      text: "Stock Missing",
      route: "/Home/DemandedProduct",
    },
    {
      text: "Product Arrangement",
      route: "/Home/ProductArrangement",
      color: "blue",
    },
  ]);
  const [check1, setCheck1] = useState([
    {
      name: "Repeated Customer",
    },
    {
      name: "Walking",
    },
    {
      name: "Add Customer",
    },
  ]);
  const [check2, setCheck2] = useState([
    {
      name: "A Category 10% Off",
    },
    {
      name: "B Category 5% Off",
    },
    {
      name: "C Category",
    },
  ]);
  const [check3, setCheck3] = useState([
    {
      name: "Cash",
      selected: true,
    },
    {
      name: "Card",
      selected: false,
    },
    {
      name: "Online",
      selected: false,
    },
    {
      name: "Credit",
      selected: false,
    },
    {
      name: "Cheque",
      selected: false,
    },
  ]);

  const getTodayAllReturnInvoice = async () => {
    try {
      let data = await localStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      let invoices = await axios.get(`${Base_Uri}getDayAllReturnInvoices`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return invoices.data; // Return the actual data instead of the entire response.
    } catch (error) {
      console.error(error, "Error fetching return invoices");
      throw error; // Propagate the error to the caller
    }
  };

  const getTodaysAllInvoice = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    setEndSaleLoading(true);

    let returnInvoice = await getTodayAllReturnInvoice();

    let invoiceData = returnInvoice?.data;

    let saleReturnAmount =
      invoiceData && invoiceData.length > 0
        ? invoiceData.reduce(
            (previous, current) => previous + current.subtotal,
            0
          )
        : 0;
    let totalReturnInvoices = invoiceData.length;

    // axios
    //   .get(`${Base_Uri}getDayAllInvoices`, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       "Content-Type": "application/json", // Include other headers as needed
    //     },
    //   })
    //   .then((res) => {
    //     let data = res.data;

    //     if (data?.data) {
    //       let invoices = data.data;

    //       // let cashSales =
    //       //   invoices &&
    //       //   invoices.length > 0 &&
    //       //   invoices.reduce((previous, current) => {
    //       //     if (current?.paymentMethod.toLowerCase() == "cash") {
    //       //       return previous + current.subtotal;
    //       //     } else {
    //       //       return previous;
    //       //     }
    //       //   }, 0);

    //       // let cardSales =
    //       //   invoices &&
    //       //   invoices.length > 0 &&
    //       //   invoices.reduce((previous, current) => {
    //       //     if (current?.paymentMethod.toLowerCase() == "card") {
    //       //       return previous + current.subtotal;
    //       //     } else {
    //       //       return previous;
    //       //     }
    //       //   }, 0);

    //       // let onlineSales =
    //       //   invoices &&
    //       //   invoices.length > 0 &&
    //       //   invoices.reduce((previous, current) => {
    //       //     if (current?.paymentMethod.toLowerCase() == "online") {
    //       //       return previous + current.subtotal;
    //       //     } else {
    //       //       return previous;
    //       //     }
    //       //   }, 0);

    //       // let creditSales =
    //       //   invoices &&
    //       //   invoices.length > 0 &&
    //       //   invoices.reduce((previous, current) => {
    //       //     if (current?.paymentMethod.toLowerCase() == "credit") {
    //       //       return previous + current.subtotal;
    //       //     } else {
    //       //       return previous;
    //       //     }
    //       //   }, 0);

    //       // let chequeSales =
    //       //   invoices &&
    //       //   invoices.length > 0 &&
    //       //   invoices.reduce((previous, current) => {
    //       //     if (current?.paymentMethod.toLowerCase() == "cheque") {
    //       //       return previous + current.subtotal;
    //       //     } else {
    //       //       return previous;
    //       //     }
    //       //   }, 0);

    //       // let endSaleSummary = {
    //       //   cashSales: cashSales,
    //       //   creditSales: creditSales,
    //       //   onlineSales: onlineSales,
    //       //   cardSales: cardSales,
    //       //   chequeSales: chequeSales,
    //       //   saleReturnAmount: saleReturnAmount,
    //       //   totalReturnInvoices: totalReturnInvoices,
    //       //   totalSaleInvoices: invoices.length,
    //       // };

    //       // dispatch(updateEndSaleSummary(endSaleSummary));
    //       dispatch(updateTodayAllInvoices(invoices));
    //       setEndSaleLoading(false);
    //       setEndingBalanceModalVisible(true);
    //     } else {
    //       setEndSaleLoading(false);
    //     }
    //   })
    //   .catch((error) => {
    //     setEndSaleLoading(false);
    //     alert(error?.message);
    //   });
  };

  const selectOptions = (item, ind) => {
    setListItems(
      ListItems.map((e, i) => {
        if (i == ind) {
          return {
            ...e,
            selected: true,
          };
        } else {
          return {
            ...e,
            selected: false,
          };
        }
      })
    );
  };
  const selectCheck1 = (item, ind) => {
    if (item.name == "Add Customer" && item.selected) {
      setAccountModal(false);
    } else if (item.name == "Add Customer" && !item.selected) {
      setAccountModal(true);
    } else if (item.name == "Repeated Customer" && item.selected) {
      dispatch(updateCustomerType(""));
    } else if (item.name == "Repeated Customer" && !item.selected) {
      dispatch(updateCustomerType("Repeated"));
    } else if (item.name == "Walking" && item.selected) {
      dispatch(updateCustomerType(""));
    } else if (item.name == "Walking" && !item.selected) {
      dispatch(updateCustomerType("Walking"));
    }

    setCheck1(
      check1.map((e, i) => {
        if (i == ind) {
          return {
            ...e,
            selected: !item.selected,
          };
        } else {
          return {
            ...e,
            selected: false,
          };
        }
      })
    );
  };

  const selectCheck2 = (item, ind) => {
    setCheck2(
      check2.map((e, i) => {
        if (i == ind) {
          return {
            ...e,
            selected: true,
          };
        } else {
          return {
            ...e,
            selected: false,
          };
        }
      })
    );
  };
  const selectCheck3 = (item, ind) => {
    dispatch(paymentMethod(item?.name));

    setCheck3(
      check3.map((e, i) => {
        if (i == ind) {
          return {
            ...e,
            selected: true,
          };
        } else {
          return {
            ...e,
            selected: false,
          };
        }
      })
    );
  };

  const handleAddDemandedProduct = async () => {
    if (!demandedProduct) {
      alert("Kindly add product");
      return;
    }

    setDemandedProductLoading(true);
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      let dataToSend = {
        productName: demandedProduct,
        employeeId : userData.employeeId
      };

      axios
        .post(`${Base_Uri}AddDemandedProduct`, dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let { data } = res;

          if (!data.status) {
            alert(data.message);
            setDemandedProductLoading(false);
          }

          alert(data.message);
          setDemandedProductLoading(false);
          setDemandedProductModal(false);
        })
        .catch((err) => {
          alert(err.message);
          setDemandedProductLoading(false);
          setDemandedProduct("");
        });
    }
  };

  const navigation = useNavigate();

  const handleLogout = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      let dataToSend = {
        employeeId: userData.id,
        employeeName: userData.name,
      };

      dispatch(updateCart([]));
      dispatch(updateCustomerType(""));
      dispatch(selectInvoicedCustomer(""));
      dispatch(updateTodayAllInvoices([]));
      dispatch(updateAllDayInvoices([]));
      dispatch(updateShiftInvoices([]));
      dispatch(edit(""));

      await localStorage.removeItem("userData");
      await localStorage.removeItem("firstLogin");
      await localStorage.removeItem("shiftStartTime");
      await localStorage.removeItem("shiftEnded");
      navigate("/");
    }
  };

  const getUserShiftInvoice = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    setEndSaleLoading(true);

    try {
      let response = await axios.get(`${Base_Uri}getShift/${userData.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      });

      let shiftData = response.data;

      if (!response.status) {
        alert(shiftData.message);
        return;
      }

      let shift = shiftData.data;

      let dataToSend = {
        shiftStartTime: shift?.date,
        employeeId: userData?.id,
      };

      axios
        .post(`${Base_Uri}getShiftAllInvoices`, dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then(async (res) => {
          let data = res.data;

          if (data?.data) {
            let invoices = data.data;

            let exchangeInvoices =
              invoices?.exchangeInvoices &&
              invoices?.exchangeInvoices?.length > 0
                ? invoices?.exchangeInvoices
                : [];
            let returnInvoices =
              invoices?.returnInvoices && invoices?.returnInvoices?.length > 0
                ? invoices?.returnInvoices
                : [];
            let saleInvoices =
              invoices?.saleInvoices && invoices?.saleInvoices?.length > 0
                ? invoices?.saleInvoices
                : [];

            let allInvoices = [
              ...saleInvoices,
              ...returnInvoices,
              ...exchangeInvoices,
            ];

            dispatch(updateShiftInvoices(allInvoices));

            let shiftRefundResponse = await axios.get(
              `${Base_Uri}getEmployeeShiftRefund/${userData.id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json", // Include other headers as needed
                },
              }
            );

            let refundData = shiftRefundResponse.data;

            if (!refundData.status) {
              alert(refundData.message);
              setEndSaleLoading(false);
              return;
            }

            let shiftCreditPaymentResponse = await axios.get(
              `${Base_Uri}getEmployeeShiftCredit/${userData.id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json", // Include other headers as needed
                },
              }
            );

            let creditPaymentData = shiftCreditPaymentResponse.data;

            if (!creditPaymentData.status) {
              alert(creditPaymentData.message);
              setEndSaleLoading(false);
              return;
            }

            dispatch(updateShiftStartTime(shift.date));

            let dataToSend = {
              id: userData?.id,
              shiftStartTime: new Date(shift.date),
              shiftEndTime: new Date(),
            };

            let expenseResponse = await axios.post(
              `${Base_Uri}getDayEmployeeExpense`,
              dataToSend,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            );

            let expenseData = expenseResponse.data;

            if (!expenseData.status) {
              alert(expenseData.message);
              setEndSaleLoading(false);
              return;
            }

            dispatch(updateEmployeeExpense(expenseData.data));
            dispatch(updateEmployeeShiftRefund(refundData.data));
            dispatch(updateShiftCreditPayment(creditPaymentData?.data));
            setEndSaleLoading(false);
            setEndingBalanceModalVisible(true);
          } else {
            setEndSaleLoading(false);
          }
        })
        .catch((error) => {
          setEndSaleLoading(false);
          alert(error?.message);
        });
    } catch (err) {
      console.log(err, "errrr");
      alert("server error");
      setEndSaleLoading(false);
    }
  };

  const handleShiftEnd = async () => {
    if (userData) {
      let data = await localStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      let dataToSend = {
        employeeId: userData.id,
        employeeName: userData?.fullName,
      };

      setEndSaleLoading(true);

      try {
        let response = await axios.put(`${Base_Uri}endShift`, dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        let shiftData = response.data;

        if (shiftData.status) {
          await localStorage.removeItem("shiftStarted");
          await localStorage.removeItem("shiftData");
          await localStorage.setItem("shiftEnded", true);
          dispatch(updateShift(false));
          dispatch(updateShiftEnded(true));
          setEndSaleLoading(false);
          setIsEndShiftConfirmationModalOpen(false);
          setEndingBalanceModalVisible(false);
        } else {
          setEndSaleLoading(false);
          alert(shiftData.message);
        }
      } catch (err) {
        setEndSaleLoading(false);
        alert("Server Error");
      }
    }
  };

  const handleDayEnd = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    let dataToSend = {
      employeeId: userData.id,
      employeeName: userData.fullName,
    };

    try {
      let response = await axios.put(`${Base_Uri}endDay`, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      });

      if (response.data?.status) {
        handleLogout();
      } else {
        try {
          let response = await axios.get(`${Base_Uri}getShiftInprocessUsers`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // Include other headers as needed
            },
          });

          let shiftprocess = response.data;

          if (shiftprocess.status) {
            let shiftInprocessUser = shiftprocess.data;

            let otherUsers =
              shiftInprocessUser &&
              shiftInprocessUser.length > 0 &&
              shiftInprocessUser.filter((e, i) => {
                return userData?.id !== e?.EmpId;
              });
            setShiftInprocessUser(otherUsers);
            setShiftInProcessModal(true);
          } else {
            alert(response.message);
          }
        } catch (err) {
          alert("server error");
        }
      }
    } catch (err) {
      alert("Server Error");
    }
  };

  const handleOpenExpenseModal = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    setLoading(true);

    let response = await axios.get(`${Base_Uri}getShift/${userData.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Include other headers as needed
      },
    });

    let shiftData = response.data;

    if (!response.status) {
      alert(shiftData.message);
      return;
    }

    let shift = shiftData.data;

    let dataToSend = {
      id: userData?.id,
      shiftStartTime: new Date(shift.date),
      shiftEndTime: new Date(),
    };

    axios
      .post(`${Base_Uri}getDayEmployeeExpense`, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        let data = res?.data;

        if (!data?.status) {
          alert(data?.message);
          setLoading(false);
          return;
        }

        let expense = data?.data;

        dispatch(updateEmployeeExpense(expense));
        setExpenseModal(true);
        setLoading(false);
      })
      .catch((error) => {
        alert(error?.message);
        setLoading(false);
        return;
      });
  };

  const handleShiftStart = async () => {
    let dataToSend = {
      employeeId: userData.id,
      employeeName: userData?.fullName,
    };

    setShiftStartLoading(true);
    try {
      let data = await localStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      let response = await axios.post(`${Base_Uri}startShift`, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      });

      let shiftData = response.data;

      if (shiftData.status) {
        let shift = shiftData.data;
        await localStorage.setItem("shiftStarted", true);
        let shiftDataString = JSON.stringify(shift);
        await localStorage.setItem("shiftData", shiftDataString);
        dispatch(updateShift(true));
        setShiftStartLoading(false);
        setShiftStartModal(false);
      } else {
        setShiftStartLoading(false);
        alert(shiftData.message);
      }
    } catch (err) {
      setShiftStartLoading(false);
      alert("Server Error");
    }
  };

  const handleOpenDayEndModal = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    setEndSaleLoading(true);
    try {
      let invoicesData = await axios.get(`${Base_Uri}getDayAllInvoices`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      let returnInvoicesData = await axios.get(
        `${Base_Uri}getDayAllReturnInvoices`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let exchangeInvoicesData = await axios.get(
        `${Base_Uri}getDayAllExchangeInvoices`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      invoicesData = invoicesData?.data;
      returnInvoicesData = returnInvoicesData?.data;
      exchangeInvoicesData = exchangeInvoicesData?.data;

      let invoices;
      let returnInvoices;
      let exchangeInvoices;

      if (invoicesData?.status) {
        invoices = invoicesData.data;
      }

      if (returnInvoicesData?.status) {
        returnInvoices = returnInvoicesData?.data;
      }

      if (exchangeInvoicesData?.status) {
        exchangeInvoices = exchangeInvoicesData?.data;
      }

      invoices = invoices && invoices?.length > 0 ? invoices : [];
      returnInvoices =
        returnInvoices && returnInvoices?.length > 0 ? returnInvoices : [];
      exchangeInvoices =
        exchangeInvoices && exchangeInvoices?.length > 0
          ? exchangeInvoices
          : [];

      let allInvoices = [...invoices, ...returnInvoices, ...exchangeInvoices];

      dispatch(updateAllDayInvoices(allInvoices));

      let dataToSend = {
        dayEndTime: new Date(),
      };

      let expenseResponse = await axios.post(
        `${Base_Uri}getDayExpense`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let expenseData = expenseResponse.data;

      if (expenseData.status) {
        let allExpenses = expenseData.data;
        dispatch(updateDayExpenses(allExpenses));
      } else {
        alert(expenseData.message);
        setEndSaleLoading(false);
        return;
      }

      let refundResponse = await axios.get(`${Base_Uri}getDayRefund`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      let refundData = refundResponse.data;

      if (refundData.status) {
        let dayRefunds = refundData.data;

        let day = refundData?.day;

        dispatch(updateDayData(day));
        dispatch(updateDayRefund(dayRefunds));
      } else {
        alert(refundData.message);
        setEndSaleLoading(false);
        return;
      }

      let creditPaymentResponse = await axios.get(`${Base_Uri}getDayCredit`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      let creditPaymentData = creditPaymentResponse.data;

      if (creditPaymentData?.status) {
        dispatch(updateDayCreditPayment(creditPaymentData?.data));
        setEndingBalanceModalVisible(false);
        setDayEndModal(true);
        setEndSaleLoading(false);
      } else {
        alert(creditPaymentData.message);
        setEndSaleLoading(false);
      }
    } catch (err) {
      setEndSaleLoading(false);
      alert("Internal Server Error");
    }
  };

  return (
    <div sx={{ color: Colors.black }}>
      <Navbar currentPage="Point Of Sale" />
      <Grid
        xs={12}
        container
        // spacing={2}
        sx={{
          padding: {
            lg: "0 10px",
            md: "0px 0px",
            display: "flex",
            justifyContent: "space-between",
          },
          backgroundColor: Colors.main,
        }}
      >
        <Grid item xs={12} sm={12} md={4} lg={1.8}>
          <Card sx={{ width: "100%", marginRight: "10px", marginTop: "10px" }}>
            <div>
              <div
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: 16,
                  marginTop: "10px",
                  color: Colors.black,
                }}
              >
                {userData?.fullName}
              </div>
              <div
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: 12,
                  color: Colors.text,
                }}
              >
                {userData?.role == "admin" ? "Admin" : userData?.role}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: 4,
                backgroundColor: "#0F64B3",
                margin: "10px 0",
              }}
            ></div>
            <div style={{}}>
              <List>
                {ListItems.map((text, index) => (
                  <ListItem
                    onClick={() =>
                      text.text == "Stock Missing"
                        ? setDemandedProductModal(true)
                        : text.text == "Product Arrangement"
                        ? setProductArrangementModal(true)
                        : text.text == "Bin"
                        ? setTrashModal(true)
                        : text.text == "Return"
                        ? setReturnModal(true)
                        : text.text == "Damages/Faulty"
                        ? setDamageModal(true)
                        : text.text == "Claim/warranty"
                        ? setClaimModal(true)
                        : ""
                    }
                    key={text.text}
                    disablePadding
                    style={{
                      padding: text?.selected ? "0px 4px 0px 4px" : 0,
                      backgroundColor: text?.selected ? "#D3EAFF" : "white",
                    }}
                  >
                    <ListItemButton
                    //  onClick={() => selectOptions(text, index)}
                    >
                      <ListItemText
                        primary={text.text?.toLowerCase() == "product arrangement" ? "Product Sourcing" : text.text}
                        style={{ color: text.color ? text.color : "#636363" }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </div>
          </Card>

          {isShiftStart && (
            <CustomButton
              onClick={() => getUserShiftInvoice()}
              text={
                endSaleLoading ? (
                  <RingLoader loading={endSaleLoading} size={30} />
                ) : (
                  "End Shift"
                )
              }
              style={{
                width: "100%",
                backgroundColor: Colors.white,
                color: Colors.button,
                mt: 2,
                mb: 2,
                borderRadius: "5px",
              }}
            />
          )}

          {isShiftStart && (
            <CustomButton
              onClick={() => !loading && handleOpenExpenseModal()}
              text={
                loading ? (
                  <RingLoader
                    color={Colors.black}
                    size={25}
                    loading={loading}
                  />
                ) : (
                  "Add Expenses"
                )
              }
              style={{
                width: "100%",
                backgroundColor: Colors.white,
                color: Colors.button,
                mt: 2,
                mb: 2,
                borderRadius: "5px",
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={7}>
          {EndingBalanceModalVisible && userData && (
            <CustomPOSModal
              open={EndingBalanceModalVisible}
              loading={endSaleLoading}
              closeModal={() => setEndingBalanceModalVisible(false)}
              buttonText={"Handed Over To Admin"}
              buttonStyle={{
                backgroundColor: Colors.button,
              }}
              confirm={() => (
                setIsEndShiftConfirmationModalOpen(true),
                setEndingBalanceModalVisible(false)
              )}
              text={"Submit Your Ending Balance Is"}
              specialText="€3000.00"
              heading={"Good Bye"}
            />
          )}

          {shiftStartModal && userData && (
            <CustomModal
              open={shiftStartModal}
              buttonText={"Start Shift"}
              loading={shiftStartLoading}
              closeModal={() => handleLogout()}
              confirm={() => !shiftStartLoading && handleShiftStart()}
              text={"Do you want to start your shift"}
              heading={"Start Shift"}
            />
          )}

          {isEndShiftConfirmationModalOpen && (
            <CustomModal
              open={isEndShiftConfirmationModalOpen}
              buttonText={"End Shift"}
              loading={endSaleLoading}
              closeModal={() => setIsEndShiftConfirmationModalOpen(false)}
              confirm={() => !endSaleLoading && handleShiftEnd()}
              text={"Are You Sure You want to end your shift"}
              heading={"End Shift"}
            />
          )}

          {isEndDayConfirmationModalOpen && (
            <CustomModal
              open={isEndDayConfirmationModalOpen}
              buttonText={"End Day"}
              closeModal={() => setIsEndDayConfirmationModalOpen(false)}
              confirm={() => handleDayEnd()}
              text={"Are You Sure You want to end day"}
              heading={"End Day"}
            />
          )}

          {shiftInprocessModal && (
            <ShiftInProcessModal
              data={shiftInprocessUser}
              open={shiftInprocessModal}
              buttonText={"Logout"}
              closeModal={() => setShiftInProcessModal(false)}
              confirm={() => handleLogout()}
              text={"Do you want to start your shift"}
              heading={"Start Shift"}
            />
          )}

          {isShiftEnded &&
            userData &&
            !dayEndModal &&
            !isEndDayConfirmationModalOpen && (
              <CustomModal
                open={isShiftEnded}
                loading={endSaleLoading}
                buttonText={"End Day"}
                closeModal={() => handleLogout()}
                confirm={() => !endSaleLoading && handleOpenDayEndModal()}
                text={"Do you want to end day"}
                heading={"Shift Ended Succesfully"}
              />
            )}

          {dayEndModal && userData && (
            <DayEndModal
              open={dayEndModal}
              closeModal={() => handleLogout()}
              buttonText={"Handed Over To Admin"}
              buttonStyle={{
                backgroundColor: Colors.button,
              }}
              confirm={() => (
                setIsEndDayConfirmationModalOpen(true), setDayEndModal(false)
              )}
              text={"Submit Your Ending Balance Is"}
              specialText="€3000.00"
              heading={"Good Bye"}
            />
          )}

          <OnlineTransactionModal
            heading={"Stock Missing"}
            text={"Product Name:"}
            closeModal={() => setDemandedProductModal(false)}
            onChange={(e) => setDemandedProduct(e.target.value)}
            open={demandedProductModal}
            buttonStyle2={{ width: "200px", fontSize: "14px", mt: 2 }}
            buttonText="Add Product"
            loading={demandedProductLoading}
            confirm={() => handleAddDemandedProduct()}
          />

          <ProductArrangementModal
            heading={"Product Arrangement"}
            text={"Product Name:"}
            open={productArrangementModal}
            barcodeData={productArrangementModal ? barcodeRead : ""}
            setBarcodeRead={setBarcodeRead}
            buttonStyle2={{ width: "200px", fontSize: "14px", mt: 2 }}
            buttonText="Add Product"
            setProductArrangementModal={setProductArrangementModal}
            confirm={() => setProductArrangementModal(false)}
          />

          <TrashModal
            heading={"Trash"}
            text={"Product Name:"}
            open={trashModal}
            setTrashModal={setTrashModal}
            closeModal={() => setTrashModal(false)}
            DbuttonStyle2={{ width: "200px", fontSize: "14px", mt: 2 }}
            buttonText="Receive Payment"
            confirm={() => setTrashModal(false)}
          />

          <MultipleReturnModal
            heading={"Return"}
            text={"Product Name:"}
            open={returnModal}
            barcodeData={returnModal ? barcodeRead : ""}
            setBarcodeRead={setBarcodeRead}
            setReturnModal={setReturnModal}
            closeModal={() => setReturnModal(false)}
            buttonStyle2={{ width: "200px", fontSize: "14px", mt: 2 }}
            // buttonText="Receive Payment"
            confirm={() => setReturnModal(false)}
          />

          <DamagesModal
            heading={"Damages"}
            text1={"Product Name:"}
            open={damageModal}
            closeModal={() => setDamageModal(false)}
            setDamageModal={setDamageModal}
            DbuttonStyle2={{ width: "200px", fontSize: "14px", mt: 2 }}
            buttonText="Receive Payment"
            confirm={() => setDamageModal(false)}
          />

          <CustomModal2
            open={accountModal}
            setCheck1={setCheck1}
            check1={check1}
            closeModal={closeAccountModal}
          />

          <Grid item xs={12} sm={12} md={0} lg={0}>
            <Card
              sx={{
                width: "100%",
                display: { xs: "block", md: "none" },
                padding: "5px 10px",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  marginTop: { xs: "0px", sm: "10px", md: "10px" },
                  border: `1px solid ${Colors.button}`,
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "5px 10px 5px 10px",
                  margin: 0,
                  backgroundColor: Colors.white,
                }}
              >
                <TextField
                  variant="standard" // <== changed this
                  margin="normal"
                  onChange={(e) => handleSearch(e.target.value)}
                  required
                  value={text}
                  fullWidth
                  InputProps={{
                    // startAdornment: <AccountCircle />, // <== adjusted this
                    disableUnderline: true,
                    fontSize: "12px",
                  }}
                  inputRef={customerInputRef}
                  placeholder="Search Customer"
                  sx={{
                    width: "90%",
                    fontSize: "12px",
                    padding: 0,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    margin: 0,
                  }}
                />
                <img
                  src={require("../../Images/search.png")}
                  style={{ height: "20px" }}
                />
              </Box>
              <Box>
                {searchCustomers &&
                  searchCustomers.length > 0 &&
                  searchCustomers.map((e, i) => {
                    return (
                      <Button
                        onClick={() => handleSelectCustomer(e)}
                        sx={{
                          width: "100%",
                          mt: 1,
                          backgroundColor: Colors.white,
                          p: "0px 10px",
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          borderRadius: 1,
                          textAlign: "left",
                          display: "flex",
                          justifyContent: "flex-start",
                          border: `1px solid ${Colors.border}`,
                        }}
                      >
                        <p style={{ fontFamily: "Poppins", textAlign: "left" }}>
                          {e.customerName}
                        </p>
                      </Button>
                    );
                  })}

                {selectedCustomer && (
                  <Grid xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        display: "flex",
                        mt: 1,
                        flexDirection: "column",
                        alignItems: "flex-start",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      <Box
                        style={{ display: "flex", flexDirection: "row", m: 0 }}
                      >
                        {" "}
                        {/* Type: */}
                        <span style={{ marginLeft: 5 }}>
                          {" "}
                          {selectedCustomer?.accountManager}
                        </span>
                      </Box>

                      <Box style={{ display: "flex", flexDirection: "row" }}>
                        {" "}
                        Business Name :{" "}
                        <span style={{ marginLeft: 5 }}>
                          {" "}
                          {selectedCustomer?.businessName}
                        </span>
                      </Box>

                      <Box style={{ display: "flex", flexDirection: "row" }}>
                        {" "}
                        Customer Name :{" "}
                        <span style={{ marginLeft: 5 }}>
                          {" "}
                          {selectedCustomer?.customerName}
                        </span>
                      </Box>

                      <Box
                        style={{ display: "flex", flexDirection: "row", m: 0 }}
                      >
                        {" "}
                        Customer Email :{" "}
                        <span style={{ marginLeft: 5 }}>
                          {" "}
                          {selectedCustomer?.email}
                        </span>
                      </Box>

                      <Box
                        style={{ display: "flex", flexDirection: "row", m: 0 }}
                      >
                        {" "}
                        Invoice Balance :{" "}
                        <span style={{ marginLeft: 5, color: "red" }}>
                          {" "}
                          £
                          {selectedCustomer?.creditBalance
                            ? Number(selectedCustomer?.creditBalance).toFixed(2)
                            : 0}
                        </span>
                      </Box>

                      <Box
                        style={{ display: "flex", flexDirection: "row", m: 0 }}
                      >
                        {" "}
                        Quotation Balance :{" "}
                        <span style={{ marginLeft: 5, color: "red" }}>
                          {" "}
                          £
                          {selectedCustomer?.quotationBalance
                            ? Number(
                                selectedCustomer?.quotationBalance
                              ).toFixed(2)
                            : 0}
                        </span>
                      </Box>

                      <Box
                        style={{ display: "flex", flexDirection: "row", m: 0 }}
                      >
                        {" "}
                        Total Balance :{" "}
                        <span style={{ marginLeft: 5, color: "red" }}>
                          {" "}
                          £
                          {selectedCustomer?.quotationBalance ||
                          selectedCustomer?.creditBalance
                            ? Number(
                                Number(
                                  selectedCustomer?.quotationBalance || 0
                                ) + Number(selectedCustomer?.creditBalance || 0)
                              ).toFixed(2)
                            : 0}
                        </span>
                      </Box>

                      {selectedCustomer?.comment && (
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            m: 0,
                            textAlign: "left",
                          }}
                        >
                          Comment:
                          <span
                            style={{
                              marginLeft: 5,
                              fontSize: "10px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                              textAlign: "left",
                            }}
                          >
                            {" "}
                            {selectedCustomer?.comment}
                          </span>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Card>
          </Grid>

          <Routes>
            <Route
              path="/"
              element={
                <MainPage
                  setBarcodeRead={setBarcodeRead}
                  barcodeRead={
                    returnModal
                      ? ""
                      : productArrangementModal
                      ? ""
                      : barcodeRead
                  }
                  customerRef={customerInputRef}
                />
              }
            />
            <Route path="/QuickSearch" element={<Quicksearch />} />
          </Routes>
        </Grid>

        <Grid item xs={0} sm={0} md={3} lg={3}>
          <Card
            sx={{
              width: "100%",
              display: { xs: "none", md: "block" },
              padding: "5px 10px",
              marginBottom: "20px",
              marginTop: "10px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                marginTop: { xs: "0px", sm: "10px", md: "10px" },
                border: `1px solid ${Colors.button}`,
                borderRadius: "5px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px 10px 5px 10px",
                margin: 0,
                backgroundColor: Colors.white,
              }}
            >
              <TextField
                variant="standard" // <== changed this
                margin="normal"
                onChange={(e) => handleSearch(e.target.value)}
                required
                value={text}
                fullWidth
                InputProps={{
                  // startAdornment: <AccountCircle />, // <== adjusted this
                  disableUnderline: true,
                  fontSize: "12px",

                  // <== added this
                  // fontSize: "2px"
                }}
                placeholder="Search Customer"
                inputRef={customerInputRef}
                sx={{
                  width: "90%",
                  fontSize: "12px",
                  padding: 0,
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  margin: 0,
                }}
              />
              <img
                src={require("../../Images/search.png")}
                style={{ height: "20px" }}
              />
            </Box>
            <Box>
              {searchCustomers &&
                searchCustomers.length > 0 &&
                searchCustomers.map((e, i) => {
                  return (
                    <Button
                      onClick={() => handleSelectCustomer(e)}
                      sx={{
                        width: "100%",
                        mt: 1,
                        backgroundColor: Colors.white,
                        p: "0px 10px",
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        borderRadius: 1,
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "flex-start",
                        border: `1px solid ${Colors.border}`,
                      }}
                    >
                      <p style={{ fontFamily: "Poppins", textAlign: "left" }}>
                        {e.businessName}
                      </p>
                    </Button>
                  );
                })}

              {selectedCustomer && (
                <Grid xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      display: "flex",
                      mt: 1,
                      flexDirection: "column",
                      alignItems: "flex-start",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    <Box
                      style={{ display: "flex", flexDirection: "row", m: 0 }}
                    >
                      {" "}
                      {/* Type: */}
                      <span style={{ marginLeft: 5 }}>
                        {" "}
                        {selectedCustomer?.accountManager}
                      </span>
                    </Box>

                    <Box style={{ display: "flex", flexDirection: "row" }}>
                      {" "}
                      Account No :{" "}
                      <span style={{ marginLeft: 5 }}>
                        {" "}
                        {selectedCustomer?.accountNo}
                      </span>
                    </Box>

                    <Box style={{ display: "flex", flexDirection: "row" }}>
                      {" "}
                      Business Name :{" "}
                      <span style={{ marginLeft: 5 }}>
                        {" "}
                        {selectedCustomer?.businessName}
                      </span>
                    </Box>

                    <Box style={{ display: "flex", flexDirection: "row" }}>
                      {" "}
                      Customer Name :{" "}
                      <span style={{ marginLeft: 5 }}>
                        {" "}
                        {selectedCustomer?.customerName}
                      </span>
                    </Box>

                    <Box
                      style={{ display: "flex", flexDirection: "row", m: 0 }}
                    >
                      {" "}
                      Customer Email :{" "}
                      <span style={{ marginLeft: 5 }}>
                        {" "}
                        {selectedCustomer?.email}
                      </span>
                    </Box>

                    <Box
                      style={{ display: "flex", flexDirection: "row", m: 0 }}
                    >
                      {" "}
                      Invoice Balance :{" "}
                      <span style={{ marginLeft: 5, color: "red" }}>
                        {" "}
                        £
                        {selectedCustomer?.creditBalance
                          ? Number(selectedCustomer?.creditBalance).toFixed(2)
                          : 0}
                      </span>
                    </Box>

                    <Box
                      style={{ display: "flex", flexDirection: "row", m: 0 }}
                    >
                      {" "}
                      Quotation Balance :{" "}
                      <span style={{ marginLeft: 5, color: "red" }}>
                        {" "}
                        £
                        {selectedCustomer?.quotationBalance
                          ? Number(selectedCustomer?.quotationBalance).toFixed(
                              2
                            )
                          : 0}
                      </span>
                    </Box>

                    <Box
                      style={{ display: "flex", flexDirection: "row", m: 0 }}
                    >
                      {" "}
                      Total Balance :{" "}
                      <span style={{ marginLeft: 5, color: "red" }}>
                        {" "}
                        £
                        {selectedCustomer?.quotationBalance ||
                        selectedCustomer?.creditBalance
                          ? Number(
                              Number(selectedCustomer?.quotationBalance || 0) +
                                Number(selectedCustomer?.creditBalance || 0)
                            ).toFixed(2)
                          : 0}
                      </span>
                    </Box>

                    {selectedCustomer?.comment && (
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          // alignItems: "center",
                          m: 0,

                          textAlign: "left",
                        }}
                      >
                        {" "}
                        Comment:{" "}
                        <span
                          style={{
                            marginLeft: 5,
                            fontSize: "12px",
                            fontWeight: "800",
                            fontFamily: "Poppins",
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {" "}
                          {selectedCustomer?.comment}
                        </span>
                      </Box>
                    )}

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        m: 0,
                        // alignItems: "center",
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      Delivery Address:{" "}
                      <span
                        style={{
                          marginLeft: 5,
                          fontSize: "10px",
                          fontWeight: "800",
                          fontFamily: "Poppins",
                          textAlign: "left",
                          color: "purple",
                        }}
                      >
                        {" "}
                        {selectedCustomer?.deliveryAddress
                          ? selectedCustomer?.deliveryAddress
                          : selectedCustomer?.address}
                      </span>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>

      <ExpenseModal
        open={expenseModal}
        setExpenseModal={setExpenseModal}
        closeModal={() => setExpenseModal(false)}
      />
    </div>
  );
}

export default PointOfSale;
