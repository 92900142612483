import * as React from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Colors } from "../Constant/Color";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState,useEffect } from "react";
import { RingLoader } from "react-spinners";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  overflow: "auto",
  maxHeight: "80vh",
  transform: "translate(-50%, -50%)",
  width: { lg: "40%", md: "35%", sm: "50%", xs: "95%" },
  borderRadius: "10px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

export default function EditEmployeeDetailsModal({
  employeeDetails,
  open,
  heading,
  headingTextStyle,
  containerStyle,
  closeModal,
  buttonText,
  buttonContainerStyle,
  buttonStyle2,
  confirm,
  loading
}) {
  const [details, setDetails] = useState({
    fullName: "",
    emailAddress: "",
    employeeId : "",
    password : "",
  });

  useEffect(() => {


    setDetails({
      fullName : employeeDetails?.full_name,
      emailAddress : employeeDetails?.email_address,
      employeeId : employeeDetails?.employee_id,
      password : ""
    })

  }, [open]);


  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[style, { containerStyle }]}>
          <Box
            onClick={() => closeModal()}
            style={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon fontSize="large" />
          </Box>

          <Typography
            sx={{
              fontSize: "20px",
              color: Colors.button,
              fontWeight: "bold",
              fontFamily: "Poppins",
              textAlign: "center",
              ...headingTextStyle,
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {heading}
          </Typography>

          <Grid
            container
            xs={12}
            md={12}
            xl={12}
            lg={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Grid item xs={12} md={5.8}>
              <Typography
                style={{
                  color: Colors.text,
                  fontSize: 14,
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  textAlign: "left",
                }}
              >
                Full Name
              </Typography>
              <Box
                sx={{
                  border: `1px solid ${Colors.border}`,
                  padding: "5px 5px 5px 5px",
                  borderRadius: "5px",
                  fontSize: "10px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 0.5,
                }}
              >
                <TextField
                  variant="standard" // <== changed this
                  margin="normal"
                  required
                  value={details.fullName}
                  onChange={(e) =>
                    setDetails({ ...details, fullName: e.target.value })
                  }
                  type="text"
                  fullWidth
                  sx={{
                    width: "100%",
                    fontSize: "10px",
                    padding: 0,
                    margin: 0,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    marginLeft: "10px",
                  }}
                  placeholder="Enter Full Name..."
                  InputProps={{
                    // startAdornment: <AccountCircle />, // <== adjusted this
                    disableUnderline: true, // <== added this
                    // fontSize: "2px"
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={5.8}>
              <Typography
                style={{
                  color: Colors.text,
                  fontSize: 14,
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  textAlign: "left",
                }}
              >
                Email Address
              </Typography>
              <Box
                sx={{
                  border: `1px solid ${Colors.border}`,
                  padding: "5px 5px 5px 5px",
                  borderRadius: "5px",
                  fontSize: "10px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 0.5,
                }}
              >
                <TextField
                  variant="standard" // <== changed this
                  margin="normal"
                  required
                  value={details.emailAddress}
                  onChange={(e) =>
                    setDetails({ ...details, emailAddress: e.target.value })
                  }
                  type="text"
                  fullWidth
                  sx={{
                    width: "100%",
                    fontSize: "10px",
                    padding: 0,
                    margin: 0,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    marginLeft: "10px",
                  }}
                  placeholder="Enter Email Address..."
                  InputProps={{
                    // startAdornment: <AccountCircle />, // <== adjusted this
                    disableUnderline: true, // <== added this
                    // fontSize: "2px"
                  }}
                />
              </Box>
            </Grid>



            <Grid item xs={12} md={5.8}>
              <Typography
                style={{
                  color: Colors.text,
                  fontSize: 14,
                  marginTop:10,
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  textAlign: "left",
                }}
              >
                Employee Id
              </Typography>
              <Box
                sx={{
                  border: `1px solid ${Colors.border}`,
                  padding: "5px 5px 5px 5px",
                  borderRadius: "5px",
                  fontSize: "10px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 0.5,
                }}
              >
                <TextField
                  variant="standard" // <== changed this
                  margin="normal"
                  required
                  value={details.employeeId}
                  onChange={(e) =>
                    setDetails({ ...details, employeeId: e.target.value })
                  }
                  type="text"
                  fullWidth
                  sx={{
                    width: "100%",
                    fontSize: "10px",
                    padding: 0,
                    margin: 0,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    marginLeft: "10px",
                  }}
                  placeholder="Enter EmployeeId..."
                  InputProps={{
                    // startAdornment: <AccountCircle />, // <== adjusted this
                    disableUnderline: true, // <== added this
                    // fontSize: "2px"
                  }}
                />
              </Box>
            </Grid>

            
            <Grid item xs={12} md={5.8}>
              <Typography
                style={{
                  color: Colors.text,
                  fontSize: 14,
                  marginTop:10,
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  textAlign: "left",
                }}
              >
                Password
              </Typography>
              <Box
                sx={{
                  border: `1px solid ${Colors.border}`,
                  padding: "5px 5px 5px 5px",
                  borderRadius: "5px",
                  fontSize: "10px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 0.5,
                }}
              >
                <TextField
                  variant="standard" // <== changed this
                  margin="normal"
                  type = "password"
                  value={details.password}
                  onChange={(e) =>
                    setDetails({ ...details, password: e.target.value })
                  }
                  fullWidth
                  sx={{
                    width: "100%",
                    fontSize: "10px",
                    padding: 0,
                    margin: 0,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    marginLeft: "10px",
                  }}
                  placeholder="Enter Password..."
                  InputProps={{
                    disableUnderline: true, 

                  }}
                />
              </Box>
            </Grid>


          </Grid>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              mt:2,
              alignItems: "center",
              justifyContent: "space-between",
              ...buttonContainerStyle,
            }}
          >
            <Button
              onClick={() => confirm(details)}
              sx={{
                backgroundColor: Colors.button,
                color: Colors.white,
                // border: `1px solid ${Colors.red}`,
                borderRadius: "10px",
                width: "100%",
                mt:2,
                fontFamily: "Poppins",
                "&:hover": {
                  color: Colors.button, // Set your desired hover color
                },
                ...buttonStyle2,
              }}
            >
              {loading ? (
                <RingLoader loading={loading} size={30} />
              ) : (
                buttonText ? buttonText : "Save"
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
